import { FieldValidation } from '@faslh/isomorphic';

export class TableField {
  constructor(
    public readonly id: string,
    data: Omit<TableField, 'id'>,
  ) {
    this.tableId = data.tableId;
    this.details = data.details;
    this.validations = data.validations ?? [];

    this.displayName = data.displayName;
    this.localizable = data.localizable ?? false;
    this.sourceId = data.sourceId;

    // this.documentation = data.documentation; // uncomment when documentation is implemented
  }

  sourceId!: string;
  displayName!: string;
  tableId!: string;
  details!: Record<string, any>;
  validations: FieldValidation[] = [];
  // FIXME: Extension related data shouldn't be here
  localizable?: boolean;
  // documentation!: FieldDocumentation; // uncomment when documentation is implemented
}
