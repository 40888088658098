import { createMap } from '@automapper/core';

import {
  ApiEndpointSetData,
  RepositorySetData,
  SecretSetData,
} from '@faslh/api/settings/domain';
import { mapper } from '@faslh/isomorphic';

import { SetApiEndpointCommand } from './lib/set_api_endpoint_settings.command';
import { SetRepositoryCommand } from './lib/set_repository.command';
import { SetSecretCommand } from './lib/set_secret.command';

createMap(mapper, SetRepositoryCommand, RepositorySetData);

createMap(mapper, SetApiEndpointCommand, ApiEndpointSetData);

createMap(mapper, SetSecretCommand, SecretSetData);
