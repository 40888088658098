import {
  GenericQueryCondition,
  QueryBuilder,
  QueryColumn,
  Visitor,
  toAst,
} from '@january/compiler/transpilers';
import { camelcase } from 'stringcase';

import { pascalcase } from '@faslh/utils';

export class TypeOrmVisitor extends Visitor<string | null> {
  constructor(
    private _rootExpr: QueryBuilder.QuerySelectExpr,
    private tableName: string,
    private qbVar = 'qb',
  ) {
    super();
  }

  private _wrapInIfStatement(condition: any, statement: string) {
    return `if(${condition}) {
      ${statement}
    }`;
  }

  private _wrapBrackets(statement: string) {
    // https://github.com/typeorm/typeorm/issues/6170#issuecomment-832790446
    return `new Brackets(qb => {${statement}})`;
    // return statement
  }

  private _addSelect(columns: QueryColumn[]) {
    // use it to group by non-aggregated columns
    const aggregateFound = columns.some((column) => column.aggregator);

    const groupbyCols = columns
      .filter((column) => !column.aggregator)
      .map((column) => `'${this.tableName}.${column.name}'`);

    const selectColumns = columns
      .map((column) => {
        if (column.aggregator) {
          /**
           * we are using scape character because of having sql aggregators inside string
           * the generated Code will be like this:
           * "qb.addSelect('avg(\'Transactions.sales\') as avgSales');"
           */
          return `'${column.aggregator}(\"${this.tableName}.${
            column.name
          }\") as ${column.alias ?? column.name}'`;
        } else if (column.name.includes('.')) {
          return `'${column.name} as ${
            column.alias ?? column.name.split('.')[1]
          }'`;
        }
        return `'${this.tableName}.${column.name}'`;
      })
      .join(', ');

    return `${this.qbVar}.addSelect(${selectColumns});${
      aggregateFound && groupbyCols.length
        ? `${this.qbVar}.addGroupBy(${groupbyCols.join(', ')});`
        : ''
    }`;
  }

  private _addJoins(joinsFields: string[]) {
    return joinsFields
      .map((join) => {
        return `${this.qbVar}.innerJoin('${
          this.tableName
        }.${join.toLowerCase()}', '${join}');`;
      })
      .join(';');
  }

  public _visitBetween(
    identifier: QueryBuilder.Identifier,
    expr: QueryBuilder.BinaryExpression<
      QueryBuilder.Identifier,
      QueryBuilder.Identifier
    >,
    context: any,
  ) {
    const left = expr.left.accept(this, {
      format: (value: string) => {
        return `:${value}`;
      },
    });
    const right = expr.right.accept(this, {
      format: (value: string) => {
        return `:${value}`;
      },
    });
    const statement = `${identifier.accept(this, {
      format: (value: string) => {
        const [tableNameOrFieldName, maybeFieldName] = value.split('.');
        if (!maybeFieldName) {
          return `${this.tableName}.${value}`;
        }
        return `${tableNameOrFieldName}.${maybeFieldName}`;
      },
    })} BETWEEN :min AND :max`;

    const parameters = `{ min: ${left}, max: ${right} }`;

    const query = `${this.qbVar}.${context.combinator}Where('${statement}', ${parameters})`;

    return query;
  }

  public visitDateLiteralExpr(
    expr: QueryBuilder.Literal<'date'>,
    context: any,
  ): any {
    // Why not use StringLiteral instead?
    return `'${expr.value}'`;
  }

  public visitBinaryExpr(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: any,
  ): any {
    switch (expr.operator) {
      case 'between':
        return this._visitBetween(expr.left, expr.right, context);
      case 'like':
        return this._visitLike(expr, {
          ...context,
          required: context.required ?? false,
          operator: context.inverse ? 'NOT LIKE' : 'LIKE',
          prefix: context.prefix ?? '',
          postfix: context.postfix ?? '',
        });
      case 'is':
        return this._visitIs(expr, {
          ...context,
          operator: context.inverse ? 'IS NOT' : 'IS',
        });
      case '===':
        return this._equal(expr, {
          ...context,
          operator: context.inverse ? '!=' : '=',
        });
      case '<':
        return this._equal(expr, {
          ...context,
          operator: context.inverse ? '>' : '<',
        });
      case '>':
        return this._equal(expr, {
          ...context,
          operator: context.inverse ? '<' : '>',
        });
      case '<=':
        return this._equal(expr, {
          ...context,
          operator: context.inverse ? '>=' : '<=',
        });
      case '>=':
        return this._equal(expr, {
          ...context,
          operator: context.inverse ? '<=' : '>=',
        });
      case 'in':
        return this._visitIn(expr, {
          ...context,
          operator: context.inverse ? 'NOT IN' : 'IN',
        });
      default:
        throw new Error(
          `Expression is not supported. operator: ${expr.operator}`,
        );
    }
  }

  private _visitIs(expr: QueryBuilder.BinaryExpression, context: any) {
    const left = this._acceptLeftExpression(expr, context);
    const binding = this._acceptBindings(expr, context);
    const statement = `${left} ${context.operator} ${binding}`;
    const parameters = this._acceptParameters(expr, context);

    const isRequired = context.required === true;
    const query = `${this.qbVar}.${context.combinator}Where('${statement}', ${parameters})`;
    const finalQuery = !isRequired
      ? this._wrapInIfStatement(expr.right.accept(this, {}), query)
      : query;
    return finalQuery;
  }

  private _visitLike(expr: QueryBuilder.BinaryExpression, context: any) {
    const left = this._acceptLeftExpression(expr, context);

    const binding = this._acceptBindings(expr, context);
    const statement = `${left} ${context.operator} ${binding}`;

    const right = expr.right.accept(this, {
      ...context,
    });

    const keyName = expr.left.accept(this, {
      ...context,
      format: (value: string) => {
        const [tableNameOrFieldName, fieldName] = value.split('.');
        if (!fieldName) {
          return `${tableNameOrFieldName}`;
        }
        return `${camelcase(value)}`;
      },
    });

    const rightWithBinding = `${
      context.prefix ? `'${context.prefix}' + ` : ''
    }${right}${context.postfix ? ` + '${context.postfix}'` : ''}`;
    const valueName = expr.right.accept(this, {
      ...context,
    });
    const parameters = `{${keyName}: ${rightWithBinding}}`;
    const isRequired = context.required === true;
    const query = `${this.qbVar}.${context.combinator}Where('${statement}', ${parameters})`;
    const finalQuery = !isRequired
      ? this._wrapInIfStatement(valueName, query)
      : query;

    return finalQuery;
  }

  private _visitIn(expr: QueryBuilder.BinaryExpression, context: any) {
    const left = this._acceptLeftExpression(expr, context);
    const right = expr.right.accept(this, {
      ...context,
    });
    const binding = this._acceptBindings(expr, context);

    const statement = `${left} ${context.operator} ${binding}`;

    const keyName = expr.left.accept(this, {
      ...context,
      format: (value: string) => {
        const [tableName, prop] = value.split('.');
        if (!prop) {
          return `${value}`;
        }
        return `${camelcase(value)}`;
      },
    });
    const parameters = `{ ${keyName}: ${right} }`;

    const valueName = expr.right.accept(this, {
      ...context,
    });

    const isRequired = context.required === true;
    const query = `${this.qbVar}.${context.combinator}Where('${statement}', ${parameters})`;
    const finalQuery = !isRequired
      ? this._wrapInIfStatement(valueName, query)
      : query;

    return finalQuery;
  }

  _acceptLeftExpression(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: any,
  ) {
    return expr.left.accept(this, {
      ...context,
      format: (value: string) => {
        const [tableNameOrFieldName, maybeFieldName] = value.split('.');
        if (!maybeFieldName) {
          return `${this.tableName}.${value}`;
        }
        return `${tableNameOrFieldName}.${maybeFieldName}`;
      },
    });
  }

  _acceptBindings(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: {
      operator: string;
    } & any,
  ) {
    return expr.left.accept(this, {
      ...context,
      format: (value: string) => {
        const [tableName, prop] = value.split('.');
        if (!prop) {
          return `:${value}`;
        }
        return `:${camelcase(value)}`;
      },
    });
  }

  _acceptParameters(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: {
      operator: string;
    } & any,
  ) {
    const valueName = expr.right.accept(this, {
      ...context,
    });

    const keyName = expr.left.accept(this, {
      ...context,
      format: (value: string) => {
        const [tableName, prop] = value.split('.');
        if (!prop) {
          return `${value}`;
        }
        return `${camelcase(value)}`;
      },
    });

    if (valueName === 'WILL_BE_USED_FROM_DESTRUCTURED_INPUT') {
      return `{${keyName}}`;
    }

    return `{ ${keyName}: ${valueName} }`;
  }

  private _equal(
    expr: QueryBuilder.BinaryExpression<any, any>,
    context: {
      operator: string;
    } & any,
  ) {
    // TODO: use template design pattern to override specific steps
    // for instance, _visitLike method uses same logic here with only
    // difference being that right expressions is formatted differently.
    const left = this._acceptLeftExpression(expr, context);

    const binding = this._acceptBindings(expr, context);

    const statement = `${left} ${context.operator} ${binding}`;

    const valueName = expr.right.accept(this, {
      ...context,
    });

    const parameters = this._acceptParameters(expr, context);

    const isRequired = context.required === true;
    const query = `${this.qbVar}.${context.combinator}Where('${statement}', ${parameters})`;
    const finalQuery = !isRequired
      ? this._wrapInIfStatement(
          valueName === 'WILL_BE_USED_FROM_DESTRUCTURED_INPUT'
            ? left
            : valueName,
          query,
        )
      : query;
    return finalQuery;
  }

  public visitNumericLiteralExpr(
    expr: QueryBuilder.Literal<'numeric'>,
  ): string {
    return `${expr.value}`;
  }

  public visitNullLiteralExpr(expr: QueryBuilder.Literal<'null'>): null {
    return null;
  }

  public visitBooleanLiteralExpr(
    expr: QueryBuilder.Literal<'boolean'>,
  ): string {
    return `${expr.value}`;
  }

  public visitStringLiteralExpr(expr: QueryBuilder.Literal<'string'>): string {
    return `'${expr.value}'`;
  }

  public visitIdentifier(
    expr: QueryBuilder.Identifier,
    context: {
      format?: (value: string) => string;
    },
  ): string {
    if (context.format) {
      return context.format(expr.value);
    }
    return expr.value;
  }

  public visitCombinator(expr: QueryBuilder.Combinator, context: any): string {
    return '';
  }

  visitListExpr(expr: QueryBuilder.ListExpr, context: any) {
    const children: string[] = expr.value.map((childExpr) =>
      childExpr.accept(this, context),
    );
    return `[${children.join(',')}]`;
  }

  public visitQuerySelectExpr(
    expr: QueryBuilder.QuerySelectExpr,
    context: any,
  ): any {
    const { columns, joinsFields } = context;
    const addSelectAndJoins =
      columns && columns.length
        ? `${this._addSelect(columns)}${this._addJoins(joinsFields)}`
        : '';

    const query = expr.value.map((group) => group.accept(this)).join(';');

    return `${addSelectAndJoins}${query}`;
  }

  public visitGroupExpr(expr: QueryBuilder.GroupExpr, context: any): any {
    const qb = expr.value.map((childExpr) => {
      return childExpr.accept(this, {
        ...context,
        combinator: expr.combinator.operator,
      });
    });
    if (qb.length > 1) {
      return `${this.qbVar}.${expr.combinator.operator}Where(${this._wrapBrackets(
        qb.join(';'),
      )})`;
    }

    return qb.join(';');
  }

  public execute(): string {
    const result = this._rootExpr.accept(this);
    return result;
  }

  public executeWithQueryBuiler() {
    const queryBuilder = `const ${this.qbVar} = createQueryBuilder(${pascalcase(
      this.tableName,
    )},'${this.tableName}')`;
    const result = this._rootExpr.accept(this);
    return `${queryBuilder};${result}`;
  }
}

export function runTypeormVisitor(props: {
  query: GenericQueryCondition<unknown>;
  tableName: string;
  qbVarName?: string;
}) {
  return new TypeOrmVisitor(
    toAst(props.query) as QueryBuilder.QuerySelectExpr,
    pascalcase(props.tableName),
    props.qbVarName,
  ).execute();
}
