import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { DelocalizeFieldCommand } from '@faslh/api/table/commands';
import { FieldLocalizedData, Table } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(DelocalizeFieldCommand)
export class DelocalizeFieldCommandHandler extends IRequestHandler<
  DelocalizeFieldCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: DelocalizeFieldCommand): Promise<string> {
    const aggregate = await this._eventStore.hyrdateAggregate(
      command.tableId,
      Table,
    );

    if (!aggregate.hasField(command.fieldId)) {
      throw new Error('Field not found');
    }

    aggregate.delocalizeField(
      this._eventUtils.getMetadata(),
      mapper.map(command, DelocalizeFieldCommand, FieldLocalizedData),
    );
    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
