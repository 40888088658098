import { classes } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  createMap,
  createMapper,
} from '@automapper/core';

export const mapper = createMapper({
  strategyInitializer: classes(),
  namingConventions: new CamelCaseNamingConvention(),
});

export function AutoMapHost(): ClassDecorator {
  return function (target) {
    createMap(mapper, target as never, target as never);
  };
}
