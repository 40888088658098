import dedent from 'dedent';
import { Injector } from 'tiny-injector';
import type * as morph from 'ts-morph';
import { Project, VariableDeclarationKind } from 'ts-morph';

import type { TableProjectionNS } from '@faslh/api/table/projection';

import { AdminExtensionNS, DevKit } from '@faslh/compiler/sdk/devkit';
import { WithChildren } from '@faslh/isomorphic';
import { AsyncVisitor, uniquify } from '@faslh/utils';

import {
  BetweenData,
  BetweenQueryCondition,
  ConditionData,
  DefaultQueryCondition,
  GroupQueryCondition,
  QuerySelect,
} from '@january/compiler/transpilers';

import {
  ActionProperty,
  ActionPropertyValidation,
  IncomingActionPropertyDict,
  ParsedInput,
} from './action-property';
import { ProcessTriggerInput } from './routing.contract';

export interface HandleActionInput<T = Record<string, any>> {
  sourceAction: AdminExtensionNS.IAction;
  outputName?: string;
  details: T;
}
export interface HandleActionOutput {
  workflowInputs?: IncomingActionPropertyDict;
  runtimeInputs: IncomingActionPropertyDict;
  transfer?: IncomingActionPropertyDict;
  output?: {
    displayName: string;
  };
}
export type ProcessActionHelpers = {
  useVariable: (name: string, value: string) => void;
  useQb: (qb: (qbString: string) => string) => void;
  resolveAction: (id: string) => ConcreteContracts.MorphStatementWriter;
};

export interface HandleFieldInput {
  sourceField: AdminExtensionNS.ExtensionField;
  details: Record<string, any>;
  displayName: string;
  featureId: string;
  validation: {
    details: Record<string, any>;
    name: string;
    sourceId: string;
  }[];
}

export interface IExtension {
  handleSetup?(
    contract: Record<string, ActionProperty>,
  ): Promise<Contracts.ExtensionSetupContract[]>;
  onFeatureContract?(
    contract: Contracts.FeatureContract,
    addFile: (concrete: ConcreteContracts.WorkflowConcreteStructure) => void,
  ): void;
}
export interface IHandleAction {
  processAction(
    action: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    inputs: Record<string, ActionProperty>,
    transfer: Record<string, ActionProperty>,
  ): Promise<ConcreteContracts.ProcessActionOutput>;
  handleAction?(contract: HandleActionInput): Promise<HandleActionOutput>;
  handleField(input: HandleFieldInput): Promise<Contracts.InputFieldUnion>;
}

export interface WithMyInput {
  extractInputs: (config: {
    namespace: string;
    value: any;
    visit: AsyncVisitor<unknown>;
  }) => Promise<Record<string, string> | null>;
  handleInputV2: (config: {
    namespace: string;
    value: any;
    visit: AsyncVisitor<unknown>;
  }) => Promise<ParsedInput | null>;
  handleInput: (
    input: string,
  ) => (ParsedInput | null) | Promise<ParsedInput | null>;
}

export namespace Contracts {
  export interface QueryConditionContractData extends ConditionData {
    type: string;
    validation: ActionPropertyValidation[];
  }
  export type GroupQueryConditionContract =
    GroupQueryCondition<QueryConditionContractData>;

  export type DefaultQueryConditionContract =
    DefaultQueryCondition<QueryConditionContractData>;

  export type BetweenQueryConditionContract = BetweenQueryCondition<
    BetweenData<QueryConditionContractData>
  >;
  export type QuerySelectConditionContract =
    QuerySelect<GroupQueryConditionContract>;

  export type QueryConditionContract =
    | DefaultQueryConditionContract
    | GroupQueryConditionContract
    | BetweenQueryConditionContract
    | QuerySelect<GroupQueryConditionContract>;

  export type QueryContract = {
    whereBy: QuerySelectConditionContract;
    sortBy: Record<string, ActionProperty>;
    groupBy: ActionProperty[];
  };

  export interface SortByInput {
    id: string;
    input: string;
  }
  export interface GroupByInput {
    id: string;
    input: string;
    // 'count' | 'sum' | 'avg' | 'min' | 'max';
    // '@aggregator:sum(price)';
  }

  function fffffff(input: string) {
    const [namespace, value] = input.split(':');
    return {
      namespace,
      value,
    };
  }

  export interface GenereateFieldContractInput {
    featureId: string;
    sourceId: string;
    displayName: string;
    details: Record<string, any>;
    validation: {
      details: Record<string, unknown>;
      name: string;
      sourceId: string;
    }[];
  }

  export interface Table {
    tableName: string;
    indexes: { columns: string[] }[];
  }

  export interface ExtensionSetupContract {
    filePath: string;
    content: ConcreteContracts.MorphStatementWriter;
  }

  export interface WorkflowActionOutput {
    sourceId: string;
    workflowInputs: Record<string, ActionProperty>;
    inputs: Record<string, ActionProperty>;
    transfer: Record<string, ActionProperty | any>;
    sourceAction: AdminExtensionNS.IAction;
    output: {
      displayName: string;
    };
  }

  export type WorkflowAction = WithChildren<
    WorkflowActionOutput & {
      id: string;
      name: string;
    }
  >;

  export interface Workflow {
    inputName: string;
    schemaName: string;
    displayName: string;
    featureName: string;
    tag: string;

    actions: WorkflowAction[];

    trigger: ProcessTriggerInput;
    /**
     * Workflow input (dto inputs)
     */
    inputs: Record<string, ActionProperty>;
    output: {
      properties: ActionProperty[];
      returnCode: string;
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface InputField {
    validations: ActionPropertyValidation[];
  }
  export interface Field extends InputField {
    primitiveType: string;
    displayName: string;
  }

  export interface InputSystemField extends InputField {
    nativeType: 'createdAt' | 'updatedAt' | 'deletedAt';
    mandatory: boolean;
    primitiveType: string;
  }
  export interface SystemField extends Field, InputSystemField {}
  export interface InputRelation extends InputField {
    nativeType: 'relation';
    relatedEntityName: string;
    nameOfColumnOnRelatedEntity: string;
    relationship: 'one-to-one' | 'many-to-one' | 'one-to-many' | 'many-to-many';
    joinSide: boolean;
    mandatory: boolean;
    unique: boolean;
    primitiveType: string;
  }
  export interface Relation extends Field, InputRelation {}
  export interface InputRelationId extends InputField {
    nativeType: 'relation-id';
    primitiveType: string;
    virtualRelationField: boolean;
    columnNameOnSelfTable: string;
    tableName: string;
    mandatory: boolean;
    unique: boolean;
  }
  export interface RelationId extends Field, InputRelationId {}
  export interface InputTextField extends InputField {
    nativeType: 'varchar';
    length?: number;
    mandatory: boolean;
    unique: boolean;
  }
  export interface TextField extends Field, InputTextField {}
  export interface InputDecimalField extends InputField {
    nativeType: 'decimal';
    precision: number;
    scale: number;
    mandatory: boolean;
    unique: boolean;
  }
  export interface DecimalField extends Field, InputDecimalField {}
  export interface InputDatetimeField extends InputField {
    nativeType: 'datetime';
    zone: 'timestamp' | 'timestamptz';
    mandatory: boolean;
    unique: boolean;
    primitiveType: string;
  }
  export interface DatetimeField extends Field, InputDatetimeField {}
  export interface InputTimeField extends InputField {
    nativeType: 'time';
    zone: 'time' | 'timetz';
    mandatory: boolean;
    unique: boolean;
  }
  export interface TimeField extends InputTimeField, Field {}
  export interface InputPrimaryField extends InputField {
    keyType: string;
    nativeType: 'primary-key';
    generated: boolean;
  }
  export interface PrimaryField extends InputPrimaryField, Field {}
  export interface InputBooleanField extends InputField {
    nativeType: 'boolean';
    mandatory: boolean;
    unique: boolean;
  }
  export interface BooleanField extends Field, InputBooleanField {}
  export interface InputIntegerField extends InputField {
    nativeType: 'integer';
    mandatory: boolean;
    unique: boolean;
  }
  export interface IntegerField extends InputIntegerField, Field {}
  export interface InputEnumField extends InputField {
    nativeType: 'enum';
    mandatory: boolean;
    unique: boolean;
    values: string[];
  }
  export interface EnumField extends InputEnumField, Field {}
  export interface InputJsonField extends InputField {
    nativeType: 'json';
    mandatory: boolean;
  }
  export interface JsonField extends InputJsonField, Field {}
  export interface InputUUIDField extends InputField {
    nativeType: 'uuid';
    mandatory: boolean;
    unique: boolean;
  }
  export interface UUIDField extends InputUUIDField, Field {}
  export interface InputFileField extends InputField {
    nativeType: 'varchar';
    mandatory: boolean;
    unique: boolean;
  }
  export interface FileField extends InputFileField, Field {}

  export type FieldUnion =
    | SystemField
    | TimeField
    | DatetimeField
    | TextField
    | DecimalField
    | Relation
    | RelationId
    | PrimaryField
    | BooleanField
    | JsonField
    | IntegerField
    | EnumField
    | UUIDField;
  export type InputFieldUnion =
    | InputSystemField
    | InputTimeField
    | InputDatetimeField
    | InputTextField
    | InputDecimalField
    | InputRelation
    | InputRelationId
    | InputPrimaryField
    | InputBooleanField
    | InputJsonField
    | InputIntegerField
    | InputEnumField
    | InputUUIDField;

  export interface TableContract {
    tableName: string;
    fields: Contracts.Field[];
    indexes: { columns: string[] }[];
  }
  export interface FeatureContract {
    tags: string[];
    displayName: string;
    workflows: Contracts.Workflow[];
    tables: TableContract[];
  }
  export interface Policy {
    displayName: string;
    rule: string;
  }

  export interface FaslhContracts {
    features: FeatureContract[];
    policies: Policy[];
  }
}

export namespace ConcreteContracts {
  export type MorphStatementWriter = (
    | string
    | morph.WriterFunction
    | morph.StatementStructures
  )[];
  export type WorkflowStructure =
    | MorphStatementWriter
    | {
        // FIXME: this been added to support adding imports
        // to the top of the file but it turns out
        // we can just return array of structures
        // one with imports
        // and the other with the rest of the code
        /**
         * @deprecated return array of arrays of structures
         */
        topLevelStructure: MorphStatementWriter;
        /**
         * @deprecated return array of arrays of structures
         */
        actionStructure: MorphStatementWriter;
      };
  export interface ProcessActionOutput {
    structure: ConcreteContracts.WorkflowStructure;
    inline: boolean;
  }

  export interface WorkflowConcreteStructure {
    filePath: string;
    structure: WorkflowStructure;
  }

  // FIXME: why not to use WorkflowConcreteStructure?
  interface TableConcreteStructure {
    tableName: string;
    entityStructure: MorphStatementWriter;
    fields: {
      fieldStructure: morph.PropertyDeclarationStructure;
      topLevelStructure: MorphStatementWriter;
      tableName: string;
    }[];
  }

  interface FeatureConcreteStructure {
    displayName: string;
    structures: WorkflowConcreteStructure[];
    workflows: WorkflowConcreteStructure[][];
    tables: TableConcreteStructure[];
    // queries: QueryConcreteStructure[];
  }

  export interface ConcreteStructure {
    features: FeatureConcreteStructure[];
    policies: WorkflowConcreteStructure[][];
    setup: Contracts.ExtensionSetupContract[];
  }
}

export function makeSchema(property: ActionProperty) {
  const result: any = {};
  (property.validations ?? [])
    .filter((validation) => validation.type !== 'unique')
    .map((validation) => {
      const validationType = validation.type;
      if (!validationType) {
        throw new Error(`Validation ${validation.id} not found.`);
      }
      if (validation.details['message']) {
        result['errorMessage'] = validation.details['message'];
      }

      switch (validationType) {
        case 'mandatory':
          result['required'] = true;
          if (property.type === 'string') {
            result['minLength'] ??= 1; // it might already been set by minlength validation
          }
          break;
        case 'string':
          // result.type = 'string';
          break; // FIXME: remove string validation and depends on the primitive type
        case 'number':
          result['type'] = 'number';
          break;
        case 'oneof':
          result['type'] = 'string';
          result['enum'] = validation.details['value'];
          break;
        case 'email':
          result['type'] = 'string';
          result['format'] = 'email';
          result['isEmail'] = [];
          break;
        case 'minlength':
          result['type'] = 'string';
          result['minLength'] = validation.details['value'];
          break;
        case 'maxlength':
          result.type = 'string';
          result.maxLength = validation.details['value'];
          break;
        case 'min':
          result.minLength = undefined;
          result.type = 'number';
          result.minimum = validation.details['value'];
          break;
        case 'max':
          result.type = 'number';
          result.maximum = validation.details['value'];
          break;
        case 'pattern':
          result.type = 'string';
          result.regex = validation.details['value'];
          break;
        case 'url':
          result.type = 'string';
          result.format = 'uri';
          result['isURL'] = [];
          break;
        case 'uuid':
          result.type = 'string';
          result.format = 'uuid';
          break;
        case 'startswith':
          result.type = 'string';
          result.pattern = `^${validation.details['value']}`;
          break;
        case 'endswith':
          result.type = 'string';
          result.pattern = `${validation.details['value']}$`;
          break;
        case 'contains':
          result.type = 'string';
          result.pattern = validation.details['value'];
          break;
        case 'before':
          // TODO: should the format be date-time or date or time? I guess based on the field type it should be decided
          result.type = 'string';
          result['isBefore'] = [validation.details['value']];
          break;
        case 'after':
          // TODO: should the format be date-time or date or time? I guess based on the field type it should be decided
          result.type = 'string';
          result['isAfter'] = [validation.details['value']];
          break;
        case 'boolean':
          result.type = 'boolean';
          break;
        case 'date':
          result.type = 'string';
          result.format = 'date';
          break;
        case 'datetime':
          result.type = 'string';
          result.format = validation.details['value'];
          break;
        case 'time':
          result.type = 'string';
          result.format = validation.details['value'];
          break;
        case 'tel':
          result['isMobilePhone'] = ['any'];
          break;
        case 'longitude':
        case 'latitude':
          result.type = 'string';
          result['isLatLong'] = [];
          break;
        case 'ip':
          result.type = 'string';
          result.format = validation.details['value'];
          break;
        case 'decimal':
          result.type = 'string';
          result.format = 'double';
          result.multipleOf = 1 / Math.pow(10, +validation.details['value']);
          break;
        default:
          throw new Error(`Validation ${validationType} not supported.`);
      }
    });
  return result;
}

export function isActionProperty(
  property: ActionProperty | any,
): property is ActionProperty {
  return property.name && property.namespace;
}

export interface SwitchCase {
  condition: string;
  logic: any;
}

export function createSwitch(
  cases: SwitchCase[],
  fallback?: SwitchCase,
): ConcreteContracts.MorphStatementWriter {
  const project = new Project({
    useInMemoryFileSystem: true,
  });
  const sourceFile = project.createSourceFile('index.ts');

  const fn = sourceFile.addFunction({
    name: 'myFunction',
    parameters: [{ name: 'value', type: 'string' }],
    returnType: 'void',
    isExported: true,
  });

  const [switchStmt] = fn.addStatements((writer) =>
    writer.write(
      dedent(`switch (true) {
        ${cases.map((it) => `case ${it.condition}:`).join('\n')}
        ${fallback ? 'default:' : ''}
        }`),
    ),
  ) as [morph.SwitchStatement];

  const clauses = switchStmt.getClauses();

  for (let index = 0; index < cases.length; index++) {
    clauses[index].addStatements(
      Array.isArray(cases[index].logic)
        ? [...cases[index].logic, 'break;']
        : [cases[index].logic, 'break;'],
    );
  }
  if (fallback && fallback.logic) {
    clauses
      .at(-1)
      ?.addStatements(
        Array.isArray(fallback.logic)
          ? [...fallback.logic, 'break;']
          : [fallback.logic, 'break;'],
      );
  }
  return [switchStmt.getFullText()];
}

export function createIfElse(defaultCase: SwitchCase, fallback?: SwitchCase) {
  const project = new Project({
    useInMemoryFileSystem: true,
  });
  const sourceFile = project.createSourceFile('index.ts');

  const fn = sourceFile.addFunction({
    name: 'myFunction',
    parameters: [{ name: 'value', type: 'string' }],
    returnType: 'void',
    isExported: true,
  });

  const [ifStmt] = fn.addStatements((writer) =>
    writer.write(
      dedent(`
        if(${defaultCase.condition}) {}
        ${fallback ? 'else {}' : ''}
      `),
    ),
  ) as [morph.IfStatement];
  (ifStmt.getThenStatement() as morph.Block).addStatements(defaultCase.logic);
  if (fallback && fallback.logic) {
    (ifStmt.getElseStatement() as morph.Block).addStatements(fallback.logic);
  }
  return [ifStmt.getFullText()];
}

export function createArrowFn(
  name: string,
  params: morph.ParameterDeclarationStructure[],
  body: ConcreteContracts.MorphStatementWriter[],
) {
  // FIXME: don't use morph here because it can take up huge amount of memory
  // figure out a way to return this as structure.
  const project = new Project({
    useInMemoryFileSystem: true,
  });
  const sourceFile = project.createSourceFile('index.ts');

  const varFnStm = sourceFile.addVariableStatement({
    declarationKind: VariableDeclarationKind.Const,
    declarations: [
      {
        name: name,
        initializer: 'async () => {}',
      },
    ],
  });

  const [declaration] = varFnStm.getDeclarations();
  const arrowFn = declaration.getInitializerOrThrow() as morph.ArrowFunction;
  for (const stmt of body) {
    arrowFn.addStatements(stmt);
    arrowFn.addParameters(params);
  }
  return varFnStm.getFullText();
}

export function useInput(parsedInput: ParsedInput) {
  if (!parsedInput) {
    return undefined;
  }
  return parsedInput.data?.['parameterName'] ?? parsedInput.value;
}

export async function generateValidationContract(
  validations: TableProjectionNS.FieldValidationVM[],
): Promise<ActionPropertyValidation[]> {
  const result: ActionPropertyValidation[] = [];
  const devKit = Injector.GetRequiredService(DevKit);
  for (const validation of validations) {
    const sourceValidation = await devKit.getValidationById(
      validation.sourceId,
    );
    result.push({
      id: validation.sourceId,
      details: validation.details,
      name: sourceValidation.name,
      type: sourceValidation.type,
    });
  }
  return result;
}
export function nonStatic<T extends { static?: boolean }>(
  inputs: Record<string, T>,
) {
  return Object.entries(inputs).filter(([name, prop]) => !prop.static);
}
export function signutureInputs<
  T extends {
    static?: boolean;
    data?: Record<string, any>;
  },
>(
  inputs: Record<string, T>,
  predicate: (input: [string, T]) => boolean = ([, prop]) =>
    !prop.data?.['local'],
) {
  const unique = uniquify(
    nonStatic(inputs).filter(([name, prop]) => predicate([name, prop])),
    ([name, prop]) => paramName([name, prop]),
  );
  return unique.map(([name, prop]) => {
    return [paramName([name, prop] as const), prop] as const;
  });
}
export function paramName<
  T extends {
    data?: Record<string, any>;
  },
>([name, prop]: [string, T]) {
  return (prop.data?.['parameterName'] as string) || name;
}
