import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, Matches } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class TagAddedData extends DomainEventData {
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;
}

export const TagAdded = createEvent<TagAddedData, 'tag_added'>('tag_added');
export type TagAdded = InstanceType<typeof TagAdded>;