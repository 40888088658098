import { groupBy } from 'lodash';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';

import {
  CLAIMS_TOKEN,
  type Claims,
  EventStore,
  ProjectionStore,
  Projector,
} from '@faslh/api/infrastructure/database';
import {
  ChangesCommitted,
  Commit,
  CommitCompleted,
  CommitFailed,
  CommitPipelineStatusSet,
  CommitPushed,
  CommitRebuilt,
  InitialCommit,
} from '@faslh/api/releases/models';

import { CommitProjection, commitProjection } from './commit.projection';

type Events =
  | CommitPipelineStatusSet
  | InitialCommit
  | ChangesCommitted
  | CommitRebuilt
  | CommitCompleted
  | CommitFailed
  | CommitPushed;

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class CommitProjector extends Projector<CommitProjection, Events> {
  protected override projectionName = 'commits';

  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    @Inject(CLAIMS_TOKEN) private _claims: Claims,
  ) {
    super();
  }
  protected override async apply(
    state: CommitProjection,
    event: Events,
  ): Promise<CommitProjection> {
    switch (event.eventType) {
      case 'initial_commit':
        state.id = event.entityId;
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.status = 'completed';
        state.conclusion = 'neutral';
        state.stage.push('generate');
        state.message = event.data.message;
        return state;
      case 'changes_committed':
        state.id = event.entityId;
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.status = 'queued';
        state.conclusion = null;
        state.stage.push('generate', 'push_changes');
        state.message = event.data.message;
        return state;
      case 'commit_completed':
        state.updatedAt = event.timestamp;
        state.status = 'completed';
        state.conclusion = event.data.conclusion;
        return state;
      case 'commit_pushed':
        state.updatedAt = event.timestamp;
        state.status = 'in_progress';
        state.commitSha = event.data.commitSha;
        state.stage.push('push_changes');
        return state;
      case 'commit_failed':
        state.updatedAt = event.timestamp;
        state.status = 'completed';
        state.conclusion = 'failure';
        return state;
      case 'commit_rebuilt':
        state.updatedAt = event.timestamp;
        state.status = 'queued';
        state.conclusion = null;
        state.stage.push('generate');
        return state;
      case 'commit_pipeline_status_set':
        state.updatedAt = event.timestamp;
        state.stage.push(event.data.stage);
        return state;

      default:
        return state;
    }
  }

  public getLastCommit(): Promise<CommitProjection> {
    return this._projectionStore
      .listProjections<CommitProjection>(this.projectionName)
      .then((list) => list[0]);
  }

  public async listCommits() {
    const events = await this._eventStore.getEventsForStreamName(Commit, {
      'metadata.projectId': this._claims.projectId,
    });
    const aggregateIdToEvents = groupBy(events, 'aggregateId');
    const projections: CommitProjection[] = [];
    for (const [aggregateId, events] of Object.entries(aggregateIdToEvents)) {
      const projection = await this.foldUp(commitProjection(), events);
      projections.push(projection);
    }
    return projections;
  }
}
