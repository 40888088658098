import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class PolicyAddedData extends DomainEventData {
  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public displayName!: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public rule!: string;
}

export const PolicyAdded = createEvent<PolicyAddedData, 'policy_added'>(
  'policy_added',
);
export type PolicyAdded = InstanceType<typeof PolicyAdded>;