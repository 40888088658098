import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost, type WorkflowRunConclusion } from '@faslh/isomorphic';

@AutoMapHost()
export class CommitCompletedData extends DomainEventData {
  @IsString()
  @IsNotEmpty()
  @AutoMap(() => String)
  public conclusion!: WorkflowRunConclusion;
}

export const CommitCompleted = createEvent<
  CommitCompletedData,
  'commit_completed'
>('commit_completed');
export type CommitCompleted = InstanceType<typeof CommitCompleted>;
