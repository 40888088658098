import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowAddedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  displayName!: string;

  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap(() => Object)
  sourceId!: string;

  @AutoMap(() => Object)
  details!: Record<string, any>;
}

export const WorkflowAdded = createEvent<WorkflowAddedData, 'workflow_added'>(
  'workflow_added',
);
export type WorkflowAdded = InstanceType<typeof WorkflowAdded>;
