import * as table from './vm/table.vm';
import * as tableField from './vm/table_field.vm';
import * as tableQuery from './vm/table_query.vm';
import * as tableWorkflow from './vm/table_workflow.vm';

export * from './changes.projection';
export * from './changes.projector';
// export * from './handlers/changes.handler';
export * from './changes.repo';

export namespace ChangesProjectionNS {
  export type TableVM = table.TableVM;
  export type QueryVM = tableQuery.QueryVM;
  export type IWorkflowActionVM = tableWorkflow.IWorkflowActionVM;
  export type IWorkflowTriggerVM = tableWorkflow.IWorkflowTriggerVM;
  export type IWorkflowVM = tableWorkflow.WorkflowVM;
  export type TableFieldVM = tableField.TableFieldVM;
  export type QueryConditionVM = tableQuery.QueryConditionVM;
  export type GroupQueryConditionVM = tableQuery.GroupQueryConditionVM;
}
