import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, Matches } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';

export class FeatureDeletedData extends DomainEventData {
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;
}

export const FeatureDeleted = createEvent<
  FeatureDeletedData,
  'feature_deleted'
>('feature_deleted');
export type FeatureDeleted = InstanceType<typeof FeatureDeleted>;
