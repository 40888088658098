import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class SetInitialSettingsCommand extends IRequest {
  @AutoMap()
  @IsString()
  public repositoryName!: string;
}
