import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class SetApiEndpointCommand extends IRequest {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  public apiEndpoint!: string;
}
