import './setup';

import { VirtualFiles } from '@january/generator';
import { toCommands } from '@january/sdk';
import type { EmptyProject } from '@january/sdk/declarative';
import type { Database, Sqlite3Static } from '@sqlite.org/sqlite-wasm';
import sqlite3InitModule from '@sqlite.org/sqlite-wasm';
import dedent from 'dedent';
import type { Context, ServiceType } from 'tiny-injector';
import { Injector } from 'tiny-injector';

import type { ChangesProjection } from '@faslh/api/releases/projection';
import { ChangesProjector } from '@faslh/api/releases/projection';

import type { Claims } from '@faslh/api/infrastructure/database';
import { SQLITE_TOKEN } from '@faslh/api/infrastructure/database';
import { AddPolicyCommand } from '@faslh/api/policies/commands';
import {
  AddActionToWorkflowCommand,
  AddFeatureCommand,
  AddFieldCommand,
  AddTableCommand,
  AddTagCommand,
  AddWorkflowCommand,
  AdjustFieldValidationCommand,
  AssignPolicyToWorkflowCommand,
  AssignTagToWorkflowCommand,
  CreateTableIndexCommand,
  DeleteFeatureCommand,
  PatchWorkflowOutputDetailsCommand,
} from '@faslh/api/table/commands';
import { InstallExtensionCommand } from '@faslh/api/user-extensions/commands';
import type { Command } from '@faslh/isomorphic';
import { mapper } from '@faslh/isomorphic';
import type { IRequest } from '@faslh/tiny-mediatr';
import { Mediator } from '@faslh/tiny-mediatr';
import { logMe } from '@faslh/utils';

Object.defineProperty(self, 'process', {
  value: {
    cwd: () => '/',
  },
});

let sqlite3: Sqlite3Static;

const sqlite$ = sqlite3InitModule({
  // print: console.log,
  print: () => {
    //
  },
  printErr: console.error,
  locateFile: (file) => {
    return 'https://cdn.jsdelivr.net/npm/@sqlite.org/sqlite-wasm@3.46.0-build2/sqlite-wasm/jswasm/sqlite3.wasm';
  },
}).then((sqlite3Module) => {
  sqlite3 = sqlite3Module;
  return sqlite3Module;
});

Injector.AddScoped(SQLITE_TOKEN, () => {
  const db = new sqlite3.oo1.DB('/mydb.sqlite3', 'cw');
  return {
    transaction: (operations) => {
      db.transaction((txn) => {
        for (const [operation, data] of operations) {
          operation(txn);
        }
      });
    },
    prepare: (op, client = db) => {
      client
        .prepare(op.sql)
        .bind(op.params as number[])
        .stepFinalize();
    },
    query: (op, client = db) => {
      return client.selectObjects(op.sql, op.params as number[]);
    },
  };
});
const commandsMap: Record<string, ServiceType<IRequest<unknown>>> = {
  InstallExtension: InstallExtensionCommand,
  AddFeature: AddFeatureCommand,
  AddTable: AddTableCommand,
  AddField: AddFieldCommand,
  AddTag: AddTagCommand,
  AddWorkflow: AddWorkflowCommand,
  AddActionToWorkflow: AddActionToWorkflowCommand,
  AssignTagToWorkflow: AssignTagToWorkflowCommand,
  PatchWorkflowOutputDetails: PatchWorkflowOutputDetailsCommand,
  AdjustFieldValidation: AdjustFieldValidationCommand,
  CreateTableIndex: CreateTableIndexCommand,
  DeleteFeature: DeleteFeatureCommand,
  AddPolicy: AddPolicyCommand,
  AssignPolicyToWorkflow: AssignPolicyToWorkflowCommand,
};

const prepare$ = async (db: Database) => {
  db.prepare(
    'CREATE TABLE IF NOT EXISTS keyvalue (key TEXT PRIMARY KEY, value TEXT);',
  ).stepFinalize();

  db.prepare(
    dedent`CREATE TABLE IF NOT EXISTS Events (
      id TEXT PRIMARY KEY,
  aggregateVersion INTEGER,
  timestamp DATETIME DEFAULT CURRENT_TIMESTAMP,
  eventType TEXT,
  aggregateId TEXT,
  streamName TEXT,
  entityId TEXT,
  metadata TEXT,
  data TEXT
    );`,
  ).stepFinalize();

  db.prepare(
    dedent`CREATE TABLE IF NOT EXISTS Projections (
      id TEXT PRIMARY KEY,
      data TEXT,
      prefix TEXT,
      timestamp DATETIME DEFAULT CURRENT_TIMESTAMP
    )`,
  ).stepFinalize();
};

async function loadStore(
  context: Context,
  commands: Command<Record<string, unknown>>[],
  claims: Claims,
) {
  context.setExtra('claims', claims);

  const mediator = Injector.GetRequiredService(Mediator, context);

  const failed: {
    error: Error;
    command: Command<Record<string, unknown>>;
  }[] = [];

  await Promise.all(
    commands.map((command) =>
      mediator
        .send(mapper.map(command.payload, commandsMap[command.command] as any))
        .catch((error) => {
          failed.push({ command, error });
        }),
    ),
  );

  if (failed.length > 0) {
    logMe({ 'Retrying failed commands': failed });
    await Promise.all(
      failed.map(({ command }) =>
        mediator.send(
          mapper.map(command.payload, commandsMap[command.command] as any),
        ),
      ),
    );
    logMe({ 'Failed commands': failed });
  }
}

interface Payload {
  projectDefinition: EmptyProject;
  claims: Claims;
  changes: ChangesProjection;
}

async function compile(payload: Payload) {
  return Injector.CreateScope(async (context) => {
    await loadStore(
      context,
      await toCommands(payload.projectDefinition),
      payload.claims,
    );
    const changesProjector = Injector.GetRequiredService(
      ChangesProjector,
      context,
    );
    const virtualFiles = Injector.GetRequiredService(VirtualFiles, context);
    const projectChanges = await changesProjector.build();
    projectChanges.imports = payload.projectDefinition.imports.map(
      (imp) => imp.moduleSpecifier,
    );
    const { save, cleanup, files } =
      await virtualFiles.getSourceCode(projectChanges);
    await save();
    await cleanup();
    return files;
  });
}

self.onmessage = async (message) => {
  try {
    await sqlite$;
    const db = new sqlite3.oo1.DB('/mydb.sqlite3', 'cw');
    await prepare$(db);

    const { type, payload } = message.data;
    switch (type) {
      case 'compile': {
        const result = await compile(payload);
        return self.postMessage({ data: result });
      }
      default:
        throw new Error(`Unknown message type: ${type}`);
    }
  } catch (error) {
    self.postMessage({ error: error });
  }
};
