import { AutoMap } from '@automapper/classes';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class CommitFailedData extends DomainEventData {}
@AutoMapHost()
export class CommitFailed extends DomainEvent<
  CommitFailedData,
  'commit_failed'
> {
  public eventType = 'commit_failed' as const;
  @AutoMap(() => CommitFailedData)
  public data!: CommitFailedData;
}
