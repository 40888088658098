import yaml from 'js-yaml';
import { Injectable, ServiceLifetime } from 'tiny-injector';

import { Contracts, IExtension } from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class FirebaseFunctionsExtension implements IExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
  ) {}

  async handleSetup(
    contract: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeRootPath('.github/workflows/deploy.yml'),
        content: [
          deployContent(
            contract['FIREBASE_FUNCTION_SERVICE_ACCOUNT_KEY'].value,
            contract['FIREBASE_FUNCTION_PROJECT_ID'].value,
          ),
        ],
      },
      {
        filePath: this._projectFS.makeRootPath('firebase.json'),
        content: [
          this._devKit.toJson({
            functions: [
              {
                ignore: ['**/node_modules/**', '**/src/**', '**/public/**'],
                codebase: 'faslh',
                source: 'dist',
                runtime: 'nodejs16',
              },
            ],
          }),
        ],
      },
      {
        filePath: this._projectFS.makeSrcPath('firebase-functions.ts'),
        content: [
          `
		import * as functions from 'firebase-functions';
		import application from './main';
		exports.api = functions.https.onRequest(application.callback());
		exports.hello = functions.https.onRequest((req, res) => {
			res.json({ result: 'Hello from Firebase!' });
		});
		`,
        ],
      },
    ];
  }
}

const deployContent = (serviceAccountKey: string, projectId: string) => {
  const sendStatusStep = (steps: string[]) => ({
    uses: 'fjogeleit/http-request-action@v1',
    if: '${{ always() }}',
    with: {
      url: '${{ env.FASLH_API_URL }}',
      method: 'POST',
      data: JSON.stringify(
        {
          jobName: '${{ github.job }}',
          correlationId: '${{ github.event.inputs.correlationId }}',
          statuses: steps.map((step) => ({
            step,
            status: `\${{ steps.${step}.outcome }}`,
          })),
        },
        null,
        2,
      ),
    },
  });
  return yaml.dump(
    {
      name: 'Deploy To Firebase Functions',
      on: {
        // push: {
        //   branches: ['main'],
        // },
        workflow_dispatch: {
          inputs: {
            correlationId: {
              description: '"Correlation Id"',
              required: true,
            },
          },
        },
      },
      env: {
        FASLH_API_URL:
          'https://us-central1-january-9f554.cloudfunctions.net/ghevents',

        // 'https://2c12-176-29-107-62.eu.ngrok.io/january-9f554/us-central1/ghevents',
      },
      jobs: {
        deploy_to_firebase_functions: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only --legacy-peer-deps --no-audit --no-fund',
            },
            {
              id: 'cache',
              name: 'Cache Or Restore Node Modules',
              uses: 'actions/cache@v3',
              with: {
                path: 'node_modules',
                key: "node-modules-${{ hashFiles('package-lock.json') }}",
              },
            },
            {
              id: 'install_deps',
              name: 'Install Deps',
              run: 'npm install --no-audit --no-fund',
            },
            {
              id: 'build',
              name: 'Build',
              run: 'npm run build:prod -- --entry ./src/firebase-functions.ts',
            },
            {
              id: 'deploy',
              name: 'Deploy to Firebase',
              uses: 'w9jds/firebase-action@master',
              with: {
                args: 'deploy --only functions --debug',
              },
              env: {
                GCP_SA_KEY: `\${{ ${serviceAccountKey} }}`,
                PROJECT_ID: `\${{ ${projectId} }}`,
              },
            },
            sendStatusStep([
              'checkout',
              'install_lockfile',
              'cache',
              'install_deps',
              'build',
              'deploy',
            ]),
          ],
        },
      },
    },
    {
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};

const deployContent_v1 = (
  serviceAccountKey: string,
  projectId: string,
  envionmentVars: string[],
) => {
  const vars = envionmentVars.reduce(
    (acc, curr) => {
      return {
        ...acc,
        ...{
          [`envkey_${curr}`]: `\${{ secrets.${curr} }}`,
        },
      };
    },
    {} as Record<string, any>,
  );
  const sendStatusStep = (steps: string[]) => ({
    uses: 'fjogeleit/http-request-action@v1',
    if: '${{ always() }}',
    with: {
      url: '${{ env.FASLH_API_URL }}',
      method: 'POST',
      data: JSON.stringify(
        {
          jobName: '${{ github.job }}',
          correlationId: '${{ github.event.inputs.correlationId }}',
          statuses: steps.map((step) => ({
            step,
            status: `\${{ steps.${step}.outcome }}`,
          })),
        },
        null,
        2,
      ),
    },
  });
  return yaml.dump(
    {
      name: 'Deploy To Firebase Functions',
      on: {
        // push: {
        //   branches: ['main'],
        // },
        workflow_dispatch: {
          inputs: {
            correlationId: {
              description: '"Correlation Id"',
              required: true,
            },
          },
        },
      },
      env: {
        FASLH_API_URL:
          'https://us-central1-january-9f554.cloudfunctions.net/ghevents',

        // 'https://2c12-176-29-107-62.eu.ngrok.io/january-9f554/us-central1/ghevents',
      },
      jobs: {
        deploy_to_firebase_functions: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only --legacy-peer-deps --no-audit --no-fund',
            },
            {
              id: 'cache',
              name: 'Cache Or Restore Node Modules',
              uses: 'actions/cache@v3',
              with: {
                path: 'node_modules',
                key: "node-modules-${{ hashFiles('package-lock.json') }}",
              },
            },
            {
              id: 'install_deps',
              name: 'Install Deps',
              run: 'npm install --no-audit --no-fund',
            },
            {
              id: 'build',
              name: 'Build',
              run: 'npm run build:prod -- --entry ./src/firebase-functions.ts',
            },
            {
              name: 'Collect Env Variables',
              uses: 'SpicyPizza/create-envfile@v1.3',
              with: {
                ...vars,
                directory: './',
                file_name: './dist/.env',
                fail_on_empty: false,
              },
            },
            {
              id: 'deploy',
              name: 'Deploy to Firebase',
              uses: 'w9jds/firebase-action@master',
              with: {
                args: 'deploy --only functions --debug',
              },
              env: {
                GCP_SA_KEY: `\${{ secrets.${serviceAccountKey} }}`,
                PROJECT_ID: `\${{ secrets.${projectId} }}`,
              },
            },
            sendStatusStep([
              'checkout',
              'install_lockfile',
              'cache',
              'install_deps',
              'build',
              'deploy',
            ]),
          ],
        },
      },
    },
    {
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};
