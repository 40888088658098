import { Injectable } from 'tiny-injector';

import type { Extension } from '@faslh/api/user-extensions/domain';
import type {
  IExtension,
  IncomingActionProperty,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  RoutingExtension,
} from '@faslh/compiler/contracts';
import type { AdminExtensionNS } from '@faslh/compiler/sdk/devkit';

export interface InstalledExtension<T extends IExtension> {
  extension: T;
  sourceExtension: AdminExtensionNS.ExtensionVM;
  installedExtension: Extension;
}

@Injectable()
export abstract class AbstractInputParser {
  abstract processTrigger(
    workflow: ProcessTriggerInput,
    workflowInputs?: Record<string, ParsedInput>,
  ): ProcessTriggerOutput;
  abstract routingExtension(): Promise<InstalledExtension<RoutingExtension>>;
  abstract parseInputV2(input: any): Promise<any>;
  abstract extractInputs(input: any): Promise<any>;
  abstract toInputs(
    input: string,
  ): Promise<Record<string, IncomingActionProperty>>;
  abstract evaulateRule(rule: string): Promise<any>;
  abstract installedSourceExtensions(): Promise<
    InstalledExtension<IExtension>[]
  >;
  abstract getFieldHandler(sourceId: string): Promise<{
    sourceExtension: AdminExtensionNS.ExtensionVM;
    sourceField: AdminExtensionNS.ExtensionField;
    installedExtension: Extension;
    handler: IExtension;
  }>;

  abstract getActionHandler(sourceId: string): Promise<{
    sourceExtension: AdminExtensionNS.ExtensionVM;
    sourceAction: AdminExtensionNS.IAction;
    handler: IExtension;
  }>;
  abstract parseInput(input: any): Promise<any>;
}
