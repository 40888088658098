import {
  AggregateRoot,
  DomainEventMetadata,
  NotRelatedEvent,
} from '@faslh/api/infrastructure';
import { parseDetails } from '@faslh/utils';

import {
  ExtensionDetailsPatched,
  ExtensionDetailsPatchedData,
  ExtensionInstalled,
  ExtensionInstalledData,
  ExtensionUninstalled,
  ExtensionUninstalledData,
} from '../events';

type Events =
  | ExtensionInstalled
  | ExtensionDetailsPatched
  | ExtensionUninstalled;

export class Extension extends AggregateRoot<Events> {
  public static override readonly streamName = 'extension';
  public override readonly streamName = Extension.streamName;
  public deleted = false;
  #details!: string;

  public override apply(event: Events) {
    switch (event.eventType) {
      case 'extension_installed':
        this.#details = event.data.details;
        this.deleted = false;
        break;
      case 'extension_details_patched':
        this.#details = event.data.details;
        break;
      case 'extension_uninstalled':
        this.deleted = true;
        break;
      default:
        throw new NotRelatedEvent(event);
    }
  }

  public static create(
    metadata: DomainEventMetadata,
    extensionId: string,
    data: ExtensionInstalledData,
  ) {
    const event = new ExtensionInstalled();
    event.aggregateId = extensionId;
    event.entityId = extensionId;
    event.data = data;
    event.metadata = metadata;

    const table = new Extension(event.aggregateId);
    table.applyChanges(event);
    return table;
  }

  public patchExtensionDetails(
    metadata: DomainEventMetadata,
    extensionId: string,
    data: ExtensionDetailsPatchedData,
  ) {
    const event = new ExtensionDetailsPatched();
    event.aggregateId = extensionId;
    event.entityId = extensionId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public delete(metadata: DomainEventMetadata, data: ExtensionUninstalledData) {
    const event = new ExtensionUninstalled();
    event.aggregateId = this.id;
    event.entityId = this.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public get details(): Record<string, unknown> {
    return parseDetails(this.#details);
  }
}
