import { AutoMap } from '@automapper/classes';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class CommitRebuiltData extends DomainEventData {}
@AutoMapHost()
export class CommitRebuilt extends DomainEvent<
  CommitRebuiltData,
  'commit_rebuilt'
> {
  public eventType = 'commit_rebuilt' as const;
  @AutoMap(() => CommitRebuiltData)
  public data!: CommitRebuiltData;
}
