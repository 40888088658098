import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class FieldAddedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  tableId!: string;

  @AutoMap()
  @IsNotEmpty()
  displayName!: string;

  @AutoMap()
  @IsUUID()
  @IsNotEmpty()
  sourceId!: string;

  @AutoMap(() => Object)
  details!: Record<string, any>;
}

export const FieldAdded = createEvent<FieldAddedData, 'field_added'>(
  'field_added',
);
export type FieldAdded = InstanceType<typeof FieldAdded>;
