import { createMap } from '@automapper/core';

import { mapper } from '@faslh/isomorphic';

import {
  ApiEndpointSet,
  ApiEndpointSetData,
  InitialSettingsAdded,
  InitialSettingsAddedData,
  RepositorySet,
  RepositorySetData,
  SecretSet,
  SecretSetData,
} from './events';

createMap(mapper, InitialSettingsAdded, InitialSettingsAdded);
createMap(mapper, InitialSettingsAddedData, InitialSettingsAddedData);

createMap(mapper, ApiEndpointSet, ApiEndpointSet);
createMap(mapper, ApiEndpointSetData, ApiEndpointSetData);

createMap(mapper, RepositorySet, RepositorySet);

createMap(mapper, SecretSet, SecretSet);
createMap(mapper, SecretSetData, SecretSetData);
