import {
  ProjectionV2,
  createOrThrow,
  createProjection,
} from '@faslh/api/infrastructure/database';

export interface PolicyProjection extends ProjectionV2 {
  displayName: string;
  rule: string;
}

export const policyProjection = (
  state?: Omit<PolicyProjection, 'id'>,
): PolicyProjection =>
  createProjection({
    ...(state ?? {
      ...createOrThrow('displayName'),
      ...createOrThrow('rule'),
    }),
  });
