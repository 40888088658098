import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowRemovedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  public workflowId!: string;
}

export const WorkflowRemoved = createEvent<
  WorkflowRemovedData,
  'workflow_removed'
>('workflow_removed');
export type WorkflowRemoved = InstanceType<typeof WorkflowRemoved>;
