import { AutoMap } from '@automapper/classes';
import { IsNotEmpty } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

export type CommitStage =
  | 'generate'
  | 'push_changes'
  | 'ci'
  | 'install_deps'
  | 'build'
  | 'deploy';
export type CommitStatus = 'in_progress' | 'failed' | 'rebuilt' | 'completed';

@AutoMapHost()
export class CommitPipelineStatusSetData extends DomainEventData {
  @IsNotEmpty()
  @AutoMap(() => String)
  public stage!: CommitStage;
}

@AutoMapHost()
export class CommitPipelineStatusSet extends DomainEvent<
  CommitPipelineStatusSetData,
  'commit_pipeline_status_set'
> {
  public eventType = 'commit_pipeline_status_set' as const;
  @AutoMap(() => CommitPipelineStatusSetData)
  public data!: CommitPipelineStatusSetData;
}
