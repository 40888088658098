import type * as morph from 'ts-morph';
import { Project, SyntaxKind, VariableDeclarationKind } from 'ts-morph';

function getDefaultExport(
  code: string,
  project = new Project({ useInMemoryFileSystem: true }),
) {
  const sourceFile = project.createSourceFile('index.ts', code);
  const defaultExport = sourceFile.getDefaultExportSymbolOrThrow();
  const exportAssignment =
    defaultExport.getValueDeclarationOrThrow() as morph.ExportAssignment;

  // assign default export to a variable

  return {
    value: exportAssignment.getExpression().getFullText(),
    sourceFile,
  };
}
export function getReturnTypeOfDefaultExport(
  workflowOutput: string,
  project = new Project({ useInMemoryFileSystem: true }),
) {
  const sourceFile = project.createSourceFile('index.ts', workflowOutput);
  const defaultExport = sourceFile.getDefaultExportSymbolOrThrow();
  const exportAssignment =
    defaultExport.getValueDeclarationOrThrow() as morph.ExportAssignment;

  const fn =
    exportAssignment.getFirstDescendantByKind(SyntaxKind.ArrowFunction) ??
    exportAssignment.getFirstDescendantByKindOrThrow(
      SyntaxKind.FunctionDeclaration,
    );

  const returnStatement = fn.getFirstDescendantByKind(
    SyntaxKind.ReturnStatement,
  );
  const returnType = fn.getReturnType();
  if (!returnStatement) {
    return {
      properties: [],
      returnCode: '',
      returnTypeStr: '',
    };
  }
  const objectLiteral = returnStatement.getFirstChildByKind(
    SyntaxKind.ObjectLiteralExpression,
  );

  if (!objectLiteral) {
    throw new Error('Return statement should return an object literal');
  }

  return {
    returnTypeStr: returnType.getText(),
    // replace "steps" with "" to get the name of the property
    returnCode: returnStatement.getText().replace(/steps\./g, ''),
    properties: returnType.getProperties().map((it) => ({
      name: it.getName(),
      primitiveType: it.getValueDeclaration()?.getType().getText(),
      parameter: it
        .getValueDeclaration()
        ?.getFirstDescendantByKindOrThrow(SyntaxKind.PropertyAccessExpression)
        ?.getText(),
    })),
  };
}

export function assignDefaultExportToVar(
  code: string,
  varName: string,
  project = new Project({ useInMemoryFileSystem: true }),
) {
  const { value, sourceFile } = getDefaultExport(code, project);
  const varStmt = sourceFile.addVariableStatement({
    declarationKind: VariableDeclarationKind.Const,
    declarations: [
      {
        name: varName,
        initializer: value,
      },
    ],
  });

  return varStmt.getFullText();
}
