import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { PatchExtensionDetailsCommand } from '@faslh/api/user-extensions/commands';
import { Extension } from '@faslh/api/user-extensions/domain';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(PatchExtensionDetailsCommand)
export class PatchExtensionDetailsCommandHandler extends IRequestHandler<
  PatchExtensionDetailsCommand,
  void
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: PatchExtensionDetailsCommand): Promise<void> {
    const aggregate = await this._eventStore.hyrdateAggregate(
      command.extensionId,
      Extension,
    );

    aggregate.patchExtensionDetails(
      this._eventUtils.getMetadata(),
      command.extensionId,
      {
        details: JSON.stringify(command.details ?? {}),
      },
    );

    await this._eventStore.save(aggregate);
  }
}
