import type { EmptyProject } from '@january/sdk/declarative';
import type { ServiceType } from 'tiny-injector';
import { Injector } from 'tiny-injector';

import { type Claims } from '@faslh/api/infrastructure/database';
import { AddPolicyCommand } from '@faslh/api/policies/commands';
import {
  AddActionToWorkflowCommand,
  AddFeatureCommand,
  AddFieldCommand,
  AddTableCommand,
  AddTagCommand,
  AddWorkflowCommand,
  AdjustFieldValidationCommand,
  AssignPolicyToWorkflowCommand,
  AssignTagToWorkflowCommand,
  CreateTableIndexCommand,
  DeleteFeatureCommand,
  PatchWorkflowOutputDetailsCommand,
} from '@faslh/api/table/commands';
import { InstallExtensionCommand } from '@faslh/api/user-extensions/commands';
import type { Command } from '@faslh/isomorphic';
import { mapper } from '@faslh/isomorphic';
import type { IRequest } from '@faslh/tiny-mediatr';
import { Mediator } from '@faslh/tiny-mediatr';
import { createRecorder, logMe } from '@faslh/utils';

import { BuildCommit } from './build_commit';
import { toCommands } from './execute';

const commandsMap: Record<string, ServiceType<IRequest<unknown>>> = {
  InstallExtension: InstallExtensionCommand,
  AddFeature: AddFeatureCommand,
  AddTable: AddTableCommand,
  AddField: AddFieldCommand,
  AddTag: AddTagCommand,
  AddWorkflow: AddWorkflowCommand,
  AddActionToWorkflow: AddActionToWorkflowCommand,
  AssignTagToWorkflow: AssignTagToWorkflowCommand,
  PatchWorkflowOutputDetails: PatchWorkflowOutputDetailsCommand,
  AdjustFieldValidation: AdjustFieldValidationCommand,
  CreateTableIndex: CreateTableIndexCommand,
  DeleteFeature: DeleteFeatureCommand,
  AddPolicy: AddPolicyCommand,
  AssignPolicyToWorkflow: AssignPolicyToWorkflowCommand,
};

export function dop(
  commands: Command<Record<string, unknown>>[],
  claims: Claims,
  genDir?: string,
  imports?: string[],
) {
  return Injector.CreateScope(async (context) => {
    const recorder = createRecorder({
      label: 'dop',
    });
    context.setExtra('claims', claims);

    recorder.record('fold changes');
    const mediator = Injector.GetRequiredService(Mediator, context);
    const failed: {
      error: Error;
      command: Command<Record<string, unknown>>;
    }[] = [];

    await Promise.all(
      commands.map((command) =>
        mediator
          .send(
            mapper.map(command.payload, commandsMap[command.command] as any),
          )
          .catch((error) => {
            failed.push({ command, error });
          }),
      ),
    );

    if (failed.length > 0) {
      logMe({ 'Retrying failed commands': failed });
      await Promise.all(
        failed.map(({ command }) =>
          mediator.send(
            mapper.map(command.payload, commandsMap[command.command] as any),
          ),
        ),
      );
      logMe({ 'Failed commands': failed });
    }

    recorder.recordEnd('fold changes');
    recorder.record('build commit');
    const result = await mediator.send(
      new BuildCommit(genDir, undefined, imports),
    );
    recorder.recordEnd('build commit');
    recorder.end();
    return result;
  });
}

dop.project = async (def: EmptyProject, claims: Claims, genDir?: string) => {
  const commands = await toCommands(def);
  return dop(
    commands,
    claims,
    genDir,
    def.imports.map((it) => it.moduleSpecifier),
  );
};
