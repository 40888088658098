import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddActionToWorkflowCommand } from '@faslh/api/table/commands';
import { Workflow } from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddActionToWorkflowCommand)
export class AddActionToWorkflowHandler extends IRequestHandler<
  AddActionToWorkflowCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
    private readonly _devKit: DevKit,
  ) {
    super();
  }

  async handle(command: AddActionToWorkflowCommand): Promise<string> {
    const sourceAction = await this._devKit.getSourceActionById(
      command.sourceId,
    );
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    if (workflow.hasAction(command.sourceId)) {
      throw new Error('Workflow already has this action');
    }

    // FIXME: ensure no circular reference

    const id = workflow.addAction(
      command.id ?? v4(),
      this._eventUtils.getMetadata(),
      {
        sourceId: command.sourceId,
        featureId: command.featureId,
        workflowId: command.workflowId,
        details: JSON.stringify(
          this._devKit.assignDefaults(
            sourceAction.metadata,
            command.details ?? {},
          ),
        ),
        displayName: command.displayName,
        outputName: command.outputName,
      },
    );
    await this._eventStore.save(workflow);

    return id;
  }
}
