import { isEmpty } from 'lodash';
import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import {
  EventStore,
  ProjectionStore,
  ProjectionV2,
  Projector,
  createProjection,
} from '@faslh/api/infrastructure/database';
import {
  WorkflowActionAdded,
  WorkflowActionDetailsPatched,
  WorkflowAdded,
} from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { DynamicSource } from '@faslh/compiler/sdk/dynamic-source';
import {
  ResolveContext,
  applyCondition,
  camelcase,
  isResolvable,
  pascalcase,
} from '@faslh/utils';

export type WorkflowOutputEvents =
  | WorkflowAdded
  | WorkflowActionAdded
  | WorkflowActionDetailsPatched;
interface WorkflowTypes {
  typeName?: string;
  parent: {
    displayName: string;
    primitiveType: string;
    typeName: string;
  };
  interface?: WorkflowTypes[];
}

export interface WorkflowOutputProjection extends ProjectionV2 {
  workflows: Record<
    string,
    {
      outputStr: string;
      actions: Record<
        string,
        {
          sourceId: string;
          types: WorkflowTypes[];
          typeName: string;
          outputName: string;
        }
      >;
    }
  >;
}


export const workflowOutputProjection = (
  state?: Omit<WorkflowOutputProjection, 'id'>,
): WorkflowOutputProjection =>
  createProjection({
    ...(state ?? {
      workflows: {},
    }),
  });
@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class WorkflowOutputProjector extends Projector<
  WorkflowOutputProjection,
  WorkflowOutputEvents
> {
  protected override projectionName = 'workflow_output';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    protected readonly _devKit: DevKit,
    protected readonly _context: Context,
  ) {
    super();
  }

  resolvers: Record<string, Resolver> = {};

  protected override async apply(
    state: WorkflowOutputProjection,
    event: WorkflowOutputEvents,
  ): Promise<any> {
    switch (event.eventType) {
      case 'workflow_added':
        this.resolvers[event.entityId] ??= new Resolver(
          this._dynamicSource,
          this._devKit,
        );
        state.workflows[event.entityId] ??= {
          actions: {},
          outputStr: '',
        };
        return state;
      case 'workflow_action_added': {
        this.resolvers[event.data.workflowId] ??= new Resolver(
          this._dynamicSource,
          this._devKit,
        );
        const sourceAction = await this._devKit.getSourceActionById(
          event.data.sourceId,
        );
        state.workflows[event.data.workflowId] ??= {
          actions: {},
          outputStr: '',
        };
        const outputName = event.data.outputName;
        const types = await this.resolvers[event.data.workflowId].resolveSource(
          state.id,
          sourceAction.output.source,
          {
            self: event.data,
            context: JSON.parse(event.data.details ?? {}),
          },
        );
        state.workflows[event.data.workflowId].actions[event.entityId] = {
          outputName: camelcase(outputName),
          typeName: pascalcase(`${outputName}Output`), // workaround till we support providing type name from actions.json
          sourceId: event.data.sourceId,
          types: types,
        };
        state.workflows[event.data.workflowId].outputStr = this.#makeOutputStr(
          `${outputName}Output`, // workaround till we support providing type name from actions.json
          state.workflows[event.data.workflowId].actions[event.entityId].types,
        );
        return state;
      }
      case 'workflow_action_details_patched': {
        this.resolvers[event.data.workflowId] ??= new Resolver(
          this._dynamicSource,
          this._devKit,
        );
        const action =
          state.workflows[event.data.workflowId].actions[event.entityId];
        const sourceAction = await this._devKit.getSourceActionById(
          action.sourceId,
        );
        const types = await this.resolvers[event.data.workflowId].resolveSource(
          state.id,
          sourceAction.output.source,
          {
            self: event.data,
            context: JSON.parse(event.data.details ?? {}),
          },
        );
        state.workflows[event.data.workflowId].actions[event.entityId] = {
          ...state.workflows[event.data.workflowId].actions[event.entityId],
          types: types,
        };

        state.workflows[event.data.workflowId].outputStr = this.#makeOutputStr(
          action.outputName,
          types,
        );
        return state;
      }
      default:
        return state;
    }
  }

  #makeOutputStr(baseOutputName: string, types: WorkflowTypes[]) {
    const interfaces: string[] = [];
    const typesMap: string[] = [];

    function makeInterface(name: string, types: WorkflowTypes[]) {
      if (!typesMap.includes(name)) {
        typesMap.push(name);
        interfaces.push(`export interface ${name} {${makeObject(types)}}`);
      }
    }

    function makeObject(types: WorkflowTypes[]): string {
      return types
        .filter((it) => !isEmpty(it))
        .filter((it) => it.parent.primitiveType)
        .map((it) => {
          if (it.parent.primitiveType === 'array') {
            const typeName = it.typeName!;
            makeInterface(pascalcase(typeName), it.interface!);
            return `${camelcase(it.parent.displayName)}: ${pascalcase(
              typeName,
            )}[]`;
          }
          if (it.parent.primitiveType === 'object') {
            const typeName = it.typeName!;
            makeInterface(pascalcase(typeName), it.interface!);
            return `${camelcase(it.parent.displayName)}: ${pascalcase(
              typeName,
            )}`;
          }
          const primitiveTypes: Record<string, string> = {
            date: 'Date',
          };
          return `${camelcase(it.parent.displayName)}: ${
            primitiveTypes[it.parent.primitiveType] || it.parent.primitiveType
          }`;
        })
        .join(';');
    }

    makeInterface(pascalcase(baseOutputName), types);
    return interfaces.join('\n');
  }

  private get _dynamicSource() {
    return Injector.GetRequiredService(DynamicSource, this._context);
  }
}

class Resolver {
  constructor(
    private _dynamicSource: DynamicSource,
    private _devKit: DevKit,
  ) {}
  #cache: Record<string, boolean> = {};
  async resolveSource(
    featureId: string,
    source: any,
    context: ResolveContext,
  ): Promise<WorkflowTypes[]> {
    const resolvedSource = await this._dynamicSource.resolveSource(
      featureId,
      source,
      context,
    );

    let items: any[] = [];

    if (Array.isArray(resolvedSource)) {
      items = resolvedSource;
    } else {
      items = [resolvedSource];
    }

    const result: WorkflowTypes[] = [];
    for (const outputItem of items) {
      const resolveContext: ResolveContext = outputItem.details
        ? {
            self: outputItem,
            context: outputItem.details,
          }
        : context;

      if (outputItem.if && applyCondition(outputItem.if, resolveContext)) {
        continue;
      }

      if (isResolvable(outputItem.primitiveType?.interface)) {
        const typeName = await this.#resolveTypeName(
          featureId,
          outputItem.primitiveType.typeName,
          resolveContext,
        );
        if (!typeName) {
          throw new Error(
            `The type name of ${JSON.stringify(
              outputItem.displayName,
            )} is not resolved.`,
          );
        }
        const resolvedPrimitiveType = this.#resolvePrimitiveType(
          outputItem.primitiveType.primitiveType,
          resolveContext,
        );
        if (this.#cache[typeName]) {
          // result.push({
          //   interface: [],
          //   typeName: typeName,
          //   parent: {
          //     ...outputItem.primitiveType,
          //     ...outputItem,
          //     primitiveType: resolvedPrimitiveType,
          //   },
          // });
          continue;
        }

        this.#cache[typeName] = true;

        result.push({
          typeName: typeName,
          parent: {
            ...outputItem.primitiveType,
            ...outputItem,
            primitiveType: resolvedPrimitiveType,
          },
          interface: await this.resolveSource(
            featureId,
            {
              ...outputItem.primitiveType.interface,
              primitiveType: resolvedPrimitiveType,
            },
            resolveContext,
          ),
        });
      } else {
        const resolvedPrimitiveType = this.#resolvePrimitiveType(
          outputItem.primitiveType,
          resolveContext,
        );
        if (['array', 'object'].includes(resolvedPrimitiveType)) {
          const typeName = await this.#resolveTypeName(
            featureId,
            outputItem.typeName,
            resolveContext,
          );
          if (!typeName) {
            throw new Error(
              `The type name of ${JSON.stringify(
                outputItem.displayName,
              )} is not resolved.`,
            );
          }
          if (this.#cache[typeName]) {
            result.push({
              interface: [],
              typeName: typeName,
              parent: {
                ...outputItem,
                primitiveType: resolvedPrimitiveType,
              },
            });
            continue;
          }
          this.#cache[typeName] = true;

          result.push({
            typeName: typeName,
            parent: {
              ...outputItem,
              primitiveType: resolvedPrimitiveType,
            },
            interface: await this.resolveSource(
              featureId,
              outputItem.interface,
              resolveContext,
            ),
          });
        } else {
          result.push({
            parent: { ...outputItem },
            interface: [],
          });
        }
      }
    }
    return result;
  }

  async #resolveTypeName(
    featureId: string,
    typeName: any,
    resolveContext: ResolveContext,
  ) {
    if (typeof typeName === 'string') {
      return typeName;
    } else {
      const resolvedTypeName = await this._dynamicSource.resolveSource(
        featureId,
        typeName,
        resolveContext,
      );
      return (resolvedTypeName as any)[typeName.use];
    }
  }

  #resolvePrimitiveType(primitiveType: any, resolveContext: ResolveContext) {
    if (!primitiveType) {
      return '';
    }
    return typeof primitiveType === 'string'
      ? this._devKit.typesResolverMap[primitiveType] || primitiveType
      : applyCondition(primitiveType, resolveContext);
  }
}
