import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class ActionConnectedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  id!: string;

  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;
}

export const ActionConnected = createEvent<
  ActionConnectedData,
  'action_connected'
>('action_connected');
export type ActionConnected = InstanceType<typeof ActionConnected>;
