import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import {
  EventStore,
  ProjectionStore,
  Projector,
} from '@faslh/api/infrastructure/database';
import { PolicyAdded } from '@faslh/api/policies/domain';
import {
  ActionConnected,
  ActionNameChanged,
  FeatureAdded,
  FeatureDeleted,
  FieldAdded,
  FieldDelocalized,
  FieldDetailsUpdated,
  FieldLocalized,
  FieldValidationAdjusted,
  PolicyAssignedToWorkflow,
  QueryAdded,
  QueryConditionMutated,
  QueryDelocalized,
  QueryLocalized,
  TableAdded,
  TableArchived,
  TableIndexCreated,
  TableNameChanged,
  TagAdded,
  TagAssignedToWorkflow,
  WorkflowActionAdded,
  WorkflowActionDetailsPatched,
  WorkflowAdded,
  WorkflowDetailsPatched,
  WorkflowNameChanged,
  WorkflowOutputDetailsPatched,
  WorkflowRemoved,
  WorkflowTriggerChanged,
} from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { DynamicSource } from '@faslh/compiler/sdk/dynamic-source';
import { titlecase, upsert, upsertAsync } from '@faslh/utils';

import { FeatureProjection, featureProjection } from './feature.projection';

type Events =
  | TagAdded
  | PolicyAdded
  | TagAssignedToWorkflow
  | PolicyAssignedToWorkflow
  | WorkflowAdded
  | WorkflowActionAdded
  | WorkflowActionDetailsPatched
  | WorkflowDetailsPatched
  | WorkflowOutputDetailsPatched
  | QueryAdded
  | TableAdded
  | FieldAdded
  | TableNameChanged
  | TableArchived
  | FieldDelocalized
  | FieldLocalized
  | QueryLocalized
  | QueryDelocalized
  | QueryConditionMutated
  | WorkflowTriggerChanged
  | WorkflowNameChanged
  | ActionNameChanged
  | WorkflowRemoved
  | FeatureAdded
  | FieldValidationAdjusted
  | FieldDetailsUpdated
  | ActionConnected
  | TableIndexCreated
  | FeatureDeleted;

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class FeatureProjector extends Projector<FeatureProjection, Events> {
  protected override projectionName = 'features';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    protected readonly _devKit: DevKit,
    protected _context: Context,
  ) {
    super();
  }

  protected override async apply(
    state: FeatureProjection,
    event: Events,
  ): Promise<FeatureProjection> {
    switch (event.eventType) {
      case 'feature_added':
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.displayName = event.data.displayName;
        return state;
      case 'feature_deleted':
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.deleted = true;
        return state;
      case 'table_added':
        state.updatedAt = event.timestamp;
        state.tables = upsert(state.tables ?? [], event.entityId, (it) => ({
          ...it,
          ...event.data,
          indexes: [...(it.indexes ?? [])],
        }));
        return state;
      case 'table_index_created':
        state.updatedAt = event.timestamp;
        state.tables = upsert(state.tables ?? [], event.entityId, (it) => ({
          ...it,
          indexes: [...(it.indexes ?? []), event.data.details],
        }));
        return state;
      case 'field_added':
        state.updatedAt = event.timestamp;
        state.tables = await upsertAsync(
          state.tables ?? [],
          event.aggregateId,
          async (it) => ({
            ...it,
            fields: await upsertAsync(
              it.fields ?? [],
              event.entityId,
              async (it) => {
                const sourceField = await this._devKit.getSourceFieldById(
                  event.data.sourceId,
                );
                return {
                  ...it,
                  ...event.data,
                  details: {
                    ...it.details,
                    ...event.data.details,
                  },
                  name: sourceField.name,
                  primitiveType: sourceField.primitiveType || null,
                  resolvedPrimitiveType:
                    (await this._dynamicSource.resolvePrimitiveType(
                      state.id,
                      sourceField.primitiveType,
                      {
                        context: event.data.details,
                        self: event.data,
                      },
                      (primitiveType) => primitiveType.primitiveType,
                    )) || '',
                  documentation: {
                    displayName: titlecase(event.data.displayName),
                  },
                };
              },
            ),
          }),
        );
        return state;
      case 'query_added':
        state.updatedAt = event.timestamp;
        state.queries = upsert(state.queries ?? [], event.entityId, (it) => ({
          ...it,
          ...event.data,
        }));
        return state;
      case 'query_condition_mutated':
        state.updatedAt = event.timestamp;
        state.queries = upsert(state.queries ?? [], event.entityId, (it) => ({
          ...it,
          condition: event.data.condition,
        }));
        return state;
      case 'workflow_added':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.entityId,
          (it) => ({
            ...it,
            output: {},
            details: event.data.details,
            sourceId: event.data.sourceId,
            featureId: event.data.featureId,
            displayName: titlecase(event.data.displayName),
            actions: [],
          }),
        );
        return state;
      case 'workflow_removed':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.entityId,
          (it) => ({
            ...it,
            removed: true,
          }),
        );
        return state;
      case 'tag_assigned_to_workflow':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            get tag() {
              return (
                (state.tags ?? []).find((it) => it.id === event.data.tagId)
                  ?.displayName ?? ''
              );
            },
          }),
        );
        return state;
      case 'policy_assigned_to_workflow':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            policies: upsert(it.policies ?? [], event.data.policyId, (it) => ({
              ...it,
              id: event.data.policyId,
            })),
          }),
        );
        return state;
      case 'workflow_name_changed':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(state.workflows, event.entityId, (it) => ({
          ...it,
          displayName: event.data.displayName,
        }));
        return state;
      case 'workflow_trigger_changed':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(state.workflows, event.entityId, (it) => ({
          ...it,
          sourceId: event.data.sourceId,
          details: event.data.details,
        }));
        return state;
      case 'action_name_changed':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
              displayName: event.data.displayName,
            })),
          }),
        );
        return state;
      case 'workflow_action_added':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
              ...event.data,
              outputName: event.data.outputName,
              displayName: event.data.displayName,
              details: JSON.parse(event.data.details),
            })),
          }),
        );
        return state;
      case 'action_connected':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
            })),
          }),
        );
        return state;
      case 'workflow_details_patched':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.entityId,
          (it) => ({
            ...it,
            details: JSON.parse(event.data.details),
          }),
        );
        return state;
      case 'workflow_action_details_patched':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
              details: JSON.parse(event.data.details),
            })),
          }),
        );
        return state;
      case 'workflow_output_details_patched':
        state.updatedAt = event.timestamp;
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            output: JSON.parse(event.data.details),
          }),
        );
        return state;
      case 'field_validation_adjusted':
        state.updatedAt = event.timestamp;
        state.tables = upsert(state.tables ?? [], event.aggregateId, (it) => ({
          ...it,
          fields: upsert(it.fields ?? [], event.entityId, (it) => ({
            ...it,
            validations: event.data.validations.map((it) => ({
              details: it.details,
              sourceId: it.sourceId,
            })),
          })),
        }));
        return state;
      case 'tag_added':
        state.updatedAt = event.timestamp;
        state.tags = upsert(state.tags ?? [], event.entityId, (it) => ({
          ...it,
          ...event.data,
        }));
        return state;

      case 'field_details_updated':
        state.updatedAt = event.timestamp;
        state.tables = upsert(state.tables ?? [], event.aggregateId, (it) => ({
          ...it,
          fields: upsert(it.fields ?? [], event.data.fieldId, (it) => ({
            ...it,
            details: {
              ...it.details,
              ...event.data.details,
            },
          })),
        }));
        return state;

      default:
        return state;
    }
  }

  private get _dynamicSource() {
    return Injector.GetRequiredService(DynamicSource, this._context);
  }

  public hydrate(id: string) {
    return this.rebuildProjection(id, featureProjection());
  }
}
