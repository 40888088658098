import * as commitVM from './lib/commit.projection';
import './lib/handlers';

export * from './lib/changes';
export * from './lib/commit.projection';
export * from './lib/commit.projector';

export namespace CommitProjectionNS {
  export type CommitProjection = commitVM.CommitProjection;
}
