import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class RepositorySetData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  repositoryName!: string;

  @AutoMap()
  @IsNotEmpty()
  repositoryId!: number;

  @AutoMap()
  @IsUUID()
  projectId!: string;

  @AutoMap()
  @IsUUID()
  workspaceId!: string;
}
export class RepositorySet extends DomainEvent<
  RepositorySetData,
  'repository_set'
> {
  public eventType = 'repository_set' as const;

  @AutoMap(() => RepositorySetData)
  public data!: RepositorySetData;
}
