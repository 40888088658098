import { AutoMap } from '@automapper/classes';
import { createMap } from '@automapper/core';
import type { FieldValue } from 'firebase-admin/firestore';
import { pascalcase } from 'stringcase';
// importing utils breaks esbuild
import { v4 } from 'uuid';

import { mapper } from '@faslh/isomorphic';
import { INotification } from '@faslh/tiny-mediatr';

import { ToPrimitive } from './primitives.type';

export class DomainEventMetadata {
  public causationId?: string; // What caused the event?
  public correlationId!: string; // To what this event is relate
  public userIp?: string;
  public userAgent?: string;
  public platformVersion?: string;
  public organizationId?: string;
  public workspaceId?: string;
  public projectId?: string;
  public userId?: string;
}

export abstract class DomainEventData {
  toJson?(): ToPrimitive<Record<string, any>> {
    return { ...this };
  }
}

export abstract class DomainEvent<
  Data extends DomainEventData,
  Type,
  Metadata = DomainEventMetadata,
> extends INotification {
  @AutoMap(() => String)
  public readonly id = v4();
  // @AutoMap(() => Object)
  // public readonly aggregateVersion = FieldValue.increment(1);
  @AutoMap(() => Object)
  public readonly timestamp!: FieldValue;
  // = FieldValue.serverTimestamp();

  public abstract readonly eventType: Type;

  @AutoMap()
  public aggregateId!: string;

  @AutoMap()
  public streamName!: string;

  @AutoMap()
  public entityId!: string;

  @AutoMap(() => Object)
  public metadata!: Metadata;

  public abstract data: Data;

  public toJson(): ToPrimitive<DomainEvent<any, any, any>> {
    return {
      id: this.id,
      streamName: this.streamName,
      eventType: this.eventType,
      aggregateId: this.aggregateId,
      entityId: this.entityId ?? v4(),
      metadata: this.metadata,
      timestamp: this.timestamp,
      // aggregateVersion: this.aggregateVersion,
      data: this.data.toJson ? this.data.toJson() : this.data,
    };
  }
}

export function createEvent<
  T extends DomainEventData,
  EventName extends string,
>(eventName: EventName) {
  const temp = {
    [pascalcase(eventName)]: class extends DomainEvent<T, EventName> {
      public override readonly eventType = eventName;
      public static readonly eventType = eventName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // public static override name = pascalcase(eventName);
      public override data!: T;
    },
  };

  createMap(mapper, temp[pascalcase(eventName)]);
  return temp[pascalcase(eventName)];
}
