import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { FailCommitCommand } from '@faslh/api/releases/commands';
import { Commit } from '@faslh/api/releases/models';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(FailCommitCommand)
export class FailCommitCommandHandler extends IRequestHandler<FailCommitCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils
  ) {
    super();
  }

  async handle(command: FailCommitCommand): Promise<void> {
    const aggregate = new Commit(command.commitId);
    aggregate.failCommit(this._eventUtils.getMetadata());
    await this._eventStore.save(aggregate);
  }
}
