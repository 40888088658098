import { AutoMap } from '@automapper/classes';
import type { GroupQueryCondition } from '@january/compiler/transpilers';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class MutateQueryConditionCommand extends IRequest {
  @IsUUID()
  @AutoMap()
  featureId!: string;

  @IsUUID()
  @AutoMap()
  queryId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  condition!: GroupQueryCondition<any>;
}
