import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class UpdatePolicyCommand {
  @AutoMap()
  @IsUUID()
  public policyId!: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public displayName!: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public rule!: string;
}
