import {
  ProjectionV2,
  createOrThrow,
  createProjection,
} from '@faslh/api/infrastructure/database';

export interface UserInstalledExtensionProjection extends ProjectionV2 {
  removed: boolean;
  name: string;
  categories: string[];
  details: Record<string, any>;
}

export const userInstalledExtensionProjection = (
  state?: UserInstalledExtensionProjection,
): UserInstalledExtensionProjection =>
  createProjection({
    ...(state ?? {
      categories: [],
      details: {},
      ...createOrThrow('name'),
      ...createOrThrow('removed'),
    }),
  });
