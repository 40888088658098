import { join } from 'path';
import { Injectable, ServiceLifetime } from 'tiny-injector';

import { dotcase, pascalcase, spinalcase } from '@faslh/utils';

import { ProjectConfig } from './project-config';

@Injectable({
  lifetime: ServiceLifetime.Singleton,
})
export class ProjectFS {
  constructor(private _projectConfig: ProjectConfig) {}

  routersGlob() {
    return this._projectConfig.getConfig().features + '/**/*.router.ts';
  }

  listenersGlob() {
    return this._projectConfig.getConfig().features + '/**/*.github.ts';
  }

  cronsGlob() {
    return this._projectConfig.getConfig().features + '/**/*.cron.ts';
  }

  entitiesGlob() {
    return this._projectConfig.getConfig().features + '/**/*.entity.ts';
  }

  makeCoreImportSpecifier = (importPath: string) => {
    return join(
      '#{relative}',
      this._projectConfig.getConfig().basePath,
      importPath,
    );
  };
  makeEntityImportSpecifier = (tableName: string) => {
    return join('#{entity}', pascalcase(tableName));
  };

  makeFeaturePath = (fileName: string) =>
    join(this._projectConfig.getConfig().features, fileName);

  makeFeatureFile = (featureName: string, fileName: string) =>
    join(
      this._projectConfig.getConfig().features,
      spinalcase(featureName),
      fileName,
    );

  makeCorePath = (fileName: string) =>
    join(this._projectConfig.getConfig().basePath, 'core', fileName);

  makeIdentityPath = (fileName: string) =>
    join(this._projectConfig.getConfig().basePath, 'identity', fileName);

  makeSrcPath = (fileName: string) =>
    join(this._projectConfig.getConfig().basePath, fileName);

  makeRootPath = (fileName: string) =>
    join(this._projectConfig.getConfig().basePath, '../', fileName);

  makeCommandPath = (
    featureName: string,
    tagName: string,
    commandName: string,
  ) =>
    this.makeFeatureFile(
      featureName,
      join(spinalcase(tagName), `${spinalcase(commandName)}.command.ts`),
    );

  makeIndexFilePath = (featureName: string, tagName: string) =>
    this.makeFeatureFile(featureName, join(spinalcase(tagName), `index.ts`));

  makeControllerPath = (featureName: string, suffix: string) =>
    this.makeFeatureFile(
      featureName,
      `${spinalcase(featureName)}.${dotcase(`${suffix} ts`)}`,
    );
  makeControllerRoutePath = (featureName: string, routeName: string) =>
    this.makeFeatureFile(
      featureName,
      join('routes', `${spinalcase(routeName)}.${dotcase(`route ts`)}`),
    );

  makeListenerRoutePath = (featureName: string, routeName: string) =>
    this.makeFeatureFile(
      featureName,
      join('listeners', `${spinalcase(routeName)}.${dotcase(`listener ts`)}`),
    );
  makeJobRoutePath = (featureName: string, routeName: string) =>
    this.makeFeatureFile(
      featureName,
      join('jobs', `${spinalcase(routeName)}.${dotcase(`job ts`)}`),
    );

  makeEntityPath = (featureName: string, tableName: string, suffix: string) =>
    join(
      this._projectConfig.getConfig().features,
      spinalcase(featureName),
      `${spinalcase(tableName)}.${dotcase(`${suffix} ts`)}`,
    );

  makeQueryPath = (tableName: string, queryName: string) =>
    join(
      this._projectConfig.getConfig().features,
      spinalcase(tableName),
      'queries',
      `${spinalcase(queryName)}.query.ts`,
    );

  makeExportPath = (workflowName: string, suffix: string) =>
    `./${spinalcase(workflowName)}.${suffix}`;
}
