import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';


@AutoMapHost()
export class ExtensionInstalledData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  name!: string;
}

export const ExtensionInstalled = createEvent<
  ExtensionInstalledData,
  'extension_installed'
>('extension_installed');

export type ExtensionInstalled = InstanceType<typeof ExtensionInstalled>;