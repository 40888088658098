import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';

export class PolicyAssignedToWorkflowData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  public featureId!: string;

  @AutoMap()
  @IsUUID()
  public policyId!: string;

  @AutoMap()
  @IsUUID()
  public workflowId!: string;
}

export const PolicyAssignedToWorkflow = createEvent<
  PolicyAssignedToWorkflowData,
  'policy_assigned_to_workflow'
>('policy_assigned_to_workflow');
export type PolicyAssignedToWorkflow = InstanceType<
  typeof PolicyAssignedToWorkflow
>;
