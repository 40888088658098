import { createMap } from '@automapper/core';
import { Injector } from 'tiny-injector';
import { v4 } from 'uuid';

import {
  EventStore,
  EventUtils,
  onRequest,
} from '@faslh/api/infrastructure/database';
import {
  AddActionToWorkflowCommand,
  AddFeatureCommand,
  AddFieldCommand,
  AddQueryCommand,
  AddTableCommand,
  AddTagCommand,
  AddWorkflowCommand,
  AdjustFieldValidationCommand,
  ArchiveTableCommand,
  AssignPolicyToWorkflowCommand,
  AssignTagToWorkflowCommand,
  ChangeActionNameCommand,
  ChangeWorkflowNameCommand,
  ChangeWorkflowTriggerCommand,
  ConnectAction,
  CreateTableIndexCommand,
  DelocalizeFieldCommand,
  DelocalizeQueryCommand,
  LocalizeFieldCommand,
  LocalizeQueryCommand,
  MutateQueryConditionCommand,
  PatchWorkflowActionDetails,
  PatchWorkflowDetails,
  PatchWorkflowOutputDetailsCommand,
  RemoveWorkflowCommand,
  UpdateFieldDetailsCommand,
} from '@faslh/api/table/commands';
import {
  ActionConnected,
  ActionNameChangedData,
  FeatureAddedData,
  FieldAddedData,
  FieldDelocalizedData,
  FieldDetailsUpdatedData,
  FieldLocalizedData,
  FieldValidationAdjustedData,
  PolicyAssignedToWorkflowData,
  QueryAddedData,
  QueryConditionMutatedData,
  QueryDelocalizedData,
  QueryLocalizedData,
  Table,
  TableAddedData,
  TableArchivedData,
  TagAddedData,
  TagAssignedToWorkflowData,
  WorkflowActionAddedData,
  WorkflowActionDetailsPatchedData,
  WorkflowAddedData,
  WorkflowDetailsPatchedData,
  WorkflowNameChangedData,
  WorkflowOutputDetailsPatchedData,
  WorkflowRemovedData,
  WorkflowTriggerChangedData,
} from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { FieldValidation, mapper } from '@faslh/isomorphic';

import './lib/command-handlers/add-feature.handler';
import './lib/command-handlers/delete-feature.handler';
import './lib/command-handlers/add-query.handler';
import './lib/command-handlers/add-table.handler';
import './lib/command-handlers/add-tag.handler';
import './lib/command-handlers/add-workflow-action.handler';
import './lib/command-handlers/add-workflow.handler';
import './lib/command-handlers/adjust-field-validation.handler';
import './lib/command-handlers/archive-table.handler';
import './lib/command-handlers/assign-tag-workflow.handler';
import './lib/command-handlers/assign-policy-workflow.handler';
import './lib/command-handlers/change-action-name.handler';
import './lib/command-handlers/change-workflow-name.handler';
import './lib/command-handlers/change-workflow-trigger.handler';
import './lib/command-handlers/connect-action.handler';
import './lib/command-handlers/delocalize_field.handler';
import './lib/command-handlers/delocalize_query.handler';
import './lib/command-handlers/localize_field.handler';
import './lib/command-handlers/localize_query.handler';
import './lib/command-handlers/mutate_query_condition.handler';
import './lib/command-handlers/patch-workflow-action-details.handler';
import './lib/command-handlers/patch-workflow-details.handler';
import './lib/command-handlers/patch-workflow-output-details.handler';
import './lib/command-handlers/remove-workflow.handler';
import './lib/command-handlers/update-field-details.handler';
import './lib/event-handlers';

createMap(mapper, MutateQueryConditionCommand, QueryConditionMutatedData);

createMap(mapper, LocalizeQueryCommand, QueryLocalizedData);
createMap(mapper, DelocalizeQueryCommand, QueryDelocalizedData);
createMap(mapper, LocalizeFieldCommand, FieldLocalizedData);
createMap(mapper, DelocalizeFieldCommand, FieldDelocalizedData);

createMap(mapper, AdjustFieldValidationCommand, FieldValidationAdjustedData);
createMap(mapper, FieldValidation, FieldValidationAdjustedData);
createMap(mapper, FieldValidationAdjustedData, FieldValidation);

createMap(mapper, ArchiveTableCommand, TableArchivedData);
createMap(mapper, AddWorkflowCommand, WorkflowAddedData);
createMap(mapper, AddActionToWorkflowCommand, WorkflowActionAddedData);
createMap(mapper, ConnectAction, ActionConnected);
createMap(mapper, ChangeWorkflowTriggerCommand, WorkflowTriggerChangedData);
createMap(mapper, ChangeWorkflowNameCommand, WorkflowNameChangedData);
createMap(mapper, ChangeActionNameCommand, ActionNameChangedData);

createMap(mapper, UpdateFieldDetailsCommand, FieldDetailsUpdatedData);
createMap(mapper, AddQueryCommand, QueryAddedData);

createMap(mapper, AddFeatureCommand, FeatureAddedData);
createMap(mapper, AddTableCommand, TableAddedData);

createMap(mapper, PatchWorkflowActionDetails, WorkflowActionDetailsPatchedData);
createMap(mapper, PatchWorkflowDetails, WorkflowDetailsPatchedData);

createMap(mapper, AddTagCommand, TagAddedData);
createMap(mapper, AssignTagToWorkflowCommand, TagAssignedToWorkflowData);
createMap(mapper, AssignPolicyToWorkflowCommand, PolicyAssignedToWorkflowData);

createMap(
  mapper,
  PatchWorkflowOutputDetailsCommand,
  WorkflowOutputDetailsPatchedData,
);
createMap(mapper, RemoveWorkflowCommand, WorkflowRemovedData);

onRequest({
  requestType: CreateTableIndexCommand,
  handler: async (command, context) => {
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const eventUtils = Injector.GetRequiredService(EventUtils, context);

    const table = await eventStore.hyrdateAggregate(command.tableId, Table);
    table.addIndex(eventUtils.getMetadata(), command);
    await eventStore.save(table);
  },
});

onRequest({
  requestType: AddFieldCommand,
  handler: async (command, context) => {
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const eventUtils = Injector.GetRequiredService(EventUtils, context);
    const devKit = Injector.GetRequiredService(DevKit, context);

    const table = await eventStore.hyrdateAggregate(command.tableId, Table);

    const sourceField = await devKit.getSourceFieldById(command.sourceId);
    if (!sourceField) {
      throw new Error(`Source field with id ${command.sourceId} not found`);
    }

    const exist = table.fieldNameExist(command.displayName);
    const shouldIgnore = command.details['ignoreIfExist'] === true;

    if (exist && !shouldIgnore) {
      throw new Error(`Field ${command.displayName} already exists`);
    }

    if (exist && shouldIgnore) {
      return table.getField(command.displayName)!.id;
    }

    const field = table.addField(
      command.id ?? v4(),
      eventUtils.getMetadata(),
      mapper.map(
        {
          displayName: command.displayName,
          sourceId: command.sourceId,
          tableId: command.tableId,
          details: devKit.assignDefaults(sourceField.metadata, command.details),
        },
        FieldAddedData,
      ),
    );

    await eventStore.save(table);
    return field.id;
  },
});
