import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddFeatureCommand } from '@faslh/api/table/commands';
import { Feature, FeatureAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddFeatureCommand)
export default class extends IRequestHandler<AddFeatureCommand, string> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AddFeatureCommand): Promise<string> {
    // This should be enabled when the diff declartive definition is ready
    // const features = await this._eventStore.listAggregates(Feature);
    // if (features.some((f) => f.displayName === command.displayName)) {
    //   throw new Error(`Feature with name ${command.displayName} already exists`);
    // }

    const feature = Feature.create(
      command.id ?? v4(),
      this._eventUtils.getMetadata(),
      mapper.map(command, AddFeatureCommand, FeatureAddedData),
    );
    await this._eventStore.save(feature);
    return feature.id;
  }
}
