import {
  ProjectionV2,
  createOrThrow,
  createProjection,
} from '@faslh/api/infrastructure/database';
import { Releasable } from '@faslh/isomorphic';

import { IWorkflowVM } from './vm/feature_workflow.vm';
import { TableVM } from './vm/table.vm';
import { QueryVM } from './vm/table_query.vm';

export interface FeatureProjection extends ProjectionV2, Releasable {
  displayName: string;
  workflows: IWorkflowVM[];
  queries: QueryVM[];
  tables: TableVM[];
  tags: TagVM[];
  policies: TagVM[];
  deleted: boolean;
}

export interface TagVM {
  id: string;
  displayName: string;
}

export const featureProjection = (
  state?: Omit<FeatureProjection, 'id'>,
): FeatureProjection =>
  createProjection({
    ...(state ?? {
      ...createOrThrow('displayName'),
      ...createOrThrow('deleted'),
      workflows: [],
      queries: [],
      tables: [],
      tags: [],
      policies: [],
    }),
  });
