import txt from './setup.txt';

import dedent from 'dedent';
import { join } from 'path';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import { CLAIMS_TOKEN, type Claims } from '@faslh/api/infrastructure/database';
import {
  ConcreteContracts,
  Contracts,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  RoutingExtension,
  WithMyInput,
  signutureInputs,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import {
  Arg,
  AsyncVisitor,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  StringLiteral,
  StringVisitor,
  addLeadingSlash,
  camelcase,
  isNullOrUndefined,
  parseDsl,
  removeTrialingSlash,
  snakecase,
  spinalcase,
} from '@faslh/utils';

import { makeSwaggerSpec } from '../koajs/swagger';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class HonoExtension implements RoutingExtension, WithMyInput {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
    @Inject(CLAIMS_TOKEN) private readonly _claims: Claims,
  ) {}
  public triggers = ['7a9d9a29-7079-4aa8-bdc0-d93a713a2440'];

  public async handleSetup(
    details: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      // {
      //   filePath: this._projectFS.makeCorePath('adapter.ts'),
      //   content: txt,
      // },
      {
        filePath: this._projectFS.makeSrcPath('main.ts'),
        content: [txt],
      },
    ];
  }

  public handleInput(input: string): Promise<ParsedInput | null> {
    const visitor = new SimpleVisitor();
    const { namespace, value } = visitor.visit(parseDsl(input)) as {
      namespace: string;
      value: Expression;
    };
    return this.handleInputV2({ namespace, value });
  }

  public async handleInputV2({
    namespace,
    value,
  }: {
    value: Expression;
    namespace: string;
  }): Promise<ParsedInput | null> {
    switch (namespace) {
      case 'subject': {
        const visitor = new HonoInputVisitor();
        const result = (await visitor.visit(value)) as
          | {
              accessor: string;
              accessee: string;
            }
          | string;

        if (typeof result === 'string') {
          if (result === 'authenticated') {
            return {
              static: true,
              type: 'string',
              value: `await verifyToken(context.req.header('Authorization'))`,
              structure: [
                {
                  kind: morph.StructureKind.ImportDeclaration,
                  moduleSpecifier: 'hono',
                  namedImports: ['Context'],
                },
                {
                  kind: morph.StructureKind.ImportDeclaration,
                  moduleSpecifier: `./subject`,
                  namedImports: ['verifyToken'],
                },
              ],
            };
          }
          throw new Error(`Unknown subject accessor: ${result}`);
        }

        return {
          static: false,
          type: 'string',
          value: `context.get('subject').${result.accessor}.${result.accessee}`,
          validations: [
            {
              name: 'mandatory',
              type: 'mandatory',
              details: {
                value: 'true',
              },
            },
          ],
        };
      }
      case 'trigger': {
        const visitor = new HonoInputVisitor();
        const result = (await visitor.visit(value)) as {
          accessor: string;
          accessee: string;
        };
        switch (result.accessor) {
          case 'request':
            return {
              static: false,
              type: 'IncomingMessage',
              value: `(context.env as { incoming: IncomingMessage }).incoming`,
              data: { parameterName: 'request' },
              structure: [
                // TODO: perhaps we need to split this into imports structure and inner/action structure
                {
                  kind: morph.StructureKind.ImportDeclaration,
                  moduleSpecifier: 'http',
                  namedImports: ['IncomingMessage'],
                },
              ],
            };
          case 'body':
            return {
              static: false,
              // FIXME: 'string' not the correct type here
              // we need to be smart at guessing that
              // I can think of new input "@forward:type" or wait, we already have that
              // I mean the table fields already add types as validation
              type: 'string',
              value: `body.${result.accessee}`,
              data: { parameterName: result.accessee },
            };
          case 'path':
            return {
              static: false,
              type: 'string',
              value: `pathParams.${result.accessee}`,
              data: { parameterName: result.accessee },
              validations: [
                {
                  name: 'mandatory',
                  type: 'mandatory',
                  details: {
                    value: 'true',
                  },
                },
              ],
            };
          case 'query':
            return {
              static: false,
              type: 'string',
              data: { parameterName: result.accessee },
              value: `searchParams.${result.accessee}`,
            };
          case 'headers':
            return {
              static: false,
              type: 'string',
              data: { parameterName: result.accessee },
              value: `context.req.header(${result.accessee})`,
            };
          default:
            return null;
        }
      }
      default:
        return null;
    }
  }

  public async extractInputs({
    namespace,
    value,
    visit,
  }: {
    value: Expression;
    namespace: string;
    visit: AsyncVisitor<unknown>;
  }): Promise<Record<string, string> | null> {
    if (!['subject', 'trigger'].includes(namespace)) {
      return null;
    }
    const visitor = new InputsExtractor(visit);
    const result = await visitor.visit(value);
    return {
      [result.accessee]: `@${namespace}:${result.accessor}.${result.accessee}`,
    };
  }

  public onFeatureContract(
    contract: Contracts.FeatureContract,
    addFile: (concrete: ConcreteContracts.WorkflowConcreteStructure) => void,
  ): void {
    const swaggerSpec = makeSwaggerSpec({
      ...contract,
      workflows: contract.workflows.filter((it) =>
        this.triggers.includes(it.trigger.sourceId),
      ),
    });
    addFile({
      filePath: this._projectFS.makeFeatureFile(
        contract.displayName,
        `${spinalcase(contract.displayName)}.swagger.json`,
      ),
      structure: [JSON.stringify(swaggerSpec, null, 2)],
    });
    // we need to generate empty swagger file at least
    if (contract.workflows.length === 0) {
      return;
    }
    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'router',
      ),
      structure: this.#generateController({
        featureName: contract.displayName,
        tags: contract.tags,
        policies: contract.workflows.map((it) => it.trigger.policies).flat(),
      }),
    });

    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'router',
      ),
      structure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: '@scalar/hono-api-reference',
          namedImports: ['apiReference'],
        },
        {
          kind: morph.StructureKind.VariableStatement,
          declarationKind: morph.VariableDeclarationKind.Const,
          declarations: [
            {
              name: 'router',
              initializer: `new Hono()`,
            },
          ],
        },
        (writer) =>
          writer.writeLine(dedent`
            import swagger from './${spinalcase(
              contract.displayName,
            )}.swagger.json';
            router.get(
              '/reference',
              apiReference({
                spec: { url: swagger as any },
              })
            );
      `),

        ...(contract.workflows.map((contract) => {
          const displayName = `${camelcase(contract.featureName)}.${camelcase(
            contract.trigger.displayName,
          )}`;

          const path = removeTrialingSlash(
            addLeadingSlash(
              join(snakecase(contract.tag), contract.trigger.details['path']),
            ),
          );
          const verb = `router.${contract.trigger.details['method']}`;
          const authorize = contract.trigger.policies.length
            ? `,authorize(${contract.trigger.policies
                .map(camelcase)
                .join(',')})`
            : '';

          return `${verb}("${path}" ${authorize}, ${displayName})`;
        }) as ConcreteContracts.MorphStatementWriter),
        {
          kind: morph.StructureKind.ExportAssignment,
          isExportEquals: false,
          expression: `['/${spinalcase(
            contract.displayName,
          )}', router] as const`,
        },
      ],
    });
  }

  #generateController(contract: {
    featureName: string;
    tags: string[];
    policies: string[];
  }): ConcreteContracts.WorkflowStructure {
    return [
      {
        kind: morph.StructureKind.ImportDeclaration,
        namedImports: ['Hono'],
        moduleSpecifier: 'hono',
      },
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier: '../../identity',
        namedImports: ['authorize'],
      },
      ...contract.policies.map(
        (it) =>
          ({
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../../identity/${spinalcase(it)}.policy`,
            namedImports: [camelcase(it)],
          }) as morph.ImportDeclarationStructure,
      ),
      // import * as {featureName} from './routes'
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier: `./routes`,
        namespaceImport: camelcase(contract.featureName),
      },
    ];
  }

  async processTrigger(
    contract: ProcessTriggerInput,
    workflowInputs: Record<string, ParsedInput>,
  ): ProcessTriggerOutput {
    const displayName = camelcase(contract.displayName);
    const inputName = camelcase('input');
    const outputName = camelcase('output');

    const vars: Record<string, string> = {};
    for (const input of Object.values(contract.inputs)) {
      if (typeof input === 'string' || isNullOrUndefined(input.value)) {
        continue;
      }
      const value = input.value;
      if (value.startsWith('body') && !vars['body']) {
        vars['body'] = 'const body = await context.req.json()';
      }
      if (value.startsWith('pathParams') && !vars['pathParams']) {
        vars['pathParams'] = 'const pathParams = context.req.param()';
      }
      if (value.startsWith('searchParams') && !vars['searchParams']) {
        vars['searchParams'] = 'const searchParams = context.req.query()';
      }
    }

    const inputParams = signutureInputs({ ...contract.inputs });
    const actionInputsStr = this._devKit.toLiteralObject(inputParams);
    const workflowLoneParams = signutureInputs({ ...workflowInputs });

    for (const [name, prop] of workflowLoneParams) {
      vars[name] = `const ${name} = ${prop.value}`;
    }
    const workflowParams: string[] = [];
    if (inputParams.length) {
      workflowParams.push(inputName);
    }
    if (workflowLoneParams.length) {
      workflowParams.push(...workflowLoneParams.map(([name]) => name));
    }

    const workflowInputsStructures: ConcreteContracts.MorphStatementWriter[] =
      Object.values(workflowInputs)
        .map((prop) => prop.structure)
        .filter(Boolean) as ConcreteContracts.MorphStatementWriter[];

    return [
      {
        filePath: this._projectFS.makeIndexFilePath(
          contract.featureName,
          'routes',
        ),
        structure: [
          {
            kind: morph.StructureKind.ExportDeclaration,
            moduleSpecifier: this._projectFS.makeExportPath(
              contract.displayName,
              `route`,
            ),
          },
        ],
      },
      {
        filePath: this._projectFS.makeControllerRoutePath(
          contract.featureName,
          contract.displayName,
        ),
        structure: [
          ...workflowInputsStructures.flat(),
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../${spinalcase(contract.tag)}`,
            namespaceImport: camelcase(contract.tag),
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: 'lodash-es',
            namedImports: ['pick'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier:
              this._projectFS.makeCoreImportSpecifier('core/validation'),
            namedImports: ['validateOrThrow'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: 'hono',
            namedImports: ['Hono', 'Context', 'Next'],
          },
          {
            kind: morph.StructureKind.Function,
            isAsync: true,
            isDefaultExport: false,
            isExported: true,
            name: displayName,
            parameters: [
              { name: 'context', type: 'Context' },
              { name: 'next', type: 'Next' },
            ],
            statements: [
              ...Object.values(vars),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `const ${inputName} = ${actionInputsStr}`,
                ),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `validateOrThrow(${camelcase(contract.tag)}.${
                    contract.schemaName
                  }, ${inputName})`,
                ),
              (writer) =>
                writer.writeLine(
                  `const ${outputName} = await ${camelcase(
                    contract.tag,
                  )}.${camelcase(contract.operationName)}(${workflowParams.join(',')})`,
                ),
              (writer) =>
                writer.writeLine(`return context.json(${outputName})`),
            ],
          },
        ],
      },
    ];
  }

  getServerSetup(exportApp?: boolean, platform?: 'vercel'): string {
    return dedent(`
import { serve } from '@hono/node-server'
import { serveStatic } from '@hono/node-server/serve-static'
import { Hono } from 'hono'
import application from './main';
import { relative, dirname } from 'node:path';
import { fileURLToPath } from 'node:url';
import { showRoutes } from 'hono/dev'

${platform === 'vercel' ? `import { handle } from '@hono/node-server/vercel'` : ''}

  const dirRelativeToCwd = relative(process.cwd(), dirname(fileURLToPath(import.meta.url)));

  application.use(
    '/:filename{.+\\\.png$}',
    serveStatic({ root: dirRelativeToCwd })
  );

  application.use('/:filename{.+\\\.swagger\\\.json$}', serveStatic({
    root: dirRelativeToCwd,
    rewriteRequestPath: (path) => path.split('/').pop() as string,
  }));

serve({
  fetch: application.fetch,
  port: parseInt(process.env.PORT ?? '3000', 10),
});

console.log(\`Server running at http://localhost:\${process.env.PORT ?? '3000'}\`);

if(process.env.NODE_ENV === 'development'){
  showRoutes(application, {
    verbose: true,
  });
}

${exportApp ? `export default handle(application);` : ''}

`);
  }
}

class HonoInputVisitor extends AsyncVisitor<any> {
  override visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitIdentifier(node: Identifier): Promise<any> {
    return node.value;
  }
  override async visitStringLiteral(node: StringLiteral): Promise<any> {
    return `'${node.value}'`;
  }
  override visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
export class InputsExtractor extends StringAsyncVisitor {
  constructor(private _visitor: AsyncVisitor<unknown>) {
    super();
  }
  override async visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
class SimpleVisitor extends StringVisitor {
  override visitArg(node: Arg): any {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): any {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): any {
    return node.toLiteral(this);
  }
  override visitPropertyAccess(node: PropertyAccess): any {
    return node.toLiteral(this);
  }
  override visitNamespace(node: Namespace): any {
    return {
      namespace: node.name.accept(this),
      value: node.expression,
    };
  }
}
