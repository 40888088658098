import {
  ExtensionDetailsPatched,
  ExtensionInstalled,
  ExtensionUninstalled,
} from '@faslh/api/user-extensions/domain';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

import { userInstalledExtensionProjection } from '../extension.projection';
import { UserInstalledExtensionProjector } from '../extension.projector';

@NotificationHandler(ExtensionUninstalled)
@NotificationHandler(ExtensionInstalled)
@NotificationHandler(ExtensionDetailsPatched)
export class ExtensionInstalledHandler extends INotificationHandler<ExtensionInstalled> {
  constructor(
    private readonly _extensionProjector: UserInstalledExtensionProjector,
  ) {
    super();
  }
  async handle(event: ExtensionInstalled): Promise<void> {
    const projection = await this._extensionProjector.getProjector(
      event.aggregateId,
      {
        defaultState: userInstalledExtensionProjection(),
        events: [event],
      },
    );
    this._extensionProjector.saveProjection(event.aggregateId, projection);
  }
}
