import { v4 } from 'uuid';

import type {
  DomainEvent,
  DomainEventMetadata,
} from '@faslh/api/infrastructure';
import { AggregateRoot } from '@faslh/api/infrastructure';
import type { RepositorySetData } from '@faslh/api/settings/domain';
import { RepositorySet } from '@faslh/api/settings/domain';

import type { CodeAssociatedWithProjectData } from '../events/code_associate_with_project.event';
import { CodeAssociatedWithProject } from '../events/code_associate_with_project.event';
import type { MemberAddedData } from '../events/member_added.event';
import { MemberAdded } from '../events/member_added.event';
import type { OrganizationCreatedData } from '../events/organization_created.event';
import { OrganizationCreated } from '../events/organization_created.event';
import type { ProjectAddedData } from '../events/project_added.event';
import { ProjectAdded } from '../events/project_added.event';
import type { WorkspaceAddedData } from '../events/workspace_created.event';
import { WorkspaceAdded } from '../events/workspace_created.event';
import { Member } from './member';
import { Project } from './project';
import { Workspace } from './workspace';

type Events =
  | OrganizationCreated
  | WorkspaceAdded
  | MemberAdded
  | ProjectAdded
  | RepositorySet
  | CodeAssociatedWithProject;
export class Organization extends AggregateRoot<Events> {
  #members: Record<string, Member> = {};
  #workspaces: Record<string, Workspace> = {};
  #projects: Record<string, Project> = {};
  public displayName!: string;
  public workspaces: Record<string, Workspace> = {};
  public static override readonly streamName = 'organization';
  public override readonly streamName = Organization.streamName;

  public static create(
    id: string,
    metadata: DomainEventMetadata,
    data: OrganizationCreatedData,
  ) {
    const event = new OrganizationCreated();

    event.entityId = id;
    event.aggregateId = event.entityId;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Organization(event.aggregateId);
    aggregate.applyChanges(event);

    return aggregate;
  }

  // public addWorkspace(metadata: DomainEventMetadata, data: WorkspaceAddedData) {
  //   const { aggregate, event } = Workspace.create(v4(), metadata, data);
  //   this.applyChanges(event);
  //   return aggregate;
  // }

  // public addFirstWorkspace(
  //   id: string,
  //   metadata: DomainEventMetadata,
  //   data: WorkspaceAddedData,
  // ) {
  //   const { aggregate, event } = Workspace.create(id, metadata, data);

  //   this.applyChanges(event);
  //   return aggregate;
  // }

  public addFirstWorkspace(
    id: string,
    metadata: DomainEventMetadata,
    data: WorkspaceAddedData,
  ) {
    const event = new WorkspaceAdded();
    event.aggregateId = this.id;
    event.entityId = id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
    return this.#workspaces[event.entityId];
  }

  public addWorkspace(metadata: DomainEventMetadata, data: WorkspaceAddedData) {
    return this.addFirstWorkspace(v4(), metadata, data);
  }
  public addFirstProject(
    id: string,
    metadata: DomainEventMetadata,
    data: ProjectAddedData,
  ) {
    const event = new ProjectAdded();
    event.aggregateId = this.id;
    event.entityId = id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
    return this.#projects[event.entityId];
  }

  public associateCodeWithProject(
    metadata: DomainEventMetadata,
    data: CodeAssociatedWithProjectData,
  ) {
    const event = new CodeAssociatedWithProject();
    event.aggregateId = this.id;
    event.entityId = data.projectId;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
    return this.#projects[event.entityId];
  }

  public setRepositoryName(
    metadata: DomainEventMetadata,
    data: RepositorySetData,
  ) {
    const event = new RepositorySet();
    event.aggregateId = this.id;
    event.entityId = data.projectId;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
    return this.#projects[event.entityId];
  }

  public addProject(
    id: string,
    metadata: DomainEventMetadata,
    data: ProjectAddedData,
  ) {
    return this.addFirstProject(id, metadata, data);
  }
  public addMember(metadata: DomainEventMetadata, data: MemberAddedData) {
    const event = new MemberAdded();
    event.aggregateId = this.id;
    event.entityId = data.id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
    return this.#members[event.entityId];
  }
  public override apply(event: Events): void {
    switch (event.eventType) {
      case 'organization_created':
        this.displayName = event.data.displayName;
        break;
      case 'workspace_added':
        // this.workspaces[event.entityId] = new Workspace(event.entityId);
        // this.workspaces[event.entityId].loadFromHistory([event]);
        this.#workspaces[event.entityId] = new Workspace(event.entityId, {
          orgId: event.data.orgId,
        });
        break;
      case 'project_added':
        this.#projects[event.entityId] = new Project(event.entityId, {
          orgId: event.data.orgId,
          workspaceId: event.data.workspaceId,
          code: '',
        });
        break;
      case 'repository_set':
        this.#projects[event.entityId].repositoryId = event.data.repositoryId;
        this.#projects[event.entityId].repositoryName =
          event.data.repositoryName;
        break;
      case 'member_added':
        this.#members[event.entityId] = new Member(event.entityId, {
          orgId: event.data.orgId,
        });
        break;
      case 'code_associate_with_project':
        this.#projects[event.entityId].code = event.data.code;
        break;
      default:
        throw new Error(
          `${(event as DomainEvent<any, any>).eventType} is not handled.`,
        );
    }
  }

  public getFirstWorkspace() {
    return Object.values(this.workspaces)[0];
  }

  public getWorkspace(id: string) {
    return this.workspaces[id];
  }
  public hasMember(id: string) {
    return !!this.#members[id];
  }

  public projectExists(wsId: string, id: string) {
    return !!this.#projects[id] && this.#projects[id].workspaceId === wsId;
  }
}
