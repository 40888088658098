import { join } from 'path';
import { Injectable, ServiceLifetime } from 'tiny-injector';

import type { ChangesProjection } from '@faslh/api/releases/projection';

import { ConcreteContracts, Contracts } from '@faslh/compiler/contracts';
import {
  ExtensionManager,
  ExtensionsRegistry,
  FeatureInput,
} from '@faslh/compiler/core';
import { ProjectConfig, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { createRecorder, pascalcase } from '@faslh/utils';

import { VirtualProject } from './sourcecode';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class VirtualFiles {
  constructor(
    private readonly _extensionManager: ExtensionManager,
    private readonly _extensionsRegistry: ExtensionsRegistry,
    private readonly _projectConfig: ProjectConfig,
    private readonly _projectFS: ProjectFS,
  ) {}

  private _refineChangesProjection(projection: ChangesProjection) {
    const features = projection.features.reduce<Array<FeatureInput>>(
      (acc, curr) => {
        const workflows = projection.workflows.filter((workflow) => {
          return workflow.featureId === curr.id && workflow.removed !== true;
        });
        const queries = projection.queries.filter((query) => {
          return query.featureId === curr.id;
        });
        const tables = projection.tables.filter((table) => {
          return table.featureId === curr.id;
        });
        acc.push({
          workflows,
          queries,
          tables,
          displayName: curr.displayName,
          id: curr.id,
        });
        return acc;
      },
      [],
    );
    const policies = projection.policies;

    return {
      features,
      policies,
      imports: projection.imports,
    };
  }

  async generateConcreteStructure(
    contracts: Contracts.FaslhContracts,
    projectDeps: Contracts.ExtensionSetupContract[],
  ): Promise<ConcreteContracts.ConcreteStructure> {
    return {
      setup: projectDeps,
      policies: await Promise.all(
        contracts.policies.map((it) =>
          this._extensionManager.processPolicy(it),
        ),
      ),
      features: await Promise.all(
        contracts.features.map(async (feature) => {
          return {
            displayName: feature.displayName,
            structures: await this._extensionManager.processFeature(feature),
            tables: await Promise.all(
              feature.tables.map(async (table) => {
                return {
                  tableName: table.tableName,
                  entityStructure: this._extensionManager
                    .getOrmExtension()
                    .generateTable({
                      tableName: pascalcase(table.tableName),
                      indexes: table.indexes,
                    }),
                  fields: table.fields.map((it) => {
                    return {
                      ...this._extensionManager
                        .getOrmExtension()
                        .generateField(it),
                      tableName: pascalcase(table.tableName),
                    };
                  }),
                };
              }),
            ),
            workflows: await Promise.all(
              feature.workflows.map((it) =>
                this._extensionManager.processWorkflow(it),
              ),
            ),
          };
        }),
      ),
    };
  }

  public async prepareProject(
    changes: ChangesProjection,
    generateDir?: string,
  ) {
    const recorder = createRecorder({
      label: 'Preparing',
    });
    if (generateDir) {
      recorder.record('update project config');
      const config = this._projectConfig.getConfig();
      this._projectConfig.updateConfig({
        basePath: join(generateDir, config.basePath),
        features: join(generateDir, config.features),
      });
      recorder.recordEnd('update project config');
    }

    recorder.record('generating project contract');
    const refinedChanges = this._refineChangesProjection(changes);

    const contracts = await this._extensionManager.generateProjectContract(
      refinedChanges.features,
      refinedChanges.policies,
    );
    recorder.recordEnd('generating project contract');
    recorder.record('generating concrete structure');
    const concreteStructure = await this.generateConcreteStructure(contracts, [
      ...(await this._extensionsRegistry.getProjectMainSetup(
        refinedChanges.imports,
      )),
      ...(await this._extensionManager.getSetups()),
    ]);
    recorder.recordEnd('generating concrete structure');
    recorder.end();
    return concreteStructure;
  }

  public async generate(
    concreteStructure: ConcreteContracts.ConcreteStructure,
    generateDir?: string,
  ) {
    const vProject = new VirtualProject(this._projectFS);
    vProject.generate(concreteStructure, generateDir);
    return {
      files: vProject.getOutput(),
      save: () => vProject.emit(),
      cleanup: () => vProject.cleanup(),
    };
  }

  public async getSourceCode(changes: ChangesProjection, generateDir?: string) {
    const recorder = createRecorder({
      label: 'Generating Source Code',
    });
    recorder.record('prepare project');
    const concreteStructure = await this.prepareProject(changes, generateDir);
    recorder.recordEnd('prepare project');
    recorder.record('generate');
    const result = await this.generate(concreteStructure, generateDir);
    recorder.recordEnd('generate');
    recorder.end();
    return result;
  }
}
