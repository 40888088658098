import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class OrganizationCreatedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  displayName!: string;
}

@AutoMapHost()
export class OrganizationCreated extends DomainEvent<
  OrganizationCreatedData,
  'organization_created'
> {
  public eventType = 'organization_created' as const;

  @AutoMap(() => OrganizationCreatedData)
  public data!: OrganizationCreatedData;
}
