import { Injectable } from 'tiny-injector';
import * as morph from 'ts-morph';

import {
  ConcreteContracts,
  Contracts,
  ProcessActionHelpers,
} from '@faslh/compiler/contracts';

export type Pagination = 'none' | 'limit_offset' | 'deferred_joins' | 'cursor';
export interface GenerateQueryInput {
  tableName: string;
  outputName: string;
  single: boolean;
  localizable: boolean;
  pagination: Pagination;
  query: Contracts.QueryContract;
  helpers: ProcessActionHelpers;
  inline: boolean;
}

export interface GenerateCommandInput {
  helpers: ProcessActionHelpers;
  action: Contracts.WorkflowAction;
  tableName: string;
  inline: boolean;
}
export interface GenerateRawSQLInput {
  helpers: ProcessActionHelpers;
  action: Contracts.WorkflowAction;
  inline: boolean;
}

@Injectable()
export abstract class OrmCodeWriter {
  abstract generateRaw(
    contract: GenerateRawSQLInput,
  ): ConcreteContracts.WorkflowStructure;
  abstract generateCommand(
    contract: GenerateCommandInput,
  ): ConcreteContracts.WorkflowStructure;
  abstract generateQuery(
    contract: GenerateQueryInput,
  ): ConcreteContracts.WorkflowStructure;
  abstract generateTable(
    contract: Contracts.Table,
  ): ConcreteContracts.MorphStatementWriter;
  abstract generateCreatedAtField(
    contract: Contracts.Field,
  ): morph.PropertyDeclarationStructure;
  abstract generateDeletedAtField(
    contract: Contracts.Field,
  ): morph.PropertyDeclarationStructure;
  abstract generateUpdatedAtField(
    contract: Contracts.Field,
  ): morph.PropertyDeclarationStructure;
  abstract generatePrimaryField(contract: Contracts.PrimaryField): void;

  abstract generateField(contract: Contracts.Field): {
    topLevelStructure: ConcreteContracts.MorphStatementWriter;
    fieldStructure: morph.PropertyDeclarationStructure;
  };
}
