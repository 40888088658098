import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class SetSecretCommand {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  name!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  displayName!: string;
}
