import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowDetailsPatchedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;
}

export const WorkflowDetailsPatched = createEvent<
  WorkflowDetailsPatchedData,
  'workflow_details_patched'
>('workflow_details_patched');
export type WorkflowDetailsPatched = InstanceType<
  typeof WorkflowDetailsPatched
>;
