import { AutoMap } from '@automapper/classes';
import { IsString, IsUUID } from 'class-validator';



import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class FailCommitCommand extends IRequest<string> {
  @IsUUID()
  @AutoMap()
  public commitId!: string;

  @IsString()
  @AutoMap()
  public stage!: string;
}