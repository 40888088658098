import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowActionAddedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  displayName!: string;

  @AutoMap()
  @IsUUID()
  sourceId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;

  @IsString()
  @IsNotEmpty()
  @AutoMap()
  outputName!: string;
}

export const WorkflowActionAdded = createEvent<
  WorkflowActionAddedData,
  'workflow_action_added'
>('workflow_action_added');
export type WorkflowActionAdded = InstanceType<typeof WorkflowActionAdded>;
