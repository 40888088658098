import dedent from 'dedent';
import { join } from 'path';
import { Inject, Injectable } from 'tiny-injector';

import type { Projection, ProjectionV2 } from '../../projection';
import { AbstractProjectionStore, AbstractStore } from '../abstract.store';
import { SQLiteStore } from './sqlite.store';
import { SQLITE_TOKEN, type SqliteOperations } from './sqlite.token';

@Injectable()
export class SqliteProjectionStore extends AbstractProjectionStore {
  constructor(
    @Inject(SQLITE_TOKEN) public db: SqliteOperations,
    @Inject(AbstractStore) private _store: SQLiteStore,
  ) {
    super();
  }

  public save(projection: Projection | ProjectionV2) {
    this._store.hold(
      join(...this._store.basePath, 'projections', projection.id),
      (client) => {
        return this.db.prepare(
          {
            sql: dedent`
        INSERT INTO Projections (id, data, prefix)
        VALUES (?, ?, ?)
        ON CONFLICT(id) DO UPDATE SET data = excluded.data;
      `,
            params: [
              projection.id,
              JSON.stringify(projection),
              projection.prefix,
            ],
            txn: true,
          },
          client,
        );
      },
      projection,
    );
  }
}
