import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class AddPolicyCommand {
  @AutoMap()
  @IsUUID()
  @IsOptional()
  id?: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public displayName!: string;

  @AutoMap()
  @IsString()
  @IsNotEmpty()
  public rule!: string;
}
