import { getReturnTypeOfDefaultExport } from '@january/generator';
import type { EmptyProject } from '@january/sdk/declarative';
import pluralize from 'pluralize';
import type { ImportDeclarationStructure } from 'ts-morph';
import { StructureKind } from 'ts-morph';
import { v4 } from 'uuid';

import type { AddPolicyCommand } from '@faslh/api/policies/commands';
import type {
  AddActionToWorkflowCommand,
  AddFeatureCommand,
  AddFieldCommand,
  AddTableCommand,
  AddTagCommand,
  AddWorkflowCommand,
  AdjustFieldValidationCommand,
  AssignPolicyToWorkflowCommand,
  AssignTagToWorkflowCommand,
  PatchWorkflowOutputDetailsCommand,
} from '@faslh/api/table/commands';
import type { InstallExtensionCommand } from '@faslh/api/user-extensions/commands';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import type { Command, FieldValidation } from '@faslh/isomorphic';
import {
  camelcase,
  hasProperty,
  isNullOrUndefined,
  logMe,
  uniquify,
} from '@faslh/utils';

export async function toCommands<X extends EmptyProject = EmptyProject>(
  projectDefinition: X,
) {
  const devKit = new DevKit();
  const commands: Command<Record<string, unknown>>[] = [];
  const tablesIds: Record<string, string> = {};
  const tagsIds: Record<string, string> = {};
  const workflowIds: Record<string, string> = {};
  const fieldsIds: Record<string, string> = {};
  const actionsIds: Record<string, string> = {};
  const pathsIds: Record<string, string> = {};
  const policiesIds: Record<string, string> = {};

  projectDefinition.features.forEach((feature) => {
    Object.keys(feature.tables).forEach((tableName) => {
      tablesIds[tableName] = v4();
      const table = feature.tables[tableName];
      Object.keys(table.fields).forEach((fieldName) => {
        fieldsIds[fieldName] = v4();
      });
    });

    Object.keys(feature.policies ?? {}).forEach((policyName) => {
      policiesIds[policyName] = v4();
    });

    feature.workflows.forEach((workflow) => {
      workflowIds[workflow.name] = v4();
      tagsIds[workflow.tag] ??= v4();
      Object.keys(workflow.actions).forEach((actionName) => {
        actionsIds[actionName] = v4();
        const action = workflow.actions[actionName];

        if (typeof action === 'function') {
          return;
        }

        if (action.type === 'branch') {
          const config = action.config;
          if (hasProperty(config, 'paths')) {
            (config.paths as { id: string; name: string }[]).forEach((path) => {
              pathsIds[path.name] = path.id;
            });
          }
        }
      });
    });
  });

  function mapQueries<T>(details: T): T {
    if (
      typeof details === 'string' ||
      typeof details === 'number' ||
      typeof details === 'boolean'
    ) {
      return details;
    }
    if (isRequest(details)) {
      return mapQuery(details as Command<{ name: string }>) as T;
    }
    if (isNullOrUndefined(details)) {
      return details;
    }

    if (Array.isArray(details)) {
      return details.map(mapQueries) as T;
    }

    return Object.entries(details as Record<string, unknown>).reduce<
      Record<string, unknown>
    >((acc, [key, value]) => {
      return {
        ...acc,
        [key]: mapQueries(value),
      };
    }, {}) as T;
  }

  function mapQuery(value: Command<{ name: string }>) {
    if (isRequest(value)) {
      let result: unknown | undefined = undefined;
      switch (value.command) {
        case 'QueryTable':
          result = tablesIds[value.payload.name];
          break;
        case 'QueryTag':
          result = tagsIds[value.payload.name];
          break;
        case 'QueryWorkflow':
          result = workflowIds[value.payload.name];
          break;
        case 'QueryFieldName':
          for (const feature of projectDefinition.features) {
            for (const table of Object.values(feature.tables)) {
              if (table.fields[value.payload.name]) {
                const field = table.fields[value.payload.name];
                if (field.type === 'relation') {
                  result = camelcase(value.payload.name + ' id');
                }
              }
            }
          }
          result ??= value.payload.name;
          break;
        case 'QueryField':
          for (const feature of projectDefinition.features) {
            for (const table of Object.values(feature.tables)) {
              if (table.fields[value.payload.name]) {
                const field = table.fields[value.payload.name];
                if (field.type === 'relation') {
                  result = fieldsIds[camelcase(value.payload.name + ' id')];
                }
              }
            }
          }
          // in case of relation field not found, fallback to the field itself
          result ??= fieldsIds[value.payload.name];
          break;
        case 'QueryAction':
          result = actionsIds[value.payload.name];
          break;
        case 'QueryPath':
          result = pathsIds[value.payload.name];
          break;
        default:
          throw new Error(`Unknown command ${value.command}`);
      }
      if (isNullOrUndefined(result)) {
        throw new Error(
          `Could not find ${value.command} ${value.payload.name}`,
        );
      }
      return result;
    }
    return value;
  }

  for (const [name, details] of Object.entries(projectDefinition.extensions)) {
    const source = await devKit.getExtensionByName(name);
    commands.push({
      command: 'InstallExtension',
      payload: {
        extensionId: source.id,
        details: details,
      } satisfies InstallExtensionCommand,
    });
  }

  for (const feature of projectDefinition.features) {
    // Features
    const featureId = v4();
    commands.push({
      command: 'AddFeature',
      payload: {
        id: featureId,
        displayName: feature.name,
      } satisfies AddFeatureCommand,
    });

    // Tags
    for (const tag in tagsIds) {
      commands.push({
        command: 'AddTag',
        payload: {
          id: tagsIds[tag],
          featureId: featureId,
          displayName: tag,
        } satisfies AddTagCommand,
      });
    }

    // Policies
    for (const policyName in policiesIds) {
      commands.push({
        command: 'AddPolicy',
        payload: {
          id: policiesIds[policyName],
          displayName: policyName,
          rule: (feature.policies ?? {})[policyName],
        } satisfies AddPolicyCommand,
      });
    }

    // Tables (make sure to add it before any logic that can use "useField")
    for (const tableName in feature.tables) {
      const table = feature.tables[tableName];
      commands.push({
        command: 'AddTable',
        payload: {
          id: tablesIds[tableName],
          featureId: featureId,
          displayName: tableName,
          details: {},
        } satisfies AddTableCommand,
      });
      for (const constraint of table.constraints) {
        commands.push({
          command: 'CreateTableIndex',
          payload: {
            tableId: tablesIds[tableName],
            details: mapQueries(constraint.details),
          },
        });
      }
    }

    // Fields
    for (const tableName in feature.tables) {
      const table = feature.tables[tableName];
      // Fields
      for (const fieldName in table.fields) {
        const field = table.fields[fieldName];
        const source = await devKit.getSourceFieldByName(field.type);

        if (field.type === 'relation') {
          const references = field.details['references'] as Command<{
            name: string;
          }>;
          const relatedEntity = references.payload.name;
          const shortText = await devKit.getSourceFieldByName('short-text');

          const relationIdSourceField =
            await devKit.getSourceFieldByName('relation-id');
          const relationSourceField =
            await devKit.getSourceFieldByName('relation');
          switch (field.details['relationship']) {
            case 'many-to-one':
              {
                // Self table
                {
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[fieldName],
                      displayName: fieldName,
                      tableId: tablesIds[tableName],
                      sourceId: source.id,
                      details: {
                        ...mapQueries(field.details),
                        ...{
                          nameOfColumnOnRelatedEntity: camelcase(
                            pluralize.plural(tableName),
                          ),
                          relatedEntityName: relatedEntity,
                        },
                      },
                    } satisfies AddFieldCommand,
                  });
                }
                {
                  const relationIdFieldName = camelcase(
                    `${pluralize.singular(fieldName)} Id`,
                  );
                  fieldsIds[relationIdFieldName] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[relationIdFieldName],
                      displayName: relationIdFieldName,
                      tableId: tablesIds[tableName],
                      sourceId: shortText.id,
                      details: {
                        length: null,
                      },
                    } satisfies AddFieldCommand,
                  });
                }

                // Other table
                {
                  const relatedEntity = references.payload.name;
                  const fieldNameOnTheOtherTable = camelcase(
                    pluralize.plural(tableName),
                  );
                  fieldsIds[fieldNameOnTheOtherTable] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[fieldNameOnTheOtherTable],
                      displayName: fieldNameOnTheOtherTable,
                      tableId: tablesIds[relatedEntity],
                      sourceId: relationSourceField.id,
                      details: {
                        ...mapQueries(field.details),
                        ...{
                          ignoreIfExist: true,
                          joinSide: false,
                          nameOfColumnOnRelatedEntity: camelcase(fieldName),
                          relationship: 'one-to-many',
                          references: tablesIds[tableName],
                          // FIXME: relation field have TableName stored in them
                          relatedEntityName: tableName,
                        },
                      },
                    } satisfies AddFieldCommand,
                  });
                }
                {
                  const relationIdFieldName = `${camelcase(
                    pluralize.plural(tableName),
                  )}Ids`;
                  fieldsIds[relationIdFieldName] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[relationIdFieldName],
                      displayName: relationIdFieldName,
                      tableId: tablesIds[relatedEntity],
                      sourceId: relationIdSourceField.id,
                      details: {
                        ignoreIfExist: true,
                        virtualRelationField: true,
                        tableName: relatedEntity,
                        columnNameOnSelfTable: pluralize.plural(
                          camelcase(tableName),
                        ),
                        relationship: 'one-to-many',
                        references: tablesIds[tableName],
                      },
                    } satisfies AddFieldCommand,
                  });
                }
              }
              break;
            case 'one-to-many':
              {
                // Self table
                {
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[fieldName],
                      displayName: fieldName,
                      tableId: tablesIds[tableName],
                      sourceId: source.id,
                      details: {
                        ...mapQueries(field.details),
                        ...{
                          nameOfColumnOnRelatedEntity: camelcase(
                            pluralize.singular(tableName),
                          ),
                          relatedEntityName: relatedEntity,
                        },
                      },
                    } satisfies AddFieldCommand,
                  });
                }
                {
                  const relationIdFieldName = camelcase(
                    `${pluralize.singular(fieldName)} Id`,
                  );
                  fieldsIds[relationIdFieldName] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[relationIdFieldName],
                      displayName: relationIdFieldName,
                      tableId: tablesIds[tableName],
                      sourceId: shortText.id,
                      details: {
                        length: null,
                      },
                    } satisfies AddFieldCommand,
                  });
                }

                // Other table
                {
                  const relatedEntity = references.payload.name;
                  const fieldNameOnTheOtherTable = camelcase(
                    pluralize.plural(relatedEntity),
                  );
                  fieldsIds[fieldNameOnTheOtherTable] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[fieldNameOnTheOtherTable],
                      displayName: fieldNameOnTheOtherTable,
                      tableId: tablesIds[relatedEntity],
                      sourceId: relationSourceField.id,
                      details: {
                        ...mapQueries(field.details),
                        ...{
                          joinSide: false,
                          nameOfColumnOnRelatedEntity: camelcase(fieldName),
                          relationship: field.details['relationship'],
                          relatedEntityName: tableName,
                          references: tablesIds[tableName],
                        },
                      },
                    } satisfies AddFieldCommand,
                  });
                }
                {
                  const relationIdFieldName = `${camelcase(
                    pluralize.singular(tableName),
                  )}Id`;
                  fieldsIds[relationIdFieldName] ??= v4();
                  commands.push({
                    command: 'AddField',
                    payload: {
                      id: fieldsIds[relationIdFieldName],
                      displayName: relationIdFieldName,
                      tableId: tablesIds[relatedEntity],
                      sourceId: relationIdSourceField.id,
                      details: {
                        virtualRelationField: true,
                        tableName: relatedEntity,
                        columnNameOnSelfTable: pluralize.singular(
                          camelcase(tableName),
                        ),
                        relationship: 'one-to-one',
                        references: tablesIds[tableName],
                      },
                    } satisfies AddFieldCommand,
                  });
                }
              }
              break;
            default:
              throw new Error(
                `Unimplemented relationship ${field.details['relationship']}`,
              );
          }
        } else {
          commands.push({
            command: 'AddField',
            payload: {
              id: fieldsIds[fieldName],
              displayName: fieldName,
              tableId: tablesIds[tableName],
              sourceId: source.id,
              details: mapQueries(field.details),
            } satisfies AddFieldCommand,
          });
        }
      }
      // Validations
      for (const fieldName in table.fields) {
        const field = table.fields[fieldName];
        if (field.validations.length) {
          const validations = uniquify(
            field.validations.flat(),
            (item) => item.name,
          );
          const fieldValidation: FieldValidation[] = [];
          for (const validation of validations) {
            const source = await devKit.getValidationByName(validation.name);
            fieldValidation.push({
              details: validation.config,
              name: source.name,
              sourceId: source.id,
            });
          }
          commands.push({
            command: 'AdjustFieldValidation',
            payload: {
              fieldId: fieldsIds[fieldName],
              tableId: tablesIds[tableName],
              validations: fieldValidation,
            } satisfies AdjustFieldValidationCommand,
          });
        }
      }

      // Relations
    }

    // Workflows
    for (const workflow of feature.workflows) {
      const trigger = await devKit.getTriggerByName(workflow.trigger.type);

      commands.push({
        command: 'AddWorkflow',
        payload: {
          id: workflowIds[workflow.name],
          featureId: featureId,
          displayName: workflow.name,
          details: mapQueries({
            ...workflow.trigger.config,
            sequence: workflow.sequence,
            inputs: workflow.trigger.inputs ?? {},
            imports: projectDefinition.imports.map(
              (imp) =>
                ({
                  kind: StructureKind.ImportDeclaration,
                  isTypeOnly: imp.isTypeOnly,
                  moduleSpecifier: imp.moduleSpecifier,
                  namedImports: imp.namedImports,
                  defaultImport: imp.defaultImport,
                  namespaceImport: imp.namespaceImport,
                }) satisfies ImportDeclarationStructure,
            ),
          }),
          sourceId: trigger.id,
        } satisfies AddWorkflowCommand,
      });

      for (const policyName of workflow.trigger.policies ?? []) {
        commands.push({
          command: 'AssignPolicyToWorkflow',
          payload: {
            featureId: featureId,
            policyId: policiesIds[policyName],
            workflowId: workflowIds[workflow.name],
          } satisfies AssignPolicyToWorkflowCommand,
        });
      }

      commands.push({
        command: 'AssignTagToWorkflow',
        payload: {
          featureId: featureId,
          workflowId: workflowIds[workflow.name],
          tagId: tagsIds[workflow.tag],
        } satisfies AssignTagToWorkflowCommand,
      });

      const outputResult = getReturnTypeOfDefaultExport(workflow.output);
      commands.push({
        command: 'PatchWorkflowOutputDetails',
        payload: {
          workflowId: workflowIds[workflow.name],
          details: {
            code: {
              inner: outputResult.returnCode || '',
              outer: '',
            },
            returnTypeStr: outputResult.returnTypeStr,
            properties: outputResult.properties.map((it) => ({
              ...it,
              kind: it.parameter?.split('steps.')[1] as string,
            })),
          },
        } satisfies PatchWorkflowOutputDetailsCommand,
      });
    }

    // Actions
    for (const workflow of feature.workflows) {
      for (const actionName in workflow.actions) {
        const action = workflow.actions[actionName];
        if (typeof action === 'function') {
          continue;
        }
        const source = await devKit.getSourceActionByName(action.type);
        commands.push({
          command: 'AddActionToWorkflow',
          payload: {
            id: actionsIds[actionName],
            featureId: featureId,
            workflowId: workflowIds[workflow.name],
            displayName: actionName,
            details: {
              ...mapQueries(action.config),
              trigger: workflow.trigger.type,
            },
            outputName: action.outputName,
            sourceId: source.id,
          } satisfies AddActionToWorkflowCommand,
        });
      }
    }
  }

  // TODO: some operations needs ids (insert-record columns)
  // do the details updates after the creation commands
  // so that we can reference the created entities
  return commands;
}

export function isRequest<T extends Record<string, unknown>>(
  command: any,
): command is Command<T> {
  if (isNullOrUndefined(command)) {
    return false;
  }
  if (Array.isArray(command)) {
    return false;
  }
  if (typeof command !== 'object') {
    return false;
  }
  return 'command' in command;
}

export async function execute(projectDefinition: EmptyProject) {
  const commands = await toCommands(projectDefinition);
  const res = await fetch('http://localhost:4321/dop', {
    method: 'POST',
    body: JSON.stringify({ commands, projectName: projectDefinition.name }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.json();
}
execute.features = async (features: EmptyProject['features']) => {
  const res = await fetch('http://localhost:4321/dop', {
    method: 'POST',
    body: JSON.stringify(features),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.json();
};
