import firebaseAppSetup from './firebase-app-setup.txt';
import serviceAccountInterface from './service-account-interface.txt';
import subjectText from './subject.txt';

import { Injectable, ServiceLifetime } from 'tiny-injector';

import {
  ActionProperty,
  Contracts,
  IExtension,
} from '@faslh/compiler/contracts';
import { ProjectFS } from '@faslh/compiler/sdk/devkit';

// FIXME: we need some kind of extension info pulling strategy
// isAuthenicated function needs to know about the trigger context
// so I need the routing extension to tell me that
// hey I have these arguments and this is their types
// or just make adapter for the middleware context to generic web context
// the adapter will be exposed from routing extension
// but how will the adapter be used? I don't want to use
// it directly over the middlewares unless I do it in the "authorize" function

// FIXME: how to make policies return different error responses?
// isPublished policy should return 400
// isAuthenticated policy should return 401
// isEgypt policy should return 403
@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class FirebaseAuthExtension implements IExtension {
  constructor(private readonly _projectFS: ProjectFS) {}

  async handleSetup?(
    contract: Record<string, ActionProperty>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeCorePath('service-account.ts'),
        content: [serviceAccountInterface],
      },
      {
        filePath: this._projectFS.makeCorePath('firebase.ts'),
        content: [firebaseAppSetup],
      },
      {
        filePath: this._projectFS.makeIdentityPath('subject.ts'),
        content: [subjectText],
      },
    ];
  }
}

// Auth level
// over workflow
// over step in workflow
// over field in step (I can't imagine it)
