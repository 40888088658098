import { Injectable, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import {
  ConcreteContracts,
  Contracts,
  IExtension,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  TriggerExtension,
  signutureInputs,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import {
  Arg,
  AsyncVisitor,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  StringLiteral,
  StringVisitor,
  camelcase,
  logMe,
  spinalcase,
} from '@faslh/utils';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class NodeCronExtension implements IExtension, TriggerExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
  ) {}
  public triggers = ['cd76caa3-e9f0-49b8-bf7a-0ebed83bd486'];

  public async handleSetup(
    details: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      // {
      //   filePath: this._projectFS.makeControllerPath('integrations', 'router'),
      //   content: [webhookHandler],
      // },
      // {
      //   filePath: this._projectFS.makeCorePath('github-webhooks.ts'),
      //   content: [receiveWebhook],
      // },
    ];
  }

  public onFeatureContract(
    contract: Contracts.FeatureContract,
    addFile: (concrete: ConcreteContracts.WorkflowConcreteStructure) => void,
  ): void {
    if (contract.workflows.length === 0) {
      return;
    }

    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'cron',
      ),
      structure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: 'node-cron',
          namedImports: ['schedule'],
        },
        ...contract.workflows
          .map((it) => it.trigger.policies)
          .flat()
          .map(
            (it) =>
              ({
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier: `../../identity/${spinalcase(it)}.policy`,
                namedImports: [camelcase(it)],
              }) as morph.ImportDeclarationStructure,
          ),
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: `./jobs`,
          namespaceImport: 'jobs',
        },

        ...(contract.workflows.map((contract) => {
          logMe({ options: contract.trigger });
          const options: [string, string][] = [];

          if ('immediate' in contract.trigger.details) {
            options.push(['runOnInit', contract.trigger.details['immediate']]);
          }

          const displayName = `jobs.${camelcase(contract.trigger.displayName)}`;
          const authorize = contract.trigger.policies.length
            ? `,${contract.trigger.policies
                .map((it) => `${camelcase(it)}`)
                .join(',')}`
            : '';

          return `schedule(
  '${contract.trigger.details['pattern']}'
  ${authorize},
  ${displayName},
  ${options.length ? this._devKit.toLiteralObject(options) : ''}

);
`;
        }) as ConcreteContracts.MorphStatementWriter),
      ],
    });
  }

  async processTrigger(
    contract: ProcessTriggerInput,
    workflowInputs: Record<string, ParsedInput>,
  ): ProcessTriggerOutput {
    const displayName = camelcase(contract.displayName);
    const inputName = camelcase('input');

    const vars: Record<string, string> = {};

    const inputParams = signutureInputs({ ...contract.inputs });
    const actionInputsStr = this._devKit.toLiteralObject(
      inputParams.map(
        ([name, prop]) =>
          [
            name,
            {
              ...prop,
              value: `payload.${prop.value}`,
            },
          ] as const,
      ),
    );
    const workflowLoneParams = signutureInputs({ ...workflowInputs });

    for (const [name, prop] of workflowLoneParams) {
      vars[name] = `const ${name} = ${prop.value}`;
    }
    const workflowParams: string[] = [];
    if (inputParams.length) {
      workflowParams.push(inputName);
    }
    if (workflowLoneParams.length) {
      workflowParams.push(...workflowLoneParams.map(([name]) => name));
    }

    const workflowInputsStructures: ConcreteContracts.MorphStatementWriter[] =
      Object.values(workflowInputs)
        .map((prop) => prop.structure)
        .filter(Boolean) as ConcreteContracts.MorphStatementWriter[];

    return [
      {
        filePath: this._projectFS.makeIndexFilePath(
          contract.featureName,
          'jobs',
        ),
        structure: [
          {
            kind: morph.StructureKind.ExportDeclaration,
            moduleSpecifier: this._projectFS.makeExportPath(
              contract.displayName,
              `job`,
            ),
          },
        ],
      },
      {
        filePath: this._projectFS.makeJobRoutePath(
          contract.featureName,
          contract.displayName,
        ),
        structure: [
          ...workflowInputsStructures.flat(),
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../${spinalcase(contract.tag)}`,
            namespaceImport: camelcase(contract.tag),
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier:
              this._projectFS.makeCoreImportSpecifier('core/validation'),
            namedImports: ['validateOrThrow'],
          },
          {
            kind: morph.StructureKind.Function,
            isAsync: true,
            isDefaultExport: false,
            isExported: true,
            name: displayName,
            parameters: [],
            statements: [
              ...Object.values(vars),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `const ${inputName} = ${actionInputsStr}`,
                ),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `validateOrThrow(${camelcase(contract.tag)}.${
                    contract.schemaName
                  }, ${inputName})`,
                ),
              (writer) =>
                writer.writeLine(
                  `await ${camelcase(
                    contract.tag,
                  )}.${camelcase(contract.operationName)}(${workflowParams.join(',')})`,
                ),
            ],
          },
        ],
      },
    ];
  }
}

class HonoInputVisitor extends AsyncVisitor<any> {
  override visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitIdentifier(node: Identifier): Promise<any> {
    return node.value;
  }
  override async visitStringLiteral(node: StringLiteral): Promise<any> {
    return `'${node.value}'`;
  }
  override visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
export class InputsExtractor extends StringAsyncVisitor {
  constructor(private _visitor: AsyncVisitor<unknown>) {
    super();
  }
  override async visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
class SimpleVisitor extends StringVisitor {
  override visitArg(node: Arg): any {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): any {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): any {
    return node.toLiteral(this);
  }
  override visitPropertyAccess(node: PropertyAccess): any {
    return node.toLiteral(this);
  }
  override visitNamespace(node: Namespace): any {
    return {
      namespace: node.name.accept(this),
      value: node.expression,
    };
  }
}
