import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { PatchWorkflowDetails } from '@faslh/api/table/commands';
import { Workflow } from '@faslh/api/table/domain';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(PatchWorkflowDetails)
export default class extends IRequestHandler<PatchWorkflowDetails> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: PatchWorkflowDetails): Promise<void> {
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    workflow.patchDetails(this._eventUtils.getMetadata(), {
      workflowId: workflow.id,
      details: JSON.stringify(command.details ?? {}),
    });
    await this._eventStore.save(workflow);
  }
}
