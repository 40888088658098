import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddInitialCommitCommand } from '@faslh/api/releases/commands';
import { Commit } from '@faslh/api/releases/models';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddInitialCommitCommand)
export class AddInitialCommitCommandHandler extends IRequestHandler<AddInitialCommitCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils
  ) {
    super();
  }

  async handle(command: AddInitialCommitCommand): Promise<void> {
    const commit = Commit.initialCommit({
      ...this._eventUtils.getMetadata(),
    });
    await this._eventStore.save(commit);
  }
}
