import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { UpdateFieldDetailsCommand } from '@faslh/api/table/commands';
import { FieldDetailsUpdatedData, Table } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(UpdateFieldDetailsCommand)
export default class extends IRequestHandler<
  UpdateFieldDetailsCommand,
  string | undefined
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(
    command: UpdateFieldDetailsCommand,
  ): Promise<string | undefined> {
    const table = await this._eventStore.hyrdateAggregate(
      command.tableId,
      Table,
    );

    if (!table.hasField(command.fieldId)) {
      throw new Error(`Field ${command.fieldId} does not exist`);
    }

    const field = table.updateFieldDetails(
      this._eventUtils.getMetadata(),
      mapper.map(command, UpdateFieldDetailsCommand, FieldDetailsUpdatedData),
    );

    await this._eventStore.save(table);
    return field.id;
  }
}
