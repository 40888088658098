import { QueryBuilder, toAst } from '@january/compiler/transpilers';
import dedent from 'dedent';
import { Injectable, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import {
  ConcreteContracts,
  Contracts,
  useInput,
} from '@faslh/compiler/contracts';
import { ProjectFS } from '@faslh/compiler/sdk/devkit';
import { camelcase, notNullOrUndefined, pascalcase } from '@faslh/utils';

import {
  GenerateCommandInput,
  GenerateQueryInput,
  GenerateRawSQLInput,
  OrmCodeWriter,
} from '../orm';
import { TypeOrmVisitor, runTypeormVisitor } from './typeorm.visitor';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
  serviceType: OrmCodeWriter,
})
export class TypeORMCodeWriter extends OrmCodeWriter {
  constructor(private readonly _projectFS: ProjectFS) {
    super();
  }

  #generateField(contract: Contracts.Field) {
    const decorators: morph.DecoratorStructure[] = [];
    const structure: morph.PropertyDeclarationStructure = {
      kind: morph.StructureKind.Property,
      name: camelcase(contract.displayName),
      type: contract.primitiveType,
      decorators: decorators,
    };
    return structure;
  }

  public override generateQuery(
    contract: GenerateQueryInput,
  ): ConcreteContracts.WorkflowStructure {
    const classTableName = pascalcase(contract.tableName);
    const statements: string[] = [];

    contract.helpers.useQb(
      (qb) =>
        `const ${qb} = createQueryBuilder(${classTableName}, '${classTableName}');`,
    );

    contract.helpers.useQb(
      (qb) =>
        `${new TypeOrmVisitor(
          toAst(contract.query.whereBy) as QueryBuilder.QuerySelectExpr,
          pascalcase(contract.tableName),
          qb,
        ).execute()}`,
    );

    Object.entries(contract.query.sortBy).forEach(([name, prop]) => {
      contract.helpers.useQb(
        (qb) =>
          `${qb}.addOrderBy('${name}', '${(prop.value as string).toUpperCase()}');`,
      );
    });

    contract.helpers.useQb((qbString) =>
      (() => {
        switch (contract.pagination) {
          case 'limit_offset':
            return `
          const paginationMetadata = deferredJoinPagination(${qbString}, {
            pageSize: pageSize,
            pageNo: pageNo,
            count: await qb.getCount(),
          });
        `;
          case 'deferred_joins':
            return `
          const paginationMetadata = deferredJoinPagination(${qbString}, {
            pageSize: pageSize,
            pageNo: pageNo,
            count: await qb.getCount(),
          });
        `;
          case 'cursor':
            return `
          const paginationMetadata = cursorPagination(${qbString}, {
            pageSize: pageSize,
            count: await qb.getCount(),
          });
        `;

          default:
            return ``;
        }
      })(),
    );

    contract.helpers.useQb(
      (qb) =>
        `const ${
          contract.single === false ? 'records' : '[record]'
        } = (await execute(${qb}${
          contract.localizable ? 'assignI18n()' : ''
        }));`,
    );

    if (contract.localizable) {
      contract.helpers.useQb((qb) => `withI18n(${qb}, input.locales);`);
    }

    return {
      actionStructure: [
        (writer) => statements.map((it) => writer.writeLine(it)),
        (writer) =>
          writer.conditionalWriteLine(
            contract.pagination === 'none',
            `return record;`,
          ),
        (writer) =>
          writer.conditionalWriteLine(
            contract.pagination !== 'none' && !contract.inline,
            dedent`return {
                meta: paginationMetadata(records),
                records: records,
              }`,
          ),
        (writer) =>
          writer.conditionalWriteLine(
            contract.pagination !== 'none' && contract.inline,
            dedent`const result {
                meta: paginationMetadata(records),
                records: records,
              };
              return result;
              `,
          ),
      ],
      topLevelStructure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier:
            this._projectFS.makeCoreImportSpecifier('core/execute'),
          namedImports: [
            'execute',
            'createQueryBuilder',
            ...[
              contract.pagination === 'deferred_joins'
                ? 'deferredJoinPagination'
                : contract.pagination === 'cursor'
                  ? 'cursorPagination'
                  : contract.pagination === 'limit_offset'
                    ? 'limitOffsetPagination'
                    : null,
            ].filter(notNullOrUndefined),
          ],
        },
        {
          // TODO: explicitly export date helpers because
          // the query dsl is emitting them without importing them
          // FIXME: Maybe we should move helpers parsing (non formal sql) to the query dsl
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: this._projectFS.makeCoreImportSpecifier('core/date'),
          namedImports: [
            'week',
            'month',
            'year',
            'currentMonth',
            'currentWeek',
            'currentYear',
          ],
        },
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier:
            this._projectFS.makeEntityImportSpecifier(classTableName),
          defaultImport: classTableName,
        },
      ],
    };
  }

  public override generateRaw(
    contract: GenerateRawSQLInput,
  ): ConcreteContracts.WorkflowStructure {
    const processdActions: string[] = [];
    const outputName = contract.action.output.displayName;
    const outVarName = contract.inline
      ? outputName
        ? `const ${outputName} = await`
        : `await`
      : outputName
        ? 'return await'
        : 'await';
    switch (contract.action.sourceAction.name) {
      case 'raw-sql-query':
        {
          processdActions.push(
            dedent(
              `
              const serialized = sql\`${contract.action.transfer['query']}\`
              ${outVarName} dataSource.query(serialized.sql, serialized.values)
              `,
            ),
          );
        }
        break;
      default:
        throw new Error(
          `Action not supported: ${contract.action.sourceAction.name}`,
        );
    }

    return {
      actionStructure: [
        (writer) => processdActions.map((it) => writer.writeLine(it)),
      ],
      topLevelStructure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier:
            this._projectFS.makeCoreImportSpecifier('core/data-source'),
          defaultImport: 'dataSource',
        },
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: 'sql-template-tag',
          defaultImport: 'sql',
        },
      ],
    };
  }

  public override generateCommand(
    contract: GenerateCommandInput,
  ): ConcreteContracts.WorkflowStructure {
    const processdActions: string[] = [];
    const classTableName = pascalcase(contract.tableName);
    const qbVarName = `qb`;
    const outputName = contract.action.output.displayName;
    const outVarName = contract.inline
      ? outputName
        ? `const ${outputName} = await`
        : `await`
      : outputName
        ? 'return await'
        : 'await';
    switch (contract.action.sourceAction.name) {
      case 'insert-record':
        {
          const inputs = Object.entries(contract.action.inputs)
            .map(([name, prop]) => {
              if (prop.static) {
                return `${camelcase(name)}: ${prop.value}`;
              }
              return `${camelcase(name)}`;
            })
            .join(', ');

          processdActions.push(
            dedent(
              `${outVarName} saveEntity(${classTableName},${
                inputs.length ? `{${inputs}}` : '{}'
              });
              `,
            ),
          );
        }
        break;
      case 'upsert-record':
        {
          const inputs = Object.entries(contract.action.inputs)
            .map(([name, prop]) => {
              if (prop.static) {
                return `${camelcase(name)}: ${prop.value}`;
              }
              return `${camelcase(name)}`;
            })
            .join(', ');
          const conflictFields = (
            contract.action.transfer['conflictFields'] ?? ['id']
          ).map((it: any) => `'${it.input}'`);
          processdActions.push(
            dedent(
              `${outVarName} upsertEntity(${classTableName},${
                inputs.length ? `{${inputs}}` : '{}'
              }, [${conflictFields.join(',')}]);
              `,
            ),
          );
        }
        break;
      case 'set-fields':
        {
          const queryString = runTypeormVisitor({
            query: contract.action.transfer['query']?.['whereBy'],
            tableName: classTableName,
            qbVarName: qbVarName,
          });
          let counters = Object.entries(contract.action.inputs).filter(
            ([name, prop]) =>
              prop.data?.['increment'] || prop.data?.['decrement'],
          );
          const counterParametrs = counters.map(([name, prop]) => {
            if (prop.data?.['decrement']) {
              return `${camelcase('decrement ' + name + ' by')}: ${prop.value}`;
            }
            return `${camelcase('increment ' + name + ' by')}: ${prop.value}`;
          });
          if (counters.length) {
            const inputs = Object.entries(contract.action.inputs)
              .filter(([name, prop]) => prop.data?.['column'])
              .map(([name, prop]) => {
                if (prop.data?.['increment']) {
                  return `${camelcase(name)}: () => '${camelcase(name)} + :${camelcase('increment ' + name + ' by')}'`;
                }
                if (prop.data?.['decrement']) {
                  return `${camelcase(name)}: () => '${camelcase(name)} - :${camelcase('decrement ' + name + ' by')}'`;
                }
                if (prop.static) {
                  return `${camelcase(name)}: ${prop.value}`;
                }
                return `${camelcase(name)}`;
              })
              .join(', ');
            processdActions.push(
              dedent(
                `const qb = createQueryBuilder(${classTableName}, '${classTableName}');
                ${queryString}
                ${outVarName} qb
                  .update()
                  .set({${inputs}})
                  .setParameters({${counterParametrs}})
                  .execute();
              `,
              ),
            );
          } else {
            const inputs = Object.entries(contract.action.inputs)
              .filter(([name, prop]) => prop.data?.['column'])
              .map(([name, prop]) => {
                if (prop.static) {
                  return `${camelcase(name)}: ${prop.value}`;
                }
                return `${camelcase(name)}`;
              })
              .join(', ');
            processdActions.push(
              dedent(
                `const qb = createQueryBuilder(${classTableName}, '${classTableName}');
            ${queryString}
            ${outVarName} updateEntity(${qbVarName}, ${
              inputs.length ? `{${inputs}}` : '{}'
            });
              `,
              ),
            );
          }
        }
        break;
      case 'increment-field': {
        const queryString = runTypeormVisitor({
          query: contract.action.transfer['query']?.['whereBy'],
          tableName: classTableName,
          qbVarName: qbVarName,
        });
        processdActions.push(
          dedent(`const qb = createQueryBuilder(${classTableName}, '${classTableName}');
            ${queryString}
            ${outVarName} increment(qb, '${useInput(contract.action.transfer['field'])}', ${useInput(contract.action.inputs['value'])});
              `),
        );
        break;
      }
      case 'decrement-field': {
        const queryString = runTypeormVisitor({
          query: contract.action.transfer['query']?.['whereBy'],
          tableName: classTableName,
          qbVarName: qbVarName,
        });
        processdActions.push(
          dedent(`const qb = createQueryBuilder(${classTableName}, '${classTableName}');
            ${queryString}
            ${outVarName} decrement(qb, '${useInput(contract.action.transfer['field'])}', ${useInput(contract.action.inputs['value'])});
              `),
        );
        break;
      }
      case 'delete-record':
        {
          // only works for "at" option
          const queryString = runTypeormVisitor({
            query: contract.action.transfer['query']?.['whereBy'],
            tableName: classTableName,
            qbVarName: qbVarName,
          });
          processdActions.push(
            dedent(`const qb = createQueryBuilder(${classTableName}, '${classTableName}');
            ${queryString}
            ${outVarName} removeEntity(${classTableName}, ${qbVarName});
            `),
          );
        }
        break;
      case 'check-record-existance':
        {
          const queryString = runTypeormVisitor({
            query: contract.action.transfer['query']?.['whereBy'],
            tableName: classTableName,
            qbVarName,
          });
          processdActions.push(
            dedent(`const ${qbVarName} = createQueryBuilder(${classTableName}, '${classTableName}');
            ${queryString}
            ${outVarName} ${qbVarName}.getOne().then(Boolean);
            `),
          );
        }
        break;
      // case 'i18n':
      //   return new I18nAction(it.details.columns);

      default:
        throw new Error(
          `Action not supported: ${contract.action.sourceAction.name}`,
        );
    }

    return {
      actionStructure: [
        (writer) => processdActions.map((it) => writer.writeLine(it)),
      ],
      topLevelStructure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: 'typeorm',
          namedImports: ['Brackets'],
        },
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier:
            this._projectFS.makeCoreImportSpecifier('core/execute'),
          namedImports: [
            'createQueryBuilder',
            'removeEntity',
            'saveEntity',
            'updateEntity',
            'increment',
            'decrement',
            'upsertEntity',
          ],
        },
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: this._projectFS.makeCoreImportSpecifier('core/date'),
          namedImports: [
            'week',
            'month',
            'year',
            'currentMonth',
            'currentWeek',
            'currentYear',
          ],
        },
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier:
            this._projectFS.makeEntityImportSpecifier(classTableName),
          defaultImport: classTableName,
        },
      ],
    };
  }

  public override generateTable(
    contract: Contracts.Table,
  ): ConcreteContracts.MorphStatementWriter {
    return [
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier: 'typeorm',
        namedImports: [
          'Brackets',
          'Entity',
          'PrimaryColumn',
          'PrimaryGeneratedColumn',
          'Relation',
          'ManyToOne',
          'OneToMany',
          'OneToOne',
          'Column',
          'CreateDateColumn',
          'UpdateDateColumn',
          'DeleteDateColumn',
          'JoinColumn',
          'JoinTable',
          'ManyToMany',
          'Index',
          'RelationId',
        ],
      },
      {
        isDefaultExport: true,
        name: contract.tableName,
        kind: morph.StructureKind.Class,
        decorators: [
          {
            name: 'Entity',
            arguments: [`"${pascalcase(contract.tableName)}"`],
          },

          ...(contract.indexes ?? []).map((index) => ({
            name: 'Index',
            arguments: [
              `[${(index.columns ?? []).map((c) => `'${c}'`).join(',')}]`,
              /**index.unique*/ true ? '{ unique: true }' : '{}',
            ],
          })),
        ],
      },
    ];
  }

  public override generateCreatedAtField(contract: Contracts.Field) {
    const structure = this.#generateField(contract);
    structure.decorators ??= [];
    structure.hasExclamationToken = true;
    structure.hasQuestionToken = false;
    structure.decorators.push({
      kind: morph.StructureKind.Decorator,
      name: 'CreateDateColumn',
      arguments: [],
    });
    return structure;
  }

  public override generateDeletedAtField(contract: Contracts.Field) {
    // FIXME: remove deleted at field and replace it with status (draft, published, archived)
    const structure = this.#generateField(contract);
    structure.decorators ??= [];
    structure.hasExclamationToken = false;
    structure.hasQuestionToken = true;
    structure.decorators.push({
      kind: morph.StructureKind.Decorator,
      name: 'DeleteDateColumn',
      arguments: [],
    });
    return structure;
  }

  public override generateUpdatedAtField(contract: Contracts.Field) {
    const structure = this.#generateField(contract);
    structure.decorators ??= [];
    structure.hasExclamationToken = false;
    structure.hasQuestionToken = true;
    structure.decorators.push({
      kind: morph.StructureKind.Decorator,
      name: 'UpdateDateColumn',
      arguments: [],
    });
    return structure;
  }

  public override generatePrimaryField(contract: Contracts.PrimaryField) {
    const structure = this.#generateField(contract);
    structure.decorators ??= [];
    structure.hasExclamationToken = true;
    structure.hasQuestionToken = false;
    // TODO: Apply validation

    switch (contract.keyType) {
      case 'primary-key-custom':
        structure.decorators.push({
          kind: morph.StructureKind.Decorator,
          name: 'PrimaryColumn',
          arguments: ['"varchar"'],
        });
        break;
      case 'primary-key-number':
        {
          if (contract.generated) {
            structure.decorators.push({
              kind: morph.StructureKind.Decorator,
              name: 'PrimaryGeneratedColumn',
              arguments: ['"increment"'],
            });
          } else {
            structure.decorators.push({
              kind: morph.StructureKind.Decorator,
              name: 'PrimaryColumn',
              arguments: ['"integer"'],
            });
          }
        }
        break;
      case 'primary-key-uuid':
        if (contract.generated) {
          structure.decorators.push({
            kind: morph.StructureKind.Decorator,
            name: 'PrimaryGeneratedColumn',
            arguments: ['"uuid"'],
          });
        } else {
          structure.decorators.push({
            kind: morph.StructureKind.Decorator,
            name: 'PrimaryColumn',
            arguments: ['"uuid"'],
          });
        }
        break;

      default:
        throw new Error(
          `Primary key type "${contract.keyType}" is not supported.`,
        );
    }

    return structure;
  }

  public override generateField(contract: Contracts.FieldUnion) {
    if (contract.nativeType === 'primary-key') {
      return {
        fieldStructure: this.generatePrimaryField(contract),
        topLevelStructure: [],
      };
    }
    if (contract.nativeType === 'createdAt') {
      return {
        fieldStructure: this.generateCreatedAtField(contract),
        topLevelStructure: [],
      };
    }
    if (contract.nativeType === 'deletedAt') {
      return {
        fieldStructure: this.generateDeletedAtField(contract),
        topLevelStructure: [],
      };
    }
    if (contract.nativeType === 'updatedAt') {
      return {
        fieldStructure: this.generateUpdatedAtField(contract),
        topLevelStructure: [],
      };
    }

    const structure = this.#generateField(contract);
    const topLevelStructure: ConcreteContracts.MorphStatementWriter = [];
    const columnOptions: string[] = [];
    structure.decorators ??= [];

    if (contract.mandatory) {
      structure.hasExclamationToken = true;
      structure.hasQuestionToken = false;
      columnOptions.push('nullable: false');
    } else {
      structure.hasExclamationToken = false;
      structure.hasQuestionToken = true;
      columnOptions.push('nullable: true');
      structure.type = `${structure.type}|null`;
    }

    // TODO: Apply validation

    switch (contract.nativeType) {
      case 'relation-id':
        if (contract.virtualRelationField) {
          structure.decorators.push({
            kind: morph.StructureKind.Decorator,
            name: 'RelationId',
            arguments: [
              `(entity: ${pascalcase(contract.tableName)}) => entity.${
                contract.columnNameOnSelfTable
              }`,
            ],
          });
        }
        return {
          fieldStructure: structure,
          topLevelStructure: topLevelStructure,
        };
      case 'relation':
        structure.type = pascalcase(contract.relatedEntityName);
        switch (contract.relationship) {
          case 'one-to-one':
            {
              topLevelStructure.push({
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier: this._projectFS.makeEntityImportSpecifier(
                  pascalcase(contract.relatedEntityName),
                ),
                defaultImport: pascalcase(contract.relatedEntityName),
              });
              // columnOptions.push('cascade: true');
              if (contract.joinSide) {
                structure.decorators.push({
                  kind: morph.StructureKind.Decorator,
                  name: 'JoinColumn',
                  arguments: [],
                });
              }
              structure.decorators.push({
                kind: morph.StructureKind.Decorator,
                name: 'OneToOne',
                arguments: [
                  `() => ${pascalcase(contract.relatedEntityName)}`,
                  `(relatedEntity) => relatedEntity.${camelcase(
                    contract.nameOfColumnOnRelatedEntity,
                  )}`,
                  columnOptions.length ? `{${columnOptions.join(',')}}` : '',
                ],
              });
            }
            break;
          case 'many-to-one':
          case 'one-to-many':
            {
              topLevelStructure.push({
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier: this._projectFS.makeEntityImportSpecifier(
                  pascalcase(contract.relatedEntityName),
                ),
                defaultImport: pascalcase(contract.relatedEntityName),
              });
              if (contract.joinSide) {
                structure.type = `Relation<${pascalcase(
                  contract.relatedEntityName,
                )}>`;
                structure.decorators.push({
                  kind: morph.StructureKind.Decorator,
                  name: 'ManyToOne',
                  arguments: [
                    `() => ${pascalcase(contract.relatedEntityName)}`,
                    `(relatedEntity) => relatedEntity.${contract.nameOfColumnOnRelatedEntity}`,
                    columnOptions.length ? `{${columnOptions.join(',')}}` : '',
                  ],
                });
              } else {
                structure.type = `${pascalcase(contract.relatedEntityName)}[]`;
                structure.decorators.push({
                  kind: morph.StructureKind.Decorator,
                  name: 'OneToMany',
                  arguments: [
                    `() => ${pascalcase(contract.relatedEntityName)}`,
                    `(relatedEntity) => relatedEntity.${contract.nameOfColumnOnRelatedEntity}`,
                    columnOptions.length ? `{${columnOptions.join(',')}}` : '',
                  ],
                });
              }
            }
            break;
          case 'many-to-many':
            {
              //
            }
            break;
          default:
            throw new Error(
              `Relationship ${contract.relationship} is not supported.`,
            );
        }
        return {
          fieldStructure: structure,
          topLevelStructure: topLevelStructure,
        };
      case 'json':
      case 'uuid':
        columnOptions.push(`type: "${contract.nativeType}"`);
        break;
      case 'varchar':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.nativeType}"`);
        if (notNullOrUndefined(contract.length)) {
          columnOptions.push(`length: "${contract.length}"`);
        }
        break;
      case 'enum':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.nativeType}"`);
        columnOptions.push(`enum: [${contract.values.map((v) => `"${v}"`)}]`);
        break;
      case 'decimal':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.nativeType}"`);
        columnOptions.push(`precision: ${contract.precision}`);
        columnOptions.push(`scale: ${contract.scale}`);
        break;
      case 'integer':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.nativeType}"`);
        break;
      case 'boolean':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.nativeType}"`);
        break;
      case 'datetime':
      case 'time':
        if (contract.unique) {
          columnOptions.push('unique: true');
        }
        columnOptions.push(`type: "${contract.zone}"`);
        columnOptions.push(` transformer: {
      to: (value: unknown) => value,
      from: (value: unknown) =>
        value instanceof Date ? value.toISOString() : value,
    },`);
        break;
      default:
        break;
    }
    structure.decorators.push({
      kind: morph.StructureKind.Decorator,
      name: 'Column',
      arguments: [columnOptions.length ? `{${columnOptions.join(',')}}` : ''],
    });
    return {
      fieldStructure: structure,
      topLevelStructure: topLevelStructure,
    };
  }
}

function wrapInTransaction(body: string) {
  // TODO: this should be an action that accepts list of actions
  return `
      const result = await dataSource.transaction(async (em) => {
        ${body}
      });
      return result
    `;
}

// export function applyValidations(
//   validations: readonly FieldValidation[]
// ): morph.DecoratorStructure[] {
//   // Should the validation be part of the Generator plugins?
//   return (validations ?? []).reduce((acc, validation) => {
//     const decoratorName = typeToValidationDecorators[validation.type];
//     if (!decoratorName) {
//       throw new Error(`${validation.type} doesn't have validation decorator.`);
//     }

//     const messageArg = validation.message
//       ? `{message: "${validation.message}"}`
//       : undefined;
//     switch (validation.type) {
//       case 'tel':
//         acc.push({
//           kind: morph.StructureKind.Decorator,
//           name: decoratorName,
//           arguments: [
//             `${validation.details.local || 'null'}`,
//             messageArg,
//           ].filter(notNullOrUndefined),
//         });
//         break;
//       case 'matches':
//         acc.push({
//           kind: morph.StructureKind.Decorator,
//           name: decoratorName,
//           arguments: [`${validation.details.pattern}`, messageArg].filter(
//             notNullOrUndefined
//           ),
//         });
//         break;
//       case 'email':
//         acc.push({
//           kind: morph.StructureKind.Decorator,
//           name: decoratorName,
//           arguments: [messageArg].filter(notNullOrUndefined),
//         });
//         break;
//       case 'decimal':
//         acc.push({
//           kind: morph.StructureKind.Decorator,
//           name: decoratorName,
//           arguments: [
//             validation.details.decimal_digits
//               ? `{decimal_digits: "${validation.details.decimal_digits}"}`
//               : undefined,
//             messageArg,
//           ].filter(notNullOrUndefined),
//         });
//         break;
//       default:
//         acc.push({
//           kind: morph.StructureKind.Decorator,
//           name: decoratorName,
//           arguments: [messageArg].filter(notNullOrUndefined),
//         });
//     }
//     return acc;
//   }, [] as morph.DecoratorStructure[]);
// }
