import dedent from 'dedent';
import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import {
  ConcreteContracts,
  Contracts,
  IExtension,
  IncomingActionProperty,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  RoutingExtension,
  TriggerExtension,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { Sdk } from '@faslh/compiler/sdk/platform';
import {
  Arg,
  Binary,
  Call,
  Expression,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  decomposeVisitor,
  parseDsl,
} from '@faslh/utils';

import {
  AbstractInputParser,
  InstalledExtension,
} from './abstract-input-parser';
import { EXTENISION_REGISTRY_TOKEN, extensionsRegistry } from './all';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ExtensionsRegistry extends AbstractInputParser {
  constructor(
    private readonly _context: Context,
    private readonly _devKit: DevKit,
    private readonly _sdk: Sdk,
    private readonly _projectFS: ProjectFS,
  ) {
    super();
  }

  public async getProjectMainSetup(
    externalDeps: string[],
  ): Promise<Contracts.ExtensionSetupContract[]> {
    const results = [];
    for (const { sourceExtension } of await this.installedSourceExtensions()) {
      results.push(sourceExtension.packages ?? []);
    }
    const dependencies = results.flat();

    return [
      // {
      //   filePath: '.gitignore',
      //   content: gitignoreTxt,
      // },
      // {
      //   filePath: 'faslh.config.json',
      //   content: toJson({
      //     basePath: 'src',
      //     features: 'src/features',
      //     tsConfigFilePath: 'tsconfig.json',
      //   }),
      // },
      {
        filePath: this._projectFS.makeRootPath('package.json'),
        content: [
          this._devKit.toJson({
            version: '0.0.0',
            main: './build/server.js',
            type: 'module',
            scripts: {
              'start:dev': 'npx tsx ./src/server.ts',
              'start:prod': 'node ./build/server.js',
              'build:remote': "echo 'already built by CI.'",
              'build:dev': 'webpack --mode=development --entry ./src/server.ts',
              'build:prod': 'webpack --mode=production --node-env=production',
              'build:watch': 'npm run build:dev -- --watch',
              'migration:generate':
                './node_modules/.bin/typeorm migration:generate ./src/migrations/migrations --dataSource ./src/datasource -o --pretty --outputJs',
            },
            dependencies: [
              ...dependencies.filter((it) => it.dev !== true),
              ...externalDeps.map((it) => ({
                name: it,
                version: 'latest',
              })),
            ].reduce((acc, it) => {
              return {
                ...acc,
                [it.name]: it.version,
              };
            }, {}),
            devDependencies: dependencies
              .filter((it) => it.dev === true)
              .reduce((acc, it) => {
                return {
                  ...acc,
                  [it.name]: it.version,
                };
              }, {}),
          }),
        ],
      },
      // {
      //   filePath: this._projectFS.makeRootPath('nodemon.json'),
      //   content: this._devKit.toJson({
      //     watch: ['./dist/'],
      //     env: {
      //       NODE_ENV: 'development',
      //       PORT: '3000',
      //       HOST: '0.0.0.0',
      //       DOTENV_CONFIG_PATH: '.env',
      //     },
      //     ext: 'ts,js,json',
      //     exec: 'node ./dist',
      //   }),
      // },
      {
        filePath: this._projectFS.makeRootPath('tsconfig.json'),
        content: [
          this._devKit.toJson({
            compilerOptions: {
              sourceMap: true,
              target: 'ESNext',
              module: 'esnext',
              moduleResolution: 'node',
              declaration: false,
              outDir: 'dist',
              baseUrl: '.',
              rootDir: '.',
              types: ['node'],
              removeComments: true,
              strict: true,
              inlineSources: true,
              sourceRoot: '/',
              allowSyntheticDefaultImports: true,
              esModuleInterop: true,
              experimentalDecorators: true,
              emitDecoratorMetadata: true,
              importHelpers: true,
              noEmitHelpers: true,
              resolveJsonModule: true,
              skipLibCheck: true,
              skipDefaultLibCheck: true,
            },
            exclude: [
              'node_modules',
              'migrations',
              'environment',
              'dist',
              'build',
            ],
          }),
        ],
      },
    ];
  }

  async getActionHandler(sourceId: string) {
    const sourceAction = await this._devKit.getSourceActionById(sourceId);
    if (!sourceAction) {
      throw new Error(`Action ${sourceId} not found.`);
    }
    const sourceExtension = this._devKit.getExtensionById(
      sourceAction.extensionId,
    );
    if (!sourceExtension) {
      throw new Error(`Extension ${sourceAction.extensionId} not found.`);
    }
    const extensionType = extensionsRegistry[sourceExtension.name];
    if (!extensionType) {
      throw new Error(`Extension ${sourceExtension.name} not found.`);
    }

    const installedExtension = await this._sdk.getInstalledExtension(
      sourceExtension.id,
    );

    if (!installedExtension) {
      throw new Error(`Extension ${sourceExtension.name} is not installed.`);
    }

    const extension = Injector.GetRequiredService(extensionType, this._context);
    return {
      sourceExtension,
      sourceAction,
      handler: extension,
    };
  }

  async getFieldHandler(sourceId: string) {
    const sourceField = await this._devKit.getSourceFieldById(sourceId);
    if (!sourceField) {
      throw new Error(`Field ${sourceId} not found.`);
    }
    const sourceExtension = this._devKit.getExtensionById(
      sourceField.extensionId,
    );
    if (!sourceExtension) {
      throw new Error(`Extension ${sourceField.extensionId} not found.`);
    }
    const extensionType = extensionsRegistry[sourceExtension.name];
    if (!extensionType) {
      throw new Error(`Extension ${sourceExtension.name} not found.`);
    }

    const installedExtension = await this._sdk.getInstalledExtension(
      sourceExtension.id,
    );

    if (!installedExtension) {
      throw new Error(`Extension ${sourceExtension.name} is not installed.`);
    }

    const extension = Injector.GetRequiredService(extensionType, this._context);
    return {
      sourceExtension,
      sourceField: sourceField,
      installedExtension,
      handler: extension,
    };
  }

  public override async installedSourceExtensions(): Promise<
    InstalledExtension<IExtension>[]
  > {
    const installedExtensions = await this._sdk.getInstalledExtensions();
    const results: InstalledExtension<IExtension>[] = [];
    for (const installedExtension of installedExtensions) {
      const sourceExtension = this._devKit.getExtensionById(
        installedExtension.id,
      );
      if (!sourceExtension) {
        throw new Error(
          `Source extension with id ${installedExtension.id} not found.`,
        );
      }

      const extensionsRegistry = Injector.GetRequiredService(
        EXTENISION_REGISTRY_TOKEN,
        this._context,
      );
      const extensionType = extensionsRegistry[sourceExtension.name];
      if (!extensionType) {
        throw new Error(`Extension type ${sourceExtension.name} not found.`);
      }
      const extension = Injector.GetRequiredService(
        extensionType,
        this._context,
      );

      results.push({
        extension,
        sourceExtension,
        installedExtension,
      });
    }
    return results;
  }

  async parseInput(
    dsl: {
      input: string;
      extras?: Record<string, unknown>;
    },
    workflow?: Contracts.Workflow,
  ) {
    const extensions = await this.installedSourceExtensions();
    for (const it of extensions) {
      if ('handleInput' in it.extension) {
        const result = (await (it.extension as any).handleInput(
          dsl.input,
          dsl.extras,
        )) as ParsedInput | null;
        if (result) {
          return result;
        }
      }
    }
    if ('input' in dsl) {
      throw new Error(dedent`
        Unable to parse input ${JSON.stringify(dsl.input)}
        Could be that related extension is not installed.
        `);
    }
    throw new Error(dedent`
      Unable to parse input ${JSON.stringify(dsl)}
      Could be that related extension is not installed.
      `);
  }

  async parseInputV2(dsl: any) {
    const extensions = await this.installedSourceExtensions();
    for (const it of extensions) {
      if ('handleInputV2' in it.extension) {
        const result = await (it.extension as any).handleInputV2(dsl);
        if (result) {
          return result;
        }
      }
    }
    throw new Error(dedent`
      Unable to parse input ${JSON.stringify(dsl)}
      Could be that related extension is not installed.
      `);
  }

  async routingExtension(): Promise<InstalledExtension<RoutingExtension>> {
    const exts = await this.installedSourceExtensions();
    const ext = exts.find((it) =>
      it.sourceExtension.categories.includes('routing'),
    );
    if (!ext) {
      throw new Error('No routing extension found');
    }
    return ext as InstalledExtension<RoutingExtension>;
  }

  async processTrigger(
    contract: ProcessTriggerInput,
    workflowInputs?: Record<string, ParsedInput>,
  ): ProcessTriggerOutput {
    const action = await this.getActionHandler(contract.sourceId);
    return (action.handler as TriggerExtension).processTrigger(
      contract,
      workflowInputs,
    );
  }

  async evaulateRule(rule: string) {
    const structures: ConcreteContracts.MorphStatementWriter[] = [];
    const indiviudalRules = decomposeVisitor(rule);
    const evaluatedRules: Record<string, string> = {};
    const evaluatedRulesIds: Record<string, string> = {};
    for (const [id, input] of Object.entries(indiviudalRules)) {
      const result = await this.parseInput({ input: input });
      if (result.structure) {
        structures.push(result.structure);
      }
      evaluatedRules[input] = result.value;
      evaluatedRulesIds[input] = id;
    }

    // FIXME: use the ContextAwareVisitor from CoreExtension
    // const visitor = new DslEvaluator(
    //   (input) => {
    //     return evaluatedRules[input];
    //   },
    //   (input) => {
    //     return evaluatedRulesIds[input];
    //   },
    // );

    const visitor = new ContextAwareVisitor(this._context);
    const evaluation = await visitor.start(parseDsl(rule), {
      emit: (structure: ConcreteContracts.MorphStatementWriter) => {
        structures.push(structure);
      },
    });

    // const evaluation = visitor.visit(parseDsl(rule));
    return {
      structures: structures.flat(),
      evaluation,
    };
  }

  public async extractInputs(dsl: any) {
    const extensions = await this.installedSourceExtensions();
    for (const it of extensions) {
      if ('extractInputs' in it.extension) {
        const result = await (it.extension as any).extractInputs(dsl);
        if (result) {
          return result;
        }
      }
    }
    return {};
  }

  public async toInputs(dsl: string) {
    const visitor = new InputsExtractor(this._context);
    let result: any[] = await visitor.visit(parseDsl(dsl));
    const inputs: Record<string, IncomingActionProperty> = {};
    if (!Array.isArray(result)) {
      result = [result];
    }
    for (const it of result.filter((it) => Object.keys(it).length > 0)) {
      const key = Object.keys(it)[0];
      const value = it[key];
      inputs[key] = { input: value };
    }
    return inputs;
  }
}

class InputsExtractor extends StringAsyncVisitor {
  constructor(private _context: Context) {
    super();
  }
  private get _inputParser() {
    return Injector.GetRequiredService(AbstractInputParser, this._context);
  }
  override async visitCall(node: Call): Promise<any> {
    const list: any[] = [];
    for (const arg of node.args) {
      list.push({
        name: await arg.name.accept(this),
        value: await arg.value.accept(this),
      });
    }
    return list;
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<string> {
    const left = await node.name.accept(this);
    const right = await node.expression.accept(this);
    return `${left}.${right}`;
  }
  override async visitBinary(node: Binary): Promise<any> {
    const left = await node.left.accept(this);
    const right = await node.right.accept(this);
    return [left, right];
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    const result = await this._inputParser.extractInputs({
      namespace: await node.name.accept(this),
      value: node.expression,
      visit: this,
    });
    if (!result) {
      return {};
    }
    return result;
  }

  override async visitArg(node: Arg): Promise<string> {
    return node.value.accept(this);
  }

  override visit(node: Expression): any {
    return node.accept(this);
  }
}

export class ContextAwareVisitor extends StringAsyncVisitor {
  emitter: any;
  constructor(private readonly _context: Context) {
    super();
  }

  private get _inputParser() {
    return Injector.GetRequiredService(AbstractInputParser, this._context);
  }

  override async visitBinary(node: Binary): Promise<any> {
    const operator = await node.operator.accept(this);
    let jsOperator = '';
    switch (operator) {
      case '=':
        jsOperator = '===';
        break;
      case '!=':
        jsOperator = '!==';
        break;

      default:
        throw new Error(`Operator ${operator} not supported`);
    }
    const left = await node.left.accept(this);
    const right = await node.right.accept(this);
    return `${left} ${jsOperator} ${right}`;
  }
  override async visitArg(node: Arg): Promise<any> {
    return {
      name: await node.name.accept(this),
      value: await node.value.accept(this),
    };
  }
  override async visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    const left = await node.name.accept(this);
    const right = await node.expression.accept(this);
    return `${left}.${right}`;
  }

  override async visitNamespace(node: Namespace): Promise<any> {
    const result = await this._inputParser.parseInputV2({
      namespace: await node.name.accept(this),
      value: node.expression,
    });

    if (result.structure) {
      this.emitter.emit(result.structure);
    }

    const returnValue = result.data?.['parameterName'] || result.value;
    return returnValue;
  }

  override visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
  start(node: Expression, emitter: any): Promise<any> {
    this.emitter = emitter;
    return node.accept(this);
  }
}
