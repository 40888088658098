import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowTriggerChangedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap(() => Object)
  sourceId!: string;

  @AutoMap(() => Object)
  details!: Record<string, any>;
}

export const WorkflowTriggerChanged = createEvent<
  WorkflowTriggerChangedData,
  'workflow_trigger_changed'
>('workflow_trigger_changed');
export type WorkflowTriggerChanged = InstanceType<
  typeof WorkflowTriggerChanged
>;
