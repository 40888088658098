

import { PolicyAdded } from '@faslh/api/policies/domain';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';
import { policyProjection } from './policy.projection';
import { PolicyProjector } from './policy.projector';

@NotificationHandler(PolicyAdded)
export default class extends INotificationHandler<PolicyAdded> {
  constructor(private readonly _policyProjector: PolicyProjector) {
    super();
  }

  public override async handle(event: PolicyAdded): Promise<void> {
    const projection = await this._policyProjector.getProjector(
      event.aggregateId,
      {
        defaultState: policyProjection(),
        events: [event],
      },
    );
    this._policyProjector.saveProjection(event.aggregateId, projection);
  }
}
