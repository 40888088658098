import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';
@AutoMapHost()
export class TableAddedData extends DomainEventData {
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;

  @AutoMap()
  @IsUUID()
  public featureId!: string;

  @AutoMap(() => Object)
  details!: Record<string, any>;
}

export const TableAdded = createEvent<TableAddedData, 'table_added'>(
  'table_added',
);
export type TableAdded = InstanceType<typeof TableAdded>;
