import receiveWebhook from './receive-webhook.txt';
import webhookHandler from './webhook-handler.txt';

import { Injectable, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import {
  ConcreteContracts,
  Contracts,
  IExtension,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  TriggerExtension,
  WithMyInput,
  signutureInputs,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import {
  Arg,
  AsyncVisitor,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  StringLiteral,
  StringVisitor,
  camelcase,
  parseDsl,
  spinalcase,
} from '@faslh/utils';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class GithubExtension
  implements IExtension, WithMyInput, TriggerExtension
{
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
  ) {}
  public triggers = ['7b1696e9-9c89-4ba7-abc0-e1448b287772'];

  public async handleSetup(
    details: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeControllerPath('integrations', 'router'),
        content: [webhookHandler],
      },
      {
        filePath: this._projectFS.makeCorePath('github-webhooks.ts'),
        content: [receiveWebhook],
      },
    ];
  }

  public async handleInput(
    input: string,
    extras: Record<string, unknown> = {},
  ): Promise<ParsedInput | null> {
    if (extras['trigger'] === 'github-trigger') {
      if (input.startsWith('@trigger:mapper')) {
        return {
          static: false,
          type: 'string',
          value: input.replace('@trigger:mapper.', ''),
          data: {
            fromMapper: true,
          },
        };
      }
      return {
        static: false,
        type: 'string',
        value: input.replace('@trigger:', '').replace('payload.', ''),
      };
    }
    const visitor = new SimpleVisitor();
    const { namespace, value } = visitor.visit(parseDsl(input)) as {
      namespace: string;
      value: Expression;
    };

    return this.handleInputV2({ namespace, value });
  }

  public async handleInputV2(
    {
      namespace,
      value,
    }: {
      value: Expression;
      namespace: string;
    },
    extras: Record<string, unknown> = {},
  ): Promise<ParsedInput | null> {
    switch (namespace) {
      case 'trigger': {
        const visitor = new HonoInputVisitor();
        const result = (await visitor.visit(value)) as {
          accessor: string;
          accessee: string;
        };
        switch (result.accessor) {
          case 'issue':
          case 'pull_request':
          case 'push':
          case 'repository':
          case 'comment':
            return {
              // FIXME: don't implement the rest of the cases as we're moving to the new version of CanonLang
              static: false,
              type: 'string',
              value: result.accessee,
            };
          default:
            return null;
        }
      }
      default:
        return null;
    }
  }

  public async extractInputs({
    namespace,
    value,
    visit,
  }: {
    value: Expression;
    namespace: string;
    visit: AsyncVisitor<unknown>;
  }): Promise<Record<string, string> | null> {
    if (!['trigger'].includes(namespace)) {
      return null;
    }
    const visitor = new InputsExtractor(visit);
    const result = await visitor.visit(value);
    return {
      [result.accessee]: `@${namespace}:${result.accessor}.${result.accessee}`,
    };
  }

  public onFeatureContract(
    contract: Contracts.FeatureContract,
    addFile: (concrete: ConcreteContracts.WorkflowConcreteStructure) => void,
  ): void {
    if (contract.workflows.length === 0) {
      return;
    }

    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'github',
      ),
      structure: [
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: this._projectFS.makeCoreImportSpecifier(
            'core/github-webhooks',
          ),
          namedImports: ['onGithubEvent'],
        },
        ...contract.workflows
          .map((it) => it.trigger.policies)
          .flat()
          .map(
            (it) =>
              ({
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier: `../../identity/${spinalcase(it)}.policy`,
                namedImports: [camelcase(it)],
              }) as morph.ImportDeclarationStructure,
          ),
        {
          kind: morph.StructureKind.ImportDeclaration,
          moduleSpecifier: `./listeners`,
          namespaceImport: 'listeners',
        },

        ...(contract.workflows.map((contract) => {
          const displayName = `listeners.${camelcase(
            contract.trigger.displayName,
          )}`;
          const authorize = contract.trigger.policies.length
            ? `,${contract.trigger.policies
                .map((it) => `${camelcase(it)}`)
                .join(',')}`
            : '';

          // return `${verb}("${path}" ${authorize}, ${displayName})`;
          return `onGithubEvent(
  '${contract.trigger.details['event']}'
  ${authorize},
  ${displayName},
);
`;
        }) as ConcreteContracts.MorphStatementWriter),
      ],
    });
  }

  async processTrigger(
    contract: ProcessTriggerInput,
    workflowInputs: Record<string, ParsedInput>,
  ): ProcessTriggerOutput {
    const displayName = camelcase(contract.displayName);
    const inputName = camelcase('input');

    const vars: Record<string, string> = {};

    const externalInputs = Object.entries(
      contract.details['inputs'] as Record<string, { value: string }>,
    );
    const inputParams = signutureInputs({ ...contract.inputs });
    const actionsInputs = [
      ...inputParams.map(
        ([name, prop]) =>
          [
            name,
            {
              ...prop,
              value:
                prop.data?.['fromMapper'] !== true
                  ? `payload.${prop.value}`
                  : prop.value,
            },
          ] as const,
      ),
    ];

    const actionInputsStr = this._devKit.toLiteralObject(actionsInputs);

    const workflowLoneParams = signutureInputs({ ...workflowInputs });

    for (const [name, prop] of workflowLoneParams) {
      vars[name] = `const ${name} = ${prop.value}`;
    }
    const workflowParams: string[] = [];
    if (inputParams.length) {
      workflowParams.push(inputName);
    }
    if (workflowLoneParams.length) {
      workflowParams.push(...workflowLoneParams.map(([name]) => name));
    }

    const workflowInputsStructures: ConcreteContracts.MorphStatementWriter[] =
      Object.values(workflowInputs)
        .map((prop) => prop.structure)
        .filter(Boolean) as ConcreteContracts.MorphStatementWriter[];

    return [
      {
        filePath: this._projectFS.makeIndexFilePath(
          contract.featureName,
          'listeners',
        ),
        structure: [
          {
            kind: morph.StructureKind.ExportDeclaration,
            moduleSpecifier: this._projectFS.makeExportPath(
              contract.displayName,
              `listener`,
            ),
          },
        ],
      },
      {
        filePath: this._projectFS.makeListenerRoutePath(
          contract.featureName,
          contract.displayName,
        ),
        structure: [
          ...workflowInputsStructures.flat(),
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../${spinalcase(contract.tag)}`,
            namespaceImport: camelcase(contract.tag),
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier:
              this._projectFS.makeCoreImportSpecifier('core/validation'),
            namedImports: ['validateOrThrow'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: '@octokit/webhooks',
            namedImports: ['EmitterWebhookEvent'],
          },
          {
            kind: morph.StructureKind.Function,
            isAsync: true,
            isDefaultExport: false,
            isExported: true,
            name: displayName,
            parameters: [
              {
                name: '{payload,name}',
                type: `EmitterWebhookEvent<'${contract.details['event']}'>`,
              },
            ],
            statements: [
              ...Object.values(vars),
              (writer) =>
                writer.writeLine(
                  "console.log(`${name}.${payload.action}`, 'has been triggered');",
                ),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `
                  ${externalInputs
                    .map(([name, prop]) => `const ${name} = ${prop.value}`)
                    .join('\n')}
                  const ${inputName} = ${actionInputsStr}
                  `,
                ),
              (writer) =>
                writer.conditionalWriteLine(
                  !!inputParams.length,
                  `validateOrThrow(${camelcase(contract.tag)}.${
                    contract.schemaName
                  }, ${inputName})`,
                ),
              (writer) =>
                writer.writeLine(
                  `await ${camelcase(
                    contract.tag,
                  )}.${camelcase(contract.operationName)}(${workflowParams.join(',')})`,
                ),
            ],
          },
        ],
      },
    ];
  }
}

class HonoInputVisitor extends AsyncVisitor<any> {
  override visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitIdentifier(node: Identifier): Promise<any> {
    return node.value;
  }
  override async visitStringLiteral(node: StringLiteral): Promise<any> {
    return `'${node.value}'`;
  }
  override visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
export class InputsExtractor extends StringAsyncVisitor {
  constructor(private _visitor: AsyncVisitor<unknown>) {
    super();
  }
  override async visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      accessor: await node.name.accept(this),
      accessee: await node.expression.accept(this),
    };
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}
class SimpleVisitor extends StringVisitor {
  override visitArg(node: Arg): any {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): any {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): any {
    return node.toLiteral(this);
  }
  override visitPropertyAccess(node: PropertyAccess): any {
    return node.toLiteral(this);
  }
  override visitNamespace(node: Namespace): any {
    return {
      namespace: node.name.accept(this),
      value: node.expression,
    };
  }
}
