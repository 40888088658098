import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class FieldLocalizedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @IsUUID()
  @AutoMap()
  fieldId!: string;
}

export class FieldLocalized extends DomainEvent<
  FieldLocalizedData,
  'field_localized'
> {
  public override readonly eventType = 'field_localized' as const;

  @AutoMap(() => FieldLocalizedData)
  public override data!: FieldLocalizedData;
}
