import dateTxt from './date.txt';
import ormExecuteTxt from './execute.txt';
import i18nTxt from './i18n.txt';

import { QueryColumn } from '@january/compiler/transpilers';
import pluralize from 'pluralize';
import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import { onEvent } from '@faslh/api/infrastructure/database';
import { TableField } from '@faslh/api/table/domain';
import {
  ActionProperty,
  ConcreteContracts,
  Contracts,
  HandleActionInput,
  HandleActionOutput,
  HandleFieldInput,
  IExtension,
  IHandleAction,
  IncomingActionProperty,
  ParsedInput,
  ProcessActionHelpers,
  WithMyInput,
  generateValidationContract,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { DynamicSource } from '@faslh/compiler/sdk/dynamic-source';
import { Sdk } from '@faslh/compiler/sdk/platform';
import { FieldValidation } from '@faslh/isomorphic';
import {
  AsyncVisitor,
  Expression,
  camelcase,
  isNullOrUndefined,
  notNullOrUndefined,
  pascalcase,
  toSimple,
} from '@faslh/utils';

import {
  InputsExtractor,
  toQueryDsl,
  toQueryDslAsync,
} from '../registry/dsl-dsl.visitor';
import './add-i18n';
import { OrmCodeWriter, Pagination } from './orm';
import { flatQueryConditions } from './typeorm/utils';

type SourceFieldName =
  | 'short-text'
  | 'email'
  | 'long-text'
  | 'local-tel'
  | 'international-tel'
  | 'date'
  | 'datetime'
  | 'time'
  | 'single-select'
  | 'multi-select'
  | 'password'
  | 'url'
  | 'boolean'
  | 'percentage'
  | 'price'
  | 'decimal'
  | 'integer'
  | 'latitude'
  | 'longitude'
  | 'json'
  | 'relation'
  | 'relation-id'
  | 'virtual'
  | 'uuid'
  | 'primary-key-number'
  | 'primary-key-uuid'
  | 'primary-key-custom';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class PostgreSQLExtension
  implements WithMyInput, IExtension, IHandleAction
{
  constructor(
    private readonly _sdk: Sdk,
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
    private readonly _dynamicSource: DynamicSource,
    private readonly _context: Context,
    private readonly _ormCodeWriter: OrmCodeWriter,
  ) {}

  public async handleInput(input: string): Promise<ParsedInput | null> {
    const { namespace, value } = toSimple(input);
    switch (true) {
      case namespace.startsWith('tables'): {
        switch (true) {
          case value.startsWith('fields'):
            return {
              value: this._devKit.substring(value, 'fields.'),
              static: false,
              type: 'string',
              data: {
                local: true,
              },
            };
          default:
            const dsl = toQueryDsl(input);
            const fnName = camelcase(`find ${dsl.tableName}`);
            return {
              static: true,
              type: 'structure',
              structure: this.#makeQueryFn({
                tableName: dsl.tableName,
                whereBy: dsl.query,
                fnName: fnName,
              }),
              value: `await ${fnName}`,
            };
        }
      }
      default:
        return null;
    }
  }

  public async handleInputV2({
    namespace,
    value,
  }: {
    value: Expression;
    namespace: string;
  }): Promise<ParsedInput | null> {
    switch (true) {
      case namespace.startsWith('tables'): {
        const dsl = await toQueryDslAsync(this._context, value);
        const fnName = camelcase(`find ${dsl.tableName}`);
        return {
          static: true,
          type: 'structure',
          structure: this.#makeQueryFn({
            tableName: dsl.tableName,
            whereBy: dsl.query,
            fnName: fnName,
          }),
          value: `await ${dsl.fnName}`,
        };
      }
      default:
        return null;
    }
  }

  public async extractInputs({
    namespace,
    value,
    visit,
  }: {
    value: Expression;
    namespace: string;
    visit: AsyncVisitor<unknown>;
  }): Promise<Record<string, string> | null> {
    switch (true) {
      case namespace.startsWith('tables'): {
        const visitor = new InputsExtractor(visit);
        return visitor.visit(value);
      }
      default:
        return null;
    }
  }

  async #generateSortByContract(
    tableId: string,
    sorts: Contracts.SortByInput[],
  ): Promise<Record<string, IncomingActionProperty>> {
    const contract: Record<string, IncomingActionProperty> = {};
    for (const item of sorts) {
      const field = await this.#getField(tableId, item.id);

      contract[camelcase(field.displayName)] = {
        input: item.input,
        validations: [],
      };
    }
    return contract;
  }

  // async #generateGroupByContract(
  //   tableId: string,
  //   groupBy: Contracts.GroupByInput[],
  // ): Promise<Record<string, IncomingActionProperty>> {
  //   const contract: Record<string, IncomingActionProperty> = {};
  //   for (const item of groupBy) {
  //     const field = await this._sdk.tables.getTableField(tableId, item.id);
  //     contract[camelcase(field.displayName)] = {
  //       input: item.input,
  //       validations: [],
  //     };
  //   }
  //   return contract;
  // }

  async #generateGroupByContract(
    tableId: string,
    groupBy: Contracts.GroupByInput[],
  ): Promise<QueryColumn[]> {
    const contract: QueryColumn[] = [];

    for (const item of groupBy) {
      const field = await this._sdk.tables.getTableField(tableId, item.id);
      // FIXME: it shouldn't be parsed here. add handleInput method to resolve the input
      // const {
      //   name: { function: aggregator, arg: alias },
      // } = parseInput(item.input);
      // contract.push({
      //   aggregator,
      //   name: camelcase(field.displayName),
      //   alias,
      // });
    }
    return contract;
  }

  async processAction(
    contract: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    inputs: Record<string, ActionProperty>,
    transfer: Record<string, ActionProperty>,
  ): Promise<ConcreteContracts.ProcessActionOutput> {
    switch (contract.sourceAction.name) {
      case 'list-records': {
        return {
          inline: false,
          structure: this._ormCodeWriter.generateQuery({
            outputName: contract.output.displayName,
            tableName: transfer['tableName'].value,
            query: transfer['query'] as any,
            single: transfer['single'].value === 'true',
            pagination: transfer['pagination'].value,
            localizable: transfer['localizable'].value === 'true',
            helpers,
            inline: false,
          }),
        };
      }

      case 'check-record-existance':
      case 'increment-field':
      case 'decrement-field':
      case 'delete-record':
      case 'set-fields': {
        return {
          inline: false,
          structure: this._ormCodeWriter.generateCommand({
            action: contract,
            helpers,
            tableName: transfer['tableName'].value,
            inline: false,
          }),
        };
      }
      case 'upsert-record':
      case 'insert-record': {
        return {
          inline: true,
          structure: this._ormCodeWriter.generateCommand({
            action: contract,
            helpers,
            tableName: transfer['tableName'].value,
            inline: true,
          }),
        };
      }
      case 'raw-sql-query': {
        return {
          inline: true,
          structure: this._ormCodeWriter.generateRaw({
            action: contract,
            helpers,
            inline: true,
          }),
        };
      }
      default:
        throw new Error(
          `Action ${contract.sourceAction.name} is not supported`,
        );
    }
  }

  #paginationInputs = async (
    type: Pagination,
    limit: number,
  ): Promise<Record<string, IncomingActionProperty>> => {
    switch (type) {
      case 'limit_offset':
      case 'deferred_joins':
        return {
          pageSize: {
            input: '@trigger:query.pageSize',
            defaultValue: limit,
            validations: [
              {
                ...(await this._devKit.getValidationByName('number')),
                details: {
                  value: 'true',
                },
              },
              {
                ...(await this._devKit.getValidationByName('mandatory')),
                details: {
                  value: 'true',
                },
              },
              {
                ...(await this._devKit.getValidationByName('min')),
                details: {
                  value: 1,
                },
              },
            ],
          },
          pageNo: {
            input: '@trigger:query.pageNo',
            validations: [
              {
                ...(await this._devKit.getValidationByName('number')),
                details: {
                  value: 'true',
                },
              },
              {
                ...(await this._devKit.getValidationByName('min')),
                details: {
                  value: 1,
                },
              },
            ],
          },
        };
      case 'cursor':
        return {
          cursor: {
            input: '@trigger:query.cursor',
            validations: [],
          },
          pageSize: {
            input: '@trigger:query.pageSize',
            defaultValue: limit,
            validations: [
              {
                ...(await this._devKit.getValidationByName('mandatory')),
                details: {
                  value: 'true',
                },
              },
              {
                ...(await this._devKit.getValidationByName('min')),
                details: {
                  value: 1,
                },
              },
            ],
          },
        };

      default:
        return {};
    }
  };

  async handleAction(input: HandleActionInput): Promise<HandleActionOutput> {
    if (input.sourceAction.name === 'raw-sql-query') {
      const parameters = ((input.details['parameters'] ?? []) as any[]).reduce(
        (acc, prop) => ({
          ...acc,
          [prop.input.split('.').at(-1)]: prop,
        }),
        {},
      );
      return {
        transfer: {
          query: input.details['query'],
          parameters: Object.keys(parameters) as any,
        },
        runtimeInputs: {
          ...parameters,
        },
      };
    }

    // FIXME: tableId and anything that is expected to be used at faslh
    // execution time should always be fixed
    // maybe we need to move them out of "metadata" to be in other property in the
    // json metadata or give them type "static" OR OR we can have new method beside #resolveInput
    // that will resolve inputs for the handleAction (it won't prefix things with "input.")
    const table = await this._sdk.tables.get(input.details['tableId']);

    switch (input.sourceAction.name) {
      case 'list-records': {
        // TODO: Invistigate adding new namespace to resolve this extension
        // inputs. if successful we might not need the query contract
        const queryContract = await this.#generateQueryContract(
          input.details['tableId'],
          input.details['query']?.['whereBy'],
        );

        const sortByContract = await this.#generateSortByContract(
          input.details['tableId'],
          input.details['query']?.['sortBy'] ?? [],
        );

        const inputs = {
          ...flatQueryConditions(queryContract),
          ...(await this.#paginationInputs(
            input.details['pagination'],
            input.details['limit'],
          )),
        };

        if (input.details['localizable'] === 'true') {
          inputs['locales'] = {
            input: '@trigger:headers.locales',
            defaultValue: '[]',
            validations: [],
          };
        }

        return {
          transfer: {
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
            single: {
              input: `@fixed:${input.details['limit'] === 1}`,
            },
            pagination: {
              input: `@fixed:${input.details['pagination']}`,
            },
            localizable: {
              input: `@fixed:${input.details['localizable']}`,
            },
            query: {
              whereBy: queryContract,
              sortBy: sortByContract,
            } as any,
          },
          runtimeInputs: inputs,
        };
      }
      case 'delete-record':
      case 'check-record-existance': {
        {
          const queryContract = await this.#generateQueryContract(
            input.details['tableId'],
            input.details['query']?.['whereBy'],
          );
          return {
            transfer: {
              tableName: {
                input: `@fixed:${table.displayName}`,
              },
              query: {
                whereBy: queryContract,
              } as any,
            },
            runtimeInputs: flatQueryConditions(queryContract),
          };
        }
      }
      case 'i18n':
        // type: 'Record<string, Record<string, any>>',
        return {
          transfer: {
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
          },
          runtimeInputs: {
            i18n: {
              input: '@trigger:body.i18n',
              validations: [],
            },
          },
        };

      case 'set-fields': {
        const queryContract = await this.#generateQueryContract(
          input.details['tableId'],
          input.details['query']?.['whereBy'],
        );

        const inputs: Record<string, IncomingActionProperty> = {
          ...flatQueryConditions(queryContract),
        };

        const columns = (input.details['columns'] ?? []) as ActionProperty[];
        for (const column of columns.filter((it) => !!it.input)) {
          const field = await this.#getField(
            input.details['tableId'],
            column.name,
          );

          const validationContract = await generateValidationContract(
            field.validations,
          );

          inputs[camelcase(field.displayName)] = {
            ...column,
            // FIXME: instead of doing this
            // return specialised postgres namespace in the input
            // and handleInput in the postgres extension
            // to resolve it in the process.
            type: await this._sdk.tables.resolvePrimitiveType(field),
            validations: validationContract,
            data: { column: true, ...(column.data ?? {}) },
          } as any;
        }

        return {
          transfer: {
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
            query: {
              whereBy: queryContract,
            } as any,
          },
          runtimeInputs: inputs,
        };
      }
      case 'insert-record': {
        const inputs: Record<string, IncomingActionProperty> = {};
        const columns = (input.details['columns'] ?? []) as {
          input: string | null;
          name: string;
        }[];
        for (const column of columns.filter((it) => !!it.input)) {
          const field = await this.#getField(
            input.details['tableId'],
            column.name,
          );

          const validationContract = await generateValidationContract(
            field.validations,
          );

          inputs[camelcase(field.displayName)] = {
            input: column.input as string,
            validations: validationContract,
            // type:
            //   sourceField.name !== 'relation-id'
            //     ? await this._sdk.tables.resolvePrimitiveType(field)
            //     : field.details['relatedEntityPrimaryColumnType'],
          } satisfies IncomingActionProperty;
        }

        return {
          transfer: {
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
          },
          runtimeInputs: inputs,
        };
      }
      case 'upsert-record': {
        const inputs: Record<string, IncomingActionProperty> = {};
        const columns = (input.details['columns'] ?? []) as {
          input: string | null;
          name: string;
        }[];
        for (const column of columns.filter((it) => !!it.input)) {
          const field = await this.#getField(
            input.details['tableId'],
            column.name,
          );

          const validationContract = await generateValidationContract(
            field.validations,
          );

          inputs[camelcase(field.displayName)] = {
            input: column.input as string,
            validations: validationContract,
          } satisfies IncomingActionProperty;
        }

        return {
          transfer: {
            conflictFields: input.details['conflictFields'],
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
          },
          runtimeInputs: inputs,
        };
      }
      case 'increment-field':
      case 'decrement-field': {
        const field = await this.#getField(
          input.details['tableId'],
          input.details['field'].name,
        );
        const queryContract = await this.#generateQueryContract(
          input.details['tableId'],
          input.details['query']?.['whereBy'],
        );

        const inputs: Record<string, IncomingActionProperty> = {
          ...flatQueryConditions(queryContract),
        };
        return {
          transfer: {
            tableName: {
              input: `@fixed:${table.displayName}`,
            },
            field: {
              input: `@fixed:${field.displayName}`,
            },
            query: {
              whereBy: queryContract,
            } as any,
          },
          runtimeInputs: {
            ...inputs,
            value: input.details['field'],
          },
        };
      }
      default:
        throw new Error(`Action ${input.sourceAction.name} is not supported`);
    }
  }

  async #getField(tableId: string, name: string) {
    const table = await this._sdk.tables.get(tableId);
    if (!name) {
      throw new Error(
        `Trying to get field with empty name in table ${table.displayName}:${tableId}`,
      );
    }
    const columnName = name.startsWith('@')
      ? await this.handleInput(name)
      : null;
    let field: TableField | undefined;
    if (isNullOrUndefined(columnName)) {
      field = table.fields.find((it) => it.id === name);
    } else {
      field = table.fields.find((it) => it.displayName === columnName.value);
    }
    if (!field) {
      throw new Error(`Field ${name} not found`);
    }
    return field;
  }

  async handleSetup(
    contract: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    const contracts = [
      {
        filePath: this._projectFS.makeCorePath('execute.ts'),
        content: [ormExecuteTxt],
      },
      {
        filePath: this._projectFS.makeCorePath('date.ts'),
        content: [dateTxt],
      },
      {
        filePath: this._projectFS.makeCorePath('data-source.ts'),
        content: [
          `
      import { DataSource, DefaultNamingStrategy } from 'typeorm';
      import { PostgresConnectionOptions } from 'typeorm/driver/postgres/PostgresConnectionOptions';
      import entites from '../features/entites';
      class NamingStrategy extends DefaultNamingStrategy {
          override tableName(
            targetName: string,
            userSpecifiedName: string | undefined,
          ): string {
            return super.tableName(userSpecifiedName ?? targetName, undefined);
          }
      }
      const options: PostgresConnectionOptions = {
        type: 'postgres',
        useUTC: true,
        url: ${contract['CONNECTION_STRING'].value},
        migrationsRun: true,
        entities: [...entites],
        logging: false, // process.env.NODE_ENV !== 'production'
        synchronize: true, // process.env.NODE_ENV !== 'production'
        ssl: process.env.NODE_ENV === 'production',
        namingStrategy: new NamingStrategy(),
      };
      export default new DataSource(options);
    `,
        ],
      },
    ];

    if (contract['i18n'].value === 'default') {
      contracts.push({
        filePath: this._projectFS.makeCorePath('i18n.ts'),
        content: [i18nTxt],
      });
    }
    return contracts;
  }

  async handleField(
    input: HandleFieldInput,
  ): Promise<Contracts.InputFieldUnion> {
    const mandatory = (input.validation ?? []).some(
      (it) => it.name === 'mandatory' && it.details['value'] === 'true',
    );
    const unique = (input.validation ?? []).some(
      (it) => it.name === 'unique' && it.details['value'] === 'true',
    );

    if (input.details['system_created_at']) {
      return {
        validations: await generateValidationContract(input.validation),
        mandatory,
        nativeType: 'createdAt',
        primitiveType: 'Date',
      };
    }
    if (input.details['system_updated_at']) {
      return {
        validations: await generateValidationContract(input.validation),
        mandatory,
        nativeType: 'updatedAt',
        primitiveType: 'Date',
      };
    }
    if (input.details['system_deleted_at']) {
      return {
        validations: await generateValidationContract(input.validation),
        mandatory,
        nativeType: 'deletedAt',
        primitiveType: 'Date',
      };
    }

    switch (input.sourceField.name as SourceFieldName) {
      case 'primary-key-uuid':
      case 'primary-key-custom':
      case 'primary-key-number':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'primary-key',
          keyType: input.sourceField.name,
          generated: input.details['system_auto_generated'],
        };
      case 'json':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'json',
          mandatory: mandatory,
        };
      case 'integer':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'integer',
          mandatory: mandatory,
          unique: unique,
        };
      case 'boolean':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'boolean',
          mandatory: mandatory,
          unique: unique,
        };
      case 'datetime': {
        const local = input.validation.find((it) => it.name === 'datetime');
        if (!local) {
          throw new Error('Datetime field must specifiy timezone.');
        }

        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'datetime',
          zone:
            local.details['value'] === 'iso-date-time'
              ? 'timestamp'
              : 'timestamptz',
          mandatory: mandatory,
          unique: unique,
          primitiveType: 'string',
        };
      }
      case 'time': {
        const local = input.validation.find((it) => it.name === 'time');
        if (!local) {
          throw new Error('Time field must specifiy timezone.');
        }

        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'time',
          zone: local.details['value'] === 'iso-time' ? 'time' : 'timetz',
          mandatory: mandatory,
          unique: unique,
        };
      }
      case 'uuid':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'uuid',
          mandatory: mandatory,
          unique: unique,
        };

      case 'url':
      case 'short-text':
      case 'email':
      case 'long-text':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'varchar',
          mandatory: mandatory,
          unique: unique,
          length: input.details['length'],
        };
      case 'decimal':
      case 'price':
      case 'percentage':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'decimal',
          mandatory: mandatory,
          unique: unique,
          precision: input.details['precision'],
          scale: input.details['scale'],
        };
      case 'single-select':
        // TODO: the user should choose the datatype either enum, varchar or lookup
        if (input.details['style'] === 'enum') {
          return {
            validations: await generateValidationContract(input.validation),
            nativeType: 'enum',
            unique,
            mandatory,
            values: input.details['values'],
          };
        }
        if (input.details['style'] === 'varchar') {
          return {
            validations: await generateValidationContract(input.validation),
            nativeType: 'varchar',
            unique,
            mandatory,
          };
        }
        throw new Error('Lookup not supported yet.');
      case 'relation-id':
        const referenceEntity = await this._sdk.tables.get(
          input.details['references'],
        );
        const primaryKey = referenceEntity.fields.find(
          (field) => field.details['system_primary_key'],
        );
        if (!primaryKey) {
          throw new Error(
            `Primary key not found on table ${referenceEntity.displayName}`,
          );
        }
        const primaryKeySourceField = await this._devKit.getSourceFieldById(
          primaryKey.sourceId,
        );
        const referencedPrimaryColumnType =
          primaryKeySourceField.primitiveType +
          `${input.details['relationship'] === 'one-to-many' ? '[]' : ''}`;
        return {
          virtualRelationField: input.details['virtualRelationField'],
          validations: await generateValidationContract(input.validation),
          nativeType: 'relation-id',
          mandatory: mandatory,
          unique: unique,
          tableName: input.details['tableName'],
          primitiveType: referencedPrimaryColumnType,
          columnNameOnSelfTable: input.details['columnNameOnSelfTable'],
        };
      case 'relation':
        return {
          validations: await generateValidationContract(input.validation),
          nativeType: 'relation',
          primitiveType: await this._dynamicSource.resolvePrimitiveType(
            input.featureId,
            input.sourceField.primitiveType,
            {
              context: input.details,
              self: {}, // FIXME: provide self
            },
            (primitiveType) => primitiveType.primitiveType,
          ),

          mandatory: mandatory,
          unique: unique,
          joinSide: input.details['joinSide'],
          nameOfColumnOnRelatedEntity:
            input.details['nameOfColumnOnRelatedEntity'],
          relationship: input.details['relationship'],
          // FIXME: deprecate relatedEntityName, fetch the table from the sdk using references
          relatedEntityName: input.details['relatedEntityName'],
        };
      default:
        throw new Error(`Field ${input.sourceField.name} not supported.`);
    }
  }

  async #generateQueryContract(
    tableId: string,
    condition: Contracts.QueryConditionContract,
  ): Promise<Contracts.QuerySelectConditionContract> {
    const stack: any[] = [{ condition, parent: null, index: null }];
    const contracts: Contracts.QueryConditionContract[] = [];

    while (stack.length > 0) {
      const { condition, parent, index } = stack.pop();
      if (condition.operator === 'querySelect') {
        const queryContract: Contracts.QuerySelectConditionContract = {
          operator: 'querySelect',
          input: await this.#generateGroupByContract(tableId, condition.input),
          data: [],
        };
        contracts.push(queryContract);

        if (parent) {
          parent.data[index] = queryContract;
        }

        for (
          let i = condition.data.filter(notNullOrUndefined).length - 1;
          i >= 0;
          i--
        ) {
          stack.push({
            condition: condition.data[i],
            parent: queryContract,
            index: i,
          });
        }
      } else if (condition.operator === 'group') {
        const groupContract: Contracts.GroupQueryConditionContract = {
          operator: 'group',
          input: condition.input,
          data: [],
        };
        contracts.push(groupContract);

        if (parent) {
          parent.data[index] = groupContract;
        }

        for (let i = condition.data.length - 1; i >= 0; i--) {
          stack.push({
            condition: condition.data[i],
            parent: groupContract,
            index: i,
          });
        }
      } else {
        const validations: FieldValidation[] = [];
        if (condition.data?.required) {
          const mandatoryValidation =
            await this._devKit.getValidationByName('mandatory');
          validations.push({
            name: mandatoryValidation.name,
            sourceId: mandatoryValidation.id,
            details: { value: 'true' },
          });
        }
        if (condition.input.length > 1) {
          const table = await this._sdk.tables.get(condition.data.name[0]);
          const field = await this._sdk.tables.getTableField(
            condition.input[0],
            condition.input[1],
          );
          const sourceField = await this._devKit.getSourceFieldById(
            field.sourceId,
          );

          const validationContracts = await generateValidationContract([
            ...validations,
            ...field.validations,
          ]);

          const contract: Contracts.QueryConditionContract = {
            operator: condition.operator,
            input: [table.displayName, field.displayName],
            data: {
              ...condition.data,
              required: validationContracts.some(
                (it) => it.name === 'mandatory',
              ),
              // FIXME: handle the type and validation in postgres handleInput
              type: condition.data?.type || sourceField.primitiveType,
              validation: await generateValidationContract([
                ...validations,
                ...field.validations,
              ]),
            },
          };

          contracts.push(contract);
          if (parent) {
            parent.data[index] = contract;
          }
        } else {
          const field = await this.#getField(tableId, condition.input[0]);
          const sourceField = await this._devKit.getSourceFieldById(
            field.sourceId,
          );
          const validationContracts = await generateValidationContract([
            ...validations,
            ...field.validations,
          ]);

          const contract: Contracts.QueryConditionContract = {
            operator: condition.operator,
            input: [field.displayName],
            data: {
              ...condition.data,
              required: validationContracts.some(
                (it) => it.name === 'mandatory',
              ),
              // FIXME: handle the type and validation in postgres handleInput
              type: condition.data?.type || sourceField.primitiveType,
              validation: validationContracts,
            },
          };

          contracts.push(contract);
          if (parent) {
            parent.data[index] = contract;
          }
        }
      }
    }

    return contracts[0] as Contracts.QuerySelectConditionContract;
  }

  // FIXME: why not to use generate query fn instead?
  #makeQueryFn(input: {
    fnName: string;
    tableName: string;
    whereBy: Contracts.QuerySelectConditionContract;
  }): ConcreteContracts.MorphStatementWriter {
    const variables: Record<string, morph.VariableStatementStructure> = {};
    const topLevel: ConcreteContracts.MorphStatementWriter[] = [];
    const body: ConcreteContracts.MorphStatementWriter[] = [];
    const qbUsage: string[] = [];

    const result = this._ormCodeWriter.generateQuery({
      inline: false,
      localizable: false,
      single: true,
      outputName: 'output',
      pagination: 'none',
      tableName: input.tableName,
      helpers: {
        useVariable: (name, value) => {
          variables[name] = {
            declarationKind: morph.VariableDeclarationKind.Const,
            kind: morph.StructureKind.VariableStatement,
            declarations: [{ name: name, initializer: value }],
          };
        },
        useQb: (usage) => {
          const qbString = 'qb';
          qbUsage.push(usage(qbString));
        },
        resolveAction: () => ['not sure what it should do here'],
      },
      query: {
        sortBy: {},
        groupBy: [],
        whereBy: input.whereBy,
      },
    });

    if (
      typeof result === 'string' ||
      typeof result === 'function' ||
      Array.isArray(result)
    ) {
      body.push(result);
    } else {
      body.push(result.actionStructure);
      topLevel.push(result.topLevelStructure);
    }

    return [
      ...topLevel.flat(),
      {
        kind: morph.StructureKind.Function,
        name: input.fnName,
        isAsync: true,
        isDefaultExport: false,
        isExported: true,
        parameters: [
          {
            name: `{${Object.keys(flatQueryConditions(input.whereBy))}}`,
            type: 'Record<string, any>',
          },
        ],
        statements: [...Object.values(variables), ...qbUsage, ...body.flat()],
      },
    ];
  }
}

// dev mode postgresql://youruser:yourpassword@postgres:5432/yourdatabase
// prod mode postgresql://january-test_owner:U17cOgTaYXIm@ep-holy-flower-a5lkn70o.us-east-2.aws.neon.tech/january-test?sslmode=require

onEvent({
  events: [],
  handler: async (event: any, context) => {
    const devKit = Injector.GetRequiredService(DevKit, context);
    const sdk = Injector.GetRequiredService(Sdk, context);
    const sourceField = await devKit.getSourceFieldById(event.data.sourceId);
    if (
      !(
        sourceField.name === 'relation' &&
        event.data.details['joinSide'] === true
      ) // otherwise will create recursive relation
    ) {
      return;
    }

    const selfEntity = await sdk.tables.get(event.data.tableId);
    {
      if (event.data.details['relationship'] === 'many-to-many') {
        // FIXME: NOT COMPLETE
        const relationSourceField =
          await devKit.getSourceFieldByName('relation');
        const assoicativeTableId = await sdk.tables.addTable({
          featureId: selfEntity.featureId,
          details: {},
          displayName: pascalcase(
            `${pluralize.singular(
              selfEntity.displayName,
            )} To ${pluralize.singular(event.data.displayName)}`,
          ),
        });
        await sdk.tables.addField({
          tableId: assoicativeTableId,
          sourceId: relationSourceField.id,
          displayName: pluralize.singular(selfEntity.displayName),
          details: {
            references: event.data.tableId,
            joinSide: true,
            relationship: 'many-to-one',
          },
        });
        await sdk.tables.addField({
          tableId: assoicativeTableId,
          sourceId: relationSourceField.id,
          displayName: pluralize.singular(event.data.displayName),
          details: {
            references: event.data.details['references'],
            joinSide: true,
            relationship: 'many-to-one',
          },
        });
      }
    }
  },
});
