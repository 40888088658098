import { QueryFactory } from '@january/compiler/transpilers';
import { Context, Injector } from 'tiny-injector';

import {
  Arg,
  AsyncVisitor,
  Binary,
  Call,
  Expression,
  Identifier,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  StringLiteral,
  Visitor,
  camelcase,
  parseDsl,
  toLiteralObject,
} from '@faslh/utils';

import { AbstractInputParser } from './abstract-input-parser';

type RT = string;

export class InputsExtractor extends StringAsyncVisitor {
  constructor(private _visitor: AsyncVisitor<unknown>) {
    super();
  }
  override async visitArg(node: Arg): Promise<any> {
    return node.value.accept(this);
  }
  override async visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitCall(node: Call): Promise<any> {
    const list: any[] = [];
    for (const arg of node.args) {
      list.push(await arg.accept(this));
    }
    return list;
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    return this._visitor.visit(node);
  }

  override async visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}

export class InputDslToQueryDslVisitor extends Visitor<RT> {
  override visitArg(node: Arg): any {
    return {
      name: node.name.accept(this),
      value: node.value.accept(this),
    };
  }
  override visitBinary(node: Binary): string {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): any {
    return {
      tableName: node.name.accept(this),
      args: node.args.map((arg) => arg.accept(this)),
    };
  }
  override visitPropertyAccess(node: PropertyAccess): string {
    return {
      ...node.name.accept(this),
      // fix it array. in the future it will be an array
      // prop.{first,second}
      columns: [node.expression.accept(this)],
    };
  }
  override visitNamespace(node: Namespace): any {
    return {
      ...node.expression.accept(this),
    };
  }
  override visitIdentifier(node: Identifier): string {
    return node.value;
  }
  override visitStringLiteral(node: StringLiteral): string {
    return `'${node.value}'`;
  }
  override visit(node: Expression): any {
    return node.accept(this);
  }
}

export class AsyncInputDslToQueryDslVisitor extends StringAsyncVisitor {
  constructor(private readonly _context: Context) {
    super();
  }

  private get _inputParser() {
    return Injector.GetRequiredService(AbstractInputParser, this._context);
  }
  override async visitArg(node: Arg): Promise<any> {
    return {
      name: await node.name.accept(this),
      value: await node.value.accept(this),
    };
  }

  override visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }

  override async visitCall(node: Call): Promise<any> {
    return {
      tableName: await node.name.accept(this),
      args: await Promise.all(node.args.map((arg) => arg.accept(this))),
    };
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    return {
      ...(await node.name.accept(this)),
      // fix it array. in the future it will be an array
      // prop.{first,second}
      columns: [await node.expression.accept(this)],
    };
  }
  override async visitNamespace(node: Namespace): Promise<any> {
    const returnValue = this._inputParser.parseInputV2({
      namespace: await node.name.accept(this),
      value: node.expression,
    });
    return returnValue;
  }
}

export async function toQueryDslAsync(context: Context, node: Expression) {
  const visitor = new AsyncInputDslToQueryDslVisitor(context);
  const result = (await visitor.visit(node)) as {
    tableName: string;
    columns: string[];
    args: { name: string; value: string }[];
  };

  const query = QueryFactory.createSelectRule(
    [
      QueryFactory.createGroupRule(
        'and',
        result.args.map((it) =>
          QueryFactory.createEqualRule([it.name], {
            type: 'string',
            required: true,
            static: false,
            value: it.value,
          }),
        ),
      ),
    ],
    (result.columns ?? []).map((it) => ({ name: it })),
  );
  const params = toLiteralObject(
    result.args.reduce(
      (acc, it) => ({
        ...acc,
        [camelcase(it.name)]: camelcase(it.name),
      }),
      {},
    ),
  );
  return {
    query,
    tableName: result.tableName,
    args: result.args,
    fnName: camelcase(`find ${result.tableName}`) + `(${params})`,
  };
}
export function toQueryDsl(input: string) {
  const dslToDslVisitor = new InputDslToQueryDslVisitor();
  const result = dslToDslVisitor.visit(parseDsl(input)) as {
    tableName: string;
    columns: string[];
    args: { name: string; value: string }[];
  };

  const query = QueryFactory.createSelectRule(
    [
      QueryFactory.createGroupRule(
        'and',
        result.args.map((it) =>
          QueryFactory.createEqualRule([it.name], {
            type: 'string',
            required: true,
            static: false,
            value: it.value,
          }),
        ),
      ),
    ],
    (result.columns ?? []).map((it) => ({ name: it })),
  );
  return { query, tableName: result.tableName };
}
