import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { SetInitialSettingsCommand } from '@faslh/api/settings/commands';
import { InitialSettingsAddedData, Settings } from '@faslh/api/settings/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

/**
 * @deprecated Not used
 */
@RequestHandler(SetInitialSettingsCommand)
export class SetInitialSettingsCommandHandler extends IRequestHandler<SetInitialSettingsCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: SetInitialSettingsCommand): Promise<void> {
    const aggregate = Settings.create(
      this._eventUtils.getMetadata(),
      mapper.map(
        {
          repositoryName: command.repositoryName,
        },
        InitialSettingsAddedData,
      ),
    );

    await this._eventStore.save(aggregate);
  }
}
