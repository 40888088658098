import { AutoMap } from '@automapper/classes';
import type { FieldValue } from 'firebase-admin/firestore';

import { DomainEvent, DomainEventMetadata } from '@faslh/api/infrastructure';

import { EventStore } from './event.store';
import { ProjectionStore } from './projection.store';

export function buildProjection<T, E>(
  initialProjection: T,
  events: E[],
  reducer: (state: T, event: E) => T,
) {
  return events.reduce(
    (state, event) => reducer(state, event),
    initialProjection,
  );
}
export async function buildProjectionAsync<T, E>(
  initialProjection: T,
  events: E[],
  reducer: (state: T, event: E) => Promise<T>,
) {
  for (const event of events) {
    initialProjection = await reducer(initialProjection, event);
  }
  return initialProjection;
}
export abstract class Projection {
  @AutoMap()
  public readonly id!: string;
  @AutoMap(() => Object)
  public createdAt?: ReturnType<typeof FieldValue.serverTimestamp>;
  @AutoMap(() => Object)
  public updatedAt?: ReturnType<typeof FieldValue.serverTimestamp>;

  appVersion?: string;
  prefix?: string;

  toJson?(): Record<string, any> {
    const json = { ...this };
    if (!json.createdAt) {
      delete json.createdAt;
    }
    return { ...this };
  }
  constructor(id: string) {
    // FIXME: changes can eieher represent first or last commmit
    this.id = id;
  }
}

export type ServerTimestamp = ReturnType<typeof FieldValue.serverTimestamp>;
export interface ProjectionV2 {
  id: string;
  createdAt: ServerTimestamp;
  updatedAt: ServerTimestamp;
  appVersion?: string;
  prefix?: string;
}
export function createOrThrow<T, K extends keyof T>(key: K): Pick<T, K> {
  // FIXME: use proxy over the projection and
  // if a field never been set throw an error
  let value: T[K] | undefined;

  const partialObject: Pick<T, K> = {} as any;

  Object.defineProperty(partialObject, key, {
    // configurable: false,
    // enumerable: true,
    set: (v: T[K]) => {
      value = v;
    },
    get: () => {
      if (value === undefined) {
        throw new Error(`Value of '${key as string}' not set yet`);
      }
    },
  });
  return partialObject;
}

export function createProjection<T extends ProjectionV2>(
  state: Omit<T, 'updatedAt' | 'createdAt' | 'id'>,
): T {
  return Object.assign(
    {},
    createOrThrow<T, 'createdAt'>('createdAt'),
    createOrThrow<T, 'updatedAt'>('updatedAt'),
    state,
  ) as T;
}

export abstract class Projector<
  P extends ProjectionV2,
  Events extends DomainEvent<any, any, DomainEventMetadata>,
> {
  protected abstract _projectionStore: ProjectionStore;
  protected abstract _eventStore: EventStore;
  // protected abstract _environment: Environment;

  // protected abstract emptyState: P;

  protected abstract readonly projectionName: string;
  protected abstract apply(state: P, event: Events): Promise<P>;

  public async foldUp(initialProjection: P, events: Events[]) {
    for (const event of events) {
      initialProjection = await this.apply(initialProjection, event);
    }
    return initialProjection;
  }

  public async getProjector(
    id: string,
    options: {
      defaultState?: Omit<P, 'id'>;
      events?: Events[];
    } = {},
  ): Promise<P> {
    let projection =
      (await this._projectionStore.getProjection<P>(this.projectionName, id)) ??
      options.defaultState;

    if (!projection) {
      return projection;
    }

    if (projection.appVersion !== 'this._environment.version') {
      const events = await this._eventStore.getEventsForAggregate(id);
      projection = await this.foldUp(projection, [...events] as []);
    } else {
      projection = await this.foldUp(projection, [
        ...(options.events ?? []),
      ] as []);
    }

    return projection;
  }

  public async listProjections() {
    return this._projectionStore.listProjections<P>(this.projectionName);
  }

  public saveProjection(id: string, projection: P) {
    // this._projectionStore.save(this.projectionName, id, projection);
  }

  protected async rebuildProjection(id: string, defaultState: Omit<P, 'id'>) {
    const events = await this._eventStore.getEvents();
    const projection = await this.getProjector(id, {
      defaultState,
      events: [...events] as [],
    });

    this.saveProjection(id, projection);
    return projection;
  }
}
