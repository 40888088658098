import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AdjustFieldValidationCommand } from '@faslh/api/table/commands';
import { FieldValidationAdjustedData, Table } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AdjustFieldValidationCommand)
export class AdjustFieldValidationCommandHandler extends IRequestHandler<
  AdjustFieldValidationCommand,
  void
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AdjustFieldValidationCommand): Promise<void> {
    const table = await this._eventStore.hyrdateAggregate(
      command.tableId,
      Table,
    );
    if (table.isArchived) {
      throw new Error(`Table ${command.tableId} is archived`);
    } else if (!table.hasField(command.fieldId)) {
    // Look at aggregate root loadFromHistory function
      // throw new Error(
      //   `Adding validation ${command.validations.map(
      //     (it) => it.sourceId,
      //   )} to non-existing field ${command.fieldId}`,
      // );
    }
    table.adjustFieldValidation(
      command.fieldId,
      this._eventUtils.getMetadata(),
      mapper.map(
        command,
        AdjustFieldValidationCommand,
        FieldValidationAdjustedData,
      ),
    );
    await this._eventStore.save(table);
  }
}
