import { ProjectionStore } from '@faslh/api/infrastructure/database';
import { SecretSet } from '@faslh/api/settings/domain';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

import { SecretsProjection } from '../secrets.projection';
import { buildSecretsProjection } from '../secrets.projector';

@NotificationHandler(SecretSet)
export class SecretSetHandler extends INotificationHandler<SecretSet> {
  constructor(private readonly _projectionStore: ProjectionStore) {
    super();
  }
  async handle(event: SecretSet): Promise<void> {
    const projection =
      (await this._projectionStore.getSingeltonProjection<SecretsProjection>(
        'secrets'
      )) ?? new SecretsProjection(event.aggregateId);
    const updatedProjection = buildSecretsProjection(projection, [event]);
    this._projectionStore.save('secrets', event.aggregateId, updatedProjection);
  }
}
