import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';

const event = 'extension_details_patched' as const;

export class ExtensionDetailsPatchedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;
}

export const ExtensionDetailsPatched = createEvent<
  ExtensionDetailsPatchedData,
  typeof event
>(event);

export type ExtensionDetailsPatched = InstanceType<
  typeof ExtensionDetailsPatched
>;
