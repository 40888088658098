import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class DelocalizeFieldCommand extends IRequest {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @IsUUID()
  @AutoMap()
  fieldId!: string;
}
