import { ExtensionInstalled } from '@faslh/api/user-extensions/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { Sdk } from '@faslh/compiler/sdk/platform';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

@NotificationHandler(ExtensionInstalled)
class AddI18nFeature extends INotificationHandler<ExtensionInstalled> {
  constructor(
    private readonly _devKit: DevKit,
    private readonly _sdk: Sdk,
  ) {
    super();
  }

  public async handle(event: ExtensionInstalled): Promise<void> {
    const details = JSON.parse(event.data.details);
    if (details['i18n'] !== '@fixed:default') {
      return;
    }

    const sourceExtension = this._devKit.getExtensionById(event.entityId);

    if (sourceExtension.name !== 'postgresql') {
      return;
    }
    const sourceSingleLineField =
      await this._devKit.getSourceFieldByName('short-text');
    const sourceCustomPrimaryKeyField =
      await this._devKit.getSourceFieldByName('primary-key-custom');
    const sourcePrimaryKeyField =
      await this._devKit.getSourceFieldByName('primary-key-uuid');
    const sourceJsonField = await this._devKit.getSourceFieldByName('json');

    const featureId = await this._sdk.features.addFeature({
      displayName: 'I18n',
    });

    const tagId = await this._sdk.features.addTag({
      displayName: 'i18n',
      featureId: featureId,
    });

    const sourceMandatoryValidation =
      await this._devKit.getValidationByName('mandatory');
    const sourceUniqueValidation =
      await this._devKit.getValidationByName('unique');

    const translationsTableId = await this._sdk.tables.addTable({
      featureId: featureId,
      displayName: 'Translations',
      details: {},
    });

    // TODO: add unoque index for locale + entityId
    const localeFieldId = await this._sdk.tables.addField({
      displayName: 'locale',
      sourceId: sourceSingleLineField.id,
      tableId: translationsTableId,
      details: {},
    });

    const entityIdFieldId = await this._sdk.tables.addField({
      displayName: 'entityId',
      sourceId: sourceSingleLineField.id,
      tableId: translationsTableId,
      details: {},
    });

    const i18nFieldId = await this._sdk.tables.addField({
      displayName: 'i18n',
      sourceId: sourceJsonField.id,
      tableId: translationsTableId,
      details: {},
    });

    await this._sdk.tables.adjustFieldValidationCommand({
      fieldId: entityIdFieldId,
      tableId: translationsTableId,
      validations: [
        {
          name: sourceUniqueValidation.name,
          sourceId: sourceUniqueValidation.id,
          details: {
            value: 'true',
          },
        },
        {
          name: sourceMandatoryValidation.name,
          sourceId: sourceMandatoryValidation.id,
          details: {
            value: 'true',
          },
        },
      ],
    });
    await this._sdk.tables.adjustFieldValidationCommand({
      fieldId: localeFieldId,
      tableId: translationsTableId,
      validations: [
        {
          name: sourceUniqueValidation.name,
          sourceId: sourceUniqueValidation.id,
          details: {
            value: 'true',
          },
        },
        {
          name: sourceMandatoryValidation.name,
          sourceId: sourceMandatoryValidation.id,
          details: {
            value: 'true',
          },
        },
      ],
    });

    await this._sdk.tables.adjustFieldValidationCommand({
      fieldId: i18nFieldId,
      tableId: translationsTableId,
      validations: [
        {
          name: sourceMandatoryValidation.name,
          sourceId: sourceMandatoryValidation.id,
          details: {
            value: 'true',
          },
        },
      ],
    });

    const localesTableId = await this._sdk.tables.addTable({
      featureId: featureId,
      displayName: 'Locales',
      details: {},
    });

    const codeFieldId = await this._sdk.tables.addField({
      displayName: 'code',
      sourceId: sourcePrimaryKeyField.id,
      tableId: localesTableId,
      details: {},
    });

    const displayNameFieldId = await this._sdk.tables.addField({
      displayName: 'displayName',
      sourceId: sourceSingleLineField.id,
      tableId: localesTableId,
      details: {},
    });

    await this._sdk.tables.adjustFieldValidationCommand({
      fieldId: codeFieldId,
      tableId: localesTableId,
      validations: [
        {
          name: sourceUniqueValidation.name,
          sourceId: sourceUniqueValidation.id,
          details: {
            value: 'true',
          },
        },
      ],
    });

    await this._sdk.tables.adjustFieldValidationCommand({
      fieldId: displayNameFieldId,
      tableId: localesTableId,
      validations: [
        {
          name: sourceUniqueValidation.name,
          sourceId: sourceUniqueValidation.id,
          details: {
            value: 'true',
          },
        },
        {
          name: sourceMandatoryValidation.name,
          sourceId: sourceMandatoryValidation.id,
          details: {
            value: 'true',
          },
        },
      ],
    });
  }
}
