import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { ChangeActionNameCommand } from '@faslh/api/table/commands';
import { Workflow, ActionNameChangedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(ChangeActionNameCommand)
export default class extends IRequestHandler<
  ChangeActionNameCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: ChangeActionNameCommand): Promise<string> {
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    workflow.changeActionName(
      this._eventUtils.getMetadata(),
      mapper.map(command, ChangeActionNameCommand, ActionNameChangedData),
    );

    await this._eventStore.save(workflow);
    return workflow.id;
  }
}
