import { DomainEvent } from './domain.event';

export abstract class AggregateRoot<TEvents extends DomainEvent<any, any>> {
  #ongoingEvents: DomainEvent<any, any>[] = [];
  #markupEvents: DomainEvent<any, any>[] = [];
  static streamName: string;
  abstract streamName: string;

  public empty = false;

  public version!: number;
  public abstract apply(event: TEvents): void;

  constructor(public readonly id: string) {}

  public getUncommittedChanges() {
    return this.#ongoingEvents;
  }

  public markChangesAsCommitted(): void {
    this.#ongoingEvents = [];
  }

  public loadFromHistory(history: TEvents[]): void {
    // FIXME: Catch apply error and replay the failed events after each successful apply
    // which would make order-stateless (same as the tree)
    history.forEach((event) => this.apply(event));
  }

  public replayEvents(history: TEvents[]): void {
    // A synonomous method for loadsFromHistory, to better explain the terms.
    this.loadFromHistory(history);
  }

  public applyChanges(event: TEvents, markupEvent = false) {
    if (markupEvent) {
      this.#markupEvents.push(event);
    } else {
      this.#ongoingEvents.push(event);
    }
    this.apply(event);
    this.empty = false;
  }
}

export class NotRelatedEvent extends Error {
  constructor(eventType: string) {
    super(
      `Event type ${eventType} is not related to the aggregate root. Please check the event type.`,
    );
  }
}
