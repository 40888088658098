import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import {
  AddQueryCommand,
  LocalizeQueryCommand,
} from '@faslh/api/table/commands';
import { Feature, QueryAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, Mediator, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddQueryCommand)
export default class extends IRequestHandler<AddQueryCommand, string> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _mediator: Mediator,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AddQueryCommand): Promise<string> {
    const feature = await this._eventStore.hyrdateAggregate(
      command.featureId,
      Feature,
    );

    if (feature.queryNameExist(command.displayName)) {
      throw new Error(`Workflow name ${command.displayName} already exist`);
    }

    const query = feature.addQuery(
      this._eventUtils.getMetadata(),
      mapper.map(command, AddQueryCommand, QueryAddedData),
    );

    await this._eventStore.save(feature);
    await this._eventStore.save(query);

    if (command.localizable) {
      await this._mediator.send(
        mapper.map(
          {
            featureId: command.featureId,
            queryId: query.id,
          },
          LocalizeQueryCommand,
          LocalizeQueryCommand,
        ),
      );
    }
    return query.id;
  }
}
