import authoriseText from './authorize.txt';
import indexText from './index.txt';
import subjectText from './subject.txt';

import { Injectable, ServiceLifetime } from 'tiny-injector';

import {
  ActionProperty,
  Contracts,
  IExtension,
} from '@faslh/compiler/contracts';
import { ProjectFS } from '@faslh/compiler/sdk/devkit';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class IdentityExtension implements IExtension {
  constructor(private readonly _projectFS: ProjectFS) {}

  async handleSetup?(
    contract: Record<string, ActionProperty>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeIdentityPath('index.ts'),
        content: [indexText],
      },
      {
        filePath: this._projectFS.makeIdentityPath('authorize.ts'),
        content: [authoriseText],
      },
      {
        filePath: this._projectFS.makeIdentityPath('subject.ts'),
        content: [subjectText],
      },
    ];
  }
}

// THOSE COMMENTS MOVED OUT OF subject.ts file

// FIXME: the loadSubject subject should only accept generic interface
// and each routing framework should adhere to provie the implementation
// based on the framework specifity

// should be moved to own extension or be part of routing extension
// function clientInfo(req: IncomingMessage) {
//   const userAgent = req.headers['user-agent'];
//   const ip = getClientIp(req);
//   const parser = new UAParser(userAgent);
//   const userAgentData = parser.getResult();
//   return {
//     ip,
//     userAgent,
//     userAgentData,
//   };
// }
