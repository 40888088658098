import { AutoMap } from '@automapper/classes';
import type { GroupQueryCondition } from '@january/compiler/transpilers';
import { IsBoolean, IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class QueryAddedData extends DomainEventData {
  @AutoMap()
  @IsBoolean()
  // FIXME: replace it with limit
  single!: boolean;

  @AutoMap()
  @Matches(/^[A-Za-z_ ]+$/)
  @IsNotEmpty()
  displayName!: string;

  @AutoMap(() => Object)
  condition!: GroupQueryCondition<any>;

  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap()
  @IsNotEmpty()
  sourceId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  details!: Record<string, any>;
}
@AutoMapHost()
export class QueryAdded extends DomainEvent<QueryAddedData, 'query_added'> {
  public override readonly eventType = 'query_added' as const;

  @AutoMap(() => QueryAddedData)
  public override data!: QueryAddedData;
}
