import { AutoMap } from '@automapper/classes';
import { IsOptional, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class ConnectAction extends IRequest {
  @IsUUID()
  @AutoMap()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsUUID()
  id!: string;
}
