export class Project {
  orgId!: string;
  workspaceId!: string;
  code!: string;
  repositoryName?: string;
  repositoryId?: number;
  constructor(
    public readonly id: string,
    data: Omit<Project, 'id'>,
  ) {
    this.orgId = data.orgId;
    this.workspaceId = data.workspaceId;
    this.code = data.code;
    this.repositoryName = data.repositoryName;
    this.repositoryId = data.repositoryId;
  }
}
