import { Injectable, ServiceLifetime } from 'tiny-injector';

import type { TableProjectionNS } from '@faslh/api/table/projection';

import { EventStore } from '@faslh/api/infrastructure/database';
import { Policy } from '@faslh/api/policies/domain';
import { Extension } from '@faslh/api/user-extensions/domain';

import { Features } from './features.sdk';
import { Tables } from './tables.sdk';
import { Workflows } from './workflows.sdk';

export interface TreeWorkflowActionVM
  extends TableProjectionNS.WorkflowActionVM {
  children?: TreeWorkflowActionVM[];
}

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class Sdk {
  constructor(
    public readonly tables: Tables,
    public readonly features: Features,
    public readonly workflows: Workflows,
    private readonly _eventStore: EventStore,
  ) {}

  public getInstalledExtensions() {
    return this._eventStore
      .listAggregates(Extension)
      .then((it) => it.filter((it) => it.deleted !== true));
  }
  public getInstalledExtension(id: string) {
    return this._eventStore.hyrdateAggregate(id, Extension);
  }

  public getPolicy(id: string) {
    return this._eventStore.hyrdateAggregate(id, Policy);
  }
}
