import deepmerge from 'deepmerge';
import { merge } from 'lodash';
import { Injectable, Injector, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import type { ChangesProjectionNS } from '@faslh/api/releases/projection';

import {
  ActionProperty,
  ConcreteContracts,
  Contracts,
  HandleActionOutput,
  IHandleAction,
  IncomingActionProperty,
  TriggerExtension,
  makeSchema,
  nonStatic,
  paramName,
  signutureInputs,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { Sdk } from '@faslh/compiler/sdk/platform';
import { BetterTree, SourceTarget } from '@faslh/isomorphic';
import {
  camelcase,
  createRecorder,
  pascalcase,
  snakecase,
  spinalcase,
  uniquify,
} from '@faslh/utils';

import { OrmCodeWriter } from './postgresql/orm';
import { AbstractInputParser } from './registry/abstract-input-parser';
import {
  ACTION_HANDLER_REGISTRY_TOKEN,
  EXTENISION_REGISTRY_TOKEN,
  actionHandlers,
  extensionsRegistry,
} from './registry/all';

Injector.AddSingleton(EXTENISION_REGISTRY_TOKEN, () => extensionsRegistry);
Injector.AddSingleton(ACTION_HANDLER_REGISTRY_TOKEN, () => actionHandlers);
@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ExtensionManager {
  constructor(
    private readonly _inputParser: AbstractInputParser,
    private readonly _sdk: Sdk,
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
    private readonly _ormCodeWriter: OrmCodeWriter,
  ) {}

  public getOrmExtension() {
    return this._ormCodeWriter;
  }

  public async getSetups(): Promise<Contracts.ExtensionSetupContract[]> {
    const results = [];
    for (const {
      extension,
      installedExtension,
    } of await this._inputParser.installedSourceExtensions()) {
      if (!extension.handleSetup) continue;
      const setup = await extension.handleSetup(
        await this.#resolveRuntimeInputs(
          Object.entries(installedExtension.details)
            .map(([name, input]) => ({ [name]: { input } }))
            .reduce((acc, it) => ({ ...acc, ...it }), {}) as any,
        ),
      );
      results.push(setup);
    }

    return results.flat();
  }

  public async generateFieldContract(
    input: Contracts.GenereateFieldContractInput,
  ): Promise<Contracts.Field> {
    const { sourceField, ...action } = await this._inputParser.getFieldHandler(
      input.sourceId,
    );
    const handler = action.handler as IHandleAction;
    const defaults = {
      displayName: input.displayName,
      primitiveType: sourceField.primitiveType as string,
    };
    const result = await handler.handleField({
      featureId: input.featureId,
      sourceField,
      details: input.details,
      validation: input.validation,
      displayName: input.displayName,
    });

    return {
      ...defaults,
      ...result,
    };
  }

  public async generateActionContract(
    sourceId: string,
    details: Record<string, any>,
    outputName: string,
  ): Promise<Contracts.WorkflowActionOutput> {
    const recorder = createRecorder({
      label: `generateActionContract ${sourceId}`,
    });
    recorder.record(`sourceId`);
    const { sourceAction, sourceExtension, ...action } =
      await this._inputParser.getActionHandler(sourceId);
    const handler = action.handler as IHandleAction;
    recorder.recordEnd(`sourceId`);

    recorder.record(`handleAction`);

    const contract: HandleActionOutput = handler.handleAction
      ? await handler.handleAction({
          sourceAction,
          outputName,
          details,
        })
      : {
          output: { displayName: outputName },
          runtimeInputs: details ?? {},
          transfer: {},
          workflowInputs: {},
        };
    recorder.recordEnd(`handleAction`);
    recorder.record(`returnVal`);
    const extras = {
      trigger: details['trigger'],
    };
    const returnVal = {
      inputs: await this.#resolveRuntimeInputs(
        contract.runtimeInputs ?? {},
        extras,
      ),
      transfer: await this.#resolveRuntimeInputs(
        contract.transfer ?? {},
        extras,
      ),
      output: contract.output ?? { displayName: outputName },
      sourceId: sourceId,
      sourceAction: sourceAction,
      workflowInputs: await this.#resolveRuntimeInputs(
        contract.workflowInputs ?? {},
        extras,
      ),
    };
    recorder.recordEnd(`returnVal`);
    recorder.end();
    return returnVal;
  }

  public async processFeature(
    contract: Contracts.FeatureContract,
  ): Promise<ConcreteContracts.WorkflowConcreteStructure[]> {
    const structures: ConcreteContracts.WorkflowConcreteStructure[] = [];

    (await this._inputParser.installedSourceExtensions()).forEach(
      ({ extension }) => {
        if (extension.onFeatureContract) {
          const workflows: Contracts.Workflow[] = [];

          for (const workflow of contract.workflows) {
            if (
              ((extension as TriggerExtension).triggers ?? []).includes(
                workflow.trigger.sourceId,
              )
            ) {
              workflows.push(workflow);
            }
          }

          extension.onFeatureContract(
            {
              ...contract,
              workflows,
            },
            (concrete) => {
              structures.push(concrete);
            },
          );
        }
      },
    );

    return structures;
  }

  public async processWorkflow(
    contract: Contracts.Workflow,
  ): Promise<ConcreteContracts.WorkflowConcreteStructure[]> {
    const commandName = camelcase(contract.displayName);
    const structures: ConcreteContracts.WorkflowConcreteStructure[] = [];
    const fnNames: ConcreteContracts.MorphStatementWriter[] = [];
    let workflowInputs: Record<string, any> = {};

    for (const action of contract.actions.slice(1)) {
      const result = await this.#processTreeActions(action);
      const structureResult = result.structures[0];
      structures.push({
        filePath: this._projectFS.makeCommandPath(
          contract.featureName,
          contract.tag,
          contract.displayName,
        ),
        structure: [
          ...result.topLevel.flat(),
          ...(structureResult.inline ? [] : structureResult.structure),
        ],
      });

      if (structureResult.inline) {
        fnNames.push(structureResult.structure);
      } else {
        fnNames.push([result.callExprStr]);
      }
      workflowInputs = {
        ...workflowInputs,
        ...action.workflowInputs,
      };
    }

    structures.push(
      ...(await this._inputParser.processTrigger(
        contract.trigger,
        workflowInputs,
      )),
    );

    const dto = this.#inputDto({
      displayName: contract.displayName,
      inputs: contract.inputs,
      schemaName: contract.schemaName,
      inputName: contract.inputName,
    });

    const destructuredInputs = signutureInputs(contract.inputs).map(
      ([name]) => name,
    );

    const workflowParams = signutureInputs(workflowInputs).map(
      ([name, prop]) => ({
        name: name,
        type: prop.type,
      }),
    );

    if (destructuredInputs.length) {
      workflowParams.unshift({
        name: `{${destructuredInputs}}`,
        type: contract.inputName,
      });
    }

    // TODO: we don't need to have explict command creation here
    // we might create new extension named CQS and move this logic there
    // which would give us ability to have different flavors of code structure
    const workflowStructure: ConcreteContracts.MorphStatementWriter = [
      ...(contract.trigger.details['imports'] ?? []),
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier:
          this._projectFS.makeCoreImportSpecifier('core/validation'),
        namedImports: ['createSchema'],
      },
      ...dto,
      {
        kind: morph.StructureKind.Function,
        name: commandName,
        isAsync: true,
        isDefaultExport: false,
        isExported: true,
        parameters: workflowParams,
        statements: [...fnNames.flat(), `${contract.output.returnCode || ''};`],
      },
    ];

    structures.push({
      filePath: this._projectFS.makeCommandPath(
        contract.featureName,
        contract.tag,
        contract.displayName,
      ),
      structure: workflowStructure,
    });

    // export the command index.ts
    structures.push({
      filePath: this._projectFS.makeIndexFilePath(
        contract.featureName,
        contract.tag,
      ),
      structure: [
        {
          kind: morph.StructureKind.ExportDeclaration,
          moduleSpecifier: this._projectFS.makeExportPath(
            contract.displayName,
            'command',
          ),
        },
      ],
    });

    return structures;
  }

  public async processPolicy(
    contract: Contracts.Policy,
  ): Promise<ConcreteContracts.WorkflowConcreteStructure[]> {
    return [
      {
        filePath: this._projectFS.makeIdentityPath(
          `${spinalcase(contract.displayName)}.policy.ts`,
        ),
        structure: [
          // ...structures.flat(),
          contract.rule,
          // {
          //   isAsync: true,
          //   kind: morph.StructureKind.Function,
          //   name: camelcase(contract.displayName),
          //   isDefaultExport: false,
          //   isExported: true,
          //   // parameters: [{ name: 'context', type: 'Record<string, any>' }],
          //   // statements: [`return ${evaluation};`],
          //   statements:[contract.rule]
          // },
        ],
      },
    ];
  }

  public async generateProjectContract(
    features: FeatureInput[],
    policies: PolicyInput[],
  ): Promise<Contracts.FaslhContracts> {
    const recorder = createRecorder({
      label: 'generateProjectContract',
    });
    const contracts: Contracts.FaslhContracts = {
      policies: policies.map((it) => ({
        displayName: it.displayName,
        rule: it.rule,
      })),
      features: await Promise.all(
        features.map(async (feature) => {
          recorder.record(`feature ${feature.displayName}`);
          const tags = new Set<string>();
          const featureContract: Contracts.FeatureContract = {
            displayName: feature.displayName,
            tags: [],
            tables: await Promise.all(
              feature.tables.map(async (table) => {
                const indices: { columns: string[] }[] = [];
                for (const index of table.indexes) {
                  const indexes: string[] = [];
                  for (const column of index.columns) {
                    const result = await this.#resolveRuntimeInputs({
                      column,
                    } as any);
                    indexes.push(result.column.value);
                  }
                  indices.push({ columns: indexes });
                }

                const tableContract: Contracts.TableContract = {
                  tableName: table.tableName,
                  indexes: indices,
                  fields: await Promise.all(
                    table.fields.map((field) => {
                      return this.generateFieldContract({
                        featureId: feature.id,
                        sourceId: field.sourceId,
                        displayName: field.displayName,
                        details: field.details,
                        validation: field.validations,
                      });
                    }),
                  ),
                };
                return tableContract;
              }),
            ),
            workflows: await Promise.all(
              feature.workflows.map(async (workflow) => {
                recorder.record(`workflow ${workflow.displayName}`);
                const actions: Contracts.WorkflowAction[] = await Promise.all(
                  workflow.actions.map(async (it) => {
                    const contract = await this.generateActionContract(
                      it.sourceId,
                      it.details,
                      it.outputName,
                    );

                    return {
                      ...contract,
                      id: it.id,
                      name: it.displayName,
                      children: [],
                      data: {},
                    };
                  }),
                );

                const schemaName = camelcase(`${workflow.displayName} schema`);
                const inputName = pascalcase(`${workflow.displayName} input`);

                const workflowTag = await this._sdk.features.getTag(
                  feature.id,
                  workflow.tagId,
                );
                if (!workflowTag) {
                  throw new Error(`Workflow doesn't have a tag`);
                }

                const policies = await Promise.all(
                  (workflow.policies ?? []).map((it) =>
                    this._sdk.getPolicy(it.id),
                  ),
                );

                const tree = new BetterTree<SourceTarget>({
                  source: workflow.id,
                  nodes: (workflow.details['sequence'] ?? []) as SourceTarget[],
                });
                const treeActions = tree.map(
                  (id) => actions.find((action) => action.id === id)!,
                );

                const inputs: any = deepmerge.all(
                  actions.map((it) => it.inputs),
                );

                const workflowContract: Contracts.Workflow = {
                  actions: treeActions,
                  inputName: inputName,
                  inputs: inputs,
                  displayName: workflow.displayName,
                  schemaName: schemaName,
                  featureName: feature.displayName,
                  tag: workflowTag.displayName,
                  output: {
                    properties: this.#generateOutputContract(
                      workflow.output?.['properties'] ?? [],
                    ),
                    returnCode: workflow.output?.['code']?.['inner'],
                  },
                  trigger: {
                    sourceId: workflow.trigger.sourceId,
                    policies: policies.map((it) => it.displayName),
                    tag: workflowTag.displayName, // NOTE: it only has effect for http triggers. we might need it for github action to support multiple webhooks
                    details: workflow.trigger.details,
                    inputName: inputName,
                    inputs: inputs,
                    displayName: workflow.displayName,
                    featureName: feature.displayName,
                    schemaName: schemaName,
                    operationName: snakecase(workflow.displayName),
                  },
                };
                tags.add(workflowTag.displayName);
                recorder.recordEnd(`workflow ${workflow.displayName}`);
                return workflowContract;
              }),
            ),
          };

          recorder.end();
          featureContract.tags = Array.from(tags);
          return featureContract;
        }),
      ),
    };

    return contracts;
  }

  #generateOutputContract(
    properties: {
      primitiveType: string;
      name: string;
      kind: string;
    }[],
  ): ActionProperty[] {
    return properties.map((it) => ({
      type: it.primitiveType,
      validations: [],
      properties: [],
      static: false,
      value: it.name,
      input: '',
      namespace: it.kind,
      name: '',
      id: '',
    }));
  }

  #inputDto(contract: {
    inputName: string;
    displayName: string;
    schemaName: string;
    inputs: Record<string, ActionProperty>;
  }): ConcreteContracts.MorphStatementWriter {
    const inputs = signutureInputs(contract.inputs);

    const schema = inputs.reduce<Record<string, any>>((acc, [name, prop]) => {
      const type = (primitive: any) =>
        [null, 'string', 'number'].includes(primitive)
          ? primitive
          : primitive === undefined
            ? []
            : primitive.endsWith('[]')
              ? 'array'
              : [];

      const primitiveType = type(prop.type);
      return {
        ...acc,
        [name]: {
          type: primitiveType,
          items:
            primitiveType === 'array'
              ? {
                  type: type(prop.type.replace('[]', '')),
                }
              : undefined,
          default: prop.defaultValue,
          ...makeSchema(prop),
        },
      };
    }, {});

    return [
      (writer) =>
        writer.writeLine(
          `export const ${contract.schemaName} = createSchema<${
            contract.inputName
          }>(${this._devKit.toJson(schema)});`,
        ),
      {
        kind: morph.StructureKind.Interface,
        isExported: true,
        isDefaultExport: false,
        name: contract.inputName,
        properties: inputs.map(([name, prop]) => {
          const isMandatory = (prop.validations ?? []).some(
            (it) => it.type === 'mandatory',
          );
          return {
            decorators: [],
            name: name,
            hasQuestionToken: !isMandatory,
            type: makeSchema(prop).type ?? prop.type,
          };
        }),
      },
    ];
  }

  async #resolveRuntimeInputs(
    inputs: HandleActionOutput['runtimeInputs'],
    extras?: Record<string, unknown>,
  ): Promise<any> {
    const value = inputs;
    const input = (value as unknown as IncomingActionProperty).input;
    if (
      value !== null &&
      typeof value === 'object' &&
      typeof input === 'string' &&
      input.startsWith('@')
    ) {
      const result = await this._inputParser.parseInput({
        input,
        extras,
      });
      merge(value, result);
    } else {
      for (const [key, value] of Object.entries(inputs ?? {})) {
        if (Array.isArray(value)) {
          for (const it of value) {
            await this.#resolveRuntimeInputs(it, extras);
          }
        } else if (value !== null && typeof value === 'object') {
          const input = value.input;
          if (typeof input === 'string' && input.startsWith('@')) {
            const result = await this._inputParser.parseInput({
              input,
              extras,
            });
            merge(value, result);
          } else {
            await this.#resolveRuntimeInputs(value as any, extras);
          }
        }
      }
    }
    return inputs;
  }
  async #processTreeActions(
    tree: Contracts.WorkflowAction,
  ): Promise<ProcessTreeActionResult> {
    const actions = tree.children;
    const parameters: Record<string, morph.ParameterDeclarationStructure> = {};
    const topLevel: ConcreteContracts.MorphStatementWriter[] = [];

    const actionsResults: Record<string, ProcessTreeActionResult> = {};
    for (const action of actions) {
      const result = await this.#processTreeActions(action);
      result.parameters.forEach((it) => (parameters[it.name] = it));
      actionsResults[action.id] = result;
      if (!result.structures[0].inline) {
        topLevel.push(...result.topLevel, result.structures[0].structure);
      } else {
        topLevel.push(...result.topLevel);
      }
    }
    const mainAction = await this.#proccesSingleAction(
      camelcase(tree.name),
      tree,
      Object.values(parameters),
      (id) => {
        const actionResult = actionsResults[id];
        if (!actionResult) {
          throw new Error(`Action result ${id} not found`);
        }
        const result = actionResult.structures[0];
        if (result.inline) {
          return actionResult.unwrapped;
        }
        return [actionResult.callExprStr];
      },
    );
    topLevel.push(...mainAction.topLevel);

    const actionStructure: ProcessTreeActionResult['structures'] = [];
    const signutureParameters: morph.ParameterDeclarationStructure[] =
      nonStatic(tree.inputs).map(([name, prop]) => {
        const isMandatory = (prop.validations ?? []).some(
          (it) => it.type === 'mandatory',
        );
        return {
          kind: morph.StructureKind.Parameter,
          name: name,
          type: makeSchema(prop).type ?? prop.type ?? 'any',
          hasQuestionToken: !isMandatory,
        };
      });
    if (mainAction.inline) {
      // FIXME: the inline needs to be wrapped in a function
      // in case it is used in different places
      // so the meaning of inline is "guess if I can be inlined"
      // actionStructure.push(
      //   createArrowFn(
      //     camelcase(tree.name),
      //     [...signutureParameters, ...Object.values(parameters)],
      //     [mainAction.unwrapped],
      //   ),
      // );
      actionStructure.push({
        inline: true,
        structure: mainAction.unwrapped,
      });
    } else {
      actionStructure.push({
        inline: false,
        structure: [
          {
            kind: morph.StructureKind.Function,
            name: camelcase(tree.name),
            isAsync: true,
            isDefaultExport: false,
            isExported: false,
            parameters: uniquify(
              [...signutureParameters, ...Object.values(parameters)],
              (item) => item.name,
            ),
            statements: mainAction.unwrapped,
          },
        ],
      });
    }

    return {
      callExprStr: mainAction.callExprStr,
      structures: actionStructure,
      parameters: mainAction.parameters,
      topLevel: topLevel,
      unwrapped: mainAction.unwrapped,
    };
  }

  async #proccesSingleAction(
    name: string,
    action: Contracts.WorkflowAction,
    childrenParameters: morph.ParameterDeclarationStructure[],
    resolveAction: (id: string) => ConcreteContracts.MorphStatementWriter,
  ): Promise<{
    parameters: morph.ParameterDeclarationStructure[];
    callExprStr: string;
    topLevel: ConcreteContracts.MorphStatementWriter[];
    inline: boolean;
    unwrapped: ConcreteContracts.MorphStatementWriter;
  }> {
    const actionHanlder = await this._inputParser.getActionHandler(
      action.sourceId,
    );
    const handler = actionHanlder.handler as IHandleAction;
    const variables: Record<string, morph.VariableStatementStructure> = {};
    const qbUsage: string[] = [];
    const topLevel: ConcreteContracts.MorphStatementWriter[] = [];
    const body: ConcreteContracts.MorphStatementWriter[] = [];
    const result = await handler.processAction(
      action,
      {
        useVariable: (name, value) => {
          variables[name] = {
            declarationKind: morph.VariableDeclarationKind.Const,
            kind: morph.StructureKind.VariableStatement,
            declarations: [{ name: name, initializer: value }],
          };
        },
        useQb: (usage) => {
          // const qbString = `${action.output.displayName}QB`;
          const qbString = 'qb'; //
          // just name it "qb" given each action is function hence no name collision
          qbUsage.push(usage(qbString));
        },
        resolveAction,
      },
      action.inputs,
      action.transfer,
    );

    const structure = result.structure;
    if (
      typeof structure === 'string' ||
      typeof structure === 'function' ||
      Array.isArray(structure)
    ) {
      body.push(structure);
    } else {
      body.push(structure.actionStructure);
      topLevel.push(structure.topLevelStructure);
    }

    const parameters: morph.ParameterDeclarationStructure[] = signutureInputs(
      action.inputs,
    ).map(([name, prop]) => {
      const isMandatory = (prop.validations ?? []).some(
        (it) => it.type === 'mandatory',
      );
      return {
        kind: morph.StructureKind.Parameter,
        name: name,
        type: makeSchema(prop).type ?? prop.type ?? 'any',
        hasQuestionToken: !isMandatory,
      };
    });
    const outputName = action.output.displayName;
    const withMaybeVar = outputName ? `const ${outputName} = ` : '';

    const callExprParameters = uniquify(
      [
        ...nonStatic(action.inputs).map(paramName),
        ...childrenParameters.map((it) => it.name),
      ],
      (item) => item,
    );

    if (result.inline) {
      return {
        inline: true,
        parameters,
        callExprStr: `${withMaybeVar} await ${name}(${callExprParameters.join(
          ', ',
        )})`,
        topLevel,
        unwrapped: [...body.flat()],
      };
    }
    return {
      inline: false,
      parameters,
      callExprStr: `${withMaybeVar} await ${name}(${callExprParameters.join(
        ', ',
      )})`,
      topLevel,
      unwrapped: [...Object.values(variables), ...qbUsage, ...body.flat()],
    };
  }
}

export type FeatureInput = {
  id: string;
  displayName: string;
  tables: Array<ChangesProjectionNS.TableVM>;
  workflows: Array<ChangesProjectionNS.IWorkflowVM>;
  queries: Array<ChangesProjectionNS.QueryVM>;
};
export type PolicyInput = {
  displayName: string;
  rule: string;
};

interface Bindings {
  actions: Contracts.WorkflowAction[];
}

function resolveStepInput(property: ActionProperty, bindings: Bindings) {
  // remove "@step-"" word to get the action name
  const sourceId = property.namespace.replace('@step-', '');

  const usedAction = bindings.actions.find((it) => it.id === sourceId);
  if (!usedAction) {
    throw new Error(
      `Trying to access action ${sourceId} but it's not defined in the workflow`,
    );
  }
  return `${usedAction.output.displayName}.${property.name}`;
}

// loadSubject, isAuthenticated must be provided from identity extensions (firebase-auth)
// loadSubject should return null if user is not authenticated and not throw an error
// isAuthenticated rule should be used on the workflow that needs access to user so the server won't break down due to user absence

type ProcessTreeActionResult = {
  callExprStr: string;
  structures: {
    inline: boolean;
    structure: ConcreteContracts.MorphStatementWriter;
  }[];
  parameters: morph.ParameterDeclarationStructure[];
  topLevel: ConcreteContracts.MorphStatementWriter[];
  unwrapped: ConcreteContracts.MorphStatementWriter;
};
