import { Injectable, ServiceLifetime } from 'tiny-injector';

export interface FaslhConfig {
  tsConfigFilePath: string;
  basePath: string;
  features: string;
}
@Injectable({
  lifetime: ServiceLifetime.Singleton,
})
export class ProjectConfig {
  #config: FaslhConfig = {
    basePath: 'src',
    features: 'src/features',
    tsConfigFilePath: 'tsconfig.app.json',
  };

  getConfig(): Readonly<FaslhConfig> {
    return this.#config;
  }

  updateConfig(config: Partial<FaslhConfig>) {
    const current = this.getConfig();
    this.#config = {
      ...current,
      ...config,
    };
  }
}
