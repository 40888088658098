import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsOptional, IsUUID, Matches } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class AddFeatureCommand extends IRequest {
  @AutoMap()
  @IsUUID()
  @IsOptional()
  id?: string;

  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  displayName!: string;
}
