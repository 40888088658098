import {
  ProjectionV2,
  createOrThrow,
  createProjection,
} from '@faslh/api/infrastructure/database';
import { CommitStage } from '@faslh/api/releases/models';
import { WorkflowRunConclusion, WorkflowRunStatus } from '@faslh/isomorphic';

export interface CommitProjection extends ProjectionV2 {
  stage: CommitStage[];
  conclusion: WorkflowRunConclusion;
  status: WorkflowRunStatus;
  message: string;
  commitSha: string;
}

export const commitProjection = (
  state?: Omit<CommitProjection, 'id'>,
): CommitProjection =>
  createProjection({
    ...(state ?? {
      stage: [],
      ...createOrThrow('status'),
      ...createOrThrow('message'),
      ...createOrThrow('commitSha'),
      ...createOrThrow('conclusion'),
    }),
  });
