import { AutoMap } from '@automapper/classes';
import { IsString, IsUUID } from 'class-validator';

import {
  DomainEvent,
  DomainEventData,
  ToPrimitive,
} from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class CodeAssociatedWithProjectData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  projectId!: string;

  @AutoMap()
  @IsUUID()
  workspaceId!: string;

  @AutoMap()
  @IsString()
  code!: string;

  // This is an arbitrary id that is used to identify the code revision
  // more like primary key in the database
  @AutoMap()
  @IsString()
  arbitraryId!: string;
}

@AutoMapHost()
export class CodeAssociatedWithProject extends DomainEvent<
  CodeAssociatedWithProjectData,
  'code_associate_with_project'
> {
  public eventType = 'code_associate_with_project' as const;

  @AutoMap(() => CodeAssociatedWithProjectData)
  public data!: CodeAssociatedWithProjectData;
}
