// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import txt from './file-manager.txt';
import serviceAccountInterface from './service-account-interface.txt';
import uploader from './uploader.txt';

import dedent from 'dedent';
import { Injectable, Injector, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import { onEvent } from '@faslh/api/infrastructure/database';
import {
  ConcreteContracts,
  Contracts,
  HandleActionInput,
  HandleActionOutput,
  HandleFieldInput,
  IExtension,
  IHandleAction,
  ProcessActionHelpers,
  generateValidationContract,
  useInput,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { Sdk } from '@faslh/compiler/sdk/platform';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class GoogleStorageExtension implements IExtension, IHandleAction {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
  ) {}

  async handleSetup(
    details: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        // TODO: extract file manager initialization to be in this extension.
        filePath: this._projectFS.makeCorePath('uploader.ts'),
        content: [uploader],
      },
      {
        filePath: this._projectFS.makeCorePath('service-account.ts'),
        content: [serviceAccountInterface],
      },
      {
        // FIXME: file-manager shouldn't be added by a specific storage extension rather it
        // should be imported from a library (we should publish (file-manager or storage-abstraction) as npm package) and
        // only use the implementation of it here
        filePath: this._projectFS.makeCorePath('file-manager.ts'),
        content: [txt],
      },
    ];
  }

  public async handleAction(
    input: HandleActionInput,
  ): Promise<HandleActionOutput> {
    switch (input.sourceAction.name) {
      case 'list-files':
        return {
          output: {
            displayName: input.outputName ?? 'files',
          },
          runtimeInputs: {
            prefix: {
              input: '@trigger:query.prefix',
            },
            flat: {
              input: '@trigger:query.flat',
              // FIXME: add boolean validation
            },
          },
        };
      case 'upload-file':
        return {
          output: {
            displayName: input.outputName ?? 'file',
          },
          runtimeInputs: {},
          workflowInputs: {
            request: {
              input: '@trigger:request.raw',
              type: 'IncomingMessage',
            },
          },
        };
      default:
        throw new Error(`action ${input.sourceAction.name} is not supported`);
    }
  }

  public async processAction(
    contract: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    inputs: Record<string, any>,
  ): Promise<ConcreteContracts.ProcessActionOutput> {
    switch (contract.sourceAction.name) {
      case 'list-files':
        return {
          inline: false,
          structure: [
            (writer) =>
              writer.writeLine(dedent`
                const ${contract.output.displayName} = await fileManager.listPublicLinks({
                  delimiter: ${inputs['flat']} ? '/' : undefined,
                  prefix: ${inputs['prefix']}
                })`),
          ],
        };
      case 'upload-file':
        // const uploadOptions:[string] = [];
        // if (inputs['maxFileSize']) {
        //   uploadOptions.push([`maxFileSize`, inputs['maxFileSize']]);
        // }

        const mimeTypes = useInput(inputs['types']);

        return {
          inline: true,
          structure: {
            topLevelStructure: [
              {
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier: 'http',
                namedImports: ['IncomingMessage'],
              },
              {
                kind: morph.StructureKind.ImportDeclaration,
                moduleSpecifier:
                  this._projectFS.makeCoreImportSpecifier('core/uploader'),
                namedImports: ['streamEnd', 'createUploader'],
              },
            ],
            actionStructure: [
              // TODO: we need move off formidable. essentially we need a way to convert a request files to
              // stream and pass it throught uploader to make it work with non request streams as well
              (writer) =>
                writer.writeLine(dedent`
                  const { parser, fileLocation } = createUploader();
                  ${contract.output.displayName ? `const [${contract.output.displayName}] = ` : ''} await Promise.all([
                    streamEnd(fileLocation),
                    parser.parse(request),
                  ]);`),
            ],
          },
        };

      default:
        throw new Error(
          `action ${contract.sourceAction.name} is not supported`,
        );
    }
  }

  public async handleField(
    input: HandleFieldInput,
  ): Promise<Contracts.InputFileField> {
    return {
      validations: await generateValidationContract(input.validation),
      nativeType: 'varchar',
      mandatory: false,
      unique: false,
    };
  }
}

onEvent({
  // events: [ExtensionInstalled],
  events: [],
  handler: async (event: any, context) => {
    const devKit = Injector.GetRequiredService(DevKit, context);
    const sdk = Injector.GetRequiredService(Sdk, context);
    const sourceExtension = devKit.getExtensionById(event.entityId);

    if (sourceExtension.name !== 'google-cloud-storage') {
      return;
    }

    const httpTrigger = await devKit.getTriggerByName('http');
    if (!httpTrigger) {
      throw new Error(`http trigger not found`);
    }

    const uploadAction = await devKit.getSourceActionByName('upload-file');

    if (!uploadAction) {
      throw new Error(`upload-file action not found`);
    }

    const listFilesAction = await devKit.getSourceActionByName('list-files');

    if (!listFilesAction) {
      throw new Error(`list-files action not found`);
    }

    const featureId = await sdk.features.addFeature({
      displayName: 'Google Cloud Storage',
    });

    const tagId = await sdk.features.addTag({
      displayName: 'files',
      featureId: featureId,
    });
    const workflowId = await sdk.workflows.addWorkflow(
      {
        featureId: featureId,
        displayName: 'Upload File',
        sourceId: httpTrigger.id,
        details: {
          method: 'post',
          path: '/upload',
          contentType: 'multipart/form-data',
          type: 'workflow',
        },
      },
      tagId,
    );

    await sdk.workflows.addAction({
      displayName: uploadAction.displayName,
      featureId: featureId,
      workflowId: workflowId,
      sourceId: uploadAction.id,
      outputName: 'file',
      details: {},
    });

    const queryId = await sdk.workflows.addWorkflow(
      {
        featureId: featureId,
        displayName: 'List Files',
        sourceId: httpTrigger.id,
        details: {
          method: 'get',
          path: '/',
          type: 'query',
        },
      },
      tagId,
    );
    await sdk.workflows.addAction({
      displayName: listFilesAction.displayName,
      featureId: featureId,
      workflowId: queryId,
      sourceId: listFilesAction.id,
      details: {},
      outputName: 'files',
    });
  },
});
