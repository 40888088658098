import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class FieldDetailsUpdatedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  tableId!: string;

  @AutoMap()
  @IsUUID()
  fieldId!: string;

  @AutoMap(() => Object)
  details!: Record<string, any>;
}

export const FieldDetailsUpdated = createEvent<
  FieldDetailsUpdatedData,
  'field_details_updated'
>('field_details_updated');
export type FieldDetailsUpdated = InstanceType<typeof FieldDetailsUpdated>;