import dockerFile from './dockerfile.txt';

import yaml from 'js-yaml';
import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import { Contracts, IExtension } from '@faslh/compiler/contracts';
import { ProjectFS } from '@faslh/compiler/sdk/devkit';

import { AbstractInputParser } from '../registry/abstract-input-parser';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class FlyExtension implements IExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _context: Context,
  ) {}

  private get _inputParser() {
    return Injector.GetRequiredService(AbstractInputParser, this._context);
  }
  async handleSetup(
    inputs: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    const routingExt = await this._inputParser.routingExtension();
    return [
      {
        filePath: this._projectFS.makeRootPath('.github/workflows/deploy.yml'),
        content: [
          deployContent(
            inputs['FLY_API_TOKEN'].value,
            inputs['FLY_APP_NAME'].value,
          ),
        ],
      },
      {
        filePath: this._projectFS.makeSrcPath('server.ts'),
        content: [routingExt.extension.getServerSetup()],
      },
      {
        filePath: this._projectFS.makeRootPath('Dockerfile'),
        content: [dockerFile],
      },
      {
        filePath: this._projectFS.makeRootPath('deploy.toml'),
        content: [
          `# deploy.toml app configuration file generated for build on 2023-06-18T14:20:13+03:00
#
# See https://fly.io/docs/reference/configuration/ for information about how to use this file.
#

primary_region = "ams"

[env]
  PORT = "3000"

[http_service]
  internal_port = 3000
  force_https = true
  auto_stop_machines = true
  auto_start_machines = true
  min_machines_running = 0
`,
        ],
      },
    ];
  }
}

const deployContent_v1 = (envionmentVars: string[], token: string) => {
  const vars = envionmentVars.reduce(
    (acc, curr) => {
      return {
        ...acc,
        ...{
          [`envkey_${curr}`]: `\${{ secrets.${curr} }}`,
        },
      };
    },
    {} as Record<string, any>,
  );
  const sendStatusStep = (steps: string[]) => ({
    uses: 'fjogeleit/http-request-action@v1',
    if: '${{ always() }}',
    with: {
      url: '${{ env.FASLH_API_URL }}',
      method: 'POST',
      data: JSON.stringify(
        {
          jobName: '${{ github.job }}',
          correlationId: '${{ github.event.inputs.correlationId }}',
          statuses: steps.map((step) => ({
            step,
            status: `\${{ steps.${step}.outcome }}`,
          })),
        },
        null,
        2,
      ),
    },
  });
  return yaml.dump(
    {
      name: 'Deploy To fly.io',
      on: {
        // push: {
        //   branches: ['main'],
        // },
        workflow_dispatch: {
          inputs: {
            correlationId: {
              description: '"Correlation Id"',
              required: true,
            },
          },
        },
      },
      env: {
        DEMO_TOKEN: 'fo1_GH0M7yvLWYkunojmEAdN8EwILUiNE0HxROsdHY1UX-4',
        FASLH_API_URL:
          'https://us-central1-january-9f554.cloudfunctions.net/ghevents',

        // 'https://2c12-176-29-107-62.eu.ngrok.io/january-9f554/us-central1/ghevents',
      },
      jobs: {
        deploy_to_fly: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only --legacy-peer-deps --no-audit --no-fund',
            },
            {
              id: 'cache',
              name: 'Cache Or Restore Node Modules',
              uses: 'actions/cache@v3',
              with: {
                path: 'node_modules',
                key: "node-modules-${{ hashFiles('package-lock.json') }}",
              },
            },
            {
              id: 'install_deps',
              name: 'Install Deps',
              run: 'npm install --no-audit --no-fund',
            },
            {
              id: 'build',
              name: 'Build',
              run: 'npm run build:prod -- --entry ./src/server.ts',
            },
            {
              uses: 'superfly/flyctl-actions/setup-flyctl@master',
            },
            {
              name: 'Collect Env Variables',
              uses: 'SpicyPizza/create-envfile@v1.3',
              with: {
                ...vars,
                directory: './',
                file_name: './build/.env',
                fail_on_empty: false,
              },
            },
            {
              id: 'deploy',
              name: 'Deploy to Fly.io',
              run: `flyctl deploy --app \${{ secrets.${token} }} --remote-only --config ./deploy.toml --verbose`,
              env: {
                FLY_API_TOKEN: `\${{ secrets.${token} }}`,
              },
            },
            // {
            //   name: 'Import secrets into Fly',
            //   run: 'flyctl secrets import < ./build/.env --config ./deploy.toml \nrm -f ./build/.env',
            //   env: {
            //     FLY_API_TOKEN: `\${{ secrets.${token} }}`,
            //   },
            // },
            sendStatusStep([
              'checkout',
              'install_lockfile',
              'cache',
              'install_deps',
              'build',
              'deploy',
            ]),
          ],
        },
      },
    },
    {
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};

const deployContent_v2 = (token: string) => {
  const sendStatusStep = (steps: string[]) => ({
    uses: 'fjogeleit/http-request-action@v1',
    if: '${{ always() }}',
    with: {
      url: '${{ env.FASLH_API_URL }}',
      method: 'POST',
      data: JSON.stringify(
        {
          jobName: '${{ github.job }}',
          correlationId: '${{ github.event.inputs.correlationId }}',
          statuses: steps.map((step) => ({
            step,
            status: `\${{ steps.${step}.outcome }}`,
          })),
        },
        null,
        2,
      ),
    },
  });
  return yaml.dump(
    {
      name: 'Deploy To fly.io',
      on: {
        // push: {
        //   branches: ['main'],
        // },
        workflow_dispatch: {
          inputs: {
            correlationId: {
              description: '"Correlation Id"',
              required: true,
            },
          },
        },
      },
      env: {
        DEMO_TOKEN: 'fo1_GH0M7yvLWYkunojmEAdN8EwILUiNE0HxROsdHY1UX-4',
        FASLH_API_URL:
          'https://us-central1-january-9f554.cloudfunctions.net/ghevents',

        // 'https://2c12-176-29-107-62.eu.ngrok.io/january-9f554/us-central1/ghevents',
      },
      jobs: {
        deploy_to_fly: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only --legacy-peer-deps --no-audit --no-fund',
            },
            {
              id: 'cache',
              name: 'Cache Or Restore Node Modules',
              uses: 'actions/cache@v3',
              with: {
                path: 'node_modules',
                key: "node-modules-${{ hashFiles('package-lock.json') }}",
              },
            },
            {
              id: 'install_deps',
              name: 'Install Deps',
              run: 'npm install --no-audit --no-fund',
            },
            {
              id: 'build',
              name: 'Build',
              run: 'npm run build:prod -- --entry ./src/server.ts',
            },
            {
              uses: 'superfly/flyctl-actions/setup-flyctl@master',
            },
            {
              id: 'deploy',
              name: 'Deploy to Fly.io',
              run: 'flyctl deploy --remote-only --config ./deploy.toml --verbose',
              env: {
                FLY_API_TOKEN: `\${{ secrets.${token} }}`,
              },
            },
            sendStatusStep([
              'checkout',
              'install_lockfile',
              'cache',
              'install_deps',
              'build',
              'deploy',
            ]),
          ],
        },
      },
    },
    {
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};

const deployContent = (appToken: string, appName: string) => {
  return yaml.dump(
    {
      name: 'Deploy To fly.io',
      on: {
        push: {
          branches: ['main'],
        },
        workflow_dispatch: {},
      },
      jobs: {
        deploy_to_fly: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            // FIXME: Removing this will give us few seconds back
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only',
            },
            {
              id: 'setup_node',
              uses: 'actions/setup-node@v3',
              name: 'Setup Node.js',
              with: {
                'node-version': '20',
                cache: 'npm',
              },
            },
            {
              id: 'cache_deps',
              name: 'Cache Or Restore Node Modules.',
              uses: 'actions/cache@v3',
              with: {
                path: 'node_modules',
                key: "${{ runner.os }}-node-${{ hashFiles('package-lock.json') }}",
              },
            },
            {
              id: 'install_deps',
              name: 'Install project dependencies.',
              run: 'npm install --no-audit --no-fund',
              if: `steps.cache_deps.outputs.cache-hit != 'true'`,
            },
            // {
            //   FIXME: set this up properly using esbuild
            //   id: 'build',
            //   name: 'Build',
            //   if: false,
            //   run: 'npm run build:prod',
            // },
            {
              name: 'Setup Fly.io cli.',
              uses: 'superfly/flyctl-actions/setup-flyctl@master',
            },
            {
              id: 'deploy',
              name: 'Deploying ...',
              run: `flyctl deploy --app \${{ secrets.${appName} }} --remote-only --config ./deploy.toml --verbose`,
              env: {
                FLY_API_TOKEN: `\${{ secrets.${appToken} }}`,
              },
            },
          ],
        },
      },
    },
    {
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};

// FlyV1 fm2_lJPECAAAAAAAALalxBC7mgZaHIvz2ywCy5if77sdwrVodHRwczovL2FwaS5mbHkuaW8vdjGWAJLOAAQVkR8Lk7lodHRwczovL2FwaS5mbHkuaW8vYWFhL3YxxDxVK5KjWvSVUoI5coQCYAOrJnm0522oCdh2PrLKl0K3A9Y6N+yknofkaG51Vguh8NbOZwUxjJPtfRC8aVXETlj/9Q4rO2NcFbTzJkc+HchFQe5e0VvbYVILqOhp7j0mOn8kRt1Ic4hAsHZmL6eIH2mGRWoUmd17wkaZHvKK3xvz5gKLlGopo/kW7u/0tw2SlAORgc4ANPPyHwWRgqdidWlsZGVyH6J3Zx8BxCCOgb7J6yyNsvisxUZU4/lrw9ex0awSC7hT99EMI1/Dcw==,fm2_lJPETlj/9Q4rO2NcFbTzJkc+HchFQe5e0VvbYVILqOhp7j0mOn8kRt1Ic4hAsHZmL6eIH2mGRWoUmd17wkaZHvKK3xvz5gKLlGopo/kW7u/0t8QQ55eJa7Wwbxm71rIOPDPescO5aHR0cHM6Ly9hcGkuZmx5LmlvL2FhYS92MZYEks5mUMYuzwAAAAEiSORMCpHOAAPM0QzEEEfs+yQGonDYiPGgDuy0PD/EID9pJNy1PzkRQ1lHiqazrogdu5wNdTcvOP9WQVECPv9h
