import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class QueryLocalizedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @IsUUID()
  @AutoMap()
  queryId!: string;
}

@AutoMapHost()
export class QueryLocalized extends DomainEvent<
  QueryLocalizedData,
  'query_localized'
> {
  public override readonly eventType = 'query_localized' as const;
  constructor() {
    super();
  }

  @AutoMap(() => QueryLocalizedData)
  public override data!: QueryLocalizedData;
}
