import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { PatchWorkflowActionDetails } from '@faslh/api/table/commands';
import { Workflow } from '@faslh/api/table/domain';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(PatchWorkflowActionDetails)
export default class extends IRequestHandler<PatchWorkflowActionDetails> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: PatchWorkflowActionDetails): Promise<void> {
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    if (!workflow.hasAction(command.id)) {
      throw new Error(`Workflow does not have action: ${command.id}`);
    }

    workflow.patchActionDetails(command.id, this._eventUtils.getMetadata(), {
      featureId: workflow.featureId,
      workflowId: workflow.id,
      details: JSON.stringify(command.details ?? {}),
    });
    await this._eventStore.save(workflow);
  }
}
