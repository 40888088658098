import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddPolicyCommand } from '@faslh/api/policies/commands';
import { Policy, PolicyAddedData } from '@faslh/api/policies/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddPolicyCommand)
export class AddPolicyCommandHandler extends IRequestHandler<
  AddPolicyCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }
  async handle(command: AddPolicyCommand): Promise<string> {
    const aggregate = Policy.create(
      command.id ?? v4(),
      this._eventUtils.getMetadata(),
      mapper.map(command, AddPolicyCommand, PolicyAddedData),
    );

    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
