import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class InitialSettingsAddedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  public repositoryName!: string;
}
export class InitialSettingsAdded extends DomainEvent<
  InitialSettingsAddedData,
  'initial_settings_added'
> {
  public eventType = 'initial_settings_added' as const;

  @AutoMap(() => InitialSettingsAddedData)
  public data!: InitialSettingsAddedData;
}
