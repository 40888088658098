import { AutoMap } from '@automapper/classes';
import type { GroupQueryCondition } from '@january/compiler/transpilers';
import {
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsUUID,
  Matches,
} from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class AddQueryCommand extends IRequest {
  @AutoMap()
  @IsBoolean()
  single!: boolean;

  @AutoMap()
  @IsBoolean()
  @IsOptional()
  localizable!: boolean;

  @AutoMap(() => Object)
  condition!: GroupQueryCondition<any>;

  //

  @AutoMap()
  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  displayName!: string;

  @AutoMap()
  @IsUUID()
  featureId!: string;

  @AutoMap()
  @IsNotEmpty()
  sourceId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  details!: Record<string, any>;
}
