import { v4 } from 'uuid';

import type {
  DomainEvent,
  DomainEventMetadata,
} from '@faslh/api/infrastructure';
import { AggregateRoot } from '@faslh/api/infrastructure';

import type { SecretSetData } from '../events';
import { SecretSet } from '../events';

type Events = SecretSet;

export class Secret extends AggregateRoot<Events> {
  public name!: string;
  public displayName!: string;
  public static override readonly streamName = 'secret';
  public override readonly streamName = Secret.streamName;

  public apply(event: Events): void {
    switch (event.eventType) {
      case 'secret_set':
        this.name = event.data.name;
        this.displayName = event.data.displayName;
        break;

      default:
        throw new Error(
          `${(event as DomainEvent<any, any>).eventType} is not handled.`,
        );
    }
  }

  public static create(metadata: DomainEventMetadata, data: SecretSetData) {
    const event = new SecretSet();

    event.entityId = v4();
    event.aggregateId = event.entityId;
    event.data = data;
    event.metadata = metadata;

    const aggregate = new Secret(event.entityId);
    aggregate.applyChanges(event);

    return aggregate;
  }
}
