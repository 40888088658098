import { Context, Injectable, Injector } from 'tiny-injector';

import { AggregateRoot } from '@faslh/api/infrastructure';

import { CLAIMS_TOKEN, type Claims } from '../event_utils';
import { Projection, ProjectionV2 } from '../projection';

export interface Filter {
  tableName: string;
  where?: {
    id?: string;
    streamName?: string;
    prefix?: string;
    aggregateId?: string;
    eventType?: string;
    'metadata.causationId'?: string;
    'metadata.correlationId'?: string;
    'metadata.userId'?: string;
    'metadata.workspaceId'?: string;
    'metadata.projectId'?: string;
    'metadata.organizationId'?: string;
  };
  orderBy?: {
    timestamp: 'asc' | 'desc';
  };
  limit?: number;
}
@Injectable()
export abstract class AbstractStore {
  protected abstract context: Context;

  public abstract commit(): Promise<unknown>;

  public abstract find<T>(filter: Filter): Promise<T[]>;

  public abstract hold(path: string, operation: unknown, data: any): void;
  public abstract get(path: string): any;
  public abstract getPendingDocs(): any[];

  get basePath() {
    const _claims = Injector.GetRequiredService<Claims>(
      CLAIMS_TOKEN,
      this.context,
    );
    return [
      'organizations',
      _claims.organizationId,
      'workspaces',
      _claims.workspaceId,
      'projects',
      _claims.projectId,
    ];
  }
}

@Injectable()
export abstract class AbstractProjectionStore {
  public abstract save(projection: Projection | ProjectionV2): void;
}

@Injectable()
export abstract class AbstractEventStore {
  public abstract save<T extends AggregateRoot<any>>(
    aggregate: T,
  ): void;
}

export class WebFirestore {}
export class IndexedDBStore {}
