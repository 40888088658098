import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { MutateQueryConditionCommand } from '@faslh/api/table/commands';
import { Feature, QueryConditionMutatedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(MutateQueryConditionCommand)
export class MutateQueryConditionCommandHandler extends IRequestHandler<
  MutateQueryConditionCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: MutateQueryConditionCommand): Promise<string> {
    const feature = await this._eventStore.hyrdateAggregate(
      command.featureId,
      Feature,
    );

    const query = feature.getQuery(command.queryId);
    if (!query) {
      throw new Error(`Query not found: ${command.queryId}`);
    }

    query.mutateQueryCondition(
      mapper.map(
        command,
        MutateQueryConditionCommand,
        QueryConditionMutatedData,
      ),
      this._eventUtils.getMetadata(),
    );

    await this._eventStore.save(feature);
    return feature.id;
  }
}
