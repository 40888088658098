import { buildProjection } from '@faslh/api/infrastructure/database';
import type {
  ApiEndpointSet,
  InitialSettingsAdded,
  RepositorySet,
} from '@faslh/api/settings/domain';

import type { SettingsProjection } from './settings.projection';

type Events = InitialSettingsAdded | ApiEndpointSet | RepositorySet;

function reducer(state: SettingsProjection, event: Events): SettingsProjection {
  switch (event.eventType) {
    case 'initial_settings_added':
      state.createdAt = event.timestamp;
      state.updatedAt = event.timestamp;
      state.repositoryName = event.data.repositoryName;
      return state;
    case 'api_endpoint_set':
      state.updatedAt = event.timestamp;
      state.apiEndpoint = event.data.apiEndpoint;
      return state;
    case 'repository_set':
      state.updatedAt = event.timestamp;
      state.repositoryName = event.data.repositoryName;
      return state;
    default:
      return state;
  }
}

export const buildSettingsProjection = (
  initialProjection: SettingsProjection,
  events: Events[],
) => buildProjection(initialProjection, events, reducer);
