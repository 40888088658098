import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class FieldDelocalizedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @IsUUID()
  @AutoMap()
  fieldId!: string;
}

export class FieldDelocalized extends DomainEvent<
  FieldDelocalizedData,
  'field_delocalized'
> {
  public override readonly eventType = 'field_delocalized' as const;
  constructor() {
    super();
  }

  @AutoMap(() => FieldDelocalizedData)
  public override data!: FieldDelocalizedData;
}
