import { createStream } from 'rotating-file-stream';
import { Logger } from 'tslog';

// const stream = createStream('tslog.log', {
//   size: '1M', // rotate every 10 MegaBytes written
//   interval: '1d', // rotate daily
//   compress: 'gzip', // compress rotated files
// });

const logger = new Logger({ type: 'pretty' });
logger.attachTransport((logObject) => {
  if (process.env['NODE_ENV'] === 'development') {
    // stream.write(JSON.stringify(logObject, null, 4) + '\n');
  }
});

export { logger };
