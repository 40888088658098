import { Injector } from 'tiny-injector';

import {
  EventStore,
  EventUtils,
  onRequest,
} from '@faslh/api/infrastructure/database';
import {
  CompleteCommitCommand,
  PushCommitCommand,
} from '@faslh/api/releases/commands';
import { Commit } from '@faslh/api/releases/models';

onRequest({
  requestType: PushCommitCommand,
  handler: async (command, context) => {
    const eventUtils = Injector.GetRequiredService(EventUtils, context);
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const aggregate = new Commit(command.commitId);
    aggregate.pushCommit(eventUtils.getMetadata(), {
      commitSha: command.commitSha,
    });
    await eventStore.save(aggregate);
  },
});
