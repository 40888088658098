import type { ProjectionV2 } from '@faslh/api/infrastructure/database';
import { createProjection } from '@faslh/api/infrastructure/database';

export interface SettingsProjection extends ProjectionV2 {
  repositoryName?: string;
  apiEndpoint?: string;
}

export const settingsProjection = (
  state?: SettingsProjection,
): SettingsProjection =>
  createProjection({
    ...(state ?? {
      stage: [],
      repositoryName: '',
      apiEndpoint: '',
    }),
  });
