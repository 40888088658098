import type {
  DomainEvent,
  DomainEventMetadata,
} from '@faslh/api/infrastructure';
import { AggregateRoot } from '@faslh/api/infrastructure';

import type { RepositorySetData } from '../events';
import { RepositorySet } from '../events';
import type { ApiEndpointSetData } from '../events/api_endpoint_set.event';
import { ApiEndpointSet } from '../events/api_endpoint_set.event';
import type { InitialSettingsAddedData } from '../events/initial_settings_added.event';
import { InitialSettingsAdded } from '../events/initial_settings_added.event';

type Events = InitialSettingsAdded | ApiEndpointSet | RepositorySet;

export class Settings extends AggregateRoot<Events> {
  public repositoryName!: string;
  public apiEndpoint?: string;
  public static override readonly streamName = 'settings';
  public override readonly streamName = Settings.streamName;

  public apply(event: Events): void {
    switch (event.eventType) {
      case 'initial_settings_added':
        this.repositoryName = event.data.repositoryName;
        break;
      case 'api_endpoint_set':
        this.apiEndpoint = event.data.apiEndpoint;
        break;
      case 'repository_set':
        this.repositoryName = event.data.repositoryName;
        break;
      default:
        throw new Error(
          `${(event as DomainEvent<any, any>).eventType} is not handled.`,
        );
    }
  }

  constructor() {
    super('settings');
  }

  public static create(
    metadata: DomainEventMetadata,
    data: InitialSettingsAddedData,
  ) {
    const event = new InitialSettingsAdded();

    event.aggregateId = 'settings';
    event.entityId = 'settings';
    event.data = data;
    event.metadata = metadata;

    const aggregate = new Settings();
    aggregate.applyChanges(event);

    return aggregate;
  }

  public setApiEndpoint(
    metadata: DomainEventMetadata,
    data: ApiEndpointSetData,
  ) {
    const event = new ApiEndpointSet();

    event.aggregateId = this.id;
    event.entityId = this.id;
    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
  }

  public setRepositoryName(
    metadata: DomainEventMetadata,
    data: RepositorySetData,
  ) {
    const event = new RepositorySet();

    event.aggregateId = this.id;
    event.entityId = this.id;
    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
  }
}
