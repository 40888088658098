import { AutoMap } from '@automapper/classes';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class InitialCommitData extends DomainEventData {
  @AutoMap()
  public message = 'Initial Commit.';
}

@AutoMapHost()
export class InitialCommit extends DomainEvent<
  InitialCommitData,
  'initial_commit'
> {
  public eventType = 'initial_commit' as const;
  @AutoMap(() => InitialCommitData)
  public data!: InitialCommitData;
}
