import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { SetApiEndpointCommand } from '@faslh/api/settings/commands';
import { ApiEndpointSetData, Settings } from '@faslh/api/settings/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(SetApiEndpointCommand)
export class SetApiEndpointCommandHandler extends IRequestHandler<SetApiEndpointCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: SetApiEndpointCommand): Promise<void> {
    const aggregate = await this._eventStore.hyrdateAggregate(
      'settings',
      Settings,
    );

    aggregate.setApiEndpoint(
      this._eventUtils.getMetadata(),
      mapper.map(command, SetApiEndpointCommand, ApiEndpointSetData),
    );

    await this._eventStore.save(aggregate);
  }
}
