import { join } from 'path';
import { Inject, Injectable } from 'tiny-injector';

import { type AggregateRoot } from '@faslh/api/infrastructure';
import { Mediator } from '@faslh/tiny-mediatr';

import { EventUtils } from '../../event_utils';
import { AbstractEventStore, AbstractStore } from '../abstract.store';
import { SQLiteStore } from './sqlite.store';
import { SQLITE_TOKEN, type SqliteOperations } from './sqlite.token';

@Injectable()
export class SqliteEventStore extends AbstractEventStore {
  constructor(
    @Inject(SQLITE_TOKEN) public db: SqliteOperations,
    private _mediator: Mediator,
    @Inject(AbstractStore) private _store: SQLiteStore,
    private _eventUtils: EventUtils,
  ) {
    super();
  }

  public override async save<T extends AggregateRoot<any>>(aggregate: T) {
    for (const event of aggregate.getUncommittedChanges()) {
      event.streamName = aggregate.streamName;
      this._store.hold(
        join(...this._store.basePath, 'events', event.id),
        (client) => {
          const it = event.toJson();
          return this._store.db.prepare(
            {
              sql: `
            INSERT INTO Events (id, eventType, aggregateVersion, aggregateId, entityId, metadata, data, streamName)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?)
            `,
              params: [
                it.id,
                it.eventType,
                /**it.aggregateVersion,**/ 0,
                it.aggregateId,
                it.entityId,
                JSON.stringify(it.metadata),
                JSON.stringify(it.data),
                it.streamName,
              ],
              txn: true,
            },
            client,
          );
        },
        event,
      );
      this._eventUtils.recordEvent(event);
      // FIXME: we need to make this asynchronous
      await this._mediator.publish(event);
      this._eventUtils.dequeue();
      // console.log('LOG EVENT: ', event.constructor);
    }
    aggregate.markChangesAsCommitted();
  }
}
