import { Injectable, ServiceLifetime } from 'tiny-injector';

import {
  ActionProperty,
  ConcreteContracts,
  Contracts,
  HandleActionInput,
  HandleActionOutput,
  HandleFieldInput,
  IExtension,
  IHandleAction,
  IncomingActionProperty,
  IncomingActionPropertyDict,
  ProcessActionHelpers,
} from '@faslh/compiler/contracts';
import { DevKit } from '@faslh/compiler/sdk/devkit';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class AjaxExtension implements IExtension, IHandleAction {
  constructor(private _devKit: DevKit) {}

  public async processAction(
    action: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    inputs: Record<string, any>,
    transfer: Record<string, any>,
  ): Promise<ConcreteContracts.ProcessActionOutput> {
    return {
      inline: false,
      structure: {
        topLevelStructure: [],
        actionStructure: [
          (writer) =>
            writer.writeLine(
              `const ${action.output.displayName} = await fetch("${
                inputs['url'].value
              }", {
              method: "${inputs['method'].value}",
              body: JSON.stringify(${this._devKit.toLiteralObject(
                transfer['body'],
              )})
            }).then((res) => res.json())`,
            ),
        ],
      },
    };
  }

  public handleField(
    input: HandleFieldInput,
  ): Promise<Contracts.InputFieldUnion> {
    throw new Error('Method not implemented.');
  }

  public async handleAction(
    contract: HandleActionInput,
  ): Promise<HandleActionOutput> {
    const { body, ...rest } = contract.details;
    return {
      runtimeInputs: {
        ...Object.entries(rest)
          .map(([name, input]) => ({ [name]: { input } }))
          .reduce((acc, it) => ({ ...acc, ...it }), {}),
        ...flatJson(JSON.parse(body ?? '{}')),
      },
      transfer: { body: JSON.parse(body ?? '{}') },
    };
  }

  public async handleSetup(
    contract: Record<string, ActionProperty>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [];
  }
}

function flatJson(
  json: IncomingActionPropertyDict,
): Record<string, IncomingActionProperty> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.entries(json).reduce<Record<string, IncomingActionProperty>>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (acc, [name, prop]) => {
      if (typeof prop === 'object' && 'input' in prop) {
        return {
          ...acc,
          [name]: prop,
        };
      }
      return {
        ...acc,
        ...flatJson(prop),
      };
    },
    {},
  );
}
