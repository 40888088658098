import dedent from 'dedent';
import { Injectable, ServiceLifetime } from 'tiny-injector';
import { StructureKind } from 'ts-morph';

import {
  ConcreteContracts,
  Contracts,
  HandleActionInput,
  HandleActionOutput,
  HandleFieldInput,
  IExtension,
  IHandleAction,
  ProcessActionHelpers,
  generateValidationContract,
  useInput,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { notNullOrUndefined } from '@faslh/utils';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ResendExtension implements IExtension, IHandleAction {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devkit: DevKit,
  ) {}

  async handleSetup(
    contract: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeCorePath('resend.ts'),
        content: [
          `
          import { Resend } from 'resend';
		      export default new Resend(${contract['RESEND_API_KEY'].value});
    		`,
        ],
      },
    ];
  }

  async processAction(
    action: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    inputs: Record<string, any>,
  ): Promise<ConcreteContracts.ProcessActionOutput> {
    switch (action.sourceAction.name) {
      case 'resend-send-email':
        return {
          inline: true,
          structure: {
            topLevelStructure: [
              {
                kind: StructureKind.ImportDeclaration,
                moduleSpecifier:
                  this._projectFS.makeCoreImportSpecifier('core/resend'),
                defaultImport: 'resend',
              },
            ],
            actionStructure: [
              (writer) =>
                writer.writeLine(dedent`
            ${action.output.displayName ? `const ${action.output.displayName} = ` : ''} await resend.emails.send({${toProps(inputs)}});
            `),
            ],
          },
        };
      case 'resend-create-contact':
        return {
          inline: true,
          structure: {
            topLevelStructure: [
              {
                kind: StructureKind.ImportDeclaration,
                defaultImport: 'resend',
                moduleSpecifier:
                  this._projectFS.makeCoreImportSpecifier('core/resend'),
              },
            ],
            actionStructure: [
              (writer) =>
                writer.writeLine(dedent`
              ${action.output.displayName ? `const ${action.output.displayName} = ` : ''} await resend.contacts.create({${toProps(inputs)}});
              `),
            ],
          },
        };
    }
    throw new Error(`Action ${action.name} not supported`);
  }

  async handleAction(
    contract: HandleActionInput<Record<string, any>>,
  ): Promise<HandleActionOutput> {
    for (const [key] of Object.entries(contract.details)) {
      const { validations = [] } = contract.sourceAction.metadata[key];
      for (const validation of validations) {
        const sourceId = (
          await this._devkit.getValidationByName(validation.name)
        ).id;
        validation.sourceId = sourceId;
      }

      contract.details[key] = {
        ...contract.details[key],
        validations: await generateValidationContract(validations),
      };
    }

    return {
      runtimeInputs: contract.details,
      transfer: {},
    };
  }

  async handleField(
    input: HandleFieldInput,
  ): Promise<Contracts.InputFieldUnion> {
    throw new Error('Method not implemented.');
  }
}

function toProps(inputs: Record<string, any>): string {
  return Object.entries(inputs)
    .filter(([, value]) => notNullOrUndefined(value))
    .map(([key, value]) => `${key}: ${useInput(value)}`)
    .join(', ');
}
