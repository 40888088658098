import { type Client } from '@libsql/client';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';

import {
  AbstractKeyValueStore,
  KEY_VALUE_TOKEN,
} from './abstract-keyvalue.store';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class SqliteKeyValueStore extends AbstractKeyValueStore {
  constructor(@Inject(KEY_VALUE_TOKEN) private client: Client) {
    super();
  }

  async set<T = unknown>(key: string, value: T): Promise<void> {
    await this.client.execute({
      sql: `
      INSERT INTO keyvalue (key, value) VALUES (?, ?)
      ON CONFLICT(key) DO UPDATE SET value = json_patch(keyvalue.value, excluded.value);
      `,
      args: [key, JSON.stringify(value)],
    });
  }

  async get<T>(key: string, defaultVal: any): Promise<T> {
    const result = await this.client.execute({
      sql: 'SELECT value FROM keyvalue WHERE key = ?;',
      args: [key],
    });
    const value = result.rows[0];
    return value?.['value']
      ? (JSON.parse(value['value'] as string) as T)
      : defaultVal;
  }

  async delete(key: string): Promise<void> {
    await this.client.execute({
      sql: 'DELETE FROM keyvalue WHERE key = ?;',
      args: [key],
    });
  }
}
