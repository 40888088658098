import type { Request } from 'express';
import { getClientIp } from 'request-ip';
import {
  Context,
  Inject,
  Injectable,
  InjectionToken,
  ServiceLifetime,
} from 'tiny-injector';

import { DomainEvent, DomainEventMetadata } from '@faslh/api/infrastructure';

export interface Claims {
  organizationId: string;
  workspaceId: string;
  projectId: string;
  uid: string;
  provider: 'anonymous' | 'google.com' | 'github.com';
  accessToken?: string; // this workaround. the access token should be passed in the cookie with httpOnly flag
}

export const JWT_TOKEN = new InjectionToken<string>('Token for the JWT');
export const CLAIMS_TOKEN = new InjectionToken<Claims>('Claims Token');
export const CORRELATION_ID_TOKEN = new InjectionToken<string>(
  'Token for the correlation id',
);

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class EventUtils {
  #events: DomainEvent<any, any, any>[] = [];
  constructor(
    private _context: Context,
    @Inject(CLAIMS_TOKEN) private _claims: Claims,
    @Inject(CORRELATION_ID_TOKEN) private correlationId: string,
  ) {}

  /**
   * Record the event to the context
   *
   * @param event assign an issuer event
   */
  recordEvent(event: DomainEvent<any, any, DomainEventMetadata>) {
    this.#events.push(event);
  }

  /**
   * Make the previous event as the issuer event
   */
  dequeue() {
    this.#events.pop();
  }

  /**
   * Get the issuer event
   *
   * @returns the issuer event
   */
  getIssuerEvent() {
    return this.#events[this.#events.length - 1]?.id;
  }

  /**
   * Get the metadata for a new event.
   *
   * @returns the metadata of the event
   */
  getMetadata(metadata?: { projectId?: string }): DomainEventMetadata {
    const req = this._context.getExtra<Request>('request');
    const userAgent = req ? (req.headers['user-agent'] as string) : ''; // FIXME: should be InjectionToken
    const userIp = req ? (getClientIp(req) as string) : ''; // FIXME: should be InjectionToken
    return {
      platformVersion: '',
      userAgent: userAgent,
      userIp: userIp,
      userId: this._claims.uid,
      causationId: this.getIssuerEvent() || '',
      correlationId: this.correlationId,
      organizationId: this._claims.organizationId,
      workspaceId: this._claims.workspaceId,
      projectId: metadata?.projectId || this._claims.projectId,
    };
  }
}
