import { v4 } from 'uuid';

import { AggregateRoot, DomainEventMetadata } from '@faslh/api/infrastructure';

import {
  ChangesCommitted,
  ChangesCommittedData,
  CommitCompleted,
  CommitCompletedData,
  CommitFailed,
  CommitFailedData,
  CommitPipelineStatusSet,
  CommitPipelineStatusSetData,
  CommitPushed,
  CommitPushedData,
  CommitRebuilt,
  CommitRebuiltData,
  InitialCommit,
  InitialCommitData,
} from '../events';

type Events =
  | CommitPipelineStatusSet
  | InitialCommit
  | ChangesCommitted
  | CommitCompleted
  | CommitRebuilt
  | CommitFailed
  | CommitPushed;

export class Commit extends AggregateRoot<Events> {
  public message!: string;
  public static override readonly streamName = 'commit';
  public override readonly streamName = Commit.streamName;

  public apply(event: Events): void {
    switch (event.eventType) {
      case 'changes_committed':
      case 'initial_commit':
        this.message = event.data.message;
        break;

      default:
        break;
    }
  }

  public static initialCommit(metadata: DomainEventMetadata) {
    const event = new InitialCommit();

    event.aggregateId = v4();
    event.entityId = event.aggregateId;

    event.data = new InitialCommitData();
    event.metadata = metadata;

    const aggregate = new Commit(event.aggregateId);
    aggregate.applyChanges(event);

    return aggregate;
  }

  public static create(
    metadata: DomainEventMetadata,
    data: ChangesCommittedData,
  ) {
    const event = new ChangesCommitted();

    event.aggregateId = v4();
    event.entityId = event.aggregateId;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Commit(event.aggregateId);
    aggregate.applyChanges(event);

    return aggregate;
  }

  public failCommit(metadata: DomainEventMetadata) {
    const event = new CommitFailed();
    event.aggregateId = this.id;
    event.entityId = this.id;

    event.data = new CommitFailedData();
    event.metadata = metadata;

    this.applyChanges(event);
  }

  public pushCommit(metadata: DomainEventMetadata, data: CommitPushedData) {
    const event = new CommitPushed();
    event.aggregateId = this.id;
    event.entityId = this.id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
  }

  public completeCommit(
    metadata: DomainEventMetadata,
    data: CommitCompletedData,
  ) {
    const event = new CommitCompleted();
    event.aggregateId = this.id;
    event.entityId = this.id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
  }

  public rebuildCommand(metadata: DomainEventMetadata) {
    const event = new CommitRebuilt();
    event.aggregateId = this.id;
    event.entityId = this.id;

    event.data = new CommitRebuiltData();
    event.metadata = metadata;

    this.applyChanges(event);
  }

  public setStatus(
    metadata: DomainEventMetadata,
    data: CommitPipelineStatusSetData,
  ) {
    const event = new CommitPipelineStatusSet();
    event.aggregateId = this.id;
    event.entityId = this.id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);
  }
}
