import { Injector } from 'tiny-injector';

import {
  CLAIMS_TOKEN,
  EventStore,
  EventUtils,
  onRequest,
} from '@faslh/api/infrastructure/database';
import { CompleteCommitCommand } from '@faslh/api/releases/commands';
import { Commit, CommitPushed } from '@faslh/api/releases/models';

onRequest({
  requestType: CompleteCommitCommand,
  handler: async (command, context) => {
    const claims = Injector.GetRequiredService(CLAIMS_TOKEN, context);
    const eventUtils = Injector.GetRequiredService(EventUtils, context);
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const events = await eventStore.getEventsOfEventType(CommitPushed, Commit, {
      'metadata.projectId': claims.projectId,
    });
    const relatedPushEvent = events.find(
      (event) => event.data.commitSha === command.commitSha,
    );
    if (!relatedPushEvent) {
      throw new Error(`Commit with sha ${command.commitSha} not found`);
    }
    const aggregate = new Commit(relatedPushEvent.aggregateId);

    aggregate.completeCommit(eventUtils.getMetadata(), {
      conclusion: command.conclusion,
    });
    await eventStore.save(aggregate);
  },
});
