import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class AddPrimaryFieldCommand extends IRequest {
  @AutoMap()
  @IsNotEmpty()
  displayName!: string;

  @AutoMap()
  @IsUUID()
  tableId!: string;

  @AutoMap()
  @IsUUID()
  sourceId!: string;

  @AutoMap(() => Object)
  details?: any = {};
}
