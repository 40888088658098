import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class PatchWorkflowDetails extends IRequest {
  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  details!: Record<string, any>;
}
