import { AdjustFieldValidationCommand } from '@faslh/api/table/commands';
import { FieldAdded } from '@faslh/api/table/domain';
import { AdminExtensionNS, DevKit } from '@faslh/compiler/sdk/devkit';
import { FieldValidation, mapper } from '@faslh/isomorphic';
import {
  INotificationHandler,
  Mediator,
  NotificationHandler,
} from '@faslh/tiny-mediatr';
import { applyCondition } from '@faslh/utils';

@NotificationHandler(FieldAdded)
export default class extends INotificationHandler<FieldAdded> {
  constructor(
    private readonly _mediator: Mediator,
    private readonly _devKit: DevKit,
  ) {
    super();
  }

  override async handle(event: FieldAdded): Promise<void> {
    const sourceField = await this._devKit.getSourceFieldById(
      event.data.sourceId,
    );

    // FIXME: move this to PostgerSQL extension and listen to field_added event
    await this._validationCommand(
      event.data.tableId,
      event.entityId,
      event.data.details,
      sourceField,
    );
  }

  private async _validationCommand(
    tableId: string,
    fieldId: string,
    fieldDetails: Record<string, any>,
    sourceField: AdminExtensionNS.ExtensionField,
  ) {
    const validations: FieldValidation[] = [];
    for (const item of sourceField.initialValidation) {
      const sourceValidation = await this._devKit.getValidationByName(
        item.name,
      );

      const condition = item.if;
      if (condition) {
        const pass = applyCondition(condition, {
          context: fieldDetails, // inital validation is always run on the field it self
          self: fieldDetails,
        });
        if (!pass) {
          continue;
        }
      }

      const details = Object.keys(item.details).reduce<Record<string, any>>(
        (acc, key) => {
          const value = item.details[key];
          if (typeof value === 'string' && value.startsWith('context.')) {
            const fieldMetadataProperty = value.replace('context.', '');
            return {
              ...acc,
              [key]: fieldDetails[fieldMetadataProperty],
            };
          }
          return {
            ...acc,
            [key]: value,
          };
        },
        {},
      );

      validations.push({
        details: details,
        sourceId: sourceValidation.id,
        name: sourceValidation.name,
      });
    }

    await this._mediator.send(
      mapper.map(
        {
          tableId: tableId,
          fieldId: fieldId,
          validations: validations,
        },
        AdjustFieldValidationCommand,
      ),
    );
  }
}
