import { v4 } from 'uuid';



import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { DeleteFeatureCommand } from '@faslh/api/table/commands';
import { Feature, FeatureAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';


@RequestHandler(DeleteFeatureCommand)
export default class extends IRequestHandler<DeleteFeatureCommand, void> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: DeleteFeatureCommand): Promise<void> {
    const features = await this._eventStore.listAggregates(Feature);
    const feature = features
      .filter((it) => it.deleted !== true)
      .find((f) => f.displayName === command.displayName);
    if (!feature) {
      if (!command.failSafe) {
        throw new Error(
          `Feature with name ${command.displayName} does not exist`,
        );
      }
      return;
    }
    feature.delete(
      this._eventUtils.getMetadata(),
      mapper.map(command, DeleteFeatureCommand),
    );
    await this._eventStore.save(feature);
  }
}