import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { CommitChangesCommand } from '@faslh/api/releases/commands';
import { ChangesCommittedData, Commit } from '@faslh/api/releases/models';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(CommitChangesCommand)
export class CommitChangesCommandHandler extends IRequestHandler<
  CommitChangesCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils
  ) {
    super();
  }

  async handle(command: CommitChangesCommand): Promise<string> {
    const data = new ChangesCommittedData();
    data.message = command.message;
    const aggregate = Commit.create(this._eventUtils.getMetadata(), data);
    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
