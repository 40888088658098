import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { SetCommitPipelineStatusCommand } from '@faslh/api/releases/commands';
import {
  Commit,
  CommitPipelineStatusSetData,
} from '@faslh/api/releases/models';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(SetCommitPipelineStatusCommand)
export default class extends IRequestHandler<
  SetCommitPipelineStatusCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: SetCommitPipelineStatusCommand): Promise<string> {
    const commit = await this._eventStore.hyrdateAggregate(
      command.commitId,
      Commit,
    );

    commit.setStatus(
      this._eventUtils.getMetadata(),
      mapper.map(
        {
          stage: command.stage,
        },
        CommitPipelineStatusSetData,
        CommitPipelineStatusSetData,
      ),
    );

    await this._eventStore.save(commit);
    return commit.id;
  }
}
