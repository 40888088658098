import { ProjectionStore } from '@faslh/api/infrastructure/database';
import {
  ApiEndpointSet,
  InitialSettingsAdded,
  RepositorySet,
} from '@faslh/api/settings/domain';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

import { SettingsProjection, settingsProjection } from '../settings.projection';
import { buildSettingsProjection } from '../settings.projector';

@NotificationHandler(InitialSettingsAdded)
@NotificationHandler(ApiEndpointSet)
@NotificationHandler(RepositorySet)
export class InitialSettingsAddedHandler extends INotificationHandler<InitialSettingsAdded> {
  constructor(private readonly _projectionStore: ProjectionStore) {
    super();
  }
  async handle(event: InitialSettingsAdded): Promise<void> {
    const projection =
      (await this._projectionStore.getSingeltonProjection<SettingsProjection>(
        'settings',
      )) ?? settingsProjection();
    const updatedProjection = buildSettingsProjection(projection, [event]);
    this._projectionStore.storeDocument('settings', updatedProjection);
  }
}
