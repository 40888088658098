import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { RemoveWorkflowCommand } from '@faslh/api/table/commands';
import { Workflow, WorkflowRemovedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(RemoveWorkflowCommand)
export class RemoveWorkflowHandler extends IRequestHandler<
  RemoveWorkflowCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: RemoveWorkflowCommand): Promise<string> {
    const aggregate = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    aggregate.remove(
      this._eventUtils.getMetadata(),
      mapper.map(command, RemoveWorkflowCommand, WorkflowRemovedData),
    );

    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
