import { Injectable, ServiceLifetime } from 'tiny-injector';

import {
  EventStore,
  ProjectionStore,
  Projector,
} from '@faslh/api/infrastructure/database';
import { PolicyAdded } from '@faslh/api/policies/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';

import { PolicyProjection } from './policy.projection';

type Events = PolicyAdded;

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class PolicyProjector extends Projector<PolicyProjection, Events> {
  protected override projectionName = 'policies';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    protected _devKit: DevKit,
  ) {
    super();
  }
  protected override async apply(
    state: PolicyProjection,
    event: Events,
  ): Promise<PolicyProjection> {
    switch (event.eventType) {
      case 'policy_added':
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.rule = event.data.rule;
        state.displayName = event.data.displayName;
        return state;
      default:
        return state;
    }
  }
}
