import { AutoMap } from '@automapper/classes';
import { IsNotEmpty } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class ApiEndpointSetData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  public apiEndpoint!: string;
}
export class ApiEndpointSet extends DomainEvent<
  ApiEndpointSetData,
  'api_endpoint_set'
> {
  public eventType = 'api_endpoint_set' as const;

  @AutoMap(() => ApiEndpointSetData)
  public data!: ApiEndpointSetData;
}
