import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost, WithProjectId } from '@faslh/isomorphic';

@AutoMapHost()
export class InstallExtensionCommand extends WithProjectId {
  @IsUUID()
  @AutoMap()
  public extensionId!: string;

  @AutoMap(() => Object)
  public details!: Record<string, any>;
}
