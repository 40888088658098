import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { ConnectAction } from '@faslh/api/table/commands';
import { Workflow } from '@faslh/api/table/domain';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(ConnectAction)
export class ConnectActionHandler extends IRequestHandler<
  ConnectAction,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: ConnectAction): Promise<string> {
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    // FIXME: enable it when treating trigger as an action
    if (!workflow.hasAction(command.id)) {
      throw new Error(`Action ${command.id} not found`);
    }

   

    const id = workflow.connectAction(this._eventUtils.getMetadata(), {
      id: command.id,
      workflowId: command.workflowId,
      featureId: command.featureId,
    });
    await this._eventStore.save(workflow);

    return id;
  }
}
