import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class TableNameChangedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  public tableId!: string;

  @AutoMap()
  @Matches(/^[A-Za-z_ ]+$/)
  @IsNotEmpty()
  public tableName!: string;
}

export class TableNameChanged extends DomainEvent<
  TableNameChangedData,
  'table_name_changed'
> {
  public override readonly eventType = 'table_name_changed' as const;

  @AutoMap(() => TableNameChangedData)
  public override data!: TableNameChangedData;
}
