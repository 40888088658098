import { Context, Injector } from 'tiny-injector';
import { v4 } from 'uuid';

import '@faslh/api/policies/application';
import '@faslh/api/releases/application';
import '@faslh/api/settings/application';
import '@faslh/api/table/application';
import '@faslh/api/user-extensions/application';

import '@faslh/api/policies/projection';
import '@faslh/api/releases/projection';
import '@faslh/api/settings/projection';
import '@faslh/api/table/projection';

import { ENVIRONMENT } from '@faslh/api/infrastructure';
import type { Claims } from '@faslh/api/infrastructure/database';
import {
  AbstractEventStore,
  AbstractKeyValueStore,
  AbstractProjectionStore,
  AbstractStore,
  CLAIMS_TOKEN,
  CORRELATION_ID_TOKEN,
  EventMediator,
  SQLiteStore,
  SqliteEventStore,
  SqliteKeyValueStore,
  SqliteProjectionStore,
} from '@faslh/api/infrastructure/database';
import { DYNAMIC_SOURCE_MAPPER } from '@faslh/compiler/sdk/dynamic-source';
import { Sdk } from '@faslh/compiler/sdk/platform';
import { Mediator } from '@faslh/tiny-mediatr';

Error.stackTraceLimit = Infinity;

Injector.AddScoped(Mediator, EventMediator);
Injector.AddScoped(Context, (context) => context);

Injector.AddScoped(AbstractStore, SQLiteStore);
Injector.AddScoped(AbstractProjectionStore, SqliteProjectionStore);
Injector.AddScoped(AbstractEventStore, SqliteEventStore);
// Injector.AddScoped(AbstractKeyValueStore, SqliteKeyValueStore);

Injector.AddScoped(ENVIRONMENT, (context) => ({
  production: process.env['NODE_ENV'] === 'production',
  version: '0.0.0',
}));

Injector.AddScoped(CLAIMS_TOKEN, (context) =>
  context.getExtra<Claims>('claims'),
);

Injector.AddScoped(CORRELATION_ID_TOKEN, (context) => v4());

Injector.AddScoped(DYNAMIC_SOURCE_MAPPER, (context) => (featureId: string) => {
  const sdk = Injector.GetRequiredService(Sdk, context);
  return {
    '/tables/:id': (id: string) => sdk.tables.get(id),
    '/tables/:id/fields': (id: string) =>
      sdk.tables.get(id).then((it) => (it ? it.fields : [])),
  };
});
