import { Injector } from 'tiny-injector';

import { EventStore, onEvent } from '@faslh/api/infrastructure/database';
import { PolicyAdded } from '@faslh/api/policies/domain';
import {
  ActionConnected,
  ActionNameChanged,
  FeatureAdded,
  FeatureDeleted,
  FieldAdded,
  FieldDetailsUpdated,
  FieldValidationAdjusted,
  PolicyAssignedToWorkflow,
  QueryAdded,
  QueryConditionMutated,
  QueryDelocalized,
  QueryLocalized,
  Table,
  TableAdded,
  TableIndexCreated,
  TagAdded,
  TagAssignedToWorkflow,
  Workflow,
  WorkflowActionAdded,
  WorkflowActionDetailsPatched,
  WorkflowAdded,
  WorkflowDetailsPatched,
  WorkflowNameChanged,
  WorkflowOutputDetailsPatched,
  WorkflowRemoved,
  WorkflowTriggerChanged,
} from '@faslh/api/table/domain';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

import { featureProjection } from '../feature.projection';
import { FeatureProjector } from '../feature.projector';
import {
  WorkflowOutputProjector,
  workflowOutputProjection,
} from '../workflow-output.projector';

type Events =
  | FeatureAdded
  | TagAdded
  | PolicyAdded
  | QueryAdded
  | QueryLocalized
  | QueryDelocalized
  | QueryConditionMutated;

@NotificationHandler(QueryConditionMutated)
@NotificationHandler(QueryLocalized)
@NotificationHandler(QueryDelocalized)
@NotificationHandler(QueryAdded)
@NotificationHandler(TagAdded)
@NotificationHandler(FeatureAdded)
@NotificationHandler(FeatureDeleted)
export default class extends INotificationHandler<Events> {
  constructor(private readonly _featureProjector: FeatureProjector) {
    super();
  }

  async handle(event: Events): Promise<void> {
    const projection = await this._featureProjector.getProjector(
      event.aggregateId,
      {
        defaultState: featureProjection(),
        events: [event],
      },
    );
    this._featureProjector.saveProjection(event.aggregateId, projection);
  }
}

type WorkflowFeatureEvents =
  | WorkflowNameChanged
  | ActionNameChanged
  | WorkflowActionAdded
  | WorkflowActionDetailsPatched
  | WorkflowDetailsPatched
  | WorkflowTriggerChanged
  | WorkflowOutputDetailsPatched
  | WorkflowRemoved
  | TagAssignedToWorkflow
  | PolicyAssignedToWorkflow
  | ActionConnected;

@NotificationHandler(WorkflowRemoved)
@NotificationHandler(WorkflowNameChanged)
@NotificationHandler(ActionNameChanged)
@NotificationHandler(WorkflowActionAdded)
@NotificationHandler(WorkflowActionDetailsPatched)
@NotificationHandler(WorkflowDetailsPatched)
@NotificationHandler(WorkflowTriggerChanged)
@NotificationHandler(TagAssignedToWorkflow)
@NotificationHandler(PolicyAssignedToWorkflow)
@NotificationHandler(WorkflowOutputDetailsPatched)
@NotificationHandler(ActionConnected)
class WorkflowFeature extends INotificationHandler<WorkflowFeatureEvents> {
  constructor(
    private readonly _featureProjector: FeatureProjector,
    private readonly _eventStore: EventStore,
  ) {
    super();
  }

  async handle(event: WorkflowFeatureEvents): Promise<void> {
    const workflow = await this._eventStore.hyrdateAggregate(
      event.data.workflowId,
      Workflow,
    );

    const projection = await this._featureProjector.getProjector(
      workflow.featureId,
      {
        defaultState: featureProjection(),
        events: [event],
      },
    );

    this._featureProjector.saveProjection(workflow.featureId, projection);
  }
}

@NotificationHandler(TableAdded)
@NotificationHandler(WorkflowAdded)
class RelatedToFeature extends INotificationHandler<
  TableAdded | WorkflowAdded
> {
  constructor(private readonly _featureProjector: FeatureProjector) {
    super();
  }

  async handle(event: TableAdded | WorkflowAdded): Promise<void> {
    const projection = await this._featureProjector.getProjector(
      event.data.featureId,
      {
        defaultState: featureProjection(),
        events: [event],
      },
    );
    this._featureProjector.saveProjection(event.data.featureId, projection);
  }
}

// @NotificationHandler(WorkflowActionAdded)
// @NotificationHandler(WorkflowActionDetailsPatched)
// @NotificationHandler(FieldAdded) // FIXME: we need it but it won't work unless we replay all the events from last replayed event. this way this NotificationHandler will be used as notifier more than event applier
class WorkflowOutputListener extends INotificationHandler<
  WorkflowActionAdded | WorkflowActionDetailsPatched
> {
  constructor(
    private readonly _workflowOutputProjector: WorkflowOutputProjector,
  ) {
    super();
  }
  public override async handle(
    event: WorkflowActionAdded | WorkflowActionDetailsPatched,
  ): Promise<void> {
    const projection = await this._workflowOutputProjector.getProjector(
      event.data.featureId,
      {
        defaultState: workflowOutputProjection(),
        events: [event],
      },
    );
    // saving the projection is not benficial because we need to replay related events
    // for it to work. if a new field added the actions won't know about it.
    // unless we replay all the events we won't get the correct state of the projection
    // SOLUTION: instead of replaying all events, we can replay starting from the last replayed event
    this._workflowOutputProjector.saveProjection(
      event.data.featureId,
      projection,
    );
  }
}
@NotificationHandler(WorkflowAdded)
class WorkflowOutputListener2 extends INotificationHandler<WorkflowAdded> {
  constructor(
    private readonly _workflowOutputProjector: WorkflowOutputProjector,
  ) {
    super();
  }

  public override async handle(event: WorkflowAdded): Promise<void> {
    const projection = await this._workflowOutputProjector.getProjector(
      event.data.featureId,
      {
        defaultState: workflowOutputProjection(),
        events: [event],
      },
    );
    this._workflowOutputProjector.saveProjection(
      event.data.featureId,
      projection,
    );
  }
}

// type FieldUpdatesEvents =
//   | FieldAdded
//   | FieldDetailsUpdated
//   | FieldValidationAdjusted;
// @NotificationHandler(FieldAdded)
// @NotificationHandler(FieldDetailsUpdated)
// @NotificationHandler(FieldValidationAdjusted)
// class FieldUpdates extends INotificationHandler<FieldUpdatesEvents> {
//   constructor(
//     private readonly _featureProjector: FeatureProjector,
//     private readonly _eventStore: EventStore,
//   ) {
//     super();
//   }

//   async handle(event: FieldUpdatesEvents): Promise<void> {
//     const table = await this._eventStore.hyrdateAggregate(
//       event.data.tableId,
//       Table,
//     );
//     const projection = await this._featureProjector.getProjector(
//       table.featureId,
//       {
//         defaultState: featureProjection(),
//         events: [event],
//       },
//     );
//     this._featureProjector.saveProjection(table.featureId, projection);
//   }
// }

onEvent({
  events: [
    TableIndexCreated,
    FieldAdded,
    FieldDetailsUpdated,
    FieldValidationAdjusted,
  ],
  handler: async (event, context) => {
    const featureProjector = Injector.GetRequiredService(
      FeatureProjector,
      context,
    );
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const table = await eventStore.hyrdateAggregate(event.data.tableId, Table);
    const projection = await featureProjector.getProjector(table.featureId, {
      defaultState: featureProjection(),
      events: [event],
    });
    featureProjector.saveProjection(table.featureId, projection);
  },
});
