import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class PatchExtensionDetailsCommand extends IRequest<string> {
  @IsUUID()
  @AutoMap()
  public extensionId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  public details!: Record<string, any>;
}
