import { GroupQueryCondition } from '@january/compiler/transpilers';
import { v4 } from 'uuid';

import { AggregateRoot, DomainEventMetadata } from '@faslh/api/infrastructure';

import {
  QueryAdded,
  QueryAddedData,
  QueryConditionMutated,
  QueryConditionMutatedData,
  WorkflowActionAdded,
  WorkflowActionAddedData,
} from '../events';
import { WorkflowTrigger } from './table_workflow_trigger';
import { WorkflowAction } from './workflow_action';

type Events = QueryAdded | QueryConditionMutated | WorkflowActionAdded;

export class Query extends AggregateRoot<Events> {
  // constructor(public readonly id: string, data: Omit<Query, 'id'>) {
  //   this.displayName = data.displayName;
  //   this.featureId = data.featureId;
  //   this.single = data.single;
  //   this.localizable = data.localizable;
  //   this.condition = data.condition;
  // }
  // displayName!: string;
  // featureId!: string;
  // single!: boolean;
  // localizable?: boolean;
  public static override readonly streamName = 'query';
  public override readonly streamName = Query.streamName;

  featureId!: string;
  displayName!: string;
  #trigger?: WorkflowTrigger;
  #condition!: GroupQueryCondition<any>;
  actions: Record<string, WorkflowAction> = {};

  public static create(metadata: DomainEventMetadata, data: QueryAddedData) {
    const event = new QueryAdded();
    event.aggregateId = data.featureId;
    event.entityId = v4();

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Query(event.entityId);
    aggregate.applyChanges(event);

    return { aggregate, event };
  }

  public override apply(event: Events): void {
    switch (event.eventType) {
      case 'query_added':
        this.featureId = event.data.featureId;
        this.displayName = event.data.displayName;
        this.#trigger = new WorkflowTrigger(event.entityId, {
          details: event.data.details,
          sourceId: event.data.sourceId,
        });
        break;
      case 'query_condition_mutated':
        this.#condition = event.data.condition;
        break;
      case 'workflow_action_added':
        this.actions[event.entityId] = {
          ...event.data,
          id: event.entityId,
        };
        break;
      default:
        break;
    }
  }
  public mutateQueryCondition(
    data: QueryConditionMutatedData,
    metadata: DomainEventMetadata,
  ) {
    const event = new QueryConditionMutated();
    event.entityId = data.queryId;
    event.aggregateId = this.id;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }
  public addAction(
    metadata: DomainEventMetadata,
    data: WorkflowActionAddedData,
  ) {
    const event = new WorkflowActionAdded();
    event.aggregateId = this.featureId;
    event.entityId = v4();
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }
  public hasAction(sourceId: string) {
    return !!this.actions[sourceId];
  }
}
