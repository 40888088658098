import {
  ChangesCommitted,
  CommitCompleted,
  CommitFailed,
  CommitPipelineStatusSet,
  CommitPushed,
  InitialCommit,
} from '@faslh/api/releases/models';
import { INotificationHandler, NotificationHandler } from '@faslh/tiny-mediatr';

import { commitProjection } from '../commit.projection';
import { CommitProjector } from '../commit.projector';

@NotificationHandler(CommitPushed)
@NotificationHandler(CommitCompleted)
@NotificationHandler(CommitFailed)
@NotificationHandler(CommitPipelineStatusSet)
@NotificationHandler(ChangesCommitted)
@NotificationHandler(InitialCommit)
export class CommitCompletedHandler extends INotificationHandler<
  | CommitCompleted
  | ChangesCommitted
  | InitialCommit
  | CommitFailed
  | CommitPipelineStatusSet
  | CommitPushed
> {
  constructor(private readonly _commitProjector: CommitProjector) {
    super();
  }
  async handle(event: CommitCompleted): Promise<void> {
    const projection = await this._commitProjector.getProjector(
      event.aggregateId,
      {
        defaultState: commitProjection(),
        events: [event],
      },
    );
    this._commitProjector.saveProjection(event.aggregateId, projection);
  }
}
