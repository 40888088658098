import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AssignPolicyToWorkflowCommand } from '@faslh/api/table/commands';
import {
  PolicyAssignedToWorkflowData,
  Workflow,
} from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AssignPolicyToWorkflowCommand)
export default class extends IRequestHandler<AssignPolicyToWorkflowCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AssignPolicyToWorkflowCommand): Promise<void> {
    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    workflow.assignPolicy(
      this._eventUtils.getMetadata(),
      mapper.map(
        command,
        AssignPolicyToWorkflowCommand,
        PolicyAssignedToWorkflowData,
      ),
    );
    await this._eventStore.save(workflow);
  }
}
