import { Injectable, ServiceLifetime } from 'tiny-injector';

import { isNullOrUndefined, toLiteralObject } from '@faslh/utils';

import actions from './data/actions.json';
import extensions from './data/extensions.json';
import fields from './data/fields.json';
import operators from './data/operators.json';
import validations from './data/validations.json';
import type { IAction } from './models/action';
import type { ExtensionVM } from './models/extensions';
import type { ExtensionField } from './models/field';
import type { ITrigger } from './models/trigger';
import type { ExtensionValidation } from './models/validation';

@Injectable({
  lifetime: ServiceLifetime.Singleton,
})
export class DevKit {
  typesResolverMap: Record<string, string> = {
    date: 'Date',
  };

  assignDefaults(metadata: Record<string, any>, details: Record<string, any>) {
    Object.entries(metadata ?? {}).forEach(([name, config]) => {
      // only undefined because null might be use to clear the value
      if (details[name] === undefined && config.defaultValue !== undefined) {
        details[name] = config.defaultValue;
      }
    });
    return details;
  }

  public async getSourceActionByName(name: string) {
    if (isNullOrUndefined(name)) {
      throw new Error('getSourceActionByName:name is required. received: ' + name);
    }
    const action = (await this.actions()).find((it) => it.name === name);
    if (!action) {
      throw new Error(`Action with name ${name} not found`);
    }
    return action;
  }

  public async getSourceActionById(id: string) {
    const action = (await this.actions()).find((it) => it.id === id);
    if (!action) {
      throw new Error(`Action with id ${id} not found`);
    }
    return action;
  }

  public getExtensionActions(id: string): IAction[] {
    const extension = this.extensions().find((it) => it.id === id);
    if (!extension) {
      throw new Error(`Extension with id ${id} not found`);
    }
    return actions.filter((it) => it.extensionId === id) as any[];
  }

  public async actions(): Promise<IAction[]> {
    return await (actions as any[]);
  }

  public async getExtensionTriggers(id: string) {
    const extension = this.extensions().find((it) => it.id === id);
    if (!extension) {
      throw new Error(`Extension with id ${id} not found`);
    }
    return (await this.triggers()).filter((it) => it.extensionId === id);
  }

  public getExtensionById(id: string): ExtensionVM {
    const item = this.extensions().find((it) => it.id === id);
    if (!item) {
      throw new Error(`Extension with id ${id} not found`);
    }
    return item;
  }

  public async triggers(): Promise<ITrigger[]> {
    return (await this.actions()).filter((it) => it.type === 'trigger');
  }

  public extensions(): ExtensionVM[] {
    return extensions as any;
  }
  public getExtensionByName(name: string): ExtensionVM {
    const item = this.extensions().find((it) => it.name === name);
    if (!item) {
      throw new Error(`Extension with name ${name} not found`);
    }
    return item;
  }

  public toJson(obj: Record<string, any>) {
    return JSON.stringify(obj, null, 2);
  }

  public async getTriggerById(id: string) {
    const trigger = (await this.triggers()).find((it) => it.id === id);
    if (!trigger) {
      throw new Error(`Trigger with id ${id} not found`);
    }
    return trigger;
  }

  public async getTriggerByName(name: string) {
    const trigger = (await this.actions()).find(
      (it) => it.type === 'trigger' && it.name === name,
    );
    if (!trigger) {
      throw new Error(`Trigger with name ${name} not found`);
    }
    return trigger;
  }

  public async getValidationById(id: string) {
    const validation = (await this.validations()).find((it) => it.id === id);
    if (!validation) {
      throw new Error(`Validation with id ${id} not found`);
    }
    return validation;
  }

  public async getValidationsByIds(ids: string[]) {
    const validations = await this.validations();
    const list = [];
    for (const id of ids) {
      const validation = validations.find((it) => it.id === id);
      if (!validation) {
        throw new Error(`Validation with id ${id} not found`);
      }
      list.push(validation);
    }
    return list;
  }

  public async getValidationsByNames(names: string[]) {
    const validations = await this.validations();
    const list = [];
    for (const name of names) {
      const validation = validations.find((it) => it.name === name);
      if (!validation) {
        throw new Error(`Validation with name ${name} not found`);
      }
      list.push(validation);
    }
    return list;
  }

  public async getValidationByName(name: string) {
    const validation = await this.validations().find((a) => a.name === name);
    if (!validation) {
      throw new Error(`Validation with name ${name} not found`);
    }
    return validation;
  }

  public async getSourceFieldById(id: string) {
    const field = (await this.getFieldsByExtension()).find(
      (it) => it.id === id,
    );
    if (!field) {
      throw new Error(`Field with id ${id} not found`);
    }

    return field;
  }

  public async getSourceFieldByName(name: string) {
    const field = (await this.getFieldsByExtension()).find(
      (it) => it.name === name,
    );
    if (!field) {
      throw new Error(`Field with name ${name} not found`);
    }
    return field;
  }

  public async getFieldsByExtension() {
    return await this.fields();
  }

  public fields(): ExtensionField[] {
    return fields as any;
  }

  public validations(): ExtensionValidation[] {
    return validations as any;
  }

  public operators(): ExtensionValidation[] {
    return operators as any;
  }

  public substring(input: string, sub: string) {
    const index = input.indexOf(sub);
    if (index === -1) {
      return input;
    }
    return input.slice(sub.length);
  }

  public toLiteralObject<T extends { value: any } | string>(
    obj: Record<string, T> | (readonly [string, T])[],
  ) {
    if (Array.isArray(obj)) {
      return toLiteralObject(Object.fromEntries(obj), (value) => {
        try {
          // @ts-ignore
          if ('value' in value) {
            return value.value;
          }
        } catch (e) {
          return value;
        }
      });
    }
    return toLiteralObject(obj, (value) => {
      try {
        // @ts-ignore
        if ('value' in value) {
          return value.value;
        }
      } catch (e) {
        return value;
      }
    });
  }
}
