import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, Matches } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';

export class FeatureAddedData extends DomainEventData {
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;
}

export const FeatureAdded = createEvent<FeatureAddedData, 'feature_added'>(
  'feature_added'
);
export type FeatureAdded = InstanceType<typeof FeatureAdded>;
