import { AutoMap } from '@automapper/classes';
import { IsIn, IsNotEmpty, IsOptional } from 'class-validator';

export class FieldDocumentation {
  @AutoMap()
  @IsOptional()
  @IsNotEmpty()
  displayName?: string;

  @AutoMap(() => String)
  @IsOptional()
  @IsIn(['both', 'readonly', 'writeonly', 'none'])
  visibility?: 'both' | 'readonly' | 'writeonly' | 'none';
}
