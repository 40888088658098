import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class CommitPushedData extends DomainEventData {
  @IsString()
  @IsNotEmpty()
  @AutoMap()
  public commitSha!: string;
}

export const CommitPushed = createEvent<CommitPushedData, 'commit_pushed'>(
  'commit_pushed',
);
export type CommitPushed = InstanceType<typeof CommitPushed>;
