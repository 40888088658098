import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class AssignPolicyToWorkflowCommand extends IRequest<string> {
  @IsUUID()
  @AutoMap()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsUUID()
  policyId!: string;
}
