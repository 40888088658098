import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class PushCommitCommand extends IRequest<string> {
  @IsUUID()
  @AutoMap()
  public commitId!: string;

  @IsString()
  @IsNotEmpty()
  @AutoMap()
  public commitSha!: string;
}
