import { join } from 'path';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';

import { ENVIRONMENT, type Environment } from '@faslh/api/infrastructure';

import { Projection, ProjectionV2 } from './projection';
import {
  AbstractProjectionStore,
  AbstractStore,
} from './stores/abstract.store';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ProjectionStore {
  constructor(
    private _store: AbstractStore,
    private _projectionStore: AbstractProjectionStore,
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) {}

  public save(
    prefix: string,
    id: string,
    projection: Projection | ProjectionV2,
  ) {
    // this._projectionStore.save({
    //   ...projection,
    //   id: id,
    //   prefix: prefix,
    //   appVersion: this._environment.version,
    // });
  }

  public storeDocument(prefix: string, projection: Projection) {
    // this._projectionStore.save({
    //   ...projection,
    //   prefix: prefix,
    //   id: prefix,
    //   appVersion: this._environment.version,
    // });
  }

  /**
   * Returns a document from the projection collection. This method is used
   * to get singelton projection.
   */
  public async getSingeltonProjection<T>(prefix: string) {
    // const doc = this._store.singeltonProjection(prefix);
    // const inHold = this._store.get(doc.path);
    // if (inHold) {
    //   return inHold as T;
    // }
    // const document = await doc.get();
    // return document.data() as T;
    const docs = await this._store.find<T>({
      tableName: 'Projections',
      where: { id: prefix },
    });

    return docs.map((it: any) => {
      if (typeof it.data === 'string') {
        return JSON.parse(it.data as string);
      }
      return it.data;
    })[0];
  }

  /**
   * Returns a document from the projection collection. This method is used
   * to get a projection from a list of projections.
   */
  public async getProjection<T>(prefix: string, id: string) {
    // const doc = this._store.projections(prefix).doc(id);
    // const inHold = this._store.get(doc.path);
    // if (inHold) {
    //   return inHold as T;
    // }
    // const document = await doc.get();

    // return document.data() as T;
    const docs = await this._store.find<T>({
      tableName: 'Projections',
      where: { prefix, id },
    });
    return docs.map((it: any) => {
      if (typeof it.data === 'string') {
        return JSON.parse(it.data as string);
      }
      return it.data;
    })[0];
  }

  public async listProjections<T extends ProjectionV2>(prefix: string) {
    // const doc = this._store.projections(prefix);
    // const inHold = this._store.get(doc.path);
    // if (inHold) {
    //   return inHold as T[];
    // }
    // const { docs } = await doc.get();
    // return docs.map((it) => it.data()) as T[];

    // const inHold = this._store.get(
    //   join(...this._store.basePath, `projections;${prefix}`),
    // );
    // if (inHold) {
    //   return inHold as T[];
    // }
    const docs = await this._store.find<T>({
      tableName: 'Projections',
      where: { prefix: prefix },
    });

    return docs.map((it: any) => {
      if (typeof it.data === 'string') {
        return JSON.parse(it.data as string);
      }
      return it.data;
    }) as T[];
  }
}
