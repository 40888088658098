import { join } from 'path';
import { Injector } from 'tiny-injector';

import {
  CLAIMS_TOKEN,
  EventStore,
  EventUtils,
  onRequest,
} from '@faslh/api/infrastructure/database';
import { Organization } from '@faslh/api/organization/domain';
import { SetRepositoryCommand } from '@faslh/api/settings/commands';
import { RepositorySetData } from '@faslh/api/settings/domain';
import { mapper } from '@faslh/isomorphic';

onRequest({
  requestType: SetRepositoryCommand,
  handler: async (request, context) => {
    const eventStore = Injector.GetRequiredService(EventStore, context);
    const eventUtils = Injector.GetRequiredService(EventUtils, context);
    const claims = Injector.GetRequiredService(CLAIMS_TOKEN, context);
    const aggregate = await eventStore.hyrdateAggregate(
      claims.organizationId,
      Organization,
    );

    aggregate.setRepositoryName(
      eventUtils.getMetadata(),
      mapper.map(
        {
          projectId: claims.projectId,
          workspaceId: claims.workspaceId,
          repositoryName: request.repositoryName,
          repositoryId: request.repositoryId,
        },
        RepositorySetData,
      ),
    );
    await eventStore.save(aggregate);
  },
});
