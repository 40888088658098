import { Injector } from 'tiny-injector';

import { AbstractInputParser } from './lib/registry/abstract-input-parser';
import { ExtensionsRegistry } from './lib/registry/input-parser';

Injector.AddScoped(AbstractInputParser, ExtensionsRegistry);

export * from './lib/extension-manager';
export * from './lib/hook/hook-manger';
export * from './lib/koajs/koajs.extension';
export * from './lib/postgresql/typeorm/typeorm.visitor';
export * from './lib/postgresql/typeorm/typeorm.writer';
export * from './lib/postgresql/typeorm/utils';
export * from './lib/registry/input-parser';
