import type {
  DomainEvent,
  DomainEventMetadata,
} from '@faslh/api/infrastructure';
import { AggregateRoot } from '@faslh/api/infrastructure';

import type { PolicyAddedData } from '../events';
import { PolicyAdded } from '../events';

type Events = PolicyAdded;
export class Policy extends AggregateRoot<Events> {
  public displayName!: string;
  public rule!: string;
  public static override readonly streamName = 'policy';
  public override readonly streamName = Policy.streamName;

  public apply(event: Events): void {
    switch (event.eventType) {
      case 'policy_added':
        this.displayName = event.data.displayName;
        this.rule = event.data.rule;
        break;
      default:
        throw new Error(
          `${(event as DomainEvent<any, any>).eventType} is not handled.`,
        );
    }
  }

  public static create(
    id: string,
    metadata: DomainEventMetadata,
    data: PolicyAddedData,
  ) {
    const event = new PolicyAdded();

    event.entityId = id;
    event.aggregateId = event.entityId;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Policy(event.aggregateId);
    aggregate.applyChanges(event);

    return aggregate;
  }
}
