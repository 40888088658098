import { Injectable, ServiceLifetime } from 'tiny-injector';

import {
  EventStore,
  ProjectionStore,
  Projector,
} from '@faslh/api/infrastructure/database';
import {
  FieldAdded,
  FieldDelocalized,
  FieldDetailsUpdated,
  FieldLocalized,
  FieldValidationAdjusted,
  TableAdded,
  TableArchived,
  TableNameChanged,
} from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { upsert, upsertAsync } from '@faslh/utils';

import { TableProjection } from './table.projection';

type Events =
  | TableAdded
  | FieldAdded
  | TableNameChanged
  | TableArchived
  | FieldDelocalized
  | FieldLocalized
  | FieldValidationAdjusted
  | FieldDetailsUpdated;

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class TableProjector extends Projector<TableProjection, Events> {
  protected override projectionName = 'tables';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    protected _devKit: DevKit,
  ) {
    super();
  }
  protected override async apply(
    state: TableProjection,
    event: Events,
  ): Promise<TableProjection> {
    switch (event.eventType) {
      case 'table_added':
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.displayName = event.data.displayName;
        state.featureId = event.data.featureId;
        state.isArchived = false;
        return state;
      case 'table_archived':
        state.updatedAt = event.timestamp;
        state.isArchived = true;
        return state;
      case 'field_added':
        state.updatedAt = event.timestamp;
        state.fields = await upsertAsync(
          state.fields ?? [],
          event.entityId,
          async (it) => ({
            ...it,
            ...event.data,
            details: {
              ...it.details,
              ...event.data.details,
            },
            name: await this._devKit
              .getSourceFieldById(event.data.sourceId)
              .then((it) => it.name),
            documentation: {
              displayName: event.data.displayName,
            },
          }),
        );
        return state;
      case 'field_localized':
        state.updatedAt = event.timestamp;
        state.fields = upsert(state.fields ?? [], event.entityId, (it) => ({
          ...it,
          localizable: true,
        }));
        return state;
      case 'field_delocalized':
        state.updatedAt = event.timestamp;
        state.fields = upsert(state.fields ?? [], event.entityId, (it) => ({
          ...it,
          localizable: false,
        }));
        return state;
      case 'field_validation_adjusted':
        state.updatedAt = event.timestamp;
        state.fields = upsert(state.fields ?? [], event.entityId, (it) => ({
          ...it,
          validations: event.data.validations.map((it) => ({
            details: it.details,
            sourceId: it.sourceId,
          })),
        }));
        return state;
      case 'field_details_updated':
        state.updatedAt = event.timestamp;
        state.fields = upsert(state.fields ?? [], event.entityId, (it) => ({
          ...it,
          details: {
            ...it.details,
            ...event.data.details,
          },
        }));
        return state;

      default:
        return state;
    }
  }
}
