import { AutoMap } from '@automapper/classes';
import { IsOptional, IsUUID } from 'class-validator';

import { IRequest } from '@faslh/tiny-mediatr';

import { AutoMapHost } from './lib/mapper';

export * from './lib/field-documentation';
export * from './lib/field-type';
export * from './lib/field-validation';
export * from './lib/linear-tree/tree';
export * from './lib/mapper';
export * from './lib/octokit';
export * from './lib/releasable';

export interface BranchPath {
  id: string;
  name: string;
  rule: string;
}

export type Command<T extends Record<string, unknown>, CallT = unknown> = {
  command: string;
  payload: T;
};

export type Driven<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] | Command<Record<string, unknown>>;
};

export interface CodeRunnerConfig {
  containerId: string;
  port: string;
  projectId: string;
  depsChanges: boolean;
  codeChanges: boolean;
}

@AutoMapHost()
export class WithProjectId<T = string> extends IRequest<T> {
  @AutoMap()
  @IsUUID()
  @IsOptional()
  projectId?: string;
}

export const baseUrl =
  process.env['NODE_ENV'] === 'development'
    ? 'http://localhost:4321'
    : 'https://api.january.sh';

export const serverizeUrl =
  process.env['NODE_ENV'] === 'development'
    ? 'http://localhost:3100'
    : 'https://dev.january.sh';
export const serverizeWs =
  process.env['NODE_ENV'] === 'development'
    ? 'ws://localhost:3100'
    : 'wss://dev.january.sh';
