import { AutoMap } from '@automapper/classes';
import type { GroupQueryCondition } from '@january/compiler/transpilers';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class QueryConditionMutatedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @IsUUID()
  @AutoMap()
  queryId!: string;

  @IsNotEmpty()
  @AutoMap(() => Object)
  condition!: GroupQueryCondition<any>;
}

export class QueryConditionMutated extends DomainEvent<
  QueryConditionMutatedData,
  'query_condition_mutated'
> {
  public override readonly eventType = 'query_condition_mutated' as const;
  constructor() {
    super();
  }

  @AutoMap(() => QueryConditionMutatedData)
  public override data!: QueryConditionMutatedData;
}
