import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkflowOutputDetailsPatchedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;
}

export const WorkflowOutputDetailsPatched = createEvent<
  WorkflowOutputDetailsPatchedData,
  'workflow_output_details_patched'
>('workflow_output_details_patched');
export type WorkflowOutputDetailsPatched = InstanceType<
  typeof WorkflowOutputDetailsPatched
>;