import { Contracts, IncomingActionProperty } from '@faslh/compiler/contracts';
import { camelcase } from '@faslh/utils';

const naminize = (input: string[]) => camelcase(input.join(' '));

function format(
  it: Omit<Contracts.DefaultQueryConditionContract, 'operator'>,
): Record<string, IncomingActionProperty> {
  // return {
  //   name: camelcase(it.input.join(' ')),
  //   namespace: 'REMOVE_NAMESPACE',
  //   value: it.data.value,
  //   type: it.data.type,
  //   defaultValue: it.data.defaultValue,
  //   validations: [], // FIXME: add required validation
  // };
  return {
    [naminize(it.input)]: {
      input: it.data.input as string,
      defaultValue: it.data.defaultValue,
      validations: it.data.validation,
    },
  };
}

function processSelect(
  select: Contracts.QuerySelectConditionContract,
): Record<string, IncomingActionProperty> {
  return select.data
    .map(processGroup)
    .flat()
    .reduce<Record<string, IncomingActionProperty>>((acc, item) => {
      return {
        ...acc,
        ...item,
      };
    }, {});
}

function processGroup(
  group: Contracts.GroupQueryConditionContract,
): Record<string, IncomingActionProperty> {
  return group.data
    .map((item) => flatQueryConditions(item))
    .flat()
    .reduce<Record<string, IncomingActionProperty>>((acc, item) => {
      return {
        ...acc,
        ...item,
      };
    }, {});
}

function processBetween(
  group: Contracts.BetweenQueryConditionContract,
): Record<string, IncomingActionProperty> {
  return {
    ...format({
      input: group.input,
      data: group.data.min,
    }),
    ...format({
      input: group.input,
      data: group.data.max,
    }),
  };
}

function processDefault(
  group: Contracts.DefaultQueryConditionContract,
): Record<string, IncomingActionProperty> {
  return format({
    input: group.input,
    data: group.data,
  });
}

export function flatQueryConditions(
  condition: Contracts.QueryConditionContract,
): Record<string, IncomingActionProperty> {
  switch (condition.operator) {
    case 'group':
      return processGroup(condition);
    case 'between':
      return processBetween(condition);
    case 'querySelect':
      return processSelect(condition);
    default:
      return processDefault(condition);
  }
}
