import { AggregateRoot, DomainEventMetadata } from '@faslh/api/infrastructure';



import { ActionNameChanged, ActionNameChangedData, PolicyAssignedToWorkflow, PolicyAssignedToWorkflowData, TagAssignedToWorkflow, TagAssignedToWorkflowData, WorkflowActionAdded, WorkflowActionAddedData, WorkflowActionDetailsPatched, WorkflowActionDetailsPatchedData, WorkflowAdded, WorkflowAddedData, WorkflowDetailsPatched, WorkflowDetailsPatchedData, WorkflowRemoved, WorkflowRemovedData } from '../events';
import { ActionConnected, ActionConnectedData } from '../events/action_connected.event';
import { WorkflowOutputDetailsPatched, WorkflowOutputDetailsPatchedData } from '../events/workflow-output-details-patched';
import { WorkflowNameChanged, WorkflowNameChangedData } from '../events/workflow_name_changed.event';
import { WorkflowTriggerChanged, WorkflowTriggerChangedData } from '../events/workflow_trigger_changed.event';
import { WorkflowTrigger } from './table_workflow_trigger';
import { WorkflowAction } from './workflow_action';


type Events =
  | WorkflowActionDetailsPatched
  | WorkflowActionAdded
  | WorkflowAdded
  | WorkflowTriggerChanged
  | TagAssignedToWorkflow
  | PolicyAssignedToWorkflow
  | WorkflowNameChanged
  | ActionNameChanged
  | WorkflowRemoved
  | ActionConnected
  | WorkflowDetailsPatched
  | WorkflowOutputDetailsPatched;

export class Workflow extends AggregateRoot<Events> {
  #trigger?: WorkflowTrigger;
  #actions: Record<string, WorkflowAction> = {};
  featureId!: string;
  displayName!: string;
  output!: string;
  #tag!: string;
  #policies: string[] = [];
  removed!: boolean;
  details?: string;
  public static override readonly streamName = 'workflow';
  public override readonly streamName = Workflow.streamName;

  public static create(
    id: string,
    metadata: DomainEventMetadata,
    data: WorkflowAddedData,
  ) {
    const event = new WorkflowAdded();
    event.aggregateId = id;
    event.entityId = event.aggregateId;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Workflow(event.entityId);
    aggregate.applyChanges(event);

    return aggregate;
  }

  public get trigger() {
    if (!this.#trigger) {
      throw new Error(`Trigger not found for workflow ${this.id}`);
    }
    return this.#trigger;
  }

  public override apply(event: Events): void {
    switch (event.eventType) {
      case 'workflow_removed':
        this.removed = true;
        break;
      case 'workflow_added':
        this.featureId = event.data.featureId;
        this.displayName = event.data.displayName;
        this.#trigger = new WorkflowTrigger(event.entityId, {
          details: event.data.details,
          sourceId: event.data.sourceId,
        });
        break;
      case 'workflow_name_changed':
        this.displayName = event.data.displayName;
        break;
      case 'workflow_details_patched':
        this.details = event.data.details;
        break;
      case 'action_name_changed':
        this.#actions[event.entityId].displayName = event.data.displayName;
        break;
      case 'workflow_trigger_changed':
        if (!this.#trigger) {
          throw new Error(`Trigger not found for workflow ${this.id}`);
        }
        this.#trigger.sourceId = event.data.sourceId;
        this.#trigger.details = event.data.details;
        break;
      case 'workflow_action_added':
        this.#actions[event.entityId] = new WorkflowAction(
          event.entityId,
          event.data,
        );
        break;
      case 'action_connected':
        {
          const action = this.#actions[event.entityId];
        }
        break;
      case 'workflow_action_details_patched':
        {
          this.#actions[event.entityId].details = event.data.details;
        }
        break;
      case 'workflow_output_details_patched':
        this.output = event.data.details;
        break;
      case 'tag_assigned_to_workflow':
        this.#tag = event.data.tagId;
        break;
      case 'policy_assigned_to_workflow':
        this.#policies.push(event.data.policyId);
        break;
      default:
        break;
    }
  }

  public addAction(
    id: string,
    metadata: DomainEventMetadata,
    data: WorkflowActionAddedData,
  ) {
    const event = new WorkflowActionAdded();
    event.aggregateId = data.workflowId;
    event.entityId = id;
    event.metadata = metadata;
    event.data = data;

    this.applyChanges(event);
    return event.entityId;
  }

  public connectAction(
    metadata: DomainEventMetadata,
    data: ActionConnectedData,
  ) {
    const event = new ActionConnected();
    event.aggregateId = data.workflowId;
    event.entityId = data.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
    return event.entityId;
  }

  public patchDetails(
    metadata: DomainEventMetadata,
    data: WorkflowDetailsPatchedData,
  ) {
    const event = new WorkflowDetailsPatched();
    event.aggregateId = data.workflowId;
    event.entityId = data.workflowId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public patchActionDetails(
    id: string,
    metadata: DomainEventMetadata,
    data: WorkflowActionDetailsPatchedData,
  ) {
    const event = new WorkflowActionDetailsPatched();
    event.aggregateId = data.workflowId;
    event.entityId = id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public patchOutputDetails(
    metadata: DomainEventMetadata,
    data: WorkflowOutputDetailsPatchedData,
  ) {
    const event = new WorkflowOutputDetailsPatched();
    event.aggregateId = data.workflowId;
    event.entityId = data.workflowId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public changeTrigger(
    metadata: DomainEventMetadata,
    data: WorkflowTriggerChangedData,
  ) {
    const event = new WorkflowTriggerChanged();
    event.aggregateId = data.workflowId;
    event.entityId = data.workflowId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public changeName(
    metadata: DomainEventMetadata,
    data: WorkflowNameChangedData,
  ) {
    const event = new WorkflowNameChanged();
    event.aggregateId = data.workflowId;
    event.entityId = data.workflowId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public changeActionName(
    metadata: DomainEventMetadata,
    data: ActionNameChangedData,
  ) {
    const event = new ActionNameChanged();
    event.aggregateId = data.workflowId;
    event.entityId = data.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public remove(metadata: DomainEventMetadata, data: WorkflowRemovedData) {
    const event = new WorkflowRemoved();
    event.aggregateId = data.workflowId;
    event.entityId = data.workflowId;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public assignTag(
    metadata: DomainEventMetadata,
    data: TagAssignedToWorkflowData,
  ) {
    const event = new TagAssignedToWorkflow();
    event.aggregateId = data.workflowId;
    event.entityId = this.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public assignPolicy(
    metadata: DomainEventMetadata,
    data: PolicyAssignedToWorkflowData,
  ) {
    const event = new PolicyAssignedToWorkflow();
    event.aggregateId = data.workflowId;
    event.entityId = this.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public hasAction(sourceId: string) {
    return !!this.#actions[sourceId];
  }

  public hasTag() {
    return !!this.#tag;
  }

  public get actions() {
    return Object.values(this.#actions);
  }
}

/**
 * A workflow is a good example why using the aggregate id as way to replay events is not good idea.
 * A workflow cannot exist without table but the workflow itself is an aggregate root.
 *
 * Using streamName as a way to replay events is better idea.
 */