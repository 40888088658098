import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString, IsUUID } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';


@AutoMapHost()
export class WorkflowActionDetailsPatchedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  featureId!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  details!: string;
}

export const WorkflowActionDetailsPatched = createEvent<
  WorkflowActionDetailsPatchedData,
  'workflow_action_details_patched'
>('workflow_action_details_patched');
export type WorkflowActionDetailsPatched = InstanceType<
  typeof WorkflowActionDetailsPatched
>;