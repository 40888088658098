export class WorkflowAction {
  sourceId: string;
  workflowId: string;
  details: string;
  featureId: string;
  outputName: string;
  displayName: string;

  constructor(
    public readonly id: string,
    props: Omit<WorkflowAction, 'id'>,
  ) {
    this.details = props.details;
    this.sourceId = props.sourceId;
    this.workflowId = props.workflowId;
    this.featureId = props.featureId;
    this.outputName = props.outputName;
    this.displayName = props.displayName;
  }
}
