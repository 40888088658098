import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';

import { NonFunctionProperties } from '@faslh/api/infrastructure';
import {
  CLAIMS_TOKEN,
  type Claims,
  EventStore,
  ProjectionStore,
  Projector,
} from '@faslh/api/infrastructure/database';
import { PolicyAdded } from '@faslh/api/policies/domain';
import { SecretSet } from '@faslh/api/settings/domain';
import {
  ActionConnected,
  ActionNameChanged,
  FeatureAdded,
  FeatureDeleted,
  FieldAdded,
  FieldDetailsUpdated,
  FieldValidationAdjusted,
  PolicyAssignedToWorkflow,
  QueryAdded,
  QueryConditionMutated,
  QueryDelocalized,
  QueryLocalized,
  TableAdded,
  TableArchived,
  TableIndexCreated,
  TableNameChanged,
  TagAssignedToWorkflow,
  WorkflowActionAdded,
  WorkflowActionDetailsPatched,
  WorkflowAdded,
  WorkflowDetailsPatched,
  WorkflowNameChanged,
  WorkflowOutputDetailsPatched,
  WorkflowRemoved,
  WorkflowTriggerChanged,
} from '@faslh/api/table/domain';
import { ExtensionInstalled } from '@faslh/api/user-extensions/domain';
import { upsert } from '@faslh/utils';

import { ChangesProjection, changesProjection } from './changes.projection';

export type Events =
  | PolicyAdded
  | ExtensionInstalled
  | FieldValidationAdjusted
  | TableAdded
  | FieldAdded
  | QueryAdded
  | WorkflowAdded
  | WorkflowNameChanged
  | WorkflowTriggerChanged
  | WorkflowActionAdded
  | ActionNameChanged
  | WorkflowActionDetailsPatched
  | WorkflowDetailsPatched
  | WorkflowOutputDetailsPatched
  | TableNameChanged
  | TableArchived
  | SecretSet
  | QueryConditionMutated
  | QueryLocalized
  | QueryDelocalized
  | FeatureAdded
  | FieldDetailsUpdated
  | WorkflowRemoved
  | WorkflowNameChanged
  | ActionConnected
  | TagAssignedToWorkflow
  | PolicyAssignedToWorkflow
  | TableIndexCreated
  | FeatureDeleted;
export type ChangesProjectionEvents = Events;

export class ExtensionVM {
  public id: string;
  public name: string;
  public config: any;
  constructor(props: NonFunctionProperties<ExtensionVM>) {
    this.id = props.id;
    this.name = props.name;
    this.config = props.config;
  }
}

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ChangesProjector extends Projector<ChangesProjection, Events> {
  protected override projectionName = 'changes';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    @Inject(CLAIMS_TOKEN) private readonly _claims: Claims,
  ) {
    super();
  }

  protected override async apply(
    state: ChangesProjection,
    event: Events,
  ): Promise<ChangesProjection> {
    switch (event.eventType) {
      case 'feature_added':
        state.features = upsert(state.features ?? [], event.entityId, (it) => ({
          ...it,
          displayName: event.data.displayName,
        }));
        return state;
      case 'feature_deleted':
        state.features = upsert(state.features ?? [], event.entityId, (it) => ({
          ...it,
          deleted: true,
        }));
        return state;
      case 'table_added':
        state.tables = upsert(state.tables ?? [], event.entityId, (it) => ({
          ...it,
          featureId: event.data.featureId,
          tableName: event.data.displayName,
          indexes: it.indexes ?? [],
        }));
        return state;
      case 'table_index_created':
        state.tables = upsert(state.tables ?? [], event.data.tableId, (it) => ({
          ...it,
          indexes: [...(it.indexes ?? []), event.data.details],
        }));
        return state;
      case 'table_name_changed':
        state.tables = upsert(state.tables ?? [], event.data.tableId, (it) => ({
          ...it,
          tableName: event.data.tableName,
        }));
        return state;
      case 'table_archived':
        state.tables = upsert(state.tables ?? [], event.data.tableId, (it) => ({
          ...it,
          isArchived: true,
        }));
        return state;
      case 'field_validation_adjusted':
        state.tables = upsert(state.tables ?? [], event.data.tableId, (it) => ({
          ...it,
          fields: upsert(it.fields ?? [], event.entityId, (it) => ({
            ...it,
            validations: [
              ...(it.validations ?? []),
              ...(event.data.validations ?? []),
            ],
          })),
        }));
        return state;
      case 'field_added':
        state.tables = upsert(state.tables ?? [], event.data.tableId, (it) => ({
          ...it,
          fields: upsert(it.fields ?? [], event.entityId, (it) => {
            return {
              ...it,
              ...event.data,
              details: {
                ...it.details,
                ...event.data.details,
              },
              name: event.data.displayName,
              tableId: event.data.tableId,
              sourceId: event.data.sourceId,
              validations: [...(it.validations ?? [])],
            };
          }),
        }));
        return state;
      case 'query_added':
        state.queries = upsert(state.queries ?? [], event.entityId, (it) => ({
          ...it,
          ...event.data,
        }));
        return state;
      case 'query_condition_mutated':
        state.queries = upsert(state.queries ?? [], event.entityId, (it) => ({
          ...it,
          condition: event.data.condition,
        }));
        return state;
      case 'extension_installed':
        state.extensions = upsert(state.extensions, event.entityId, (it) => ({
          ...it,
          details: JSON.parse(event.data.details),
          name: event.data.name,
        }));
        return state;
      case 'secret_set':
        state.secrets = upsert(state.secrets, event.entityId, (it) => ({
          ...it,
          name: event.data.name,
        }));
        return state;
      case 'policy_added':
        state.policies = upsert(state.policies, event.entityId, (it) => ({
          ...it,
          displayName: event.data.displayName,
          rule: event.data.rule,
        }));
        return state;
      case 'workflow_added':
        state.workflows = upsert(
          state.workflows ?? [],
          event.entityId,
          (it) => ({
            ...it,
            featureId: event.data.featureId,
            displayName: event.data.displayName,
            actions: it.actions ?? [],
            details: {
              ...it.details,
              ...event.data.details,
            },
            trigger: {
              sourceId: event.data.sourceId,
              details: event.data.details,
            },
          }),
        );
        return state;
      case 'workflow_name_changed':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            displayName: event.data.displayName,
          }),
        );
        return state;
      case 'workflow_removed':
        state.workflows = upsert(
          state.workflows ?? [],
          event.entityId,
          (it) => ({
            ...it,
            removed: true,
          }),
        );
        return state;
      case 'workflow_action_added':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, () => {
              return {
                sourceId: event.data.sourceId,
                id: event.entityId,
                outputName: event.data.outputName,

                details: JSON.parse(event.data.details),
                displayName: event.data.displayName,
              };
            }),
          }),
        );
        return state;
      case 'action_name_changed':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
              displayName: event.data.displayName,
            })),
          }),
        );
        return state;
      case 'action_connected':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
            })),
          }),
        );
        return state;
      case 'workflow_details_patched':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            details: JSON.parse(event.data.details) ?? {},
          }),
        );
        return state;
      case 'workflow_action_details_patched':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            actions: upsert(it.actions ?? [], event.entityId, (it) => ({
              ...it,
              details: JSON.parse(event.data.details),
            })),
          }),
        );
        return state;
      case 'workflow_output_details_patched':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            output: JSON.parse(event.data.details),
          }),
        );
        return state;
      case 'field_details_updated':
        state.tables = upsert(state.tables, event.data.tableId, (it) => ({
          ...it,
          fields: upsert(it.fields ?? [], event.entityId, (it) => ({
            ...it,
            details: {
              ...it.details,
              ...event.data.details,
            },
          })),
        }));
        return state;
      case 'tag_assigned_to_workflow':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            tagId: event.data.tagId,
          }),
        );
        return state;
      case 'policy_assigned_to_workflow':
        state.workflows = upsert(
          state.workflows ?? [],
          event.data.workflowId,
          (it) => ({
            ...it,
            policies: upsert(it.policies ?? [], event.data.policyId, (it) => ({
              ...it,
              id: event.data.policyId,
            })),
          }),
        );
        return state;

      default:
        return state;
    }
  }

  async build(
    initialChanges = changesProjection(),
  ): Promise<ChangesProjection> {
    const events = await this._eventStore.getEvents({
      where: {
        'metadata.projectId': this._claims.projectId,
      },
    });
    const changes = await this.foldUp(initialChanges, events);
    changes.features = changes.features.filter((it) => it.deleted !== true);
    return changes;
  }
}
