import { v4 } from 'uuid';

import { AggregateRoot, DomainEventMetadata } from '@faslh/api/infrastructure';
import { camelcase } from '@faslh/utils';

import {
  FieldAdded,
  FieldAddedData,
  FieldDelocalized,
  FieldDelocalizedData,
  FieldDetailsUpdated,
  FieldDetailsUpdatedData,
  FieldLocalized,
  FieldLocalizedData,
  FieldValidationAdjusted,
  FieldValidationAdjustedData,
  QueryAdded,
  QueryConditionMutated,
  QueryConditionMutatedData,
  QueryDelocalized,
  QueryLocalized,
  TableAdded,
  TableAddedData,
  TableNameChanged,
  TableNameChangedData,
} from '../events';
import {
  TableArchived,
  TableArchivedData,
} from '../events/table-archived.event';
import {
  TableIndexCreateData,
  TableIndexCreated,
} from '../events/table-index-created.event';
import { TableField } from './table-field';

type Events =
  | QueryAdded
  | QueryConditionMutated
  | TableAdded
  | FieldAdded
  | TableNameChanged
  | TableArchived
  | FieldDetailsUpdated
  | QueryLocalized
  | QueryDelocalized
  | FieldLocalized
  | FieldDelocalized
  | FieldValidationAdjusted
  | TableIndexCreated;

export class Table extends AggregateRoot<Events> {
  #fields: Record<string, TableField> = {};
  #primaryFields: Record<string, TableField> = {};
  public static override readonly streamName = 'table';
  public override readonly streamName = Table.streamName;

  public featureId!: string;
  public displayName!: string;
  public isArchived!: boolean;

  public indexes: unknown[] = [];

  public static create(
    id: string,
    metadata: DomainEventMetadata,
    data: TableAddedData,
  ) {
    const event = new TableAdded();
    event.entityId = id;
    event.aggregateId = event.entityId;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Table(event.entityId);
    aggregate.applyChanges(event);

    return aggregate;
  }

  public override apply(event: Events) {
    switch (event.eventType) {
      case 'table_added':
        this.displayName = event.data.displayName;
        this.featureId = event.data.featureId;
        break;
      case 'table_archived':
        this.isArchived = true;
        break;
      case 'field_localized':
        this.#fields[event.entityId].localizable = true;
        break;
      case 'field_delocalized':
        this.#fields[event.entityId].localizable = false;
        break;
      case 'field_added':
        this.#fields[event.entityId] ??= new TableField(event.entityId, {
          ...event.data,
          validations: [],
        });
        this.#fields[event.entityId].details = {
          ...this.#fields[event.entityId].details,
          ...event.data.details,
        };
        this.#fields[event.entityId].displayName = event.data.displayName;
        this.#fields[event.entityId].sourceId = event.data.sourceId;
        break;
      case 'field_details_updated':
        this.#fields[event.entityId].details = {
          ...this.#fields[event.entityId].details,
          ...event.data.details,
        };
        break;
      case 'table_name_changed':
        this.displayName = event.data.tableName;
        break;
      case 'table_index_created':
        this.indexes.push(event.data.details);
        break;
      case 'field_validation_adjusted':
        this.#fields[event.entityId] ??= new TableField(event.entityId, {
          tableId: event.aggregateId,
          displayName: '',
          sourceId: '',
          details: {},
          validations: [],
        });
        this.#fields[event.entityId].validations.push(
          ...event.data.validations.map((it) => ({
            details: it.details,
            name: it.name,
            sourceId: it.sourceId,
          })),
        );
        break;
      default:
        break;
    }
  }

  public updateName(metadata: DomainEventMetadata, data: TableNameChangedData) {
    const event = new TableNameChanged();
    event.data = data;
    event.aggregateId = data.tableId;
    event.entityId = this.id;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public archive(metadata: DomainEventMetadata, data: TableArchivedData) {
    const event = new TableArchived();
    event.entityId = data.tableId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public mutateQueryCondition(
    data: QueryConditionMutatedData,
    metadata: DomainEventMetadata = {
      causationId: '',
      correlationId: v4(),
    },
  ) {
    const event = new QueryConditionMutated();
    event.entityId = data.queryId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public addField(
    id: string,
    metadata: DomainEventMetadata,
    data: FieldAddedData,
  ) {
    const event = new FieldAdded();
    event.entityId = id;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
    return this.#fields[event.entityId];
  }

  public updateFieldDetails(
    metadata: DomainEventMetadata,
    data: FieldDetailsUpdatedData,
  ) {
    const event = new FieldDetailsUpdated();
    event.entityId = data.fieldId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
    return this.#fields[event.entityId];
  }

  public adjustFieldValidation(
    fieldId: string,
    metadata: DomainEventMetadata,
    data: FieldValidationAdjustedData,
  ) {
    const event = new FieldValidationAdjusted();
    event.entityId = fieldId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public addIndex(metadata: DomainEventMetadata, data: TableIndexCreateData) {
    const event = new TableIndexCreated();
    event.entityId = this.id;
    event.aggregateId = this.id;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public hasField(id: string) {
    return !!this.#fields[id];
  }

  public fieldNameExist(displayName: string) {
    return Object.values(this.#fields).some(
      (it) => camelcase(it.displayName) === camelcase(displayName),
    );
  }
  public getField(displayName: string) {
    return Object.values(this.#fields).find(
      (it) => camelcase(it.displayName) === camelcase(displayName),
    );
  }

  public getFirstPrimaryKey() {
    return Object.values(this.#primaryFields)[0];
  }

  public getPrimaryKey(id: string) {
    return !!this.#primaryFields[id];
  }

  public get fields() {
    return Object.values(this.#fields);
  }

  public localizeField(
    metadata: DomainEventMetadata,
    data: FieldLocalizedData,
  ) {
    const event = new FieldLocalized();
    event.entityId = data.fieldId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }

  public delocalizeField(
    metadata: DomainEventMetadata,
    data: FieldDelocalizedData,
  ) {
    const event = new FieldDelocalized();
    event.entityId = data.fieldId;
    event.aggregateId = data.tableId;
    event.data = data;
    event.metadata = metadata;
    this.applyChanges(event);
  }
}
