import { Injectable, ServiceLifetime } from 'tiny-injector';

import { EventStore } from '@faslh/api/infrastructure/database';
import { AddFeatureCommand, AddTagCommand } from '@faslh/api/table/commands';
import { Feature } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { Mediator } from '@faslh/tiny-mediatr';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class Features {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _mediator: Mediator,
  ) {}

  public list() {
    return this._eventStore
      .listAggregates(Feature)
      .then((it) => it.filter((it) => it.deleted !== true));
  }

  public async get(featureId: string) {
    return this._eventStore.hyrdateAggregate(featureId, Feature);
  }

  public async tags(featureId: string) {
    const feature = await this.get(featureId);
    return feature.tags;
  }

  public async getTag(featureId: string, tagId: string) {
    const feature = await this.get(featureId);
    return feature.tags.find((t) => t.id === tagId);
  }

  public async addFeature(command: AddFeatureCommand): Promise<string> {
    return this._mediator.send(mapper.map(command, AddFeatureCommand));
  }

  public async addTag(command: AddTagCommand): Promise<string> {
    return this._mediator.send(mapper.map(command, AddTagCommand));
  }
}
