import { InjectionToken } from 'tiny-injector';

export interface SqliteOperations {
  prepare(
    op: {
      sql: string;
      params: unknown[];
      txn?: boolean;
    },
    client?: any,
  ): void | Promise<void>;
  query(op: { sql: string; params: unknown[] }): unknown | Promise<unknown>;
  transaction(operations: any[]): void | Promise<void>;
}

export const SQLITE_TOKEN = new InjectionToken<SqliteOperations>(
  'SQLITE_TOKEN',
);
