import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class RemoveWorkflowCommand extends IRequest {
  @AutoMap()
  @IsUUID()
  workflowId!: string;
}
