// import { v4 } from 'uuid';



// import { AggregateRoot, DomainEvent, DomainEventMetadata } from '@faslh/api/infrastructure';



// import { ProjectAdded, ProjectAddedData } from '../events/project_added.event';
// import { WorkspaceAdded, WorkspaceAddedData } from '../events/workspace_created.event';
// import { Project } from './project';


// type Events = WorkspaceAdded | ProjectAdded;

// // FIXME: this shouldn't be an aggregate root
// export class Workspace extends AggregateRoot<Events> {
//   public displayName!: string;
//   #projects: Record<string, Project> = {};
//   public static override readonly streamName = 'workspace';
//   public override readonly streamName = Workspace.streamName;

//   public static create(
//     id: string,
//     metadata: DomainEventMetadata,
//     data: WorkspaceAddedData,
//   ) {
//     const event = new WorkspaceAdded();
//     event.aggregateId = data.orgId;
//     event.entityId = id;

//     event.data = data;
//     event.metadata = metadata;

//     const aggregate = new Workspace(event.entityId);
//     aggregate.applyChanges(event);

//     return { aggregate, event };
//   }

//   public override apply(event: Events): void {
//     switch (event.eventType) {
//       case 'workspace_added':
//         this.displayName = event.data.displayName;
//         break;

//       case 'project_added':
//         this.#projects[event.entityId] = new Project(event.entityId, {
//           orgId: event.data.orgId,
//           workspaceId: event.data.workspaceId,
//         });
//         break;

//       default:
//         throw new Error(
//           `${(event as DomainEvent<any, any>).eventType} is not handled.`,
//         );
//     }
//   }

//   public addFirstProject(
//     id: string,
//     metadata: DomainEventMetadata,
//     data: ProjectAddedData,
//   ) {
//     const event = new ProjectAdded();
//     event.aggregateId = this.id;
//     event.entityId = id;

//     event.data = data;
//     event.metadata = metadata;

//     this.applyChanges(event);
//     return this.#projects[event.entityId];
//   }

//   public addProject(metadata: DomainEventMetadata, data: ProjectAddedData) {
//     const event = new ProjectAdded();
//     event.aggregateId = this.id;
//     event.entityId = v4();

//     event.data = data;
//     event.metadata = metadata;

//     this.applyChanges(event);
//     return this.#projects[event.entityId];
//   }

//   public getFirstProject() {
//     return Object.values(this.#projects)[0];
//   }

//   public projectExists(id: string) {
//     return !!this.#projects[id];
//   }

//   get projects() {
//     return Object.values(this.#projects);
//   }
// }

export class Workspace {
  orgId!: string;
  constructor(
    public readonly id: string,
    data: Omit<Workspace, 'id'>,
  ) {
    this.orgId = data.orgId;
  }
}
