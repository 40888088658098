import dedent from 'dedent';
import yaml from 'js-yaml';
import { Injectable, ServiceLifetime } from 'tiny-injector';

import { Contracts, IExtension } from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class PrettierExtension implements IExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
  ) {}

  async handleSetup(
    contract: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeRootPath('.prettierrc'),
        content: [
          this._devKit.toJson({
            singleQuote: true,
            importOrder: ['^./main$', '<THIRD_PARTY_MODULES>', '^[./]'],
            importOrderSeparation: true,
            importOrderSortSpecifiers: true,
            importOrderParserPlugins: ['typescript', 'decorators-legacy'],
          }),
        ],
      },
      {
        filePath: this._projectFS.makeRootPath('.prettierignore'),
        content: [
          dedent`
			/dist
			/coverage
		  `,
        ],
      },
      {
        filePath: this._projectFS.makeRootPath(
          '.github/workflows/prettier.yml',
        ),
        content: [
          yaml.dump({
            name: 'Format with Prettier',
            on: {
              push: {
                branches: ['main'],
              },
            },
            jobs: {
              format: {
                'runs-on': 'ubuntu-latest',
                steps: [
                  {
                    name: 'Checkout',
                    uses: 'actions/checkout@v3',
                  },
                  {
                    name: 'Install',
                    run: 'npm install --only=dev --no-audit --no-fund',
                  },
                  {
                    name: 'Format',
                    run: './node_modules/.bin/prettier --write .',
                  },
                  {
                    name: 'Push changes',
                    uses: 'stefanzweifel/git-auto-commit-action@v4',
                    with: {
                      commit_message: '"Format with Prettier"',
                      commit_user_name: '"ci"',
                      commit_user_email: '"ci@github.com"',
                    },
                  },
                ],
              },
            },
          }),
        ],
      },
    ];
  }
}
