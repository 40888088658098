import './lib/handlers';
import './lib/handlers/feature-handler';
import * as tableProjection from './lib/table.projection';
import * as tableWorkflow from './lib/vm/feature_workflow.vm';
import * as table from './lib/vm/table.vm';
import * as tableField from './lib/vm/table_field.vm';
import * as tableQuery from './lib/vm/table_query.vm';

export * from './lib/feature.projection';
export * from './lib/feature.projector';
export * from './lib/table.projection';
export * from './lib/table.projector';
export * from './lib/workflow-output.projector';
export namespace TableProjectionNS {
  export type TableProjection = tableProjection.TableProjection;
  export type TableFieldVM = tableField.TableFieldVM;
  export type WorkflowVM = tableWorkflow.IWorkflowVM;
  export type QueryVM = tableQuery.QueryVM;
  export type WorkflowActionVM<
    T extends Record<string, any> = Record<string, any>,
  > = tableWorkflow.IWorkflowActionVM<T>;
  export type FieldValidationVM = tableField.TableFieldVM['validations'][0];
  export type TableVM = table.TableVM;
}
