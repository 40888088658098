import { AutoMap } from '@automapper/classes';
import { IsNotEmpty } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class CommitChangesCommand {
  @IsNotEmpty()
  @AutoMap()
  public message!: string;

  @IsNotEmpty()
  @AutoMap()
  public files!: string;
}
