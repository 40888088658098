import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class ActionNameChangedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  public id!: string;

  @AutoMap()
  @IsUUID()
  public workflowId!: string;

  @AutoMap()
  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  public displayName!: string;
}

export const ActionNameChanged = createEvent<
  ActionNameChangedData,
  'action_name_changed'
>('action_name_changed');
export type ActionNameChanged = InstanceType<typeof ActionNameChanged>;
