import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class TableArchivedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;
}

export const TableArchived = createEvent<TableArchivedData, 'table_archived'>(
  'table_archived',
);
export type TableArchived = InstanceType<typeof TableArchived>;
