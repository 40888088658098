import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';


@AutoMapHost()
export class TagAssignedToWorkflowData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  public featureId!: string;

  @AutoMap()
  @IsUUID()
  public tagId!: string;

  @AutoMap()
  @IsUUID()
  public workflowId!: string;
}

export const TagAssignedToWorkflow = createEvent<
  TagAssignedToWorkflowData,
  'tag_assigned_to_workflow'
>('tag_assigned_to_workflow');
export type TagAssignedToWorkflow = InstanceType<typeof TagAssignedToWorkflow>;