import { v4 } from 'uuid';

import { DomainEventMetadata } from '@faslh/api/infrastructure';
import { EventStore } from '@faslh/api/infrastructure/database';
import { ArchiveTableCommand } from '@faslh/api/table/commands';
import { Table, TableArchivedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(ArchiveTableCommand)
export class DeleteTableCommandHandler extends IRequestHandler<
  ArchiveTableCommand,
  string
> {
  constructor(private readonly _eventStore: EventStore) {
    super();
  }

  async handle(command: ArchiveTableCommand): Promise<string> {
    const metadata: DomainEventMetadata = {
      causationId: '', // should be nothing for all subsequent event
      correlationId: v4(),
    };
    const aggregate = new Table(command.tableId);
    aggregate.archive(
      metadata,
      mapper.map(command, ArchiveTableCommand, TableArchivedData),
    );
    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
