import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddWorkflowCommand } from '@faslh/api/table/commands';
import { Workflow, WorkflowAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddWorkflowCommand)
export default class extends IRequestHandler<AddWorkflowCommand, string> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AddWorkflowCommand): Promise<string> {
    const workflow = Workflow.create(
      command.id ?? v4(),
      this._eventUtils.getMetadata(),
      mapper.map(command, AddWorkflowCommand, WorkflowAddedData),
    );

    await this._eventStore.save(workflow);
    return workflow.id;
  }
}
