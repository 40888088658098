import { Injectable, ServiceLifetime } from 'tiny-injector';

import { EventStore } from '@faslh/api/infrastructure/database';
import {
  AddActionToWorkflowCommand,
  AddWorkflowCommand,
  AssignTagToWorkflowCommand,
  ChangeWorkflowTriggerCommand,
  ConnectAction,
  PatchWorkflowActionDetails,
  PatchWorkflowDetails,
  PatchWorkflowOutputDetailsCommand,
} from '@faslh/api/table/commands';
import { Workflow } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { Mediator } from '@faslh/tiny-mediatr';

import { Features } from './features.sdk';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class Workflows {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _features: Features,
    private readonly _mediator: Mediator,
  ) {}

  public async list(featureId: string) {
    const feature = await this._features.get(featureId);
    return feature.workflows.filter((it) => it.removed !== true);
  }

  public async get(workflowId: string) {
    return this._eventStore.hyrdateAggregate(workflowId, Workflow);
  }

  public async getAction(workflowId: string, sourceId: string) {
    const workflow = await this.get(workflowId);
    if (!workflow) {
      throw new Error(`Workflow ${workflowId} not found`);
    }
    const action = workflow.actions.find((it) => it.id === sourceId);
    if (!action) {
      throw new Error(`Action ${sourceId} not found`);
    }
    return action;
  }

  public addAction(command: AddActionToWorkflowCommand) {
    return this._mediator.send<string>(
      mapper.map(command, AddActionToWorkflowCommand),
    );
  }

  public connectAction(command: ConnectAction) {
    return this._mediator.send<string>(mapper.map(command, ConnectAction));
  }

  public async addWorkflow(command: AddWorkflowCommand, tagId?: string) {
    const workflowId = await this._mediator.send<string>(
      mapper.map(command, AddWorkflowCommand),
    );
    if (tagId) {
      await this.assignTagToWorkflow({
        workflowId,
        tagId,
        featureId: command.featureId,
      });
    }
    return workflowId;
  }

  public async changeWorkflowTrigger(command: ChangeWorkflowTriggerCommand) {
    const workflowId = await this._mediator.send<string>(
      mapper.map(command, ChangeWorkflowTriggerCommand),
    );
    return workflowId;
  }

  public assignTagToWorkflow(command: AssignTagToWorkflowCommand) {
    return this._mediator.send(mapper.map(command, AssignTagToWorkflowCommand));
  }

  public patchWorkflowActionDetails(command: PatchWorkflowActionDetails) {
    return this._mediator.send(mapper.map(command, PatchWorkflowActionDetails));
  }
  public patchWorkflowDetails(command: PatchWorkflowDetails) {
    return this._mediator.send(mapper.map(command, PatchWorkflowDetails));
  }

  public patchWorkflowOutputDetails(
    command: PatchWorkflowOutputDetailsCommand,
  ) {
    return this._mediator.send(
      mapper.map(command, PatchWorkflowOutputDetailsCommand),
    );
  }

  public async getWorkflowAction(workflowId: string, sourceId: string) {
    const workflow = await this.get(workflowId);
    if (!workflow) {
      throw new Error(`Workflow ${workflowId} not found`);
    }
    const action = workflow.actions.find((it) => it.id === sourceId);
    if (!action) {
      throw new Error(`Action ${sourceId} not found`);
    }
    return action;
  }

  public async getWorkflowsActions(featureId: string) {
    const workflows = await this.list(featureId);
    return workflows.map((workflow) => workflow.actions ?? []).flat();
  }
}
