import type { ProjectionV2 } from '@faslh/api/infrastructure/database';
import { createProjection } from '@faslh/api/infrastructure/database';

import type { PolicyVM } from './vm/policy.vm';
import type { TableVM } from './vm/table.vm';
import type { QueryVM } from './vm/table_query.vm';
import type { WorkflowVM } from './vm/table_workflow.vm';

interface ExtensionVM {
  id: string;
  name: string;
  details: Record<string, any>;
}
interface FeatureVM {
  id: string;
  displayName: string;
  deleted: boolean;
}

export interface ChangesProjection extends ProjectionV2 {
  secrets: { id: string; name: string }[];
  extensions: ExtensionVM[];
  tables: TableVM[];
  features: FeatureVM[];

  imports: string[];
  policies: PolicyVM[];
  workflows: WorkflowVM[];
  queries: QueryVM[];
}

export const changesProjection = (
  state?: Omit<ChangesProjection, 'id'>,
): ChangesProjection =>
  createProjection({
    ...(state ?? {
      extensions: [],
      features: [],
      policies: [],
      queries: [],
      tables: [],
      workflows: [],
      secrets: [],
      imports: [],
    }),
  });
