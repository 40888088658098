import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddTagCommand } from '@faslh/api/table/commands';
import { Feature, TagAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddTagCommand)
export default class extends IRequestHandler<AddTagCommand, string> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AddTagCommand): Promise<string> {
    const feature = await this._eventStore.hyrdateAggregate(
      command.featureId,
      Feature,
    );

    const tag = feature.addTag(
      command.id ?? v4(),
      this._eventUtils.getMetadata(),
      mapper.map(command, AddTagCommand, TagAddedData),
    );

    await this._eventStore.save(feature);
    return tag.id;
  }
}
