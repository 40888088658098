export class WorkflowTrigger {
  // workflowId: string;
  sourceId: string;
  details: Record<string, any>;

  constructor(
    public readonly id: string,
    data: Omit<WorkflowTrigger, 'id'>,
  ) {
    // this.workflowId = data.workflowId;
    this.sourceId = data.sourceId;
    this.details = data.details;
  }
}
