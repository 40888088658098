import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class UninstallExtensionCommand extends IRequest<string> {
  @IsUUID()
  @AutoMap()
  public extensionId!: string;
}
