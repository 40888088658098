import txt from './setup.txt';

import dedent from 'dedent';
import { join } from 'path';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';
import * as morph from 'ts-morph';

import { CLAIMS_TOKEN, type Claims } from '@faslh/api/infrastructure/database';
import {
  ConcreteContracts,
  Contracts,
  ParsedInput,
  ProcessTriggerInput,
  ProcessTriggerOutput,
  RoutingExtension,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import {
  addLeadingSlash,
  camelcase,
  snakecase,
  spinalcase,
  toSimple,
} from '@faslh/utils';

import { makeSwaggerSpec } from './swagger';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class KoaJsExtension implements RoutingExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _devKit: DevKit,
    @Inject(CLAIMS_TOKEN) private readonly _claims: Claims,
  ) {}

  public async handleSetup(
    details: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeSrcPath('main.ts'),
        content: [txt],
      },
    ];
  }

  public triggers = ['http'];

  public handleInput(input: string): ParsedInput | null {
    const { namespace, value } = toSimple(input);

    switch (namespace) {
      case 'subject':
        return {
          static: false,
          type: 'string',
          value: `context.subject.${value}`,
          validations: [
            {
              name: 'mandatory',
              type: 'mandatory',
              details: {
                value: 'true',
              },
            },
          ],
        };
      case 'trigger': {
        switch (true) {
          case value.startsWith('body'):
            return {
              static: false,
              type: 'string',
              // value: this._devKit.substring(value, 'body.'),
              value: `(context.request.body as Record<string, any>).${this._devKit.substring(
                value,
                'body.',
              )}`,
            };
          case value.startsWith('path'):
            return {
              static: false,
              type: 'string',
              value: `context.params.${this._devKit.substring(value, 'path.')}`,
              validations: [
                {
                  name: 'mandatory',
                  type: 'mandatory',
                  details: {
                    value: 'true',
                  },
                },
              ],
            };
          case value.startsWith('query'):
            return {
              static: false,
              type: 'string',
              value: `(context.query as Record<string, any>).${this._devKit.substring(
                value,
                'query.',
              )}`,
            };
          case value.startsWith('headers'):
            return {
              static: false,
              type: 'string',
              // value: this._devKit.substring(value, 'headers.'),
              value: `context.headers.${this._devKit.substring(
                value,
                'headers.',
              )}`,
            };
          default:
            return null;
        }
      }
      default:
        return null;
    }
  }

  public onFeatureContract(
    contract: Contracts.FeatureContract,
    addFile: (concrete: ConcreteContracts.WorkflowConcreteStructure) => void,
  ): void {
    const swaggerSpec = makeSwaggerSpec(contract);
    addFile({
      filePath: this._projectFS.makeFeatureFile(
        contract.displayName,
        'swagger.json',
      ),
      structure: [JSON.stringify(swaggerSpec, null, 2)],
    });

    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'router',
      ),
      structure: this.#generateController({
        featureName: contract.displayName,
        tags: contract.tags,
        policies: contract.workflows.map((it) => it.trigger.policies).flat(),
      }),
    });

    addFile({
      filePath: this._projectFS.makeControllerPath(
        contract.displayName,
        'router',
      ),
      structure: [
        {
          kind: morph.StructureKind.VariableStatement,
          declarationKind: morph.VariableDeclarationKind.Const,
          declarations: [
            {
              name: 'router',
              initializer: `new Router({prefix: '/${spinalcase(
                contract.displayName,
              )}'})`,
            },
          ],
        },
        ...(contract.workflows.map((contract) => {
          const displayName = `${camelcase(contract.featureName)}.${camelcase(
            contract.trigger.displayName,
          )}`;
          const path = addLeadingSlash(
            join(contract.trigger.details['path'], snakecase(contract.tag)),
          );
          const verb = `router.${contract.trigger.details['method']}`;
          const authorize = contract.trigger.policies.length
            ? `,authorize(${contract.trigger.policies
                .map((it) => `${camelcase(it)}`)
                .join(',')})`
            : '';

          return `${verb}("${path}" ${authorize}, ${displayName})`;
        }) as ConcreteContracts.MorphStatementWriter),
        {
          kind: morph.StructureKind.ExportAssignment,
          isExportEquals: false,
          expression: 'router',
        },
      ],
    });
  }

  #generateController(contract: {
    featureName: string;
    tags: string[];
    policies: string[];
  }): ConcreteContracts.WorkflowStructure {
    return [
      {
        kind: morph.StructureKind.ImportDeclaration,
        defaultImport: 'Router',
        moduleSpecifier: '@koa/router',
      },
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier: '../../identity',
        namedImports: ['authorize'],
      },
      ...contract.policies.map(
        (it) =>
          ({
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../../identity/${spinalcase(it)}.policy`,
            namedImports: [camelcase(it)],
          }) as morph.ImportDeclarationStructure,
      ),
      // import * as {featureName} from './routes'
      {
        kind: morph.StructureKind.ImportDeclaration,
        moduleSpecifier: `./routes`,
        namespaceImport: camelcase(contract.featureName),
      },
    ];
  }

  async processTrigger(contract: ProcessTriggerInput): ProcessTriggerOutput {
    const displayName = camelcase(contract.displayName);
    const inputName = camelcase('input');
    const outputName = camelcase('output');
    const actionInputsStr = `${this._devKit.toLiteralObject(contract.inputs)}`;
    return [
      {
        filePath: this._projectFS.makeControllerRoutePath(
          contract.featureName,
          contract.displayName,
        ),
        structure: [
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: `../${spinalcase(contract.tag)}`,
            namespaceImport: camelcase(contract.tag),
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: 'lodash-es',
            namedImports: ['pick'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier:
              this._projectFS.makeCoreImportSpecifier('core/validation'),
            namedImports: ['validateOrThrow'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: '@koa/router',
            namedImports: ['RouterContext'],
          },
          {
            kind: morph.StructureKind.ImportDeclaration,
            moduleSpecifier: 'koa',
            namedImports: ['Next'],
          },
          {
            kind: morph.StructureKind.Function,
            isAsync: true,
            isDefaultExport: false,
            isExported: true,
            name: displayName,
            parameters: [
              { name: 'context', type: 'RouterContext' },
              { name: 'next', type: 'Next' },
            ],
            statements: [
              `const ${inputName} = ${actionInputsStr}`,
              (writer) =>
                writer.conditionalWriteLine(
                  actionInputsStr.trim().length > 0,
                  `validateOrThrow(${camelcase(contract.tag)}.${
                    contract.schemaName
                  }, ${inputName})`,
                ),
              // (writer) => writer.writeLine(`context.can({})`),
              (writer) =>
                writer.writeLine(
                  `const ${outputName} = await handleOperation('${contract.operationName}', ${inputName})`,
                ),
              (writer) => writer.writeLine(`context.body = ${outputName}`),
              (writer) => writer.writeLine(`await next()`),
            ],
          },
        ],
      },
    ];
  }

  getServerSetup(): string {
    return dedent`
        import application from './main';
        const host = process.env['HOST'] ?? '0.0.0.0';
        const port = parseInt(process.env['PORT'] || '3000') ;
        application.listen(port, host);
        console.log(\`http://\${host}:\${port}\`);
        	`;
  }
}
