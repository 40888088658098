import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { UninstallExtensionCommand } from '@faslh/api/user-extensions/commands';
import { Extension } from '@faslh/api/user-extensions/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

import './lib/command-handlers/install_extension.handler';
import './lib/command-handlers/patch_extension_details.handler';

@RequestHandler(UninstallExtensionCommand)
export class UninstallExtensionCommandHandler extends IRequestHandler<
  UninstallExtensionCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _devKit: DevKit,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: UninstallExtensionCommand): Promise<string> {
    const sourceExtension = await this._devKit.getExtensionById(
      command.extensionId,
    );

    const aggregate = await this._eventStore.hyrdateAggregate(
      command.extensionId,
      Extension,
    );

    aggregate.delete(this._eventUtils.getMetadata(), {});

    await this._eventStore.save(aggregate);

    return aggregate.id;
  }
}
