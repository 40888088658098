import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { SetSecretCommand } from '@faslh/api/settings/commands';
import { Secret, SecretSetData } from '@faslh/api/settings/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(SetSecretCommand)
export class SetSecretCommandHandler extends IRequestHandler<SetSecretCommand> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: SetSecretCommand): Promise<void> {
    const aggregate = Secret.create(
      this._eventUtils.getMetadata(),
      mapper.map(command, SetSecretCommand, SecretSetData),
    );

    await this._eventStore.save(aggregate);
  }
}
