import { AutoMap } from '@automapper/classes';
import { IsNotEmpty } from 'class-validator';



import {   DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class WorkspaceAddedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  displayName!: string;

  @AutoMap()
  @IsNotEmpty()
  orgId!: string; // FIXME: this shouldn't be here as we're doing thing in tree manner
}

@AutoMapHost()
export class WorkspaceAdded extends DomainEvent<
  WorkspaceAddedData,
  'workspace_added'
> {
  public eventType = 'workspace_added' as const;

  @AutoMap(() => WorkspaceAddedData)
  public data!: WorkspaceAddedData;
}