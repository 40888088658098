import {
  ProjectionV2,
  createOrThrow,
  createProjection,
} from '@faslh/api/infrastructure/database';

import { TableFieldVM } from './vm/table_field.vm';

export interface TableProjection extends ProjectionV2 {
  displayName: string;
  featureId: string;
  isArchived?: boolean;
  fields: TableFieldVM[];
}

export const tableProjection = (
  state?: Omit<TableProjection, 'id'>,
): TableProjection =>
  createProjection({
    ...(state ?? {
      fields: [],
      ...createOrThrow('featureId'),
      ...createOrThrow('displayName'),
    }),
  });
