import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, Matches } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';

export class SecretSetData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  public name!: string;

  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;
}
export class SecretSet extends DomainEvent<SecretSetData, 'secret_set'> {
  public eventType = 'secret_set' as const;

  @AutoMap(() => SecretSetData)
  public data!: SecretSetData;
}
