import {
  Inject,
  Injectable,
  InjectionToken,
  ServiceLifetime,
} from 'tiny-injector';

import { AdminExtensionNS, DevKit } from '@faslh/compiler/sdk/devkit';
import {
  ResolveContext,
  applyCondition,
  buildUrl,
  isCondition,
  isResolvable,
} from '@faslh/utils';

export const DYNAMIC_SOURCE_MAPPER = new InjectionToken<
  (
    featureId: string,
  ) => Record<string, Promise<any> | ((...args: any) => Promise<any>)>
>(`a token to hold dynamic source mapper`);

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class DynamicSource {
  constructor(
    private readonly _devKit: DevKit,
    @Inject(DYNAMIC_SOURCE_MAPPER)
    private readonly _mapper: (
      featureId?: string,
    ) => Record<string, Promise<any> | ((...args: any) => Promise<any>)>,
  ) {}

  #get<T>(
    featureId: string | undefined,
    url: string,
    ...args: any[]
  ): Promise<T> {
    const fetcher = this._mapper(featureId);
    if (!fetcher) {
      throw new Error(`No mapping found ${url}`);
    }

    const obs = fetcher[url];
    if (!obs) {
      throw new Error(`No matching found for url ${url}`);
    }
    if (typeof obs === 'function') {
      return obs(...args);
    }
    return obs as Promise<T>;
  }

  async resolveSource<T = any[]>(
    featureId: string | undefined,
    source: AdminExtensionNS.FieldMetadataSource,
    details: ResolveContext,
  ): Promise<T> {
    let _source = source;
    if (isCondition<AdminExtensionNS.ConditionLogic>(source)) {
      _source = applyCondition(source, details);
    }

    if (!isResolvable(_source)) {
      return _source as any;
    }

    if (Array.isArray(_source)) {
      return _source as any;
    }
    const { url, params } = buildUrl(
      _source.url,
      details,
      _source.binding ?? {},
    );
    if (!url) return [] as any;

    return this.#get<any>(featureId, url, ...params);
  }

  async resolvePrimitiveType(
    featureId: string | undefined,
    primitiveType: AdminExtensionNS.ExtensionField['primitiveType'],
    context: ResolveContext,
    resolver: (primitiveType: any) => any,
  ) {
    if (!primitiveType) {
      return '';
    }

    if (typeof primitiveType === 'string') {
      return this._devKit.typesResolverMap[primitiveType] || primitiveType;
    }

    if (isCondition<AdminExtensionNS.ConditionLogic>(primitiveType)) {
      return applyCondition(primitiveType, context);
    }
    const maybeThisWillWork = resolver(primitiveType);

    if (isCondition<AdminExtensionNS.ConditionLogic>(maybeThisWillWork)) {
      return applyCondition(maybeThisWillWork, context);
    }
    return this.resolveSource(featureId, maybeThisWillWork, context);
  }
}
