import { Injectable, ServiceLifetime } from 'tiny-injector';

import { EventStore } from '@faslh/api/infrastructure/database';
import {
  AddFieldCommand,
  AddTableCommand,
  AdjustFieldValidationCommand,
  UpdateFieldDetailsCommand,
} from '@faslh/api/table/commands';
import { Table, TableField } from '@faslh/api/table/domain';
import { AdminExtensionNS, DevKit } from '@faslh/compiler/sdk/devkit';
import { DynamicSource } from '@faslh/compiler/sdk/dynamic-source';
import { mapper } from '@faslh/isomorphic';
import { Mediator } from '@faslh/tiny-mediatr';

import { Features } from './features.sdk';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class Tables {
  constructor(
    private readonly _features: Features,
    private readonly _mediator: Mediator,
    private readonly _eventStore: EventStore,
    private readonly _dynamicSource: DynamicSource,
    private readonly _devKit: DevKit,
  ) {}

  public async get(tableId: string) {
    const table = await this._eventStore.hyrdateAggregate(tableId, Table);
    if (table.empty) {
      throw new Error(`Table ${tableId} not found`);
    }
    return table;
  }

  public async addTable(command: AddTableCommand): Promise<string> {
    return this._mediator.send(mapper.map(command, AddTableCommand));
  }

  public async addField(command: AddFieldCommand): Promise<string> {
    return this._mediator.send(mapper.map(command, AddFieldCommand));
  }

  public async updateFieldDetails(
    command: UpdateFieldDetailsCommand,
  ): Promise<string> {
    return this._mediator.send(mapper.map(command, UpdateFieldDetailsCommand));
  }

  async getTableField(tableId: string, id: string) {
    const table = await this.get(tableId);
    const field = table.fields.find((it) => it.id === id);
    if (!field) {
      throw new Error(`Field ${id} not found`);
    }
    return field;
  }

  async resolvePrimitiveType(
    field: TableField,
    sourceField?: AdminExtensionNS.ExtensionField,
  ) {
    sourceField ??= await this._devKit.getSourceFieldById(field.sourceId);
    return await this._dynamicSource.resolvePrimitiveType(
      undefined,
      sourceField.primitiveType,
      {
        context: field.details,
        self: field,
      },
      (primitiveType) => primitiveType.primitiveType,
    );
  }

  public async adjustFieldValidationCommand(
    command: AdjustFieldValidationCommand,
  ): Promise<string> {
    return this._mediator.send(
      mapper.map(command, AdjustFieldValidationCommand),
    );
  }
}
