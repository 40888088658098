import { Context, Inject, Injectable } from 'tiny-injector';

import { AbstractStore, Filter } from '../abstract.store';
import { SQLITE_TOKEN, type SqliteOperations } from './sqlite.token';

@Injectable()
export class TursoStore extends AbstractStore {
  private _queue: Record<string, [() => unknown, unknown]> = {};

  constructor(
    @Inject(SQLITE_TOKEN) public db: SqliteOperations,
    protected override context: Context,
  ) {
    super();
  }

  public override async find(filter: Filter): Promise<any> {
    let query = `SELECT * FROM ${filter.tableName}`;
    const params: any = [];
    const where = filter.where;
    if (!where) {
      return await this.db.query({
        sql: query,
        params: params,
      });
    }
    const entries = Object.entries(where);
    if (entries.length) {
      query += ' WHERE ';
      query += entries
        .map(([key, value]) => {
          if (key.includes('.')) {
            const [part1, part2] = key.split('.'); // metadata.projectId
            params.push(value);
            return `json_extract(${part1}, '$.${part2}') = ?`;
          }
          return `${key} = '${value}'`;
        })
        .join(' AND ');
    }
    query += ' ORDER BY timestamp ';
    if (filter.orderBy?.timestamp) {
      query += `${filter.orderBy.timestamp || 'ASC'}`;
    }
    if (filter.limit) {
      query += ` LIMIT ${filter.limit}`;
    }
    return await this.db.query({
      sql: query,
      params: params,
    });
  }

  public override hold<T extends { id: string }>(
    path: string,
    operation: () => unknown,
    data: T,
  ): void {
    this._queue[path] = [operation, data];
  }

  public getPendingDocs() {
    return Object.values(this._queue).map(([, doc]) => doc);
  }

  public get(path: string, force = false): any {
    const pendingDocument = this._queue[path];
    if (pendingDocument) {
      return pendingDocument;
    }
    return null;
  }

  clearPending() {
    this._queue = {};
  }

  public override async commit(): Promise<void> {
    const pending = Object.values(this._queue);
    if (!pending.length) {
      return Promise.resolve(void 0);
    }
    await this.db.transaction(pending);

    this.clearPending();
  }
}
