import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class SetRepositoryCommand extends IRequest {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  repositoryName!: string;

  @AutoMap()
  @IsNotEmpty()
  repositoryId!: number;
}
