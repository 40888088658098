import type { ChangesProjection } from '@faslh/api/releases/projection';

import { IRequest } from '@faslh/tiny-mediatr';

export class BuildCommit extends IRequest {
  constructor(
    public repoPath?: string,
    public changes?: ChangesProjection,
    public imports?: string[],
  ) {
    super();
  }
}
