import type * as actionVM from './lib/models/action';
import type * as extensionsVM from './lib/models/extensions';
import type * as fieldVM from './lib/models/field';
import type * as operatorVM from './lib/models/operators';
import type * as triggerVM from './lib/models/trigger';
import type * as validationVM from './lib/models/validation';

export * from './lib/devkit';
export * from './lib/project-config';
export * from './lib/project-fs';

export namespace AdminExtensionNS {
  export type FieldMetadata = extensionsVM.FieldMetadata;
  export type FieldMetadataSource = extensionsVM.FieldMetadataSource;
  export type FieldSourceArray = extensionsVM.FieldSourceArray;
  export type FieldSourceDynamic = extensionsVM.FieldSourceDynamic;
  export type PrimitiveTypeName = fieldVM.PrimitiveTypeName;
  export type PrimitiveTypeSource = fieldVM.PrimitiveTypeSource;
  export type ConditionLogic = fieldVM.ConditionLogic;
  export type ExtensionVM = extensionsVM.ExtensionVM;
  export type IAction = actionVM.IAction;
  export type ITrigger = triggerVM.ITrigger;
  export type ExtensionValidation = validationVM.ExtensionValidation;
  export type Operator = operatorVM.Operator;
  export type ExtensionField = fieldVM.ExtensionField;
}
