import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class TableIndexCreateData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  tableId!: string;

  @AutoMap(() => Object)
  details!: { columns: { input: string }[] };
}

export const TableIndexCreated = createEvent<
  TableIndexCreateData,
  'table_index_created'
>('table_index_created');
export type TableIndexCreated = InstanceType<typeof TableIndexCreated>;
