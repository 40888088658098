import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import {
  DomainEventData,
  ToPrimitive,
  createEvent,
} from '@faslh/api/infrastructure';
import { AutoMapHost, FieldValidation } from '@faslh/isomorphic';

@AutoMapHost()
export class FieldValidationAdjustedData extends DomainEventData {
  @IsUUID()
  @AutoMap()
  tableId!: string;

  @AutoMap(() => [FieldValidation])
  validations!: FieldValidation[];

  override toJson(): ToPrimitive<FieldValidationAdjustedData> {
    return {
      tableId: this.tableId,
      validations: this.validations.map((it) => ({
        sourceId: it.sourceId,
        details: it.details,
        name: it.name,
      })),
    };
  }
}
export const FieldValidationAdjusted = createEvent<
  FieldValidationAdjustedData,
  'field_validation_adjusted'
>('field_validation_adjusted');
export type FieldValidationAdjusted = InstanceType<
  typeof FieldValidationAdjusted
>;
