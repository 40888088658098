import { InjectionToken, ServiceType } from 'tiny-injector';

import { IExtension, IHandleAction } from '@faslh/compiler/contracts';

import { AjaxExtension } from '../ajax/ajax.extension';
import { CoreExtension } from '../core/core.extension';
import { FirebaseAuthExtension } from '../firebase/auth/firebase-auth.extension';
import { FirebaseFunctionsExtension } from '../firebase/firebase-functions/firebase-functions.extension';
import { FlyExtension } from '../fly/fly.extension';
import { GithubExtension } from '../github/github.extension';
import { GoogleStorageExtension } from '../google/google-storage.extension';
import { HonoExtension } from '../hono/hono.extension';
import { IdentityExtension } from '../identity/identity.extension';
import { KoaJsExtension } from '../koajs/koajs.extension';
import { PostgreSQLExtension } from '../postgresql/postgresql.extension';
import { PrettierExtension } from '../prettier/prettier.extension';
import { ResendExtension } from '../resend/resend.extension';
import { VercelExtension } from '../vercel/vercel.extension';
import { NodeCronExtension } from '../node-cron/node-cron.extension';

export type ExtensionRegistry = Record<string, ServiceType<IExtension>>;
export const extensionsRegistry: ExtensionRegistry = {
  'firebase-functions': FirebaseFunctionsExtension,
  'google-cloud-storage': GoogleStorageExtension,
  prettier: PrettierExtension,
  postgresql: PostgreSQLExtension,
  koajs: KoaJsExtension,
  hono: HonoExtension,
  fly: FlyExtension,
  vercel: VercelExtension,
  resend: ResendExtension,
  ajax: AjaxExtension,
  core: CoreExtension,
  identity: IdentityExtension,
  'firebase-auth': FirebaseAuthExtension,
  github: GithubExtension,
  'node-cron': NodeCronExtension,
};

export const actionHandlers: Record<string, ServiceType<IHandleAction>> = {
  'google-cloud-storage': GoogleStorageExtension,
  postgresql: PostgreSQLExtension,
  resend: ResendExtension,
  ajax: AjaxExtension,
  core: CoreExtension,
};
export const EXTENISION_REGISTRY_TOKEN = new InjectionToken<ExtensionRegistry>(
  'EXTENISION_REGISTRY_TOKEN',
);

export const ACTION_HANDLER_REGISTRY_TOKEN = new InjectionToken<
  Record<string, ServiceType<IHandleAction>>
>('ACTION_HANDLER_REGISTRY_TOKEN');
