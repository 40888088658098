import EventEmitter, { once } from 'events';
import { Injectable, ServiceLifetime } from 'tiny-injector';
import type TypedEventEmitter from 'typed-emitter';
import { v4 } from 'uuid';

/**
 * @deprecated use native DomainEvent or create Integration Event that uses
 * mediatr along with SDK instead of using hook manager
 */
@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class HookManager {
  #handlers: Map<(...args: any[]) => any, (...args: any[]) => any> = new Map();
  #em = new EventEmitter({
    captureRejections: true,
  }) as TypedEventEmitter<HookManagerEvents>;

  off<E extends keyof HookManagerEvents>(
    eventName: E,
    callback: (...args: Parameters<HookManagerEvents[E]>) => void,
  ) {
    const handler = this.#handlers.get(callback);
    if (!handler) {
      throw new Error('Handler not found');
    }
    this.#em.off(eventName, handler);
  }

  on<E extends keyof HookManagerEvents>(
    eventName: E,
    callback: (...args: Parameters<HookManagerEvents[E]>) => Promise<void>,
  ) {
    const handler = async (...args: any) => {
      const config = args.pop();
      try {
        await callback(...args);
        config.end();
      } catch (error) {
        config.end(error);
      }
    };
    this.#handlers.set(callback, handler);
    this.#em.on(eventName, handler);
  }

  async emit<E extends keyof HookManagerEvents>(
    eventName: E,
    ...args: Parameters<HookManagerEvents[E]>
  ) {
    return new Promise<any>((resolve, reject) => {
      let emitCount = 0;
      const listenerCount = this.#em.listenerCount(eventName);
      if (listenerCount === 0) {
        return resolve(void 0);
      }
      const operationId: any = v4();
      this.#em.emit(
        eventName,
        ...([
          ...args,
          {
            end: (error: any) => {
              if (error) {
                console.log(
                  `Error processing operation: ${operationId} Error: ${error}`,
                );
                this.#em.removeAllListeners(operationId);
                return reject(error);
              }

              emitCount++;
              if (listenerCount === emitCount) {
                this.#em.emit(operationId);
                this.#em.removeAllListeners(operationId);
              }
            },
          },
        ] as any),
      );

      once(this.#em, operationId).then(resolve).catch(reject);
    });
  }
}

export type HookManagerEvents = {
  'system:extension_installed': (helpers: { sourceId: string }) => void;
  'system:field_added': (helpers: {
    fieldName: string;
    tableName: string;
    fieldId: string;
    tableId: string;
    featureId: string;
    sourceId: string;
    details: Record<string, any>;
  }) => void;
  'system:table_added': (helpers: {
    featureId: string;
    tableName: string;
    tableId: string;
    details: Record<string, any>;
  }) => void;
};
