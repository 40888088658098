import { Injectable, ServiceType } from 'tiny-injector';

import {
  INotification,
  INotificationHandler,
  IRequest,
  Mediator,
} from '@faslh/tiny-mediatr';

@Injectable()
export class EventMediator extends Mediator {
  #requests = new Map<ServiceType<any>, IRequest<any>>();

  override send<TResponse>(request: IRequest<TResponse>): Promise<TResponse> {
    this.#requests.set(request.constructor, request);
    return super.send(request);
  }

  protected override async publishCore<TNotification extends INotification>(
    handlers: INotificationHandler<TNotification>[],
    notification: TNotification,
  ): Promise<void> {
    // TODO: try to push the notifications outside of this stack trace.
    await Promise.all(handlers.map((handler) => handler.handle(notification)));
    return void 0;
  }

  getRequest<T>(type: ServiceType<T>): T {
    const request = this.#requests.get(type);
    if (!request) {
      throw new Error(`Request not found: ${type.name}`);
    }
    return request as T;
  }
}
