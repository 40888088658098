import ajvText from './ajv.txt';

import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import {
  ActionProperty,
  ConcreteContracts,
  Contracts,
  HandleActionInput,
  HandleActionOutput,
  HandleFieldInput,
  IExtension,
  IHandleAction,
  IncomingActionProperty,
  ParsedInput,
  ProcessActionHelpers,
  SwitchCase,
  WithMyInput,
  createIfElse,
  createSwitch,
} from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { BranchPath } from '@faslh/isomorphic';
import {
  Arg,
  AsyncVisitor,
  Binary,
  Call,
  Expression,
  Namespace,
  PropertyAccess,
  StringAsyncVisitor,
  StringVisitor,
  camelcase,
  parseDsl,
} from '@faslh/utils';

import { AbstractInputParser } from '../registry/abstract-input-parser';
import { ContextAwareVisitor } from '../registry/input-parser';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class CoreExtension implements WithMyInput, IExtension, IHandleAction {
  constructor(
    private readonly _devKit: DevKit,
    private readonly _context: Context,
    private readonly _projectFS: ProjectFS,
  ) {}

  async handleSetup?(
    contract: Record<string, ActionProperty>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    return [
      {
        filePath: this._projectFS.makeCorePath('validation.ts'),
        content: [ajvText],
      },
    ];
  }

  public handleInput(input: string): Promise<ParsedInput | null> {
    const visitor = new SimpleVisitor();
    const { namespace, value } = visitor.visit(parseDsl(input)) as {
      namespace: string;
      value: Expression;
    };
    return this.handleInputV2({ namespace, value });
  }
  public async handleInputV2(detail: {
    value: Expression;
    namespace: string;
  }): Promise<ParsedInput | null> {
    const visitor = new CoreInputVisitor();
    switch (true) {
      case detail.namespace.startsWith('process'): {
        const result = await visitor.visit(detail.value);
        return {
          type: 'string',
          static: true,
          value: `process.${result}`,
        };
      }
      case detail.namespace.startsWith('build'): {
        const result = await visitor.visit(detail.value);
        return {
          type: 'string',
          static: true,
          // we might need to introduce "secrets" and "env" wheres
          // secrets refer to github/gitlab secrets and env refers to
          // env in workflow scope.
          value: this._devKit.substring(result, 'secrets.'),
        };
      }
      case detail.namespace.startsWith('fixed'): {
        const result = await visitor.visit(detail.value);
        return {
          type: 'string',
          value: result,
          static: true,
        };
      }
      case detail.namespace.startsWith('path'): {
        const result = await visitor.visit(detail.value);
        return {
          type: 'string',
          value: 'result',
          data: {
            parameterName: result,
            local: true,
          },
          static: true,
        };
      }
      case detail.namespace.startsWith('workflow'):
        const result = await visitor.visit(detail.value);
        return {
          type: 'string',
          value: result,
          data: {
            parameterName: result,
            local: true,
          },
          static: true,
        };
      default:
        return null;
    }
  }
  public async extractInputs({
    namespace,
    value,
    visit,
  }: {
    value: Expression;
    namespace: string;
    visit: AsyncVisitor<unknown>;
  }): Promise<Record<string, string> | null> {
    return null;
  }
  async #evaulate(
    paths: BranchPath[],
    action: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
  ) {
    const emits: ConcreteContracts.MorphStatementWriter[] = [];
    const visitor = new ContextAwareVisitor(this._context);
    const cases: SwitchCase[] = [];
    const fallbackCase: SwitchCase = { condition: '', logic: '' };
    const pathsNames: string[] = [];
    for (const path of paths) {
      const realtedAction = action.children.find(
        (it) => it.data['handle'] === path.id,
      );
      const result = realtedAction
        ? helpers.resolveAction(realtedAction.id)
        : '';
      if (path.name === 'fallback') {
        fallbackCase.logic = result || '';
      } else {
        const evaluatedRule = await visitor.start(parseDsl(path.rule), {
          emit: (input: ConcreteContracts.MorphStatementWriter) => {
            emits.push(input);
          },
        });
        const name = camelcase(path.name.replace(' ', '_'));
        pathsNames.push(`const ${name} = ${evaluatedRule};`);
        cases.push({
          condition: name,
          logic: result || '',
        });
      }
    }
    return { emits, cases, fallbackCase, pathsNames };
  }

  async processAction(
    action: Contracts.WorkflowAction,
    helpers: ProcessActionHelpers,
    runtimeInputs: Record<string, ActionProperty>,
    transfer: Record<string, ActionProperty>,
  ): Promise<ConcreteContracts.ProcessActionOutput> {
    switch (action.sourceAction.name) {
      case 'branch':
        const { cases, fallbackCase, emits, pathsNames } = await this.#evaulate(
          transfer['paths'] as unknown as BranchPath[],
          action,
          helpers,
        );
        const useSwitch = cases.length > 1;
        return {
          inline: !useSwitch,
          structure: {
            topLevelStructure: emits.flat(),
            actionStructure: [
              ...pathsNames,
              ...(useSwitch
                ? createSwitch(cases, fallbackCase)
                : createIfElse(cases[0], fallbackCase)),
            ],
          },
        };
      case 'custom-code':
        return {
          inline: !!transfer['inline'],
          structure: {
            topLevelStructure: [...((transfer['structures'] ?? []) as any)],
            actionStructure: [transfer['code'] as any],
          },
        };
      default:
        throw new Error(`Action ${action.sourceAction.name} is not supported`);
    }
  }

  async handleAction(contract: HandleActionInput): Promise<HandleActionOutput> {
    switch (contract.sourceAction.name) {
      case 'branch':
        const inputParser = Injector.GetRequiredService(
          AbstractInputParser,
          this._context,
        );

        const paths = (contract.details['paths'] as Array<BranchPath>).filter(
          (it) => it.name !== 'fallback',
        );
        let inputs: Record<string, IncomingActionProperty> = {};

        for (const path of paths) {
          const result = await inputParser.toInputs(path.rule);
          inputs = {
            ...inputs,
            ...result,
          };
        }

        return {
          transfer: {
            ...(contract.details as any),
          },
          runtimeInputs: inputs,
          output: {
            displayName: '',
          },
        };
      case 'custom-code':
        return {
          transfer: {
            code: contract.details['code'],
            inline: contract.details['inline'],
            structures: contract.details['structures'],
          },
          runtimeInputs: contract.details['inputs'],
          output: {
            displayName: '',
          },
        };
      default:
        throw new Error(
          `Action ${contract.sourceAction.name} is not supported`,
        );
    }
  }

  public async handleField(
    input: HandleFieldInput,
  ): Promise<Contracts.InputFieldUnion> {
    throw new Error('Method not implemented.');
  }
}

class CoreInputVisitor extends StringAsyncVisitor {
  override visitCall(node: Call): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override async visitPropertyAccess(node: PropertyAccess): Promise<any> {
    const accessor = await node.name.accept(this);
    const accessee = await node.expression.accept(this);
    return `${accessor}.${accessee}`;
  }
  override visitBinary(node: Binary): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visitNamespace(node: Namespace): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visitArg(node: Arg): Promise<any> {
    throw new Error('Method not implemented.');
  }
  override visit(node: Expression): Promise<any> {
    return node.accept(this);
  }
}

class SimpleVisitor extends StringVisitor {
  override visitArg(node: Arg): any {
    throw new Error('Method not implemented.');
  }
  override visitBinary(propertyAccess: Binary): any {
    throw new Error('Method not implemented.');
  }
  override visitCall(node: Call): any {
    return node.toLiteral(this);
  }
  override visitPropertyAccess(node: PropertyAccess): any {
    return node.toLiteral(this);
  }
  override visitNamespace(node: Namespace): any {
    return {
      namespace: node.name.accept(this),
      value: node.expression,
    };
  }
}
