import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class ProjectAddedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  orgId!: string;

  @AutoMap()
  @IsNotEmpty()
  workspaceId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  displayName!: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  code?: string;
}

@AutoMapHost()
export class ProjectAdded extends DomainEvent<
  ProjectAddedData,
  'project_added'
> {
  public eventType = 'project_added' as const;

  @AutoMap(() => ProjectAddedData)
  public data!: ProjectAddedData;
}
