import { ToPrimitive } from '@faslh/api/infrastructure';

export class FeatureTag {
  id: string;
  displayName: string;

  constructor(props: ToPrimitive<FeatureTag>) {
    this.id = props.id;
    this.displayName = props.displayName;
  }
}
