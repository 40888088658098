import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class ChangesCommittedData extends DomainEventData {
  @IsNotEmpty()
  @IsString()
  @AutoMap()
  public message!: string;
}

export const ChangesCommitted = createEvent<
  ChangesCommittedData,
  'changes_committed'
>('changes_committed');
export type ChangesCommitted = InstanceType<typeof ChangesCommitted>;
