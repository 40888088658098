import { v4 } from 'uuid';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { AddTableCommand } from '@faslh/api/table/commands';
import { Table, TableAddedData } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(AddTableCommand)
export class AddTableCommandHandler extends IRequestHandler<
  AddTableCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: AddTableCommand): Promise<string> {
    // const events = await this._eventStore.getEventsForAggregate(
    //   command.featureId
    // );
    // const feature = new Feature(command.featureId);
    // feature.replayEvents(events);

    // if (feature.tableNameExist(command.displayName)) {
    //   throw new Error(`Table name ${command.displayName} already exist`);
    // }

    // const table = feature.addTable(
    //   this._eventUtils.getMetadata(),
    //   mapper.map(command, AddTableCommand, TableAddedData)
    // );

    // await this._eventStore.save(table);
    // await this._eventStore.save(feature);
    // return table.id;

    const tableId = command.id ?? v4();
    const aggregate = Table.create(
      tableId,
      this._eventUtils.getMetadata(),
      mapper.map(command, AddTableCommand, TableAddedData),
    );
    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
