import { dirname, extname, join, relative, sep } from 'path';
import { Injectable, Injector, ServiceLifetime } from 'tiny-injector';
import type * as morph from 'ts-morph';
import {
  ModuleKind,
  ModuleResolutionKind,
  Project,
  ScriptTarget,
  StructureKind,
  SyntaxKind,
} from 'ts-morph';

import type { ConcreteContracts } from '@faslh/compiler/contracts';
import { ProjectConfig, ProjectFS } from '@faslh/compiler/sdk/devkit';
import { addLeadingSlash, camelcase } from '@faslh/utils';

// FIXME: should be fetched from the project preferences
const tsConfig = {
  compilerOptions: {
    sourceMap: true,
    target: 'ESNext',
    module: 'esnext',
    moduleResolution: 'node',
    declaration: false,
    types: ['node'],
    removeComments: true,
    strict: true,
    inlineSources: true,
    sourceRoot: '/',
    allowSyntheticDefaultImports: true,
    esModuleInterop: true,
    experimentalDecorators: true,
    emitDecoratorMetadata: true,
    importHelpers: true,
    noEmitHelpers: true,
    resolveJsonModule: true,
    skipLibCheck: true,
    skipDefaultLibCheck: true,
  },
};

function getMorph(generateDir?: string) {
  const options: morph.ProjectOptions = {
    compilerOptions: {
      ...tsConfig.compilerOptions,
      module: ModuleKind.ESNext,
      moduleResolution: ModuleResolutionKind.NodeJs,
      target: ScriptTarget.ESNext,
    },
    skipFileDependencyResolution: false,
    skipAddingFilesFromTsConfig: true,
    useInMemoryFileSystem: !generateDir,
  };

  return new Project(options);
}

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class VirtualProject {
  #morphProject!: morph.Project;

  constructor(private _projectFS: ProjectFS) {}

  #resolveImports() {
    for (const sourceFile of this.#morphProject.getSourceFiles()) {
      const imports = sourceFile.getImportDeclarations();
      for (const it of imports) {
        let moduleSpecifier = it.getModuleSpecifierValue();
        if (!moduleSpecifier.startsWith('#{')) {
          continue;
        }

        switch (true) {
          case moduleSpecifier.startsWith('#{relative}'): {
            const filePath = moduleSpecifier.replace('#{relative}/', '');

            moduleSpecifier = relative(
              dirname(addLeadingSlash(sourceFile.getFilePath())),
              addLeadingSlash(filePath),
            );

            // if file is in the same directory, add ./ to the path
            if (!moduleSpecifier.startsWith('.')) {
              moduleSpecifier = './' + moduleSpecifier;
            }

            it.setModuleSpecifier(moduleSpecifier);
            break;
          }
          case moduleSpecifier.startsWith('#{entity}'): {
            const entityName = moduleSpecifier.replace('#{entity}/', '');
            const files = this.#morphProject.getSourceFiles(
              '/**/src/features/**/*.entity.ts',
            );
            const filePath = this.#findClassSourceFile(files, entityName);
            if (!filePath) {
              throw new Error(`Entity ${entityName} file not found.`);
            }

            moduleSpecifier = relative(
              dirname(addLeadingSlash(sourceFile.getFilePath())),
              addLeadingSlash(filePath),
            );

            // if file is in the same directory, add ./ to the path
            if (!moduleSpecifier.startsWith('.')) {
              moduleSpecifier = './' + moduleSpecifier;
            }

            it.setModuleSpecifier(moduleSpecifier);
            break;
          }
        }
      }
    }
  }

  #findClassSourceFile(
    files: morph.SourceFile[],
    className: string,
  ): string | null {
    for (const sourceFile of files) {
      const classDeclaration = sourceFile.getClass(className);
      if (classDeclaration) {
        // remove leading slash and file ext
        const filePath = sourceFile.getFilePath();
        const extName = extname(filePath);
        const noExt = filePath.slice(0, -extName.length);
        return noExt;
      }
    }

    // If the class wasn't found or wasn't imported, return null
    return null;
  }

  #exportRoutes() {
    // look for files that ends with .router
    const routerFiles = this.#morphProject.getSourceFiles(
      this._projectFS.routersGlob(),
    );
    const imports: string[] = [];
    const exportDefaults = [];
    for (const routerFile of routerFiles) {
      const fileName = routerFile.getBaseName();
      const defaultImportName = camelcase(fileName.replace('.ts', ''));
      imports.push(
        `import ${defaultImportName} from './${getLastNParts(
          routerFile.getFilePath(),
          2,
          false,
        )}'`,
      );
      exportDefaults.push(defaultImportName);
    }

    // create routes.ts file
    this.#morphProject.createSourceFile(
      this._projectFS.makeFeaturePath('routes.ts'),
      `import { Hono } from 'hono';\n${imports.join('\n')}\n\nexport default [${exportDefaults.join(', ')}] as [string, Hono][]`,
      { overwrite: true },
    );
  }
  #exportListeners() {
    // look for files that ends with .router
    const files = this.#morphProject.getSourceFiles(
      this._projectFS.listenersGlob(),
    );
    const imports: string[] = [];
    const exportDefaults = [];
    for (const routerFile of files) {
      const fileName = routerFile.getBaseName();
      const defaultImportName = camelcase(fileName.replace('.ts', ''));
      imports.push(
        `import './${getLastNParts(routerFile.getFilePath(), 2, false)}'`,
      );
      exportDefaults.push(defaultImportName);
    }

    // create listeners.ts file
    this.#morphProject.createSourceFile(
      this._projectFS.makeFeaturePath('listeners.ts'),
      `${imports.join('\n')}`,
      { overwrite: true },
    );
  }

  #exportJobs() {
    // look for files that ends with .job
    const files = this.#morphProject.getSourceFiles(
      this._projectFS.cronsGlob(),
    );
    const imports: string[] = [];
    const exportDefaults = [];
    for (const routerFile of files) {
      const fileName = routerFile.getBaseName();
      const defaultImportName = camelcase(fileName.replace('.ts', ''));
      imports.push(
        `import './${getLastNParts(routerFile.getFilePath(), 2, false)}'`,
      );
      exportDefaults.push(defaultImportName);
    }

    // create crons.ts file
    this.#morphProject.createSourceFile(
      this._projectFS.makeFeaturePath('crons.ts'),
      `${imports.join('\n')}`,
      { overwrite: true },
    );
  }

  #exportEntites() {
    // look for files that ends with .entites
    const routerFiles = this.#morphProject.getSourceFiles(
      this._projectFS.entitiesGlob(),
    );
    const imports: string[] = [];
    const exportDefaults = [];
    for (const entityFiles of routerFiles) {
      const fileName = entityFiles.getBaseName();
      const defaultImportName = camelcase(fileName.replace('.ts', ''));
      imports.push(
        `import ${defaultImportName} from './${getLastNParts(
          entityFiles.getFilePath(),
          2,
          false,
        )}'`,
      );
      exportDefaults.push(defaultImportName);
    }

    // create routes.ts file
    this.#morphProject.createSourceFile(
      this._projectFS.makeFeaturePath('entites.ts'),
      `${imports.join('\n')}\n\nexport default [${exportDefaults.join(', ')}]`,
      { overwrite: true },
    );
  }

  #tuneImports(file: morph.SourceFile) {
    const imports = file.getImportDeclarations();
    const uniqueImports: Record<
      string,
      {
        namedImports: Map<string, morph.ImportSpecifierStructure>;
        assertElements: Map<string, morph.AssertEntryStructure>;
        defaultImport?: string | undefined;
        namespaceImport?: string | undefined;
      }
    > = {};
    const uniqueTypeImports: Record<
      string,
      {
        namedImports: Map<string, morph.ImportSpecifierStructure>;
        defaultImport?: string | undefined;
        namespaceImport?: string | undefined;
      }
    > = {};

    for (const importDeclaration of imports.filter((it) => it.isTypeOnly())) {
      const moduleSpecifierValue = importDeclaration.getModuleSpecifierValue();
      uniqueTypeImports[moduleSpecifierValue] ??= {
        namedImports: new Map(),
        defaultImport: undefined,
        namespaceImport: undefined,
      };

      uniqueTypeImports[moduleSpecifierValue].defaultImport = importDeclaration
        .getDefaultImport()
        ?.getText();
      uniqueTypeImports[moduleSpecifierValue].namespaceImport =
        importDeclaration.getNamespaceImport()?.getText();

      importDeclaration.getNamedImports().forEach((item) => {
        uniqueTypeImports[moduleSpecifierValue].namedImports.set(
          item.getName(),
          item.getStructure(),
        );
      });
    }

    for (const importDeclaration of imports.filter((it) => !it.isTypeOnly())) {
      const moduleSpecifierValue = importDeclaration.getModuleSpecifierValue();

      uniqueImports[moduleSpecifierValue] ??= {
        namedImports: new Map(),
        assertElements: new Map(),
        defaultImport: undefined,
        namespaceImport: undefined,
      };

      uniqueImports[moduleSpecifierValue].defaultImport = importDeclaration
        .getDefaultImport()
        ?.getText();
      uniqueImports[moduleSpecifierValue].namespaceImport = importDeclaration
        .getNamespaceImport()
        ?.getText();

      importDeclaration.getNamedImports().forEach((item) => {
        if (item.isTypeOnly()) {
          uniqueTypeImports[moduleSpecifierValue] ??= {
            namedImports: new Map(),
          };
          uniqueTypeImports[moduleSpecifierValue].namedImports.set(
            item.getName(),
            item.getStructure(),
          );
        } else {
          uniqueImports[moduleSpecifierValue].namedImports.set(
            item.getName(),
            item.getStructure(),
          );
        }
      });
      importDeclaration
        .getAssertClause()
        ?.getElements()
        .forEach((item) => {
          uniqueImports[moduleSpecifierValue].assertElements.set(
            item.getName(),
            item.getStructure(),
          );
        });
    }

    imports.forEach((it) => {
      it.remove();
    });

    Object.entries(uniqueImports).forEach(([moduleSpecifier, it]) => {
      file.addImportDeclaration({
        kind: StructureKind.ImportDeclaration,
        moduleSpecifier: moduleSpecifier,
        namedImports: Array.from(it.namedImports.values()),
        assertElements: Array.from(it.assertElements.values()),
        defaultImport: it.defaultImport,
        isTypeOnly: false,
        namespaceImport: it.namespaceImport,
      });
    });
    Object.entries(uniqueTypeImports).forEach(([moduleSpecifier, it]) => {
      file.addImportDeclaration({
        kind: StructureKind.ImportDeclaration,
        moduleSpecifier: moduleSpecifier,
        namedImports: Array.from(it.namedImports.values()),
        assertElements: [],
        defaultImport: it.defaultImport,
        isTypeOnly: true,
        namespaceImport: it.namespaceImport,
      });
    });
  }

  #removeUnusedImports(file: morph.SourceFile) {
    const imports = file.getImportDeclarations();
    for (const importDeclaration of imports) {
      const isInjectImport = !importDeclaration.getImportClause();
      const isNamespaceImport = importDeclaration.getNamespaceImport();
      const defaultImport = importDeclaration.getDefaultImport();

      if (isInjectImport || isNamespaceImport || defaultImport) {
        continue;
      }

      const namedImports = importDeclaration.getNamedImports();
      for (const namedImport of namedImports) {
        const importedName = namedImport.getName();

        const isUsed = file
          .getDescendantsOfKind(SyntaxKind.Identifier)
          .some(
            (it) =>
              it.getText() === importedName && it.getParent() !== namedImport,
          );

        if (isUsed) {
          continue;
        }

        namedImport.remove();
      }
      if (!importDeclaration.getNamedImports().length) {
        importDeclaration.remove();
      }
    }
  }

  #moveImportsToTop(file: morph.SourceFile) {
    const imports = file.getImportDeclarations();
    imports.forEach((it, index) => {
      file.insertImportDeclaration(index, {
        moduleSpecifier: it.getModuleSpecifierValue(),
        namespaceImport: it.getNamespaceImport()?.getText(),
        namedImports: it.getNamedImports().map((namedImport) => ({
          name: namedImport.getName(),
          alias: namedImport.getAliasNode()?.getText(),
        })),
        defaultImport: it.getDefaultImport()?.getText(),
      });
    });
    imports.forEach((it) => it.remove());
  }

  generate(
    concreteStructure: ConcreteContracts.ConcreteStructure,
    generateDir?: string,
  ) {
    this.#morphProject = getMorph(generateDir);

    const sourceFiles: Record<string, morph.SourceFile> = {};
    for (const feature of concreteStructure.features) {
      feature.structures.forEach((it) => {
        sourceFiles[it.filePath] ??= this.#morphProject.createSourceFile(
          it.filePath,
          '',
          {
            overwrite: true,
          },
        );
        const structure =
          'actionStructure' in it.structure
            ? [
                ...it.structure.actionStructure,
                ...it.structure.topLevelStructure,
              ]
            : it.structure;
        sourceFiles[it.filePath].addStatements(structure);
      });

      for (const table of feature.tables) {
        const entityfile = this.#morphProject.createSourceFile(
          this._projectFS.makeEntityPath(
            feature.displayName,
            table.tableName,
            'entity',
          ),
          '',
          { overwrite: true },
        );
        entityfile.addStatements(table.entityStructure);

        for (const contract of table.fields) {
          entityfile
            .getClassOrThrow(contract.tableName)
            .addProperty(contract.fieldStructure);
          entityfile.addStatements(contract.topLevelStructure);
        }
      }

      for (const workflow of feature.workflows ?? []) {
        workflow.forEach((it) => {
          sourceFiles[it.filePath] ??= this.#morphProject.createSourceFile(
            it.filePath,
            '',
            { overwrite: true },
          );
          const structure =
            'actionStructure' in it.structure
              ? [
                  ...it.structure.actionStructure,
                  ...it.structure.topLevelStructure,
                ]
              : it.structure;
          sourceFiles[it.filePath].addStatements(structure);
        });
      }
    }
    for (const policy of concreteStructure.policies) {
      policy.forEach((policy) => {
        sourceFiles[policy.filePath] ??= this.#morphProject.createSourceFile(
          policy.filePath,
          '',
          {
            overwrite: true,
          },
        );
        const structure =
          'actionStructure' in policy.structure
            ? (() => {
                return [
                  ...policy.structure.actionStructure,
                  ...policy.structure.topLevelStructure,
                ];
              })()
            : policy.structure;

        sourceFiles[policy.filePath].addStatements(structure);
      });
    }
    for (const it of concreteStructure.setup) {
      const sourceFile =
        this.#morphProject.getSourceFile(it.filePath) ??
        this.#morphProject.createSourceFile(it.filePath, '', {
          overwrite: true,
        });
      if (it.filePath.endsWith('.ts')) {
        sourceFile.removeStatements([0, sourceFile.getStatements().length]);
      }
      sourceFile.addStatements(it.content);
    }
  }

  getOutput() {
    this.#resolveImports();
    this.#exportEntites();
    this.#exportListeners();
    this.#exportJobs();
    this.#exportRoutes();

    const morphFiles = this.#morphProject.getSourceFiles();
    const files = morphFiles.map((file) => {
      if (file.getFilePath().endsWith('.ts')) {
        this.#tuneImports(file);
        this.#moveImportsToTop(file);
        this.#removeUnusedImports(file);
      }

      // if (file.getFilePath().endsWith('.ts')) {
      //   file.fixMissingImports(); // FIXME: remove this because it's so slow
      // }
      // file.organizeImports();
      // NOTE: SourceFile.formatText() duplicates code so don't user it

      return {
        path: file.getFilePath(),
        content: file.getFullText(),
      };
    });
    return files;
  }

  cleanup() {
    this.#morphProject.getSourceFiles().forEach((file) => {
      file.forget();
    });
  }

  emit() {
    return this.#morphProject.save();
  }
}

function getLastNParts(path: string, n: number, withExt = true) {
  const result = path.split(sep).slice(-n).join(sep);
  return withExt ? result : result.replace(extname(result), '');
}

export function emitFiles(
  concreteStructure: ConcreteContracts.ConcreteStructure,
  projectFS: ProjectFS,
  generateDir?: string,
) {
  const vProject = new VirtualProject(projectFS);
  vProject.generate(concreteStructure, generateDir);
  return {
    files: vProject.getOutput(),
    save: () => vProject.emit(),
    cleanup: () => vProject.cleanup(),
  };
}

export function emitFilesNoConfig(
  concreteStructure: ConcreteContracts.ConcreteStructure,
  generateDir?: string,
) {
  const projectFs = Injector.GetRequiredService(ProjectFS);
  const projectConfig = Injector.GetRequiredService(ProjectConfig);
  const vProject = new VirtualProject(projectFs);
  if (generateDir) {
    const config = projectConfig.getConfig();
    projectConfig.updateConfig({
      basePath: join(generateDir, config.basePath),
      features: join(generateDir, config.features),
    });
  }

  vProject.generate(concreteStructure, generateDir);
  return {
    files: vProject.getOutput(),
    save: () => vProject.emit(),
    cleanup: () => vProject.cleanup(),
  };
}
