export type WithChildren<T extends { id: string }> = T & {
  children: WithChildren<T>[];
  data: Record<string, any>;
};

export interface WithSequence {
  sequence: string;
  parentSequence?: string;
  siblingSequence?: string;
}
export interface SourceTarget {
  source: string;
  target: string;
  data: Record<string, any>;
}

export interface ITree<T extends WithSequence = WithSequence> {
  source?: T;
  target?: T;
  paths: ITree<T>[];
  type: 'root' | 'leaf' | 'branch';
  orphan?: boolean;
  self: T;
}
export interface IBetterTree<T> {
  id: string;
  children: IBetterTree<T>[];
  data: Record<string, any>;
}

export interface Edge<T extends WithSequence> {
  source: T;
  target: T;
  path: boolean;
}

export class LinearTree<T extends WithSequence> {
  line: ITree<T>[] = [];
  orphans: T[] = [];

  constructor(root: T) {
    this.line.push({
      self: root,
      source: undefined,
      target: undefined,
      paths: [],
      type: 'root',
    });
  }

  #findParent(node: T) {
    if (!node.parentSequence) {
      return this.line;
    }

    const stack = [...this.line];
    while (stack.length) {
      const subtree = stack.pop()!;
      if (node.parentSequence === subtree.self.sequence) {
        return subtree.paths;
      }
      stack.push(...subtree.paths);
    }

    return null;
  }

  addNode(node: T) {
    const parent = this.#findParent(node);

    switch (true) {
      case !parent:
        this.orphans.push(node);
        return;
      case parent?.length === 0:
        parent.push({
          self: node,
          source: undefined,
          target: undefined,
          paths: [],
          type: 'leaf',
        });
        this.#tryAddOrphans();
        return;
      case !node.siblingSequence:
        // at this point we know that the parent is not empty
        // and the node has no sibling
        parent.push({
          self: node,
          source: undefined,
          target: undefined,
          paths: [],
          type: 'leaf',
        });
        this.#tryAddOrphans();
        return;
    }

    for (const branch of parent) {
      if (node.siblingSequence === branch.self.sequence) {
        // sibiling found, good. now need to add it after the sibiling
        const index = parent.findIndex(
          (it) => it.source?.sequence === branch.source?.sequence,
        );

        const sourceSibiling = parent[index];
        const targetSibiling = parent[index + 1];
        sourceSibiling.target = node;

        // NOTE: if the node have same sequance sibling, it'll conflict
        parent.splice(index + 1, 0, {
          self: node,
          source: sourceSibiling.self,
          target: targetSibiling?.self,
          paths: [],
          type: 'leaf',
        });

        this.#tryAddOrphans();

        return;
      }
      if (node.sequence === branch.self.siblingSequence) {
        // sibiling found, good. now need to add it before the sibiling
        const index = parent.findIndex(
          (it) => it.source?.sequence === branch.source?.sequence,
        );

        const sibiling = parent[index];

        sibiling.target = node;

        parent.splice(index - 1, 0, {
          self: node,
          source: sibiling.self,
          target: undefined,
          paths: [],
          type: 'leaf',
        });

        this.#tryAddOrphans();

        return;
      }
    }

    this.orphans.push(node);
  }

  #tryAddOrphans() {
    // check if there are orphans that can be added
    const clone = [...this.orphans];
    this.orphans = [];
    clone.forEach((orphan) => this.addNode(orphan));
  }

  connect(source: WithSequence, target: WithSequence) {
    //
  }

  toJson() {
    //
  }

  toEdges(stack = [...this.line], path = false) {
    const edges: Edge<T>[] = [];

    while (stack.length) {
      const subtreeTarget = stack.pop();
      if (!subtreeTarget || !subtreeTarget.source) {
        continue;
      }
      const subtreeSource = stack.at(-1)!;
      edges.push({
        source: subtreeSource.self,
        target: subtreeTarget.self,
        path: path,
      });
      if (subtreeTarget.paths) {
        const vnodes = subtreeTarget.paths
          .map((it) => [
            {
              ...subtreeTarget,
              source: undefined, // remove the source as this acts as root to its paths
            },
            { ...it, source: subtreeTarget.self },
          ])
          .flat();
        edges.push(...this.toEdges(vnodes, true));
      }
    }

    return edges;
  }

  pretty() {
    for (const orphan of this.orphans) {
      const parent = this.#findParent(orphan) ?? [...this.line];
      parent.push({
        self: orphan,
        source: undefined,
        target: undefined,
        paths: [],
        type: 'leaf',
        orphan: true,
      });
    }
    return this.line;
  }
}

export class BetterTree<T extends SourceTarget> {
  line: IBetterTree<T>[] = [];
  orphans: T[] = [];

  constructor(
    private start: {
      source: string;
      nodes: T[];
    },
  ) {
    this.start.nodes.forEach((node) => this.addNode(node));
  }

  #findParent(node: T) {
    const stack = [...this.line];
    while (stack.length) {
      const subtree = stack.pop()!;
      if (node.source === subtree.id) {
        return subtree;
      }
      stack.push(...subtree.children);
    }

    return null;
  }

  #tryAddOrphans() {
    // check if there are orphans that can be added
    const clone = [...this.orphans];
    this.orphans = [];
    clone.forEach((orphan) => this.addNode(orphan));
  }

  addNode(node: T) {
    const [root] = this.line;
    if (!root) {
      const maybeRoot = this.start.source === node.source ? node : null;
      if (maybeRoot) {
        this.line.push({
          id: node.source,
          children: [],
          data: node.data,
        });
        this.line.push({
          id: node.target,
          children: [],
          data: node.data,
        });
        this.#tryAddOrphans();
        return;
      } else {
        this.orphans.push(node);
        return;
      }
    }

    if (node.data?.['child']) {
      const parent = this.#findParent(node);
      if (parent) {
        parent.children.push({
          id: node.target,
          children: [],
          data: node.data,
        });
        this.#tryAddOrphans();
      } else {
        this.orphans.push(node);
      }
      return;
    } else {
      const last = this.line.at(-1);
      if (!last) {
        throw new Error(`No parent found for ${node.source}`);
      }
      if (last.id === node.source) {
        this.line.push({
          id: node.target,
          children: [],
          data: node.data,
        });
        this.#tryAddOrphans();
      } else {
        this.orphans.push(node);
      }
    }
  }

  map<R extends { id: string }>(
    mapFn: (id: string) => R,
    array = [...this.line],
  ) {
    const list: WithChildren<R>[] = [];
    while (array.length) {
      const node = array.shift()!;
      list.push({
        ...node,
        ...mapFn(node.id),
        children: this.map(mapFn, [...node.children]),
        data: node.data,
      });
    }
    return list;
  }
}

// const tree = new BetterTree<SourceTarget>({
//   source: '7986d237-e6e1-4ae4-8b34-fd677e63028a',
//   nodes,
// });

// console.dir(tree.line, {
//   showHidden: false,
//   depth: Infinity,
//   maxArrayLength: Infinity,
//   colors: true,
// });
