import { AggregateRoot, DomainEventMetadata } from '@faslh/api/infrastructure';
import { camelcase } from '@faslh/utils';

import {
  FeatureAdded,
  FeatureAddedData,
  FeatureDeleted,
  FeatureDeletedData,
  QueryAdded,
  QueryAddedData,
  QueryDelocalized,
  QueryLocalized,
  QueryLocalizedData,
  TagAdded,
  TagAddedData,
} from '../events';
import { FeatureTag } from './feature-tag';
import { Query } from './query';
import { Workflow } from './workflow';

type Events = QueryAdded | TagAdded | FeatureAdded | FeatureDeleted;

export class Feature extends AggregateRoot<Events> {
  #queries: Record<string, Query> = {};
  #workflows: Record<string, Workflow> = {};
  #tags: Record<string, FeatureTag> = {};
  displayName!: string;
  deleted = false;

  public static override readonly streamName = 'feature';
  public override readonly streamName = Feature.streamName;

  public static create(
    id: string,
    metadata: DomainEventMetadata,
    data: FeatureAddedData,
  ) {
    const event = new FeatureAdded();

    event.aggregateId = id;
    event.entityId = id;

    event.data = data;
    event.metadata = metadata;

    const aggregate = new Feature(id);
    aggregate.applyChanges(event);

    return aggregate;
  }

  public override apply(event: Events) {
    switch (event.eventType) {
      case 'feature_added':
        this.displayName = event.data.displayName;
        break;
      case 'query_added':
        this.#queries[event.entityId] = new Query(event.entityId);
        this.#queries[event.entityId].loadFromHistory([event]);
        break;
      case 'tag_added':
        this.#tags[event.entityId] = new FeatureTag({
          id: event.entityId,
          displayName: event.data.displayName,
        });
        break;
      case 'feature_deleted':
        this.deleted = true;
        break;
      default:
        break;
    }
  }

  public delete(metadata: DomainEventMetadata, data: FeatureDeletedData) {
    const event = new FeatureDeleted();
    event.aggregateId = this.id;
    event.entityId = this.id;
    event.metadata = metadata;
    event.data = data;
    this.applyChanges(event);
  }

  public addQuery(metadata: DomainEventMetadata, data: QueryAddedData) {
    const { aggregate, event } = Query.create(metadata, data);
    this.applyChanges(event, true);
    return aggregate;
  }

  public getQuery(id: string) {
    return this.#queries[id];
  }

  public hasQuery(id: string) {
    return !!this.#queries[id];
  }

  public queryNameExist(displayName: string) {
    return Object.values(this.#queries).some(
      (it) => camelcase(it.displayName) === camelcase(displayName),
    );
  }

  public localizeQuery(
    metadata: DomainEventMetadata,
    data: QueryLocalizedData,
  ) {
    const event = new QueryLocalized();
    event.entityId = data.queryId;
    event.aggregateId = this.id;
    event.data = data;
    event.metadata = metadata;
    // this.applyChanges(event);
  }

  public delocalizeQuery(
    metadata: DomainEventMetadata,
    data: QueryLocalizedData,
  ) {
    const event = new QueryDelocalized();
    event.entityId = data.queryId;
    event.aggregateId = this.id;
    event.data = data;
    event.metadata = metadata;
    // this.applyChanges(event);
  }

  public addTag(id: string, metadata: DomainEventMetadata, data: TagAddedData) {
    const event = new TagAdded();

    event.entityId = id;
    event.aggregateId = this.id;

    event.data = data;
    event.metadata = metadata;

    this.applyChanges(event);

    return this.#tags[event.entityId];
  }

  public get tags() {
    return Object.values(this.#tags);
  }

  /**
   * @deprecated this will always return an empty array. use workflow aggregate to get workflows
   */
  public get workflows() {
    return Object.values(this.#workflows);
  }
}
