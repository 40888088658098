import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { ChangeWorkflowTriggerCommand } from '@faslh/api/table/commands';
import {
  Feature,
  Workflow,
  WorkflowTriggerChangedData,
} from '@faslh/api/table/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(ChangeWorkflowTriggerCommand)
export default class extends IRequestHandler<
  ChangeWorkflowTriggerCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
    private readonly _devKit: DevKit,
  ) {
    super();
  }

  async handle(command: ChangeWorkflowTriggerCommand): Promise<string> {
    const newRefTrigger = await this._devKit.getTriggerById(command.sourceId);

    if (!newRefTrigger) {
      throw new Error(`Trigger with id ${command.sourceId} not found`);
    }
    const feature = await this._eventStore.hyrdateAggregate(
      command.featureId,
      Feature,
    );

    const workflow = await this._eventStore.hyrdateAggregate(
      command.workflowId,
      Workflow,
    );

    // FIXME: send event using hook manager with the event name and let the installed routing extension verifies this operation
    // instead of doing it here
    if (newRefTrigger.name === 'http') {
      for (const it of feature.workflows) {
        const refTrigger = await this._devKit.getTriggerById(
          it.trigger.sourceId,
        );

        if (!refTrigger) {
          throw new Error(
            `Cannot find trigger for existing action. Trigger ID: ${it.trigger.id}`,
          );
        }

        if (refTrigger?.name === 'http') {
          if (command.details['path'] === it.trigger.details['path']) {
            if (command.details['method'] === it.trigger.details['method']) {
              throw new Error(
                `Workflow with trigger ${it.trigger.id} already exists and has the same path and method`,
              );
            }
          }
        }
      }
    }

    workflow.changeTrigger(
      this._eventUtils.getMetadata(),
      mapper.map(
        command,
        ChangeWorkflowTriggerCommand,
        WorkflowTriggerChangedData,
      ),
    );

    await this._eventStore.save(workflow);
    return workflow.id;
  }
}
