import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEventData, createEvent } from '@faslh/api/infrastructure';

const event = 'extension_uninstalled' as const;

export class ExtensionUninstalledData extends DomainEventData {}

export const ExtensionUninstalled = createEvent<
  ExtensionUninstalledData,
  typeof event
>(event);

export type ExtensionUninstalled = InstanceType<typeof ExtensionUninstalled>;
