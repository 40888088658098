import { AutoMap } from '@automapper/classes';
import { IsNotEmpty } from 'class-validator';

import { AutoMapHost } from './mapper';

export type FieldValidationType =
  | 'longitude'
  | 'latitude'
  | 'email'
  | 'contains'
  | 'tel'
  | 'optional'
  | 'required'
  | 'string'
  | 'number'
  | 'decimal'
  | 'url'
  | 'uuid'
  | 'matches'
  | 'datetime'
  | 'min'
  | 'max'
  | 'minlength'
  | 'minlength'
  | 'maxlength'
  | 'startwith'
  | 'endwith'
  | 'boolean';

@AutoMapHost()
export class FieldValidation {
  @AutoMap(() => Object)
  @IsNotEmpty()
  details!: Record<string, any>;

  @AutoMap()
  @IsNotEmpty()
  sourceId!: string;

  @AutoMap()
  @IsNotEmpty()
  name!: string;
}
