import { buildProjection } from '@faslh/api/infrastructure/database';
import type { SecretSet } from '@faslh/api/settings/domain';

import type { SecretsProjection } from './secrets.projection';

type Events = SecretSet;

function reducer(state: SecretsProjection, event: Events): SecretsProjection {
  switch (event.eventType) {
    case 'secret_set':
      state.createdAt = event.timestamp;
      state.updatedAt = event.timestamp;
      state.displayName = event.data.displayName;
      state.name = event.data.name;
      return state;
    default:
      return state;
  }
}

export const buildSecretsProjection = (
  initialProjection: SecretsProjection,
  events: Events[],
) => buildProjection(initialProjection, events, reducer);
