import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

import { DomainEvent, DomainEventData } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class MemberAddedData extends DomainEventData {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  id!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  orgId!: string;
}

@AutoMapHost()
export class MemberAdded extends DomainEvent<MemberAddedData, 'member_added'> {
  public eventType = 'member_added' as const;

  @AutoMap(() => MemberAddedData)
  public data!: MemberAddedData;
}
