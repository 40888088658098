import { AutoMap } from '@automapper/classes';
import { IsBoolean, IsNotEmpty, IsString, Matches } from 'class-validator';



import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';


@AutoMapHost()
export class DeleteFeatureCommand extends IRequest {
  @Matches(/^[A-Za-z_ ]+$/)
  @AutoMap()
  @IsNotEmpty()
  public displayName!: string;

  @AutoMap()
  @IsBoolean()
  public failSafe!: boolean;
}