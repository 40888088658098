import yaml from 'js-yaml';
import { Context, Injectable, Injector, ServiceLifetime } from 'tiny-injector';

import { Contracts, IExtension } from '@faslh/compiler/contracts';
import { DevKit, ProjectFS } from '@faslh/compiler/sdk/devkit';

import { AbstractInputParser } from '../registry/abstract-input-parser';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class VercelExtension implements IExtension {
  constructor(
    private readonly _projectFS: ProjectFS,
    private readonly _context: Context,
    private readonly _devKit: DevKit,
  ) {}

  private get _inputParser() {
    return Injector.GetRequiredService(AbstractInputParser, this._context);
  }

  async handleSetup(
    inputs: Record<string, any>,
  ): Promise<Contracts.ExtensionSetupContract[]> {
    const routingExt = await this._inputParser.routingExtension();
    return [
      {
        filePath: this._projectFS.makeRootPath('.github/workflows/deploy.yml'),
        content: [
          deployContent(
            // rZ9aCDcjqdy5WcNH7aEFEd3C
            // {"token":"rZ9aCDcjqdy5WcNH7aEFEd3C", "projectId":"prj_pJIBRbeDIacUqmeoCce0BMdcvgAB","orgId":"team_bQEldc2p1yCwDSZm0Xb6dnFU"}
            inputs['VERCEL_API_TOKEN'].value,
            inputs['VERCEL_PROJECT_ID'].value,
            inputs['VERCEL_ORG_ID'].value,
          ),
        ],
      },
      {
        filePath: this._projectFS.makeSrcPath('server.ts'),
        content: [routingExt.extension.getServerSetup(true, 'vercel')],
      },
      {
        filePath: this._projectFS.makeRootPath('vercel.json'),
        content: [
          this._devKit.toJson({
            $schema: 'https://openapi.vercel.sh/vercel.json',
            version: 2,
            installCommand: 'npm install --omit=dev --no-audit --no-fund',
            buildCommand: 'npm run build:remote',
            devCommand: 'npm run start:prod',
            outputDirectory: '',
            builds: [
              {
                src: 'build/server.js',
                use: '@vercel/node',
              },
            ],
            routes: [
              {
                src: '/(.*)',
                dest: '/build/server.js',
              },
            ],
          }),
        ],
      },
    ];
  }
}

const deployContent = (appToken: string, projectId: string, orgId: string) => {
  return yaml.dump(
    {
      name: 'Deploy To Vercel',
      on: {
        push: {
          branches: ['main'],
        },
        workflow_dispatch: {},
      },
      env: {
        VERCEL_ORG_ID: `\${{ secrets.${orgId} }}`,
        VERCEL_PROJECT_ID: `\${{ secrets.${projectId} }}`,
      },
      jobs: {
        deploy_to_vercel: {
          'runs-on': 'ubuntu-latest',
          steps: [
            {
              id: 'checkout',
              name: 'checkout App Repo',
              uses: 'actions/checkout@v3',
            },
            // FIXME: Removing this will give us few seconds back
            {
              id: 'install_lockfile',
              name: 'Install Lockfile',
              run: 'npm install --package-lock-only --no-audit --no-fund',
            },
            {
              id: 'setup_node',
              uses: 'actions/setup-node@v3',
              name: 'Setup Node.js',
              with: {
                'node-version': '20',
                cache: 'npm',
              },
            },
            {
              name: 'Install Vercel CLI',
              run: 'npm install --global vercel@latest --no-audit --no-fund',
            },
            {
              name: 'Pull Vercel Environment Information',
              run: `vercel pull --yes --environment=production --token=\${{ secrets.${appToken} }}`,
            },
            {
              name: 'Build Project Artifacts',
              run: `vercel build --prod --token=\${{ secrets.${appToken} }}`,
            },
            {
              name: 'Deploy Project Artifacts to Vercel',
              run: `vercel deploy --prebuilt --prod --token=\${{ secrets.${appToken} }}`,
            },
          ],
        },
      },
    },
    {
      lineWidth: -1,
      skipInvalid: false,
      noRefs: true,
      noCompatMode: true,
      schema: yaml.JSON_SCHEMA,
    },
  );
};
