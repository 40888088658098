import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsString } from 'class-validator';

export class DeleteFieldCommand {
  @AutoMap()
  @IsNotEmpty()
  @IsString()
  displayName!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  tableId!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  tableName!: string;
}
