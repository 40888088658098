import { groupBy } from 'lodash';
import { Context, Injectable, ServiceLifetime } from 'tiny-injector';
import { ClassType } from 'tiny-injector/Types';

import {
  AggregateRoot,
  DomainEvent,
  DomainEventData,
  DomainEventMetadata,
} from '@faslh/api/infrastructure';
import {
  INotificationHandler,
  IRequest,
  IRequestHandler,
  NotificationHandler,
  RequestHandler,
} from '@faslh/tiny-mediatr';
import { profile } from '@faslh/utils';

import {
  AbstractEventStore,
  AbstractStore,
  Filter,
} from './stores/abstract.store';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class EventStore {
  constructor(
    private _store: AbstractStore,
    private _eventStore: AbstractEventStore,
  ) {}

  /**
   * Order events by causationId
   * It doesn't guarantee the order of events that have the same causationId
   */
  private _orderByCausationId(
    events: DomainEvent<any, any>[],
  ): DomainEvent<any, any>[] {
    return events;
    const orderedEvents: DomainEvent<any, any>[] = [];

    while (events.length > 0) {
      let foundParent = false;
      for (let i = 0; i < events.length; i++) {
        const currentEvent = events[i];
        const parentEventIndex = events.findIndex(
          (it) => it.id === currentEvent.metadata.causationId,
        );

        if (parentEventIndex > -1) {
          // The parent exists
          const parentEvent = events[parentEventIndex];
          orderedEvents.push(parentEvent);
          events = [
            ...events.slice(0, parentEventIndex),
            ...events.slice(parentEventIndex + 1),
          ];
          foundParent = true;
          break;
        }
      }

      if (!foundParent) {
        // No parent found, push the next event and remove it from the events array
        orderedEvents.push(events[0]);
        events = events.slice(1);
      }
    }

    return orderedEvents;
  }

  public async save<T extends AggregateRoot<any>>(aggregate: T) {
    await profile({ label: aggregate.streamName }, () => {
      return this._eventStore.save(aggregate);
    });
  }

  public async getEventsForStreamName(
    streamName: string | { streamName: string },
    filter?: Filter['where'],
  ) {
    const docs = await this._store.find<DomainEvent<any, any>>({
      tableName: 'Events',
      where: {
        streamName:
          typeof streamName === 'string' ? streamName : streamName.streamName,
        ...filter,
      },
    });

    return this._orderByCausationId([
      ...mapData(docs),
      ...(this.getPendingEvents().filter(
        (it) => it.streamName === streamName,
      ) as DomainEvent<any, any>[]),
    ]);
  }

  public async getEventsForAggregate(id: string, pendingOnly = false) {
    if (pendingOnly) {
      return this._orderByCausationId(
        this.getPendingEvents().filter(
          (it) => it.aggregateId === id,
        ) as DomainEvent<any, any>[],
      );
    }

    const docs = await this._store.find<
      DomainEvent<DomainEventData, unknown, DomainEventMetadata>
    >({
      tableName: 'Events',
      where: { aggregateId: id },
    });

    return this._orderByCausationId([
      ...mapData(docs),
      ...(this.getPendingEvents().filter(
        (it) => it.aggregateId === id,
      ) as DomainEvent<any, any>[]),
    ]);
  }

  public async getEventsOfEventType<T extends DomainEvent<any, any>>(
    eventType: string | { eventType: string },
    streamName?: string | { streamName: string },
    filter: Omit<Filter['where'], 'eventType' | 'streamName'> = {},
  ) {
    eventType = typeof eventType === 'string' ? eventType : eventType.eventType;
    const docs = await this._store.find<T>({
      tableName: 'Events',
      where: {
        eventType,
        streamName:
          typeof streamName === 'string' ? streamName : streamName?.streamName,
        ...filter,
      },
    });

    return this._orderByCausationId([
      ...mapData(docs),
      ...this.getPendingEvents().filter((it) => it.eventType === eventType),
    ]);
  }

  public async getCorrelatedEvents(correlationId: string) {
    const docs = await this._store.find<DomainEvent<any, any>>({
      tableName: 'Events',
      where: { 'metadata.correlationId': correlationId },
    });

    return this._orderByCausationId([
      ...mapData(docs),
      ...this.getPendingEvents().filter(
        (it) => it.metadata.correlationId === correlationId,
      ),
    ]);
  }

  public async getEvents(filter?: Omit<Filter, 'tableName'>) {
    const docs = await this._store.find<DomainEvent<any, any>>({
      tableName: 'Events',
      ...(filter ?? {}),
    });

    return this._orderByCausationId([
      ...mapData(docs),
      ...this.getPendingEvents(),
    ]);
  }

  public getPendingEvents(): DomainEvent<any, any>[] {
    return this._store
      .getPendingDocs()
      .filter((it) => it instanceof DomainEvent);
  }

  async hyrdateAggregate<
    T extends new (...args: any[]) => A,
    A extends AggregateRoot<E>,
    E extends DomainEvent<any, any, any>,
  >(aggregateId: string, aggregateType: T): Promise<InstanceType<T>> {
    const events = (await this.getEventsForAggregate(aggregateId)) as E[];
    const aggregate = new aggregateType(aggregateId);
    aggregate.empty = events.length === 0;
    aggregate.replayEvents(events);
    return aggregate as InstanceType<T>;
  }

  async listAggregates<
    T extends new (...args: any[]) => A,
    A extends AggregateRoot<E>,
    E extends DomainEvent<any, any, any>,
  >(aggregateType: T & { streamName: string }) {
    const events = await this.getEventsForStreamName(aggregateType.streamName);
    const grouped = groupBy(events, 'aggregateId');
    const aggregates: InstanceType<T>[] = [];
    for (const [aggregateId, events] of Object.entries(grouped)) {
      const aggregate = new aggregateType(aggregateId);
      aggregate.empty = events.length === 0;
      aggregate.replayEvents(events as E[]);
      aggregates.push(aggregate as InstanceType<T>);
    }
    return aggregates;
  }
}

export function onEvent<
  T extends ClassType<DomainEvent<any, any, any>>,
>(config: {
  events: T[];
  handler: (event: InstanceType<T>, context: Context) => Promise<void>;
}) {
  // we need inject() to make it work with the DI
  for (const eventType of config.events) {
    @NotificationHandler(eventType)
    class FunctionalNotificationHandler extends INotificationHandler<
      InstanceType<typeof eventType>
    > {
      constructor(private readonly _context: Context) {
        super();
      }
      async handle(event: InstanceType<typeof eventType>): Promise<void> {
        await config.handler(event, this._context);
      }
    }
  }
}

export function onRequest<T extends ClassType<IRequest<Y>>, Y>(config: {
  requestType: T;
  handler: (request: InstanceType<T>, context: Context) => Promise<Y>;
}) {
  @RequestHandler(config.requestType)
  class FunctionalNotificationHandler extends IRequestHandler<
    InstanceType<typeof config.requestType>,
    Y
  > {
    constructor(private readonly _context: Context) {
      super();
    }
    async handle(request: InstanceType<typeof config.requestType>): Promise<Y> {
      const result = await config.handler(request, this._context);
      return result;
    }
  }
  // we need inject() to make it work with the DI
}

function mapData(docs: any[]) {
  return docs.map((it) => {
    if (typeof it.data === 'string') {
      return {
        ...it,
        data: JSON.parse(it.data as string),
      };
    }
    return {
      ...it,
    };
  });
}
