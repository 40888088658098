import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';



import { DomainEventData, createEvent } from '@faslh/api/infrastructure';
import { AutoMapHost } from '@faslh/isomorphic';


@AutoMapHost()
export class WorkflowNameChangedData extends DomainEventData {
  @AutoMap()
  @IsUUID()
  public workflowId!: string;

  @AutoMap()
  @IsNotEmpty()
  @Matches(/^[A-Za-z_ ]+$/)
  public displayName!: string;
}

export const WorkflowNameChanged = createEvent<
  WorkflowNameChangedData,
  'workflow_name_changed'
>('workflow_name_changed');
export type WorkflowNameChanged = InstanceType<typeof WorkflowNameChanged>;