import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { LocalizeFieldCommand } from '@faslh/api/table/commands';
import { FieldLocalizedData, Table } from '@faslh/api/table/domain';
import { mapper } from '@faslh/isomorphic';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(LocalizeFieldCommand)
export class LocalizeFieldCommandHandler extends IRequestHandler<
  LocalizeFieldCommand,
  string
> {
  constructor(
    private readonly _eventStore: EventStore,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  public async handle(command: LocalizeFieldCommand): Promise<string> {
    const aggregate = await this._eventStore.hyrdateAggregate(
      command.tableId,
      Table,
    );

    if (!aggregate.hasField(command.fieldId)) {
      throw new Error(`Field ${command.fieldId} does not exist`);
    }

    aggregate.localizeField(
      this._eventUtils.getMetadata(),
      mapper.map(command, LocalizeFieldCommand, FieldLocalizedData),
    );
    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
