import { groupBy } from 'lodash';
import { Inject, Injectable, ServiceLifetime } from 'tiny-injector';

import {
  type Claims,
  EventStore,
  ProjectionStore,
} from '@faslh/api/infrastructure/database';
import { CLAIMS_TOKEN, Projector } from '@faslh/api/infrastructure/database';
import type {
  ExtensionDetailsPatched,
  ExtensionInstalled,
  ExtensionUninstalled,
} from '@faslh/api/user-extensions/domain';
import { Extension } from '@faslh/api/user-extensions/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';

import type { UserInstalledExtensionProjection } from './extension.projection';
import { userInstalledExtensionProjection } from './extension.projection';

type Events =
  | ExtensionInstalled
  | ExtensionDetailsPatched
  | ExtensionUninstalled;

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class UserInstalledExtensionProjector extends Projector<
  UserInstalledExtensionProjection,
  Events
> {
  protected override projectionName = 'user_extension';
  constructor(
    protected override _projectionStore: ProjectionStore,
    protected override _eventStore: EventStore,
    protected _devKit: DevKit,
    @Inject(CLAIMS_TOKEN) private _claims: Claims,
  ) {
    super();
  }

  protected override async apply(
    state: UserInstalledExtensionProjection,
    event: Events,
  ) {
    switch (event.eventType) {
      case 'extension_installed':
        state.categories = await this._devKit.getExtensionById(event.entityId)
          .categories;
        state.createdAt = event.timestamp;
        state.updatedAt = event.timestamp;
        state.details = JSON.parse(event.data.details);
        state.name = event.data.name;
        state.id = event.entityId;
        state.removed = false;
        return state;
      case 'extension_details_patched':
        state.updatedAt = event.timestamp;
        state.details = {
          ...state.details,
          ...JSON.parse(event.data.details),
        };
        return state;
      case 'extension_uninstalled':
        state.removed = true;
        return state;
      default:
        return state;
    }
  }

  public async getInstalledExtensions() {
    const events = await this._eventStore.getEventsForStreamName(Extension, {
      'metadata.projectId': this._claims.projectId,
    });
    const aggregateIdToEvents = groupBy(events, 'aggregateId');
    const projections: UserInstalledExtensionProjection[] = [];
    for (const [aggregateId, events] of Object.entries(aggregateIdToEvents)) {
      const projection = await this.foldUp(
        userInstalledExtensionProjection(),
        events,
      );
      projections.push(projection);
    }

    return projections;
  }
}
