import { UserInstalledExtensionProjector } from '@faslh/api/user-extensions/projection';

import { EventStore, EventUtils } from '@faslh/api/infrastructure/database';
import { InstallExtensionCommand } from '@faslh/api/user-extensions/commands';
import { Extension } from '@faslh/api/user-extensions/domain';
import { DevKit } from '@faslh/compiler/sdk/devkit';
import { IRequestHandler, RequestHandler } from '@faslh/tiny-mediatr';

@RequestHandler(InstallExtensionCommand)
export class InstallExtensionCommandHandler extends IRequestHandler<
  InstallExtensionCommand,
  string
> {
  constructor(
    private readonly _usedInstalledExtensionProjector: UserInstalledExtensionProjector,
    private readonly _eventStore: EventStore,
    private readonly _devKit: DevKit,
    private readonly _eventUtils: EventUtils,
  ) {
    super();
  }

  async handle(command: InstallExtensionCommand): Promise<string> {
    const sourceExtension = await this._devKit.getExtensionById(
      command.extensionId,
    );

    const projections =
      await this._usedInstalledExtensionProjector.listProjections();
    const installedCategories = projections.map((p) => p.categories).flat();

    const alreadyInstalled =
      await this._usedInstalledExtensionProjector.getProjector(
        command.extensionId,
      );

    if (alreadyInstalled && !alreadyInstalled.removed) {
      throw new Error(
        `Extension ${sourceExtension.title} is already installed.`,
      );
    }

    if (
      (installedCategories.includes('database') &&
        sourceExtension.categories.includes('database')) ||
      (installedCategories.includes('routing') &&
        sourceExtension.categories.includes('routing'))
    ) {
      throw new Error(
        `Extension ${command.extensionId} Category is already installed.`,
      );
    }

    const aggregate = Extension.create(
      this._eventUtils.getMetadata(command),
      command.extensionId,
      {
        name: sourceExtension.name,
        details: JSON.stringify(
          this._devKit.assignDefaults(
            sourceExtension.metadata,
            command.details ?? {},
          ),
        ),
      },
    );

    await this._eventStore.save(aggregate);
    return aggregate.id;
  }
}
