import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID, Matches } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class ChangeActionNameCommand extends IRequest {
  @AutoMap()
  @IsUUID()
  id!: string;

  @AutoMap()
  @IsUUID()
  workflowId!: string;

  @AutoMap()
  @Matches(/^[A-Za-z_ ]+$/)
  @IsNotEmpty()
  displayName!: string;
}
