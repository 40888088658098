import { AutoMap } from '@automapper/classes';
import { IsString, IsUUID } from 'class-validator';

import type { CommitStage } from '@faslh/api/releases/models';
import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class SetCommitPipelineStatusCommand {
  @IsUUID()
  @AutoMap()
  public commitId!: string;

  @IsString()
  @AutoMap(() => String)
  public stage!: CommitStage;
}
