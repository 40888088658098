import { Injectable, ServiceLifetime } from 'tiny-injector';

import { EventStore } from '@faslh/api/infrastructure/database';

@Injectable({
  lifetime: ServiceLifetime.Scoped,
})
export class ChangesRepo {
  constructor(private eventStore: EventStore) {}

  // public async getCommitEvents(lastCommitId: string, newCommitId: string) {
  //   // const lastCommit = await this.events.doc(lastCommitId).get();
  //   const [lastCommit] = (
  //     await this.eventStore.events
  //       .where('eventType', '==', 'initial_commit')
  //       .limit(1)
  //       .get()
  //   ).docs;
  //   const newCommit = await this.eventStore.events.doc(newCommitId).get();
  //   // Get event till the new commit, so anything the user does after the commit is not included
  //   // But what if the user created new commit while the pipeline is running? cancel the running pipeline?

  //   const ref = this.eventStore.events
  //     .orderBy('timestamp', 'asc')
  //     .startAfter(lastCommit.get('timestamp'))
  //     .endBefore(newCommit.get('timestamp'));
  //   const { docs } = await ref.get();
  //   return docs.map((it) => it.data());
  // }

  public async getChangesEvents() {
    // const [initialCommit] = (
    //   await this.eventStore.events
    //     .where('eventType', '==', 'initial_commit')
    //     .limit(1)
    //     .get()
    // ).docs;
    // // const [lastCommit] = (
    // //   await this.eventStore.events.orderBy('timestamp', 'desc').limit(1).get()
    // // ).docs;

    // const ref = this.eventStore.events
    //   .orderBy('timestamp', 'asc')
    //   .startAfter(initialCommit.get('timestamp'));
    // const { docs } = await ref.get();
    return this.eventStore.getEvents();
  }

  // public async getDebugEvents(lastCommitId: string, newCommitId: string) {
  //   // const lastCommit = await this.events.doc(lastCommitId).get();
  //   const [lastCommit] = (
  //     await this.eventStore.events
  //       .where('eventType', '==', 'initial_commit')
  //       .limit(1)
  //       .get()
  //   ).docs;
  //   const [newCommit] = (
  //     await this.eventStore.events
  //       .where('aggregateId', '==', newCommitId)
  //       .limit(1)
  //       .get()
  //   ).docs;
  //   // Get event till the new commit, so anything the user does after the commit is not included
  //   // But what if the user created new commit while the pipeline is running? cancel the running pipeline?

  //   const ref = this.eventStore.events
  //     .orderBy('timestamp', 'asc')
  //     .startAfter(lastCommit.get('timestamp'))
  //     .endBefore(newCommit.get('timestamp'));
  //   const { docs } = await ref.get();
  //   return docs.map((it) => it.data());
  // }

  // public async getLastCommits() {
  //   const lastSuccessedCommit = this.eventStore.events
  //     .where('eventType', 'in', ['commit_completed', 'initial_commit'])
  //     .orderBy('timestamp', 'desc')
  //     .limit(1);
  //   const { docs } = await lastSuccessedCommit.get();
  //   const mapped = docs.map((it) => it.data());
  //   return mapped;
  // }
}
