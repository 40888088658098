import { AutoMap } from '@automapper/classes';
import { IsNotEmpty, IsUUID } from 'class-validator';

import { AutoMapHost } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class CreateTableIndexCommand extends IRequest {
  @AutoMap()
  @IsUUID()
  tableId!: string;

  @AutoMap(() => Object)
  @IsNotEmpty()
  details!: { columns: { input: string }[] };
}
