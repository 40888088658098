import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';



import { AutoMapHost } from '@faslh/isomorphic';

@AutoMapHost()
export class RebuildCommitCommand {
  @IsUUID()
  @AutoMap()
  public commitId!: string;
}