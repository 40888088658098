import { AutoMap } from '@automapper/classes';
import { IsUUID } from 'class-validator';

import { AutoMapHost, FieldValidation } from '@faslh/isomorphic';
import { IRequest } from '@faslh/tiny-mediatr';

@AutoMapHost()
export class AdjustFieldValidationCommand extends IRequest {
  @AutoMap(() => [FieldValidation])
  validations!: FieldValidation[];

  @AutoMap()
  @IsUUID()
  // FIXME: we shouldn't need to specify the table id here
  tableId!: string;

  @AutoMap()
  @IsUUID()
  fieldId!: string;
}
