import { Injector } from 'tiny-injector';

import { onEvent } from '@faslh/api/infrastructure/database';
import {
  FieldAdded,
  FieldDelocalized,
  FieldDetailsUpdated,
  FieldLocalized,
  FieldValidationAdjusted,
  TableAdded,
  TableArchived,
} from '@faslh/api/table/domain';

import { tableProjection } from '../table.projection';
import { TableProjector } from '../table.projector';

onEvent({
  events: [
    TableAdded,
    TableArchived,
    FieldAdded,
    FieldDetailsUpdated,
    FieldValidationAdjusted,
    FieldLocalized,
    FieldDelocalized,
  ],
  handler: async (event, context) => {
    const projector = Injector.GetRequiredService(TableProjector, context);
    const projection = await projector.getProjector(event.aggregateId, {
      defaultState: tableProjection(),
      events: [event],
    });
    projector.saveProjection(event.aggregateId, projection);
  },
});
